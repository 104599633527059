import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isValid, decode } from "js-base64";
import * as actionCreators from "../../../store/actions";
import "../../../../node_modules/video-react/dist/video-react.css";
import WinnerList from "./WinnerList";
import moment from "moment";
import { Typography, Grid, Paper, makeStyles } from "@material-ui/core";
import ReactPlayer from "react-player";
import "./Winner.css";
import { useTranslation } from "react-i18next";
import loading from "../../../store/actions/loading";
import ReactGA from "react-ga";

import useMediaQuery from "@material-ui/core/useMediaQuery";
var winnerId;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: "28px",
    fontFamily: "Almarai-SemiBold",
    padding: "9px 0px",
    color: theme.palette.text.secondary,
  },
  protitle: {
    fontSize: "16px",
    fontFamily: "Almarai-SemiBold",
  },
  winnerdiv: {
    borderRadius: "27px",
    boxShadow: "0px 0px 20px #00000029",
    textAlign: "center",
    padding: "30px",
  },
  ticketTxt: {
    color: theme.palette.text.secondary,
    fontSize: "10px",
    marginBottom: "10px",
  },
  ticketnum: {
    color: theme.palette.text.secondary,
    fontFamily: "Almarai-SemiBold",
    marginBottom: "10px",
  },
  tag: {
    backgroundColor: theme.palette.secondary.main,
    padding: "5px",
    margin: "5px",
    borderRadius: "10px",
    width: "60px",
    textAlign: "center",
    fontFamily: "Almarai-SemiBold",
  },
}));
function WinnerDetails(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const language = useSelector((state) => state.language);
  const winnerDetails = useSelector((state) => state.promotions.winner);
  useEffect(() => {
    dispatch(loading(true));
    init();
  }, []);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const init = () => {
    const url = window.location.href;
    winnerId = url.substring(url.lastIndexOf("/") + 1);
    if (winnerId != undefined) {
      dispatch(loading(true));
      dispatch(actionCreators.loadWinnerDetails(winnerId))
        .then((res) => {
          dispatch(actionCreators.winnerPromotionView(res.promotion_id));
          dispatch(loading());
        })
        .catch((error) => {
          dispatch(loading());
        });
    }
  };

  return (
    <div style={{ backgroundColor: "#3E3E3E" }}>
      <Grid container className="player">
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          style={{ marginBottom: "20px" }}
        >
          <ReactPlayer
            url={winnerDetails?.promotion?.draw_video}
            width="100%"
            height={isMobile ? "200px" : "623px"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} style={{ padding: "0px 3%" }}>
          {/* {winnerDetails?.promotion?.draw_date == "2021-09-15" && (
            <Typography className={classes.tag}>
              {language == "en" ? "NEW" : "الجديد"}
            </Typography>
          )} */}

          <Typography
            variant="h3"
            component="h3"
            dir={language == "en" ? "rtr" : "rtl"}
            className={classes.title}
            dangerouslySetInnerHTML={{
              __html:
                language === "en"
                  ? isValid(
                      winnerDetails?.promotion?.winner_details?.winner_title
                    )
                    ? decode(
                        winnerDetails?.promotion?.winner_details?.winner_title
                      )
                    : winnerDetails?.promotion?.winner_details?.winner_title
                  : isValid(
                      winnerDetails?.promotion?.winner_details?.winner_title_ar
                    )
                  ? decode(
                      winnerDetails?.promotion?.winner_details?.winner_title_ar
                    )
                  : winnerDetails?.promotion?.winner_details?.winner_title_ar,
            }}
          />
          <Typography variant="h6" component="h6"></Typography>
          <Typography
            variant="h6"
            component="h6"
            style={{ marginBottom: "1.35em", color: "#FFFFFF" }}
            dangerouslySetInnerHTML={{
              __html:
                language == "en"
                  ? isValid(
                      winnerDetails?.promotion?.winner_details?.winner_detail
                    )
                    ? decode(
                        winnerDetails?.promotion?.winner_details?.winner_detail
                      )
                    : winnerDetails?.promotion?.winner_details?.winner_detail
                  : isValid(
                      winnerDetails?.promotion?.winner_details?.winner_detail_ar
                    )
                  ? decode(
                      winnerDetails?.promotion?.winner_details?.winner_detail_ar
                    )
                  : winnerDetails?.promotion?.winner_details?.winner_detail_ar,
            }}
          />
          <Paper className={classes.winnerdiv}>
            {/* <Typography
              variant="h6"
              component="h6"
              className={classes.protitle}
              dangerouslySetInnerHTML={{
                __html:
                  language === "en"
                    ? isValid(winnerDetails?.promotion?.title)
                      ? decode(winnerDetails?.promotion?.title)
                      : winnerDetails?.promotion?.title
                    : isValid(winnerDetails?.promotion?.title_ar)
                    ? decode(winnerDetails?.promotion?.title_ar)
                    : winnerDetails?.promotion?.title_ar,
              }}
            /> */}
            <img
              src={winnerDetails?.promotion?.files[0]?.file}
              justifyContent="center"
              width="75%"
              style={{ marginBottom: "11px" }}
            />
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <p className={classes.ticketTxt}>{t("Ticket Number")}</p>
                <p className={classes.ticketnum}>
                  {winnerDetails?.ticket_number}
                </p>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <p className={classes.ticketTxt}>{t("Draw Date")}</p>
                <p className={classes.ticketnum}>
                  {moment(winnerDetails?.promotion?.draw_date).format(
                    "DD/MM/YYYY"
                  )}
                </p>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <WinnerList />
    </div>
  );
}

export default WinnerDetails;

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    padding: "10vh 0px",
    [theme.breakpoints.down("sm")]: { padding: "0px" },
  },
  panel: {
    backgroundColor: theme.palette.primary.main,
    minHeight: "70vh",
    color: "white",
    borderRadius: "24px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: { borderRadius: "0px" },
  },
  detailsGrid: {
    padding: "50px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: { padding: "20px" },
  },
  profileGrid: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minHeight: "150px",
    margin: "40px 0px 20px 0px",
  },
  points: {
    padding: "7px 0px",
    marginBottom: "20px",
    backgroundColor: theme.palette.background.lightgray,
  },
  link: {
    color: theme.palette.secondary.main,
  },
}));

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  makeStyles,
  Tabs,
  Tab,
  Paper,
  Grid,
  Box,
  IconButton,
  Typography,
  Button,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import ProgressBar from "../../common/ProgressBar";
import { isValid, decode } from "js-base64";
import * as actionCreators from "../../../store/actions";
import Slider from "react-slick";
import CurrencyFormat from "react-currency-format";
import "./home.css";
import CountdownTimer from "react-component-countdown-timer";
import moment from "moment";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { getToken } from "../../../services/authService";
import { useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import loginDialog from "../../../store/actions/loginDialog";
import ShareIcon from "@material-ui/icons/Share";
import SocialPopup from "../../common/socialPopup";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import calcCurrency from "../../../utils/CalcCurrency";
import loading from "../../../store/actions/loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { dateFormate } from "../../../services/common";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ReactHtmlParser from "react-html-parser";
import PromotionDialog from "../promotion/PromotionDialog";
import ReactPixel from "react-facebook-pixel";
import analyticsEventTracker from "../../common/analyticsEventTracker";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 224,
  },
  tabs: {
    alignItems: "center",
  },
  closePaper: {
    borderRadius: "37px",
    padding: "20px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 20px 20px",
    },
  },
  discountPer: {
    padding: "6px",
    margin: "0% 4%",
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.background.paper,
    borderRadius: "3px",
    fontFamily: "Almarai-SemiBold",
    fontSize: "9px",
    [theme.breakpoints.down("sm")]: { margin: "0% 9%", whiteSpace: "nowrap" },
  },
  proimg: {
    position: "relative",
    marginTop: "32%",
    [theme.breakpoints.down("sm")]: { marginTop: "0%", padding: "0% 13%" },
  },
  batchimg_en: {
    position: "absolute",
    top: "4%",
    left: "-3.5%",
    width: "54%",
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: "2%",
      left: "-3.1%",
      width: "47%",
    },
  },
  batchimg_ar: {
    position: "absolute",
    top: "4%",
    right: "-3%",
    width: "54%",
  },
  promotitle: {
    width: "99%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "8px",
    color: theme.palette.text.secondary,
  },
  drawdate: {
    fontSize: "11px",
    color: theme.palette.text.secondary,
    fontFamily: "Almarai-Regular",
    textAlign: "center",
    textTransform: "uppercase",
    lineHeight: "1.1rem",
    paddingBottom: "4%",
    fontWeight: "normal",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "1rem",
    },
  },
  promotitle1: {
    fontFamily: "Almarai-Bold",
    color: theme.palette.secondary.main,
    paddingBottom: "5px",
    fontSize: "18px",
    lineHeight: "1.5rem",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: { fontSize: "12px", lineHeight: "1rem" },
  },
  promotitle2: {
    fontSize: "14px",
    lineHeight: "1.1rem",
    height: "48px",
    color: theme.palette.text.secondary,
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    fontFamily: "Almarai-SemiBold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "1rem",
      height: "42px",
    },
  },
  promoPrice: {
    fontFamily: "Almarai-SemiBold",
    fontSize: "22px",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: { fontSize: "16px" },
  },
  promoPrice1: {
    fontFamily: "Almarai-SemiBold",
    fontSize: "16px",
    color: theme.palette.secondary.main,
    textDecoration: "line-through",
    textDecorationThickness: "2px",
  },
  promobtn: {
    width: "100%",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "16px",
    padding: "5px 16px",
    textTransform: "uppercase",
    fontFamily: "Almarai-SemiBold",
    whiteSpace: "nowrap",
    color: theme.palette.text.black,
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: { fontSize: "14px" },
  },
  title: {
    fontSize: "30px",
    fontFamily: "Almarai-Bold",
    padding: "10px 6%",
    [theme.breakpoints.down("sm")]: { fontSize: "23px", alignItems: "center" },
  },
  subtitle: {
    fontSize: "18px",
    fontFamily: "Almarai-Regular",
    paddingTop: "1%",
  },
  ticketCount: {
    margin: "7px 3px",
    color: "#FFFEFE",
    display: "flex",
    justifyContent: "space-between",
    textTransform: "uppercase",
    fontWeight: "normal",
    [theme.breakpoints.down("sm")]: { fontSize: "10px" },
  },
}));

function PromotionList(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const gaEventTracker = analyticsEventTracker("Home Page");
  const language = useSelector((state) => state.language);
  const currency = useSelector((state) => state.currency);
  const [value, setValue] = React.useState("");
  const [promoName, setPromoName] = React.useState(
    language === "en" ? "All" : "جميع الفئات"
  );
  const user = useSelector((state) => state.user?.user);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);
  const [socialPopup, setSocialPopup] = useState(false);
  const [promotionData, setPromotionData] = useState();
  const classes = useStyles();
  const promotions = useSelector((state) => state.promotions);
  const time = new Date();
  time.setSeconds(time.getSeconds() + 600);
  var componentRef = useRef();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [promotionDetailsOpen, setPromotionDetailsOpen] = React.useState(false);
  const [proId, setProId] = React.useState("");
  const [fav, setFavUpdate] = React.useState("");
  const promotionDetailsHandleOpen = (id) => {
    if (isMobile) {
      loadPromotion(id);
    } else {
      setProId(id);

      setPromotionDetailsOpen(true);
    }
  };

  const promotionDetailsClose = () => {
    setPromotionDetailsOpen(false);
  };

  useEffect(() => {
    setPromoName(language === "en" ? "All" : "جميع الفئات");
  }, [language]);

  const handleSocialShare = (item) => {
    var data = {
      promotion_id: item.promotion_id,
      title:
        language === "en"
          ? ReactHtmlParser(decode(item?.draw_title).replace(/<[^>]+>/g, "")) +
            "\n" +
            ReactHtmlParser(decode(item?.draw_detail).replace(/<[^>]+>/g, ""))
          : ReactHtmlParser(
              decode(item?.draw_title_ar).replace(/<[^>]+>/g, "")
            ) +
            "\n" +
            ReactHtmlParser(
              decode(item?.draw_detail_ar).replace(/<[^>]+>/g, "")
            ),
    };
    gaEventTracker("Share", isLoggedIn ? user.user_id : "Guest");
    setPromotionData(data);
    setSocialPopup(true);
  };
  const handleChange = (event, item) => {
    dispatch(loading(true));
    if (item === "") {
      setPromoName(language === "en" ? "All" : "جميع الفئات");
    } else {
      setPromoName(
        language === "en" ? item.category_name : item.category_name_ar
      );
    }
    setValue(item);
    dispatch(actionCreators.loadPromotions(1, item.category_id))
      .then((res) => {
        ReactPixel.track("Search", {
          search_string: item.category_name,
          Email: isLoggedIn ? user.email : "Guest",
          External_id: isLoggedIn ? user.user_id : "Guest",
        });

        gaEventTracker("Search", isLoggedIn ? user.user_id : "Guest");
        dispatch(loading());
      })
      .catch((error) => {});
  };

  const timeDiffCalc = (date1, show_timer, promotion_id) => {
    var dateNow = new Date();
    var dateFuture1 = moment(date1).format("YYYY/MM/DD HH:mm:ss");
    var dateFuture = new Date(dateFuture1);
    var dif = dateFuture - dateNow;
    var minutes = Math.round(dif / 1000 / 60);
    var milesec = minutes * 60;
    var hours = minutes / 60;

    if (promotion_id == "70") {
    }
    if (minutes > 0 && hours < 48) {
      var showtime = {
        show: show_timer,
        time: milesec,
      };
      return showtime;
    } else {
      var showtime = {
        show: false,
        time: "",
      };
      return showtime;
    }
  };
  const getPercentage = (ticketNow, totalTicket) => {
    return (ticketNow / totalTicket) * 100;
  };
  const getBarColor = (ticketNow, totalTicket) => {
    if ((ticketNow / totalTicket) * 100 <= 25) {
      return "linear-gradient(0deg, #4dde89, #d1ffe4)";
    } else if (
      (ticketNow / totalTicket) * 100 > 25 &&
      (ticketNow / totalTicket) * 100 <= 75
    ) {
      return "linear-gradient(0deg, #EF942C, #F9D3A2)";
    } else if ((ticketNow / totalTicket) * 100 > 75) {
      return "linear-gradient(0deg, #E74C3C, #FFB3AB)";
    }
  };

  const handleAddFavourite = (event, value) => {
    event.preventDefault();
    if (getToken()) {
      if (value.favourite === "true") {
        value.favourite = "false";
      } else {
        value.favourite = "true";
        dispatch(
          actionCreators.loadPage("web", "AddToWishlist", value.promotion_id)
        );
        ReactPixel.track("AddToWishlist", {
          content_ids: value.promotion_id,
          Email: isLoggedIn ? user.email : "Guest",
          External_id: isLoggedIn ? user.user_id : "Guest",
        });
        gaEventTracker("AddToWishlist", isLoggedIn ? user.user_id : "Guest");
      }
      setFavUpdate(value.favourite);
      var data = {
        action: value.favourite === "true" ? "add" : "remove",
        product_id: value.product_id,
        promotion_id: value.promotion_id,
      };
      dispatch(actionCreators.addToFavourite(data))
        .then(() => {
          dispatch(actionCreators.loadPromotions(1));
        })
        .catch((error) => {});
    } else {
      if (isMobile) navigate("/login");
      else dispatch(loginDialog(event.currentTarget));
    }
  };

  const handleAddToCart = (event, promotionDetails) => {
    let data;
    if (isLoggedIn)
      data = {
        product_id: promotionDetails.product_id,
        promotion_id: promotionDetails.promotion_id,
        product_quantity: promotionDetails.product.product_quantity,
      };
    else
      data = {
        product_id: promotionDetails.product_id,
        promotion_id: promotionDetails.promotion_id,
        product_quantity: promotionDetails.product.product_quantity,
        product: promotionDetails.product,
        promotion: promotionDetails,
      };
    dispatch(
      actionCreators.loadPage("web", "AddToCart", promotionDetails.promotion_id)
    );
    dispatch(actionCreators.addToCart(data))
      .then(() => {
        toast(t("Added to Cart"));
        if (promotionDetails.product.product_quantity > 0) {
          ReactPixel.track("AddToCart", {
            content_type: "Product",
            content_ids: promotionDetails.promotion_id,
            value:
              Number(promotionDetails.product.product_quantity) *
              Number(promotionDetails.ticket_price_with_tax),
            currency: "AED",
            Email: isLoggedIn ? user.email : "Guest",
            External_id: isLoggedIn ? user.user_id : "Guest",
          });

          gaEventTracker("AddToCart", isLoggedIn ? user.user_id : "Guest");
        }

        navigate("/cart");
      })
      .catch(() => {});
  };

  const loadPromotion = (promotion_id) => {
    navigate(`promotion/details/${promotion_id}`);
  };

  const isBase64 = (str) => {
    if (str === "" || str.trim() === "") {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  };
  const getTagImage = (promotion) => {
    return (
      <img
        alt=""
        src={
          language === "en"
            ? promotion.ticket_type.image_en
            : promotion.ticket_type.image_ar
        }
        className={
          language === "en" ? classes.batchimg_en : classes.batchimg_ar
        }
      />
    );
  };
  var settings = {
    className: "center",
    centerMode: isMobile ? true : false,
    infinite: promotions?.promotions?.length > 4 ? true : false,
    speed: 100,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: isMobile ? false : false,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: promotions?.promotions?.length > 3 ? true : false,
        },
      },
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: promotions?.promotions?.length > 2 ? true : false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: promotions?.promotions?.length > 2 ? true : false,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: promotions?.promotions?.length > 1 ? true : false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: promotions?.promotions?.length > 1 ? true : false,
        },
      },
    ],
  };

  function Arrow(props) {
    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " promotionarrow";
    return (
      <span className={className} onClick={props.onClick}>
        <div
          style={{
            position: "relative",
            top: "calc(45%)",
            textAlign: props.type === "next" ? "left" : "right",
          }}
        >
          {props.type === "next" ? (
            <ChevronRightIcon style={{ fontSize: "50px" }} />
          ) : (
            <ChevronLeftIcon style={{ fontSize: "50px" }} />
          )}
        </div>
      </span>
    );
  }
  return (
    promotions.promotions.length > 0 && (
      <div id="promotionDiv">
        <img
          src="https://secure.adnxs.com/px?id=1549083&seg=28965576&t=2"
          width="1"
          height="1"
        />
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Box
              display="flex"
              style={{ paddingTop: "2%" }}
              className={classes.title}
            >
              <Box flexGrow={1}>
                <p>{promoName}</p>
                <p className={classes.subtitle}>
                  {language == "en" ? props.title_en : props.title_ar}
                </p>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1} sm={1}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              aria-label="Vertical tabs example"
              indicatorColor="#e2e2e3"
              value={value}
              onChange={handleChange}
              className={classes.tabs}
              style={{
                paddingTop: "27%",
              }}
            >
              {promotions.categories.length ? (
                <Tab
                  value={""}
                  label={
                    <>
                      <h4>{language === "en" ? "All" : "جميع الفئات"}</h4>
                      <FiberManualRecordIcon fontSize="small" />
                    </>
                  }
                  className={language === "en" ? "protab" : "protab_ar"}
                ></Tab>
              ) : (
                ""
              )}
              {promotions.categories.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    value={item}
                    label={
                      <>
                        <h4>
                          {language === "en"
                            ? item.category_name
                            : item.category_name_ar}
                        </h4>
                        <FiberManualRecordIcon fontSize="small" />
                      </>
                    }
                    className={language === "en" ? "protab" : "protab_ar"}
                  ></Tab>
                );
              })}
            </Tabs>
          </Grid>
          <Grid item xs={11} sm={11}>
            <Slider
              nextArrow={<Arrow type="next" />}
              prevArrow={<Arrow type="prev" />}
              {...settings}
              ref={(el) => (componentRef = el)}
              className="prmolist"
              dir={language === "en" ? "ltr" : "rtl"}
            >
              {promotions.promotions.map((item, index) => {
                var tagImage = getTagImage(item);
                var showTimer = timeDiffCalc(
                  item?.expiry_date,
                  item?.show_timer,
                  item.promotion_id
                );
                var percentage = getPercentage(
                  item?.sold_out_tickets,
                  item?.total_tickets_alloted
                );
                var barColor = getBarColor(
                  item?.sold_out_tickets,
                  item?.total_tickets_alloted
                );
                return (
                  <Paper key={index} className={classes.closePaper}>
                    {item.favourite === "true" ? (
                      <IconButton
                        onClick={(e) => handleAddFavourite(e, item)}
                        style={{ float: "right" }}
                      >
                        <FavoriteIcon color="secondary" />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={(e) => handleAddFavourite(e, item)}
                        style={{ float: "right" }}
                      >
                        <FavoriteBorderIcon color="secondary" />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={(e) => handleSocialShare(item)}
                      style={{ float: "right" }}
                    >
                      <ShareIcon color="secondary" />
                    </IconButton>
                    <div
                      className="promolist"
                      onClick={() =>
                        promotionDetailsHandleOpen(item.promotion_id)
                      }
                    >
                      {tagImage}
                      {showTimer.show == "true" && (
                        <CountdownTimer
                          count={showTimer.time}
                          size={20}
                          hideDay={showTimer.time > 86399 ? false : true}
                          color="#353434"
                          backgroundColor="#F5F8F8"
                        />
                      )}
                      <div className={classes.proimg}>
                        <img
                          alt=""
                          src={
                            parseInt(item.sold_out_tickets) <
                            parseInt(item.total_tickets_alloted)
                              ? item?.draw_image
                              : item?.soldout_image
                              ? item?.soldout_image
                              : item?.draw_image
                          }
                          style={{ width: "100%", marginTop: "10px" }}
                        />
                      </div>

                      <p className={classes.promotitle}>{item.note}</p>
                      <Typography
                        variant="p"
                        component="h2"
                        dir={language == "en" ? "rtr" : "rtl"}
                        className={classes.promotitle1}
                        dangerouslySetInnerHTML={{
                          __html: item
                            ? language === "en"
                              ? decode(item?.draw_title)
                              : decode(item?.draw_title_ar)
                            : "",
                        }}
                      />
                      <Typography
                        variant="p"
                        component="h1"
                        className={classes.promotitle2}
                        dir={language == "en" ? "rtr" : "rtl"}
                        dangerouslySetInnerHTML={{
                          __html: item
                            ? language === "en"
                              ? isValid(item?.draw_detail)
                                ? decode(item?.draw_detail)
                                : decode(item?.draw_detail)
                              : isValid(item?.draw_detail_ar)
                              ? decode(item?.draw_detail_ar)
                              : decode(item?.draw_detail_ar)
                            : "",
                        }}
                      />
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingBottom: "8px",
                        }}
                      >
                        <CurrencyFormat
                          value={
                            currency.value
                              ? calcCurrency(
                                  currency.value,
                                  item?.ticket_price_with_tax
                                )
                              : item?.ticket_price_with_tax
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={` ${currency.label} `}
                          renderText={(value) => (
                            <h3 className={classes.promoPrice}> {value} </h3>
                          )}
                        />
                        {item?.original_price > 0 && (
                          <>
                            <span className={classes.discountPer}>
                              {(
                                100 *
                                ((parseInt(item?.original_price) -
                                  parseInt(item?.ticket_price_with_tax)) /
                                  parseInt(item?.original_price))
                              ).toFixed(0)}
                              % OFF
                            </span>

                            <CurrencyFormat
                              value={
                                currency.value
                                  ? calcCurrency(
                                      currency.value,
                                      item?.original_price
                                    )
                                  : item?.original_price
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={` ${currency.label} `}
                              renderText={(value) => (
                                <div className={classes.promoPrice1}>
                                  {" "}
                                  {value}{" "}
                                </div>
                              )}
                            />
                          </>
                        )}
                      </p>
                      <h4 className={classes.ticketCount}>
                        <span>
                          {language === "en"
                            ? "Tickets Sold"
                            : "عدد التذاكر المباعة"}
                        </span>
                        <span style={{ float: "right" }}>
                          {item.sold_out_tickets} / {item.total_tickets_alloted}
                        </span>
                      </h4>

                      <ProgressBar bgcolor={barColor} completed={percentage} />

                      <Typography
                        variant="p"
                        component="h6"
                        className={classes.drawdate}
                        dangerouslySetInnerHTML={{
                          __html: item
                            ? language === "en"
                              ? decode(item?.draw_note)
                              : decode(item?.draw_note_ar)
                            : "",
                        }}
                      />
                    </div>
                    <Button
                      variant="contained"
                      onClick={(e) => handleAddToCart(e, item)}
                      className={classes.promobtn}
                    >
                      {t("Add to Cart")}
                    </Button>
                  </Paper>
                );
              })}
            </Slider>
          </Grid>
        </Grid>
        <SocialPopup
          HandlePopup={setSocialPopup}
          show={socialPopup}
          url={
            "https://ishro.com/promotion/details/" + promotionData?.promotion_id
          }
          title={promotionData?.title}
        />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Dialog
          open={promotionDetailsOpen}
          fullWidth={true}
          maxWidth={"lg"}
          style={{ borderRadius: "27px" }}
          onClose={promotionDetailsClose}
        >
          <DialogContent
            style={{
              padding: "0px",
              overflowY: "auto",
              height: "81vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PromotionDialog
              promotionId={proId}
              dialog={true}
              promotionDetailsCloseCallback={promotionDetailsClose}
            />
          </DialogContent>
        </Dialog>
      </div>
    )
  );
}

export default PromotionList;

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    padding: "10vh 0px",
    [theme.breakpoints.down("sm")]: { padding: "0px" },
  },
  fullwidth: {
    width: "93%",
    height: "48px",
    marginTop: "16.7px",
  },
  panel: {
    backgroundColor: theme.palette.primary.main,
    minHeight: "70vh",
    color: "white",
    borderRadius: "24px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: { borderRadius: "0px" },
  },
  formGrid: {
    padding: "50px 0px",
  },
  logoGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,

    "& img": {
      width: "205px",
      height: "76px",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0px 30px 30px 40px",
    [theme.breakpoints.down("sm")]: { paddingTop: "10%" },
  },
  selector: {
    backgroundColor: theme.palette.background.lightgray,
    color: "white",
    padding: "10px 30px",
    borderRadius: "16px",
    margin: "10px",
    "&:hover": {
      backgroundColor: theme.palette.background.lightgray,
    },
  },

  socialMediaSelector: {
    backgroundColor: theme.palette.background.lightgray,
    color: "white",
    margin: "0px 8.35%",
    marginBottom: "16px",
    "&:hover": {
      backgroundColor: theme.palette.background.lightgray,
    },
  },

  selected: {
    backgroundColor: theme.palette.secondary.main,
    color: "black",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  dateSelector: {
    color: "white",
    [theme.breakpoints.down("sm")]: {
      width: "89%",
    },
    "& .MuiInput-root": {
      margin: "1px",
    },
    "& .MuiInputLabel-root": {
      padding: "0px 20px",
    },
    "& .Mui-focused": {
      color: "white",
    },
  },
  phoneInput: {
    backgroundColor: `${theme.palette.background.lightgray} !important;`,
    border: "none !important",
    color: "white",
    height: "53px !important",
    borderRadius: "14px !important",
    width: "100%!important",
    paddingLeft: "46px !important",
  },
  buttonClass: {
    backgroundColor: `${theme.palette.background.lightgray} !important;`,
    borderRadius: "14px !important",
    border: "none !important",
    "&:hover": {
      backgroundColor: `${theme.palette.background.lightgray} !important;`,
    },
    "& .selected-flag": {
      padding: "0px 8px",
    },
    "& .arrow": {
      left: "20px !important",
    },
  },
  dropdownClass: {
    backgroundColor: `${theme.palette.background.lightgray} !important;`,
  },
  link: {
    color: theme.palette.secondary.main,
  },
}));

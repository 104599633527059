import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    padding: "10% 0px 5%",
    [theme.breakpoints.down("sm")]: { padding: "0% 0% 0% 0%" },
  },
  panel: {
    backgroundColor: theme.palette.primary.main,
    minHeight: "70vh",
    color: "white",
    borderRadius: "24px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: { borderRadius: "0px" },
  },
  detailsGrid: {
    padding: "50px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: { padding: "20px" },
  },
  iconGrid: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: { padding: "13% 5%" },
  },
  form: {
    margin: "30px 0px",
  },
  message: {
    backgroundColor: theme.palette.background.lightgray,
    width: "100%",
    border: "none",
    color: "white",
    padding: "18px",
    borderRadius: "18px",
  },
  link: {
    color: theme.palette.secondary.main,
  },
  text: {
    fontSize: "14px",
  },
}));

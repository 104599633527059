import React, { useEffect } from "react";
import "./privacyPolicy.css";
import { useSelector, useDispatch } from "react-redux";
import loading from "../../../store/actions/loading";
import ReactGA from "react-ga";
import {
  makeStyles,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  paperDiv: {
    margin: "2% 0%",
    color: theme.palette.text.secondary,
    borderRadius: "4px",
  },
  heading: {
    fontFamily: "Almarai-SemiBold",
    paddingLeft: "24px!important",
    fontSize: "14px!important",
    [theme.breakpoints.down("sm")]: { paddingLeft: "8px!important" },
  },
  // Mobilebackground: {
  //   backgroundColor: theme.palette.primary.main,
  //   "& h3": {
  //     color: "white !important",
  //   },
  //   "& h4": {
  //     color: "white !important",
  //   },
  //   "& span": {
  //     color: "white !important",
  //   },
  //   "& p": {
  //     color: "white !important",
  //   },
  //   "& li": {
  //     color: "white !important",
  //   },
  //   "& div": {
  //     color: "white !important",
  //   },
  //   "& strong": {
  //     color: "white !important",
  //   },
  // },
}));

function PrivacyPolicy({ mobile, lang = null }) {
  const dispatch = useDispatch();
  let language = useSelector((state) => state.language);
  if (lang) language = lang;
  const classes = useStyles();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <div className={`container-fluid mt_sticky pzero_mob`}>
        <div className="container Termcontainer">
          <div
            className={
              language === "en"
                ? "col-sm-12 p-0 wrapper_main_privacy"
                : "col-sm-12 p-0 wrapper_main_privacy wrapper_main_privacy_ar"
            }
          >
            <h3>{language === "en" ? "Privacy Policy" : "سياسة الخصوصية"}</h3>

            <span>
              {language === "en" ? (
                "Ishro is owned and operated by Ishro General Trading LLC."
              ) : (
                <span dir="rtl">
                  تمتلك "إيشرو للتجارة العامة ش.ذ.م.م." موقع Ishro وتديره.
                </span>
              )}
            </span>

            <p>
              {language === "en" ? (
                "Ishro values your privacy and the protection of your personal data. This privacy policy describes what information we collect from you, how we collect it, how we use it, how we obtain your consent, how long we keep it in our databases and, if necessary, with whom we share it."
              ) : (
                <span dir="rtl">
                  يقدر Ishro خصوصيتك وحماية بياناتك الشخصية. تصف سياسة الخصوصية
                  هذه المعلومات التي نجمعها منك، وكيف نجمعها، وكيف نستخدمها،
                  وكيف نحصل على موافقتك، ومدة الاحتفاظ بها في قواعد بياناتنا،
                  وإذا لزم الأمر، مع من نشاركها.
                </span>
              )}
            </p>

            <p>
              {language === "en" ? (
                "By purchasing the products, you are accepting the practices described in this Privacy Policy. Use of the website and purchase of the products is also subject to our Terms and Conditions."
              ) : (
                <span dir="rtl">
                  من خلال شراء المنتجات، فإنك تقبل الممارسات الموضحة في سياسة
                  الخصوصية هذه، كما يخضع استخدام الموقع وشراء المنتجات للشروط
                  والأحكام الخاصة بنا.
                </span>
              )}
            </p>
            <p>
              {language === "en" ? (
                "This Privacy Policy may change from time to time. Your continued use of the website after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates. This Privacy Policy has been developed and is maintained in accordance with all applicable federal and international laws and regulations and specifically with the GDPR (General Data Protection Regulation - European regulation) and the personal data protection laws of the United Arab Emirates."
              ) : (
                <span dir="rtl">
                  قد تتغير سياسة الخصوصية الحالية من وقت لآخر. يعتبر استمرار
                  استخدامك للموقع بعد إجراء التغييرات بمثابة قبول لهذه
                  التغييرات، لذا يرجى مراجعة السياسة بشكل دوري للحصول على
                  التحديثات. تم وضع سياسة الخصوصية هذه ويتم تعهدها وفقاً لجميع
                  القوانين واللوائح الاتحادية والدولية المعمول بها وعلى وجه
                  التحديد وفقاً للائحة GDPR (اللائحة العامة لحماية البيانات -
                  اللائحة الأوروبية) وقوانين حماية البيانات الشخصية لدولة
                  الإمارات العربية المتحدة.
                </span>
              )}
            </p>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {language === "en" ? (
                    "1. GENERAL INFORMATION"
                  ) : (
                    <span dir="rtl">1- معلومات عامة</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "The personal data of the users that are collected and processed through the website:"
                    ) : (
                      <span dir="rtl">
                        البيانات الشخصية للمستخدمين التي يتم جمعها ومعالجتها من
                        خلال موقع:
                      </span>
                    )}
                  </span>
                  <strong>
                    <a
                      href="https://ishro.com/"
                      target="_blank"
                      style={{ wordWrap: "break-word" }}
                    >
                      https://ishro.com/{" "}
                    </a>
                  </strong>
                  <span>
                    {language === "en" ? (
                      "Will be under responsibility and in charge of:"
                    ) : (
                      <span dir="rtl">ستكون تحت مسؤولية وفي عهدة:</span>
                    )}
                  </span>

                  <strong>
                    {language === "en" ? (
                      "Ishro General Trading LLC."
                    ) : (
                      <span dir="rtl">إيشرو للتجارة العامة ش.ذ.م.م.</span>
                    )}
                  </strong>
                  <strong>
                    {language === "en" ? (
                      "Info@ishro.com , Office 606 Ivory II, Business Bay,"
                    ) : (
                      <span dir="rtl">
                        Info@ishro.com، مكتب 606 ايفوري 2، الخليج التجاري، دبي،
                        الإمارات العربية المتحدة، ص.ب. 24887{" "}
                      </span>
                    )}
                  </strong>
                  <strong>
                    {language === "en" ? (
                      "Dubai United Arab Emirates PO BOX 24887"
                    ) : (
                      <span dir="rtl"></span>
                    )}
                  </strong>
                  <strong>
                    {language === "en" ? (
                      "Phone: +971(4)575-4924"
                    ) : (
                      <span dir="rtl">هاتف: 4924-575(4)971+</span>
                    )}
                  </strong>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {language === "en" ? (
                    "2. TYPES OF INFORMATION GATHERED"
                  ) : (
                    <span dir="rtl">2. أنواع المعلومات التي يتم جمعها</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "The information we collect from our users helps to personalize and continually improve the user experience on the website. Here are the types of information we gather:"
                    ) : (
                      <span dir="rtl">
                        تساعد المعلومات التي نجمعها من مستخدمينا على تخصيص تجربة
                        المستخدم على الموقع وتحسينها باستمرار . فيما يلي أنواع
                        المعلومات التي نجمعها.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "Information You Give Us. You provide information when you search, read and view content, register as a user, purchase products and/or communicate with us through our contact information or through our contact forms. As a result of those actions, you might supply us with the following information:"
                    ) : (
                      <span dir="rtl">
                        المعلومات التي تقدمها لنا. أنت تقدم معلومات عند البحث عن
                        المحتوى وقراءته وعرضه، و / أو التسجيل كمستخدم، و / أو
                        شراء المنتجات، و / أو التواصل معنا من خلال معلومات
                        الاتصال الخاصة بنا أو من خلال نماذج الاتصال الخاصة بنا.
                        نتيجة لهذه الإجراءات، قد تزودنا بالمعلومات التالية:
                      </span>
                    )}
                  </span>
                  <ul className="col-sm-12 p-0 fl">
                    <li>
                      {language === "en" ? (
                        "First and last name"
                      ) : (
                        <span dir="rtl">الاسم واللقب</span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Email address"
                      ) : (
                        <span dir="rtl">البريد الإلكتروني</span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Phone number (includes WhatsApp number)"
                      ) : (
                        <span dir="rtl">
                          رقم الهاتف (ويشمل ذلك رقم الواتساب)
                        </span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Address (shipping purposes)"
                      ) : (
                        <span dir="rtl">العنوان (لأغراض الشحن)</span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "ZIP/Postal code"
                      ) : (
                        <span dir="rtl">
                          الرمز البريدي / رمز العنوان البريدي
                        </span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "IP address"
                      ) : (
                        <span dir="rtl">عنوان بروتوكول الإنترنت (IP)</span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Payment Information"
                      ) : (
                        <span dir="rtl"> معلومات الدفع</span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Any additional information relating to you that you provide to us directly through our website or indirectly through our website or online presence such as ‘cookies’."
                      ) : (
                        <span dir="rtl">
                          أي معلومات إضافية تتعلق بك تقدمها لنا مباشرة من خلال
                          موقعنا أو بشكل غير مباشر من خلال موقعنا أو التواجد عبر
                          الإنترنت مثل “ملفات تعريف الارتباط”.
                        </span>
                      )}
                    </li>
                  </ul>
                  <span>
                    {language === "en" ? (
                      "Ishro will not collect any personally identifiable information about you, unless you provide it."
                    ) : (
                      <span dir="rtl">
                        لن تجمع Ishro أي معلومات تعريف شخصية عنك، إلا إذا قمت
                        أنت بتقديمها.
                      </span>
                    )}
                  </span>
                  <div className="col-sm-12 p-0 fl mt_width_bold">
                    <span>
                      <strong className="width_bold">
                        {language === "en" ? (
                          "Payment information:"
                        ) : (
                          <span dir="rtl">معلومات الدفع:</span>
                        )}
                      </strong>{" "}
                      {language === "en" ? (
                        " Your credit/debit card information or payment information will be processed by the payment platform available on the website, which will treat and safeguard your data with total security and with the exclusive purpose of processing the purchase of the products. Ishro reserves the right to contract any payment platform available in the market, which treats your data for the exclusive purpose of processing the purchase of the products. "
                      ) : (
                        <span dir="rtl">
                          ستتم معالجة معلومات بطاقة الائتمان / الخصم أو معلومات
                          الدفع الخاصة بك عن طريق منصة الدفع المتاحة على الموقع،
                          والتي ستتعامل مع بياناتك وتحميها بأمان تام ولغرض وحيد
                          هو معالجة شراء المنتجات. ويحتفظ موقع "Ishro" بالحق في
                          التعاقد مع أي منصة دفع متاحة في السوق للتعامل مع
                          بياناتك بغرض وحيد هو معالجة شراء المنتجات.
                        </span>
                      )}
                    </span>
                  </div>

                  <div className="col-sm-12 p-0 fl mt_width_bold">
                    <span>
                      <strong className="width_bold">
                        {language === "en" ? (
                          "GOOGLE Analytics: "
                        ) : (
                          <span dir="rtl">
                            تحليلات جوجل (Google Analytics):{" "}
                          </span>
                        )}
                      </strong>{" "}
                      {language === "en" ? (
                        "We use Google Analytics provided by Google, Inc., USA (“Google”). These tool and technologies collect and analyze certain types of information, including IP addresses, device and software identifiers, referring and exit URLs, feature use metrics and statistics, usage and purchase history, media access control address (MAC Address), mobile unique device identifiers, and other similar information via the use of cookies. The information generated by Google Analytics (including your IP address) may be transmitted to and stored by Google on servers in the United States. We use the GOOGLE Analytics collection of data to enhance the website and improve our service."
                      ) : (
                        <span dir="rtl">
                          نحن نستخدم "تحليلات جوجل" التي تقدمها شركة جوجل،
                          الولايات المتحدة (جوجل)، وتجمع هذه الأداة والتقنيات
                          أنواعاً معينة من المعلومات وتحللها، بما في ذلك عناوين
                          بروتوكول الإنترنت (IP)، ومعرفات الأجهزة والبرامج،
                          وعناوين روابط الإحالة والخروج، ومقاييس استخدام الميزات
                          والإحصاءات، وسجل الاستخدام والشراء، وعنوان التحكم في
                          الوصول إلى الوسائط (عنوان MAC)، ومعرفات الأجهزة
                          الجوالة الفريدة، وغيرها من المعلومات المشابهة عن طريق
                          استخدام ملفات تعريف الارتباط. وقد يتم نقل وتخزين
                          المعلومات التي تولدها "تحليلات جوجل" (بما في ذلك عنوان
                          بروتوكول الإنترنت الخاص بك) من قبل جوجل على خوادم في
                          الولايات المتحدة. نحن نستخدم خدمة جمع البيانات في
                          "تحليلات جوجل" لتحسين الموقع وتحسين خدماتنا.{" "}
                        </span>
                      )}
                    </span>
                  </div>

                  <span>
                    {language === "en" ? (
                      "Please consult Google's privacy policy here: "
                    ) : (
                      <span dir="rtl">
                        يرجى الرجوع إلى سياسة الخصوصية الخاصة بجوجل هنا:{" "}
                      </span>
                    )}{" "}
                    <span className="bold">
                      <a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        style={{ wordWrap: "break-word" }}
                      >
                        https://policies.google.com/privacy{" "}
                      </a>
                    </span>
                  </span>
                  <span>
                    <strong className="width_bold">
                      {" "}
                      {language === "en" ? (
                        "Facebook Pixel: "
                      ) : (
                        <span dir="rtl">فيسبوك بيكسل (Facebook Pixel): </span>
                      )}{" "}
                      &nbsp;
                    </strong>{" "}
                    {language === "en" ? (
                      "Our website uses Facebook pixels that enable us, our advertisers and service providers:"
                    ) : (
                      <span dir="rtl">
                        يستخدم موقعنا بكسلات فيسبوك التي تمكننا نحن والمعلنين
                        ومقدمي الخدمات لدينا من:
                      </span>
                    )}
                  </span>

                  <ul className="fl">
                    <li>
                      {" "}
                      {language === "en" ? (
                        "Collect statistics on our websites and social networks (for example, number of users who visited a page)."
                      ) : (
                        <span dir="rtl">
                          جمع الإحصائيات على مواقعنا وشبكاتنا الاجتماعية (على
                          سبيل المثال، عدد المستخدمين الذين زاروا إحدى الصفحات).
                        </span>
                      )}
                    </li>
                    <li>
                      {" "}
                      {language === "en" ? (
                        "Collect information about how you interact with our websites and social networks (e.g., whether you opened or followed links contained within)."
                      ) : (
                        <span dir="rtl">
                          جمع معلومات حول كيفية تفاعلك مع مواقعنا وشبكاتنا
                          الاجتماعية (على سبيل المثال، ما إذا كنت قد فتحت أو
                          اتبعت الروابط الموجودة بداخلها).
                        </span>
                      )}
                    </li>
                    <li>
                      {" "}
                      {language === "en" ? (
                        "Personalize online services and marketing communications."
                      ) : (
                        <span dir="rtl">
                          {" "}
                          إضفاء الطابع الشخصي على الخدمات عبر الإنترنت
                          والمراسلات التسويقية.
                        </span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Match ads to users and optimize advertising campaigns."
                      ) : (
                        <span dir="rtl">
                          {" "}
                          مطابقة الإعلانات مع المستخدمين وتحسين الحملات
                          الإعلانية.
                        </span>
                      )}
                    </li>
                  </ul>

                  <span>
                    {language === "en" ? (
                      "Please consult Facebook's privacy policy here: "
                    ) : (
                      <span dir="rtl">
                        يرجى الرجوع إلى سياسة الخصوصية الخاصة بفيسبوك هنا:
                      </span>
                    )}{" "}
                    <span className="bold">
                      <a
                        href="https://www.facebook.com/privacy/explanation"
                        target="_blank"
                        style={{ wordWrap: "break-word" }}
                      >
                        https://www.facebook.com/privacy/explanation{" "}
                      </a>
                    </span>
                  </span>

                  <div className="col-sm-12 p-0 fl mt_width_bold">
                    <span>
                      <strong className="width_bold">
                        {language === "en" ? (
                          "Automatic Information: "
                        ) : (
                          <span dir="rtl">المعلومات التلقائية: </span>
                        )}
                      </strong>{" "}
                      &nbsp;
                      {language === "en" ? (
                        " Like many websites, we use 'cookies.' We obtain certain types of anonymous information which is not personally identifiable when your web browser accesses, Ishro or third-party content served by or on behalf of Ishro on other websites. Some of the information we collect and analyze includes the Internet protocol (IP) address. computer and connection information such as browser type, version, and connection speed. purchase history. and the products you searched for, viewed, and possibly purchased."
                      ) : (
                        <span dir="rtl">
                          مثل العديد من المواقع، نستخدم "ملفات تعريف الارتباط"
                          لنحصل على أنواع معينة من المعلومات المجّهلة والتي لا
                          يمكن تحديدها شخصياً عند وصول متصفح الويب الخاص بك إلى
                          Ishro أو محتوى تابع لطرف ثالث يتم تقديمه من قبل أو
                          نيابةً عن Ishro على مواقع أخرى. تتضمن بعض المعلومات
                          التي نجمعها ونحللها عنوان بروتوكول الإنترنت (IP)؛
                          ومعلومات الحاسوب والاتصال مثل نوع المتصفح والإصدار
                          وسرعة الاتصال؛ وتاريخ شراء؛ والمنتجات التي بحثت عنها
                          وعرضتها وربما اشتريتها.
                        </span>
                      )}
                    </span>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {language === "en" ? (
                    "3. HOW LONG WE KEEP YOUR DATA "
                  ) : (
                    <span dir="rtl">3. المدة التي نحتفظ بها ببياناتك</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "Personal data will be kept for the time necessary for the provision of the products and services. The owner may be allowed to retain personal data for a longer period whenever the user has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the owner may be obliged to retain personal data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority. Once the retention period expires, personal data shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period."
                    ) : (
                      <span dir="rtl">
                        سيتم الاحتفاظ بالبيانات الشخصية للوقت اللازم لتوفير
                        المنتجات والخدمات.يُسمح للمالك بالاحتفاظ بالبيانات
                        الشخصية لفترة أطول بعد موافقة المستخدم، وطالما لم يتم
                        سحب هذه الموافقة. علاوة على ذلك، قد يكون المالك ملزماً
                        بالاحتفاظ بالبيانات الشخصية لفترة أطول كلما اقتضى الأمر
                        ذلك لأداء التزام قانوني أو بناء على أمر من إحدى السلطات.
                        وبمجرد انتهاء فترة الاحتفاظ، يتم حذف البيانات الشخصية.
                        وبالتالي، فإن الحق في النفاذ إلى المعلومات، والحق في
                        محوها، والحق في تعديلها، والحق في نقلها، لايمكن إنفاذه
                        بعد انقضاء فترة الاحتفاظ.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "4. HOW WE USE YOUR INFORMATION."
                  ) : (
                    <span dir="rtl">4. كيف نستخدم معلوماتك.</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "In general, we use the information we collect primarily to provide, maintain, protect and improve our current website and products. We use personal information collected through our site as described below and described elsewhere in this Policy to:"
                    ) : (
                      <span dir="rtl">
                        بشكل عام، نستخدم المعلومات التي نجمعها في المقام الأول
                        لتوفير وصيانة وحماية وتحسين موقعنا ومنتجاتنا الحالية،
                        حيث نستخدم المعلومات الشخصية التي يتم جمعها من خلال
                        موقعنا على النحو الموضح أدناه والموضح في مواضع أخرى من
                        هذه السياسة من أجل:
                      </span>
                    )}
                  </span>
                  <ul className="col-sm-12 p-0 fl">
                    <li>
                      {language === "en" ? (
                        "Identify you as a user in our system."
                      ) : (
                        <span dir="rtl">تحديدك كمستخدم في نظامنا.</span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "User registration."
                      ) : (
                        <span dir="rtl">تسجيل المستخدم.</span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Process payments."
                      ) : (
                        <span dir="rtl">معالجة المدفوعات</span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Deliver the products."
                      ) : (
                        <span dir="rtl">تسليم المنتجات.</span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Manage returns and refunds."
                      ) : (
                        <span dir="rtl">
                          إدارة المرتجعات والمبالغ المستردة.
                        </span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Improve our products, website, and how we operate our business."
                      ) : (
                        <span dir="rtl">
                          تحسين منتجاتنا وموقعنا وكيفية إدارة أعمالنا.
                        </span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Understand and enhance your experience using our website and products."
                      ) : (
                        <span dir="rtl">
                          فهم وتعزيز تجربتك باستخدام موقعنا ومنتجاتنا.
                        </span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Respond to your comments or questions through our support team."
                      ) : (
                        <span dir="rtl">
                          الرد على تعليقاتك أو أسئلتك من خلال فريق الدعم لدينا.
                        </span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Send you related information, including confirmations, invoices, technical notices, updates, security alerts and support and administrative messages."
                      ) : (
                        <span dir="rtl">
                          إرسال المعلومات ذات الصلة إليك، بما في ذلك التأكيدات
                          والفواتير والإشعارات الفنية والتحديثات والتنبيهات
                          الأمنية ورسائل الدعم والرسائل الإدارية.
                        </span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Communicate with you about upcoming events, offers and news about products and services offered by Ishro and our selected partners"
                      ) : (
                        <span dir="rtl">
                          التواصل معك حول الفعاليات القادمة والعروض والأخبار حول
                          المنتجات والخدمات التي يقدمها Ishro وشركائنا
                          المختارون.
                        </span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Marketing purposes of Ishro."
                      ) : (
                        <span dir="rtl">أغراض التسويق لموقع Ishro.</span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Link or combine your information with other information we get from third parties to help understand your needs and provide you with better service."
                      ) : (
                        <span dir="rtl">
                          ربط أو دمج معلوماتك مع المعلومات الأخرى التي نحصل
                          عليها من أطراف ثالثة للمساعدة في فهم احتياجاتك وتزويدك
                          بخدمة أفضل.
                        </span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Protect, investigate and deter against fraudulent, unauthorized or illegal activity."
                      ) : (
                        <span dir="rtl">
                          الحماية من والتحقيق في وردع الأنشطة الاحتيالية أو غير
                          المصرح بها أو غير القانونية.
                        </span>
                      )}
                    </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {language === "en" ? (
                    "5. HOW DO YOU GET MY CONSENT?"
                  ) : (
                    <span dir="rtl">كيف تحصلون على موافقتي؟</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "By placing an order, communicating with us through the contact forms or our contact information, accepting the use of cookies by our website and providing us with personal information to communicate with you, you consent to our collection, storage and use of your information on the terms contained in this privacy policy.  You consent to the use of cookies on our website when you give your acceptance through the pop-up window shown on the home page when you enter the website. You may withdraw your consent by sending us your request via the contact information or the contact page."
                    ) : (
                      <span dir="rtl">
                        من خلال تقديم لطلب، والتواصل معنا من خلال نماذج الاتصال
                        أو معلومات الاتصال الخاصة بنا، وقبول استخدام ملفات تعريف
                        الارتباط من خلال موقعنا وتزويدنا بالمعلومات الشخصية
                        للتواصل معك، فإنك توافق على جمعنا لمعلوماتك وتخزينها
                        واستخدامها وفقاً للشروط الواردة في سياسة الخصوصية هذه،
                        كما أنك توافق على استخدام ملفات تعريف الارتباط على
                        موقعنا عندما تعطي موافقتك من خلال النافذة المنبثقة التي
                        تظهر على الصفحة الرئيسية عند دخولك إلى الموقع. يمكنك سحب
                        موافقتك عن طريق إرسال طلبك إلينا عبر معلومات الاتصال أو
                        صفحة الاتصال.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "6. SOCIAL MEDIA"
                  ) : (
                    <span dir="rtl">6. وسائل التواصل الاجتماعي</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "On our website you will find links and functions linked to different social networks, in which you can share your information."
                    ) : (
                      <span dir="rtl">
                        ستجد على موقعنا روابط ووظائف مرتبطة بوسائل التواصل
                        الاجتماعية المختلفة، حيث يمكنك مشاركة معلوماتك.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "It is advisable to consult the privacy policy and data protection of each social network used on our website."
                    ) : (
                      <span dir="rtl">
                        من المستحسن الرجوع إلى سياسة الخصوصية وحماية البيانات
                        لكل وسيلة تواصل اجتماعي مستخدمة على موقعنا.
                      </span>
                    )}
                  </span>
                  <span>
                    <a
                      href="https://www.facebook.com/privacy/explanation"
                      target="_blank"
                      style={{ wordWrap: "break-word" }}
                    >
                      {language === "en" ? (
                        "Facebook: https://www.facebook.com/privacy/explanation"
                      ) : (
                        <span dir="rtl">
                          الفيسبوك: https://www.facebook.com/privacy/explanation
                        </span>
                      )}{" "}
                    </a>
                  </span>
                  <span>
                    <a
                      href="http://instagram.com/about/legal/privacy/"
                      target="_blank"
                      style={{ wordWrap: "break-word" }}
                    >
                      {language === "en" ? (
                        "Instagram: http://instagram.com/about/legal/privacy/ "
                      ) : (
                        <span dir="rtl">
                          انستغرام: http://instagram.com/about/legal/privacy/
                        </span>
                      )}
                    </a>
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {language === "en" ? (
                    "7. HOW WE SHARE INFORMATION"
                  ) : (
                    <span dir="rtl">7. كيف نقوم بمشاركة المعلومات</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "Information about our customers is an important part of our business, and we are not in the business of selling it to others. We share customer information only as described below."
                    ) : (
                      <span dir="rtl">
                        المعلومات عن عملائنا هي جزء مهم من أعمالنا، لكننا لا في
                        مجال بيع هذه المعلومات للآخرين. نحن نشارك معلومات
                        العملاء فقط كما هو موضح أدناه.
                      </span>
                    )}
                  </span>

                  <div className="col-sm-12 p-0 fl mt_width_bold">
                    <span>
                      <strong className="width_bold">
                        {language === "en" ? (
                          "Third-Party Service Providers:"
                        ) : (
                          <span dir="rtl">
                            مزودي الخدمات من الأطراف الثالث:
                          </span>
                        )}
                      </strong>
                      &nbsp;
                      {language === "en" ? (
                        "We employ other companies and individuals to perform functions on our behalf. Examples include process payments, create the platform and host the website, deliver the products (courier companies), sending email, analyzing data (Google Analytics), Create Ads (Facebook, Google), providing marketing assistance, providing search results and links. They have access to personal information needed to perform their functions, but may not use it for other purposes."
                      ) : (
                        <span dir="rtl">
                          نحن نقوم بتوظيف شركات وأفراد الآخرين لأداء وظائف
                          نيابةً عنا. من الأمثلة على ذلك عمليات الدفع، وإنشاء
                          المنصة واستضافة الموقع، وتسليم المنتجات (شركات البريد
                          السريع)، وإرسال رسائل البريد الإلكتروني، وتحليل
                          البيانات (تحليلات جوجل)، وإنشاء الإعلانات (فيسبوك
                          وجوجل)، وتقديم المساعدة التسويقية، وتقديم نتائج البحث
                          والروابط. وتتوفر لهم إمكانية الوصول إلى المعلومات
                          الشخصية اللازمة لأداء وظائفهم، إلا أنه لا يجوز لهم
                          استخدام هذه المعلومات لأغراض أخرى.
                        </span>
                      )}
                    </span>
                  </div>

                  <span>
                    {language === "en" ? (
                      "These third-party services and tools may have access to personal information needed to perform their functions, but may not use that information for other purposes. Information shared with these third-party services will be treated and stored in accordance with their respective privacy policies and our privacy policy."
                    ) : (
                      <span dir="rtl">
                        قد تتمكن خدمات وأدوات الأطراف الثالثة هذه من الوصول إلى
                        المعلومات الشخصية اللازمة لأداء وظائفها، ولكن لا يجوز
                        لها استخدام هذه المعلومات لأغراض أخرى. سيتم التعامل مع
                        المعلومات التي تتم مشاركتها مع خدمات الأطراف الثالثة هذه
                        وتخزينها وفقاً لسياسات الخصوصية الخاصة بكل منها وسياسة
                        الخصوصية الخاصة بنا.
                      </span>
                    )}
                  </span>

                  <div className="col-sm-12 p-0 fl mt_width_bold">
                    <span>
                      <strong className="width_bold">
                        {language === "en" ? (
                          "Business Transfers:"
                        ) : (
                          <span dir="rtl">عمليات نقل الأعمال:</span>
                        )}
                      </strong>{" "}
                      &nbsp;
                      {language === "en" ? (
                        "In the event that Ishro creates, merges with, or is acquired by another entity, your information will most likely be transferred. Ishro will email you or place a prominent notice on our Website before your information becomes subject to another privacy policy."
                      ) : (
                        <span dir="rtl">
                          في حال أنشأ Ishro أو أندمج مع أو تم الاستحواذ عليه من
                          كيان آخر، فمن المرجح أن يتم نقل معلوماتك، وفي هذه
                          الحالة سيرسل Ishro رسالة بريد إلكتروني إليك أو يضع
                          إشعاراً بارزاً على موقعنا قبل أن تخضع معلوماتك لسياسة
                          خصوصية أخرى.
                        </span>
                      )}
                    </span>
                  </div>

                  <div className="col-sm-12 p-0 fl mt_width_bold">
                    <span>
                      <strong className="width_bold">
                        {language === "en" ? (
                          "Protection of Ishro and others:"
                        ) : (
                          <span dir="rtl">حماية Ishro والآخرين:</span>
                        )}
                      </strong>{" "}
                      &nbsp;
                      {language === "en" ? (
                        "We release personal information when we believe release is appropriate to comply with the law, enforce or apply our Terms and conditions and other agreements, or protect the rights, property, or safety of Ishro, our users or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction."
                      ) : (
                        <span dir="rtl">
                          نكشف عن المعلومات الشخصية عندما نعتقد أن الإفصاح عنها
                          ملائم من أجل الامتثال للقانون، أو إنفاذ أو تطبيق
                          الشروط والأحكام الخاصة بنا والاتفاقيات الأخرى ، أو
                          حماية حقوق أو ممتلكات أو سلامة Ishro أو مستخدمينا أو
                          غيرهم، ويشمل هذا تبادل المعلومات مع الشركات والمؤسسات
                          الأخرى لأغراض الحماية من الخداع والحد من مخاطر
                          الائتمان.
                        </span>
                      )}
                    </span>
                  </div>

                  <div className="col-sm-12 p-0 fl mt_width_bold">
                    <span>
                      <strong className="width_bold">
                        {language === "en" ? (
                          "With Your Consent:"
                        ) : (
                          <span dir="rtl">بموافقتك:</span>
                        )}
                      </strong>{" "}
                      &nbsp;
                      {language === "en" ? (
                        "Other than as set out above, you will receive notice when personally identifiable information about you might go to third parties, and you will have an opportunity to choose not to share the information."
                      ) : (
                        <span dir="rtl">
                          بخلاف ما هو مبين أعلاه، سوف تتلقى إشعاراً عندما تنتقل
                          المعلومات المحددة للهوية الشخصية الخاصة بك إلى أطراف
                          ثالثة، وستتاح لك الفرصة لاختيار عدم مشاركة المعلومات.
                        </span>
                      )}
                    </span>
                  </div>

                  <div className="col-sm-12 p-0 fl mt_width_bold">
                    <span>
                      <strong className="width_bold">
                        {" "}
                        {language === "en" ? (
                          "Anonymous Information:"
                        ) : (
                          <span dir="rtl">المعلومات المجّهلة:</span>
                        )}
                      </strong>{" "}
                      &nbsp;
                      {language === "en" ? (
                        "Ishro uses the anonymous browsing information collected automatically by our servers primarily to help us administer and improve the Website. We may also use aggregated anonymous information to provide information about the Website to potential business partners and other unaffiliated entities. This information is not personally identifiable."
                      ) : (
                        <span dir="rtl">
                          يستخدم Ishro معلومات التصفح المجّهلة التي يتم جمعها
                          تلقائياً عن طريق خوادمنا بشكل أساسي لمساعدتنا في إدارة
                          الموقع وتحسينه، وقد نستخدم أيضاً معلومات مجّهلة مجمعة
                          لتوفير معلومات حول الموقع لشركاء الأعمال المحتملين
                          والكيانات الأخرى غير المنتسبة لنا، لكن هذه المعلومات
                          لن تكون محددة للهوية الشخصية.
                        </span>
                      )}
                    </span>
                  </div>

                  <div className="col-sm-12 p-0 fl mt_width_bold">
                    <span>
                      <strong className="width_bold">
                        {language === "en" ? (
                          "Email Address: "
                        ) : (
                          <span dir="rtl">عنوان البريد الإلكتروني: </span>
                        )}
                      </strong>{" "}
                      &nbsp;
                      {language === "en" ? (
                        "The email address that you supply to us for purposes of receiving our email communications will never be rented or sold to a third party."
                      ) : (
                        <span dir="rtl">
                          عنوان البريد الإلكتروني الذي تزودنا به لأغراض تلقي
                          اتصالات البريد الإلكتروني لن يتم تأجيره أو بيعه إلى أي
                          طرف ثالث.
                        </span>
                      )}
                    </span>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "8. COOKIE POLICY"
                  ) : (
                    <span dir="rtl">8. سياسة ملفات الارتباط</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "This Cookie Policy explains how and why cookies and other similar technologies may be stored on and accessed from your device when you use or visit the website."
                    ) : (
                      <span dir="rtl">
                        تشرح سياسة ملفات تعريف الارتباط هذه كيف ولماذا قد يتم
                        تخزين ملفات تعريف الارتباط والتقنيات الأخرى المماثلة على
                        جهازك والوصول إليها من خلال استخدامك أو زيارة الموقع.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "This Cookie Policy should be read together with our Privacy Policy and our Terms and Conditions."
                    ) : (
                      <span dir="rtl">
                        يجب قراءة سياسة ملفات تعريف الارتباط هذه جنباً إلى جنب
                        مع سياسة الخصوصية والبنود والشروط الخاصة بنا.
                      </span>
                    )}
                  </span>

                  <strong className="pl-bold">
                    {language === "en" ? (
                      "By using the website, you accept the use of cookies by Ishro, in the terms contained in this policy."
                    ) : (
                      <span dir="rtl">
                        من خلال استخدام الموقع، فإنك تقبل استخدام Ishro لملفات
                        تعريف الارتباط وفقاً للشروط الواردة في هذه السياسة.
                      </span>
                    )}
                  </strong>

                  <strong className="pl-bold">
                    {language === "en" ? (
                      "WHAT ARE COOKIES?"
                    ) : (
                      <span dir="rtl">ما هي ملفات تعريف الارتباط؟</span>
                    )}
                  </strong>
                  <p>
                    {language === "en" ? (
                      "Cookies are small text files that are stored on your computer or mobile device when you visit a website. They allow the website to recognize your device and remember if you have been to the website before."
                    ) : (
                      <span dir="rtl">
                        ملفات تعريف الارتباط هي ملفات نصية صغيرة يتم تخزينها على
                        جهاز الحاسوب أو الجهاز الجوال الخاص بك عند زيارة أحد
                        المواقع، وهي تسمح للموقع بالتعرف على جهازك وتذكر ما إذا
                        كنت قد زرت الموقع من قبل.
                      </span>
                    )}
                  </p>
                  <p>
                    {language === "en" ? (
                      "Cookies are a very common web technology. most websites use cookies and have done so for years. Cookies are widely used to make the website work more efficiently."
                    ) : (
                      <span dir="rtl">
                        ملفات تعريف الارتباط هي تقنية شائعة جداً على الإنترنت؛
                        حيث تستخدم معظم المواقع ملفات تعريف الارتباط وهي تفعل
                        ذلك لسنوات، وتُستخدم ملفات تعريف الارتباط على نطاق واسع
                        لجعل الموقع يعمل على نحو أكثر كفاءة.
                      </span>
                    )}
                  </p>
                  <p>
                    {language === "en" ? (
                      "Cookies are used to measure which parts of the website users visit and to personalize their experience. Cookies also provide information that helps us monitor and improve the performance of the website."
                    ) : (
                      <span dir="rtl">
                        تُستخدم ملفات تعريف الارتباط لقياس أجزاء الموقع التي
                        يزورها المستخدمون وتخصيص تجربتهم، كما توفر ملفات تعريف
                        الارتباط معلومات تساعدنا في مراقبة أداء الموقع وتحسينه.
                      </span>
                    )}
                  </p>

                  <strong className="pl-bold">
                    {language === "en" ? (
                      "REFUSING OR WITHDRAWING CONSENT TO THE USE OF COOKIES"
                    ) : (
                      <span dir="rtl">
                        رفض أو سحب الموافقة على استخدام ملفات تعريف الارتباط
                      </span>
                    )}
                  </strong>

                  <p>
                    {language === "en" ? (
                      "If you do not want Cookies to be dropped on your device, you can adjust the setting of your Internet browser to reject the setting of all or some Cookies and to alert you when a Cookie is placed on your device. For further information about how to do so, please refer to your browser ‘help’, ‘tool’, or ‘edit’ section. Please note that if you use your browser settings to block all Cookies, including strictly necessary Cookies, you may not be able to access or use all or parts of the functionalities of Ishro."
                    ) : (
                      <span dir="rtl">
                        إذا كنت لا تريد أن يتم وضع ملفات تعريف الارتباط على
                        جهازك، فيمكنك ضبط إعدادات متصفح الإنترنت الخاص بك لرفض
                        إعداد كل أو بعض ملفات تعريف الارتباط وتنبيهك عند وضع ملف
                        تعريف ارتباط على جهازك. لمزيد من المعلومات حول كيفية
                        القيام بذلك، يرجى الرجوع إلى قسم "المساعدة" (help) أو
                        "الأدوات" (tool) أو "التحرير" (edit) في متصفحك. يرجى
                        ملاحظة أنه إذا كنت تستخدم إعدادات المتصفح الخاص بك لحظر
                        جميع ملفات تعريف الارتباط، بما في ذلك ملفات تعريف
                        الارتباط الضرورية للغاية، فقد لا تتمكن من الوصول إلى
                        وظائف Ishro أو استخدامها كلها أو بعضاً منها.
                      </span>
                    )}
                  </p>

                  <p>
                    {language === "en" ? (
                      "If you want to remove previously-stored Cookies, you can manually delete the Cookies at any time. However, this will not prevent Ishro from placing further Cookies on your device unless and until you adjust your Internet browser setting as described above."
                    ) : (
                      <span dir="rtl">
                        إذا كنت تريد إزالة ملفات تعريف الارتباط المخزنة مسبقاً،
                        فيمكنك حذف ملفات تعريف الارتباط يدوياً في أي وقت. ومع
                        ذلك، لن يمنع هذا Ishro من وضع المزيد من ملفات تعريف
                        الارتباط على جهازك إلا إذا قمت بضبط إعدادات متصفح
                        الإنترنت الخاص بك كما هو موضح أعلاه.
                      </span>
                    )}
                  </p>

                  <p>
                    {language === "en" ? (
                      "We provide the links for the management and blocking of cookies depending on the browser you use:"
                    ) : (
                      <span dir="rtl">
                        نحن نقدم روابط لإدارة وحظر ملفات تعريف الارتباط حسب
                        المتصفح الذي تستخدمه:
                      </span>
                    )}
                  </p>
                  <div className="pl_90">
                    <ul className="col-sm-12  fl">
                      <li>
                        <a
                          href=" https://support.microsoft.com/en-us/office/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09?ui=en-us&rs=en-us&ad=us"
                          target="_blank"
                          style={{ wordWrap: "break-word" }}
                        >
                          {language === "en" ? (
                            "Microsoft Edge: https://support.microsoft.com/en-us/office/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09?ui=en-us&rs=en-us&ad=us"
                          ) : (
                            <span dir="rtl">
                              مايكروسوفت إيدج:
                              https://support.microsoft.com/en-us/office/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09?ui=en-us&rs=en-us&ad=us
                            </span>
                          )}{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox"
                          target="_blank"
                          style={{ wordWrap: "break-word" }}
                        >
                          {language === "en" ? (
                            "Firefox: https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox"
                          ) : (
                            <span dir="rtl">
                              فايرفوكس:
                              https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox
                            </span>
                          )}{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href=" https://support.google.com/chrome/answer/95647?hl=en"
                          target="_blank"
                          style={{ wordWrap: "break-word" }}
                        >
                          {language === "en" ? (
                            "Chrome: https://support.google.com/chrome/answer/95647?hl=en"
                          ) : (
                            <span dir="rtl">
                              كروم:
                              https://support.google.com/chrome/answer/95647?hl=en
                            </span>
                          )}{" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                          target="_blank"
                          style={{ wordWrap: "break-word" }}
                        >
                          {language === "en" ? (
                            "Safari: https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                          ) : (
                            <span dir="rtl">
                              سفاري:
                              https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                            </span>
                          )}{" "}
                        </a>
                      </li>
                    </ul>
                  </div>

                  <strong className="pl-bold">
                    {language === "en"
                      ? "FIRST-PARTY COOKIES"
                      : "ملفات تعريف الارتباط اgلطرف الأول"}
                  </strong>

                  <p>
                    {language === "en" ? (
                      "We use Cookies to enhance the performance of our website and personalize your online Ishro experience.  Cookies help us to collect information on how people use our website and which pages they visit. They enable us to monitor the number of visitors and to analyze website usage patterns and trends. We collect this information anonymously, so it does not identify anyone as an individual and no personal information is stored in our Cookies. We always use Cookie data in a responsible way."
                    ) : (
                      <span dir="rtl">
                        نحن نستخدم ملفات تعريف الارتباط لتحسين أداء موقعنا
                        وتخصيص تجربة Ishro الخاصة بك على الإنترنت. تساعدنا ملفات
                        تعريف الارتباط في جمع معلومات حول كيفية استخدام الأشخاص
                        لموقعنا والصفحات التي يزورونها، وتمكننا من مراقبة عدد
                        الزوار وتحليل أنماط واتجاهات استخدام الموقع. نقوم بجمع
                        هذه المعلومات بشكل مجّهل، لذلك لا تحدد هوية أي شخص كفرد
                        ولا يتم تخزين أي معلومات شخصية في ملفات تعريف الارتباط
                        الخاصة بنا. نحن دائماً ما نستخدم بيانات ملفات تعريف
                        الارتباط بطريقة مسؤولة.
                      </span>
                    )}
                  </p>

                  <strong className="pl-bold">
                    {language === "en"
                      ? "THIRD-PARTY COOKIES"
                      : "ملفات تعريف ارتباط للأطراف الثالثة"}
                  </strong>

                  <p>
                    {language === "en"
                      ? "Third-party cookies may come from partners or third-party companies that provide functional web services or tools for our website and the optimal functioning and operation of our services. We use third party cookies responsibly and for the sole purpose of providing optimal functioning of the platform and services. You may opt out of these cookies by following the cookie removal information contained in this document or the technical information of the browser from which you access our website and services."
                      : "قد تأتي ملفات تعريف الارتباط للأطراف الثالثة من شركاء أو شركات تابعة لأطراف ثالثة توفر خدمات أو أدوات شبكية وظيفية لموقعنا والتشغيل الأمثل لخدماتنا وإدارتها، ونحن نستخدم ملفات تعريف ارتباط للأطراف الثالثة بشكل مسؤول ولغرض وحيد هو توفير الأداء الأمثل للمنصة والخدمات. يمكنك إلغاء الاشتراك في ملفات تعريف الارتباط هذه باتباع معلومات إزالة ملفات تعريف الارتباط الواردة في هذا المستند أو المعلومات الفنية للمتصفح الذي تصل من خلاله إلى موقعنا وخدماتنا."}
                  </p>

                  <strong className="pl-bold">
                    {language === "en"
                      ? "PERSISTENT COOKIES"
                      : "ملفات تعريف الارتباط الدائمة"}
                  </strong>

                  <p>
                    {language === "en" ? (
                      "We use persistent Cookies to improve your experience of using Ishro. This includes recording your acceptance of our Cookie Policy to remove the cookie message which first appears when you use Ishro. Persistent Cookies remain on your device until deleted manually or automatically."
                    ) : (
                      <span dir="rtl">
                        نحن نستخدم ملفات تعريف الارتباط الدائمة لتحسين تجربتك في
                        استخدام Ishro. يتضمن ذلك تسجيل موافقتك على سياسة ملفات
                        تعريف الارتباط الخاصة بنا لإزالة رسالة ملفات تعريف
                        الارتباط التي تظهر لأول مرة عند استخدام Ishro. تظل ملفات
                        تعريف الارتباط الدائمة على جهازك حتى يتم حذفها يدوياً أو
                        تلقائياً.
                      </span>
                    )}
                  </p>
                  <strong className="pl-bold">
                    {language === "en"
                      ? "SESSION COOKIES"
                      : "ملفات تعريف الارتباط للجلسة"}
                  </strong>
                  <p>
                    {language === "en" ? (
                      "Session cookies are used to keep the session open for users when they enter the website and platform with their credentials and password. Session cookies are temporary and are deleted from your machine when the session is closed and the browser is closed. We use session cookies to keep your session open when you use our services and to identify you on our system each time you log on to the platform by starting a session."
                    ) : (
                      <span dir="rtl">
                        تُستخدم ملفات تعريف الارتباط للجلسة لإبقاء الجلسة مفتوحة
                        للمستخدمين عند دخولهم إلى الموقع والمنصة باستخدام بيانات
                        الاعتماد وكلمة المرور الخاصة بهم. ملفات تعريف ارتباط
                        الجلسة مؤقتة ويتم حذفها من جهازك عند إغلاق الجلسة وإغلاق
                        المتصفح. نحن نستخدم ملفات تعريف الارتباط للجلسة لإبقاء
                        جلستك مفتوحة عند استخدام خدماتنا وللتعرف عليك على نظامنا
                        في كل مرة تقوم فيها بتسجيل الدخول إلى المنصة عن طريق بدء
                        جلسة.
                      </span>
                    )}
                  </p>
                  <p>
                    {language === "en" ? (
                      "The data collected by Ishro and/or through Cookies that may be placed on your computer will not be kept for longer than is necessary to fulfil the purposes mentioned above."
                    ) : (
                      <span dir="rtl">
                        لن يتم الاحتفاظ بالبيانات التي يتم جمعها من قبل Ishro و
                        / أو من خلال ملفات تعريف الارتباط التي قد يتم وضعها على
                        جهاز الحاسوب الخاص بك لفترة أطول مما هو ضروري لتحقيق
                        الأغراض المذكورة أعلاه.
                      </span>
                    )}
                  </p>
                  <strong className="pl-bold">
                    {language === "en"
                      ? "SOCIAL COOKIES"
                      : "ملفات تعريف الارتباط الاجتماعية"}
                  </strong>

                  <p>
                    {language === "en" ? (
                      "These cookies allow you to share our website and click “Like” on social networks like Instagram, Twitter, Google, and LinkedIn, etc. They also allow you interact with each distinct platform’s contents. The way these cookies are used and the information gathered is governed by the privacy policy of each social platform."
                    ) : (
                      <span dir="rtl">
                        تتيح لك ملفات تعريف الارتباط هذه مشاركة موقعنا والنقر
                        فوق “أعجبني” على شبكات التواصل الاجتماعي مثل انستغرام
                        وتويتر وجوجل ولينكد إن، إلخ. كما أنها تسمح لك بالتفاعل
                        مع محتويات كل منصة من هذه المنصات المختلفة، وطريقة
                        استخدام ملفات تعريف الارتباط والمعلومات التي يتم جمعها
                        تحكمها سياسة الخصوصية لكل منصة اجتماعية.
                      </span>
                    )}
                  </p>

                  <strong className="pl-bold">
                    {language === "en"
                      ? "USES OF COOKIES"
                      : "استخدامات ملفات تعريف الارتباط"}
                  </strong>
                  <p>
                    {language === "en" ? (
                      "Our Cookies are used for the following purposes:"
                    ) : (
                      <span dir="rtl">
                        تُستخدم ملفات تعريف الارتباط الخاصة بنا للأغراض التالية:
                      </span>
                    )}
                  </p>

                  <div className="col-sm-12 p-0 fl mt_width_bold pl_80">
                    <span>
                      <strong className="width_bold">
                        {language === "en" ? (
                          "Strictly Necessary:"
                        ) : (
                          <span dir="rtl">ضرورية للغاية:</span>
                        )}
                      </strong>
                      &nbsp;
                      {language === "en" ? (
                        "These Cookies are essential for Ishro to perform its basic functions."
                      ) : (
                        <span dir="rtl">
                          ملفات تعريف الارتباط هذه ضرورية لموقع Ishro من أجل
                          أداء وظائفه الأساسية.
                        </span>
                      )}
                    </span>
                  </div>

                  <div className="col-sm-12 p-0 fl mt_width_bold pl_80">
                    <span>
                      <strong className="width_bold">
                        {language === "en" ? (
                          "Security: "
                        ) : (
                          <span dir="rtl">الحماية:</span>
                        )}
                      </strong>
                      &nbsp;
                      {language === "en" ? (
                        "We use these Cookies to help identify and prevent potential security risks."
                      ) : (
                        <span dir="rtl">
                          نستخدم ملفات تعريف الارتباط هذه للمساعدة في تحديد
                          المخاطر الأمنية المحتملة ومنعها.
                        </span>
                      )}
                    </span>
                  </div>

                  <div className="col-sm-12 p-0 fl mt_width_bold pl_80">
                    <span>
                      <strong className="width_bold">
                        {language === "en" ? (
                          "Analytics and Performance: "
                        ) : (
                          <span dir="rtl">التحليلات والأداء: </span>
                        )}
                      </strong>
                      &nbsp;
                      {language === "en" ? (
                        "Performance Cookies collect information on how users interact with our website, including what pages are visited most, as well as other analytical data. We use these details to improve how our website function and to understand how users interact with them."
                      ) : (
                        <span dir="rtl">
                          تجمع ملفات تعريف الارتباط الخاصة بالأداء معلومات حول
                          كيفية تفاعل المستخدمين مع موقعنا، بما في ذلك الصفحات
                          الأكثر زيارة، بالإضافة إلى البيانات التحليلية الأخرى.
                          نستخدم هذه التفاصيل لتحسين كيفية عمل موقعنا وفهم كيفية
                          تفاعل المستخدمين معها.
                        </span>
                      )}
                    </span>
                  </div>

                  <div className="col-sm-12 p-0 fl mt_width_bold pl_80">
                    <span>
                      <strong className="width_bold">
                        {language === "en" ? (
                          "Advertising: "
                        ) : (
                          <span dir="rtl">الدعاية:</span>
                        )}
                      </strong>
                      &nbsp;
                      {language === "en" ? (
                        "These Cookies are used to display relevant advertising to visitors who use our services or visit website we host or provide, as well as to understand and report on the efficacy of ads served on our website. They track details such as the number of unique visitors, the number of times particular ads have been displayed, and the number of clicks the ads have received. They are also used to build user profiles, including showing you ads based on products you’ve viewed on our website. These are set by Ishro and trusted third party networks and are generally persistent in nature."
                      ) : (
                        <span dir="rtl">
                          تُستخدم ملفات تعريف الارتباط هذه لعرض الإعلانات ذات
                          الصلة للزوار الذين يستخدمون خدماتنا أو يزورون الموقع
                          الذي نستضيفه أو نقدمه، وكذلك لفهم فعالية الإعلانات
                          المعروضة على موقعنا وإنشاء التقارير عنها، وهي تتبع
                          تفاصيل مثل عدد الزوار المنفردين، وعدد المرات التي تم
                          فيها عرض إعلانات معينة، وعدد النقرات التي تلقتها
                          الإعلانات، كما يتم استخدامها لإنشاء ملفات تعريف
                          المستخدمين، بما في ذلك عرض الإعلانات لك بناءً على
                          المنتجات التي شاهدتها على موقعنا، ويتم إعداد هذه
                          الملفات من قبل Ishro وشبكات الأطراف الثالثة الموثوق
                          بها وهي دائمة في طبيعتها بشكل عام.
                        </span>
                      )}
                    </span>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "9. PROTECTING YOUR INFORMATION"
                  ) : (
                    <span dir="rtl">9. حماية معلوماتك</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "We work to protect the security of your information during transmission by using Secure Sockets Layer (SSL) software, which encrypts information you input. If transactions are processed on the Website, transaction information is transmitted to and from the Website in encrypted form using industry-standard SSL connections to help protect such information from interception. We restrict authorized access to your personal information to those persons who have a legitimate purpose to know that information to provide products or services to you and those persons you have authorized to have access to such information."
                    ) : (
                      <span dir="rtl">
                        نحن نعمل على حماية أمن المعلومات الخاصة بك أثناء الإرسال
                        وذلك عن طريق استخدام برمجية طبقة المنافذ الآمنة (SSL)،
                        والذي يقوم بتشفير المعلومات التي تقوم بإدخالها، وفي حالة
                        معالجة المعاملات على الموقع، يتم نقل معلومات المعاملات
                        من وإلى الموقع بشكل مشفر باستخدام اتصالات SSL القياسية
                        في هذا المجال للمساعدة في حماية هذه المعلومات من
                        الاعتراض. نحن نحصر الوصول المصرح به إلى معلوماتك الشخصية
                        على الأشخاص الذين لديهم غرض مشروع لمعرفة تلك المعلومات
                        لتقديم منتجات أو خدمات لك ولأولئك الأشخاص الذين سمحت لهم
                        بالوصول إلى هذه المعلومات.
                      </span>
                    )}
                  </span>

                  <span>
                    {language === "en" ? (
                      "Ishro follows generally accepted industry standards to protect the personal information submitted to us, both during transmission and once Ishro receives it. No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while Ishro strives to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security."
                    ) : (
                      <span dir="rtl">
                        يتبع Ishro معايير الصناعة المقبولة عموماً لحماية
                        المعلومات الشخصية المقدمة إلينا أثناء الإرسال وبمجرد
                        استلام Ishro لها. لا توجد طريقة نقل عبر الإنترنت أو
                        طريقة تخزين إلكتروني آمنة بنسبة 100٪، لذلك على الرغم من
                        أن Ishro يسعى جاهداً لاستخدام وسائل مقبولة تجارياً
                        لحماية معلوماتك الشخصية، لا يمكننا ضمان أمنها المطلق.
                      </span>
                    )}
                  </span>

                  <span>
                    {language === "en" ? (
                      "We will not sell, distribute, or lease your personal information to third parties unless we have your permission or are required by law to do so."
                    ) : (
                      <span dir="rtl">
                        لن نبيع أو نوزع أو نؤجر معلوماتك الشخصية لأطراف ثالثة ما
                        لم نحصل على إذن منك أو يطلب منا القانون القيام بذلك.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {language === "en" ? (
                    "10. EU MEMBERS’ RIGHTS (GDPR COMPLIANT)"
                  ) : (
                    <span dir="rtl">
                      10. حقوق أعضاء الاتحاد الأوروبي (متوافقة مع اللائحة العامة
                      لحماية البيانات)
                    </span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "If you are habitually located in the European Union, you have the right to access, rectify, download or erase your information, as well as the right to restrict and object to certain processing of your information. While some of these rights apply generally, certain rights apply only in certain limited circumstances."
                    ) : (
                      <span dir="rtl">
                        إذا كنت مقيماً بصفة اعتيادية في الاتحاد الأوروبي، فيحق
                        لك الوصول إلى معلوماتك أو تصحيحها أو تنزيلها أو محوها،
                        وكذلك الحق في تقييد والاعتراض على معالجة معينة
                        لمعلوماتك. بينما تنطبق بعض هذه الحقوق بشكل عام، فإن بعض
                        الحقوق تنطبق فقط في ظروف محدودة معينة.
                      </span>
                    )}
                  </span>

                  <span>
                    {language === "en" ? (
                      "We describe these rights below:"
                    ) : (
                      <span dir="rtl">نصف هذه الحقوق أدناه:</span>
                    )}
                  </span>

                  <ul className="col-sm-12  fl">
                    <li>
                      {" "}
                      <strong className="width_bold">
                        {language === "en" ? (
                          "Access and portability: "
                        ) : (
                          <span dir="rtl">
                            للوصول إلى ومعرفة المعلومات المخزنة في خوادمنا،
                            يمكنك إرسال طلبك إلينا من خلال معلومات الاتصال
                            الخاصة بنا.
                          </span>
                        )}
                      </strong>
                      &nbsp;
                      {language === "en" ? (
                        "to access and know what information is stored in our servers, you can send us your request through our contact information."
                      ) : (
                        <span dir="rtl">
                          للوصول إلى ومعرفة المعلومات المخزنة في خوادمنا، يمكنك
                          إرسال طلبك إلينا من خلال معلومات الاتصال الخاصة بنا.
                        </span>
                      )}
                    </li>

                    <li>
                      <strong className="width_bold">
                        {language === "en" ? (
                          "Rectify, Restrict, Limit, Delete: "
                        ) : (
                          <span dir="rtl">
                            التصحيح والتقييد والتحديد والحذف:{" "}
                          </span>
                        )}
                      </strong>
                      &nbsp;
                      {language === "en" ? (
                        "You can also rectify, restrict, limit or delete much of your information."
                      ) : (
                        <span dir="rtl">
                          يمكنك أيضاً تصحيح الكثير من معلوماتك أو تقييدها أو
                          تحديدها أو حذفها.
                        </span>
                      )}
                    </li>
                    <li>
                      <strong className="width_bold">
                        {language === "en" ? (
                          "Object: "
                        ) : (
                          <span dir="rtl">الاعتراض:</span>
                        )}
                      </strong>
                      &nbsp;
                      {language === "en" ? (
                        "When we process your information based on our legitimate interests as explained above, or in the public interest, you may object to this processing in certain circumstances. In such cases, we will stop processing your information unless we have compelling legitimate reasons to continue processing it or where it is necessary for legal reasons."
                      ) : (
                        <span dir="rtl">
                          عندما نعالج معلوماتك بناءً على مصالحنا المشروعة كما هو
                          موضح أعلاه، أو من أجل المصلحة العامة، يمكنك الاعتراض
                          على هذه المعالجة في ظروف معينة. في مثل هذه الحالات،
                          سنتوقف عن معالجة معلوماتك ما لم تكن لدينا أسباب مشروعة
                          مقنعة لمواصلة معالجتها أو عندما يكون ذلك ضرورياً
                          لأسباب قانونية.
                        </span>
                      )}
                    </li>

                    <li>
                      <strong className="width_bold">
                        {language === "en" ? (
                          "Revoke consent: "
                        ) : (
                          <span dir="rtl">إلغاء الموافقة:</span>
                        )}
                      </strong>
                      &nbsp;
                      {language === "en" ? (
                        "Where you have previously given your consent, such as to allow us to process and store your personal information, you have the right to revoke your consent to the processing and storage of your information at any time. For example, you may withdraw your consent by updating your settings. In certain cases, we may continue to process your information after you have withdrawn your consent if we have a legal basis for doing so or if your withdrawal of consent was limited to certain processing activities."
                      ) : (
                        <span dir="rtl">
                          إذا أعطيت موافقتك مسبقاً، مثل السماح لنا بمعالجة
                          معلوماتك الشخصية وتخزينها، فيحق لك إلغاء موافقتك على
                          معالجة معلوماتك وتخزينها في أي وقت. على سبيل المثال،
                          يمكنك سحب موافقتك عن طريق تحديث إعداداتك، وفي بعض
                          الحالات، قد نستمر في معالجة معلوماتك بعد أن تسحب
                          موافقتك إذا كان لدينا أساس قانوني للقيام بذلك أو إذا
                          كان سحب موافقتك مقصوراً على أنشطة معالجة معينة.
                        </span>
                      )}
                    </li>

                    <li>
                      <strong className="width_bold">
                        {language === "en" ? (
                          "Complaint: "
                        ) : (
                          <span dir="rtl">الشكوى:</span>
                        )}
                      </strong>
                      &nbsp;
                      {language === "en" ? (
                        "If you wish to file a complaint about our use of your information (and without prejudice to any other rights you may have), you have the right to do so with your local supervisory authority. Users can exercise all these rights by contacting us through the contact information or the contact page."
                      ) : (
                        <span dir="rtl">
                          إذا كنت ترغب في تقديم شكوى بشأن استخدامنا لمعلوماتك
                          (ودون المساس بأي حقوق أخرى قد تكون لديك)، فيحق لك
                          القيام بذلك مع سلطة الإشراف المحلية، ويمكن للمستخدمين
                          ممارسة جميع هذه الحقوق عن طريق الاتصال بنا من خلال
                          معلومات الاتصال أو صفحة الاتصال.
                        </span>
                      )}
                    </li>
                  </ul>
                  <p>
                    {language === "en" ? (
                      "Additionally, if you are a European resident, we note that we are processing your information in order to fulfil contracts we might have with you or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe."
                    ) : (
                      <span dir="rtl">
                        الإضافة إلى ذلك، إذا كنت مقيماً في أوروبا، فإننا نشير
                        إلى أننا نقوم بمعالجة معلوماتك من أجل الوفاء بالعقود
                        التي قد تكون لدينا معك أو لتحقيق مصالحنا التجارية
                        المشروعة المذكورة أعلاه. بالإضافة إلى ذلك، يرجى ملاحظة
                        أنه سيتم نقل معلوماتك خارج أوروبا.
                      </span>
                    )}
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "11. CHILDREN’S ONLINE PRIVACY PROTECTION "
                  ) : (
                    <span dir="rtl">11. حماية خصوصية الأطفال عبر الإنترنت</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "We are in compliance with the requirements of the GDPR (General Data Protection Regulation - European regulation) and the personal data protection laws of the United Arab Emirates, regarding the protection of the personal data of minors. We do not collect any information from anyone under 18 years of age. Our website and products are all directed to people who are at least 18 years old or older. If you become aware that your child has provided us with personal information, please contact us. If we become aware that a child has provided us with personal information, we will take steps to delete that information, terminate that person's account, and restrict access to that person. "
                    ) : (
                      <span dir="rtl">
                        نحن ملتزمون بمتطلبات اللائحة العامة لحماية البيانات
                        (GDPR - اللائحة الأوروبية) وقوانين حماية البيانات
                        الشخصية لدولة الإمارات العربية المتحدة، فيما يتعلق
                        بحماية البيانات الشخصية للقصر. نحن لا نجمع أي معلومات من
                        أي شخص أقل من 18 عاماً. موقعنا ومنتجاتنا كلها موجهة إلى
                        الأشخاص الذين يبلغون عن 18 عاماً أو أكبر. إذا علمت أن
                        طفلك قد زودنا بمعلومات شخصية، فيرجى الاتصال بنا. إذا
                        علمنا أن أحد الأطفال قد زودنا بمعلومات شخصية، فسنتخذ
                        الخطوات لحذف تلك المعلومات وإنهاء حساب هذا الشخص وتقييد
                        الوصول إلى هذا الشخص.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {language === "en" ? (
                    "12. EDITING AND DELETING INFORMATION"
                  ) : (
                    <span dir="rtl">12. معلومات التعديل و الحذف</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "If you believe that any information, we are holding on you is incorrect or incomplete, please write to or email us as soon as possible. We will promptly correct any information found to be incorrect. You can change, modify, rectify and delete your Information at any time, please contact us through the contact information. To opt-out of Ishro email, follow the instructions included in the email. Your request should be processed within 48 hours."
                    ) : (
                      <span dir="rtl">
                        إذا كنت تعتقد أن أي معلومات نحتفظ بها عنك غير صحيحة أو
                        غير كاملة، فيرجى مخاطبتنا أو مراسلتنا عبر البريد
                        الإلكتروني في أقرب وقت ممكن. سنقوم على الفور بتصحيح أي
                        معلومات تبين أنها غير صحيحة. يمكنك تغيير وتعديل وتصحيح
                        وحذف معلوماتك في أي وقت، لذا يرجى الاتصال بنا من خلال
                        معلومات الاتصال. لإلغاء الاشتراك في مراسلات Ishro عبر
                        البريد الإلكتروني، اتبع التعليمات الواردة في البريد
                        الإلكتروني. ستتم معالجة طلبك في غضون 48 ساعة.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "13. THIRD PARTIES "
                  ) : (
                    <span dir="rtl">13. الأطراف الثالثة</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "Except as otherwise expressly included in this Privacy Policy, this document addresses only the use and disclosure of information Ishro collects from you. If you disclose your information to others, whether other users or suppliers on Ishro, different rules may apply to their use or disclosure of the information you disclose to them. Ishro does not control the privacy policies of third parties, and you are subject to the privacy policies of those third parties where applicable. Ishro is not responsible for the privacy or security practices of other websites on the Internet, even those linked to or from the Ishro site. Ishro encourages you to ask questions before you disclose your personal information to others."
                    ) : (
                      <span dir="rtl">
                        باستثناء ما تم تضمينه صراحةً في سياسة الخصوصية هذه،
                        تتناول هذه الوثيقة فقط استخدام المعلومات التي يجمعها
                        Ishro منك والكشف عنها. إذا كشفت عن معلوماتك للآخرين،
                        سواء كانوا مستخدمين أو موردين آخرين في Ishro، قد تنطبق
                        قواعد مختلفة على استخدامها أو الكشف عن المعلومات التي
                        تكشف أنت عنها. Ishro لا يتحكم في سياسات الخصوصية الخاصة
                        بالأطراف الثالثة، وأنت تخضع لسياسات الخصوصية لتلك
                        الأطراف الثالثة عند الاقتضاء. Ishro ليس مسؤولاً عن
                        ممارسات الخصوصية أو الأمان للمواقع الأخرى على الإنترنت،
                        حتى تلك المرتبطة بـموقع Ishro أو منه. Ishro يشجعك على
                        طرح الأسئلة قبل الكشف عن معلوماتك الشخصية للآخرين.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "14. CONTACT US"
                  ) : (
                    <span dir="rtl">14.اتصل بنا</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "If you have questions or concerns about these Privacy Policy and the handling and security of your data, please contact us through our contact page or via the contact information below:"
                    ) : (
                      <span dir="rtl">
                        إذا كانت لديك أسئلة أو استفسارات بشأن سياسة الخصوصية
                        هذه، يرجى الاتصال بنا من خلال صفحة الاتصال الخاصة بنا أو
                        عبر معلومات الاتصال أدناه:
                      </span>
                    )}
                  </span>
                  <strong className="pl_30">
                    {language === "en" ? (
                      "Ishro General Trading LLC."
                    ) : (
                      <span dir="rtl">إيشرو للتجارة العامة ش.ذ.م.م.</span>
                    )}
                  </strong>
                  <strong className="pl_30">
                    {language === "en" ? (
                      "Info@ishro.com, Office 606 Ivory II, Business Bay, Dubai United Arab Emirates PO BOX 24887 Phone: +971(4)575-4924"
                    ) : (
                      <span dir="rtl">
                        Info@ishro.com، مكتب 606 ايفوري 2، الخليج التجاري، دبي،
                        الإمارات العربية المتحدة، ص.ب. 24887 هاتف:
                        4924-575(4)971+
                      </span>
                    )}
                  </strong>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Hidden,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import IshroinsPoints from "../pages/profile/components/ishroinsPoints/IshroinsPoints";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import profileIcon from "../../../src/assets/image/profile.svg";
import storage from "../../../src/assets/image/storage.svg";
import notes from "../../../src/assets/image/notes.svg";
import support from "../../../src/assets/image/support.svg";
import contact from "../../../src/assets/image/contact.svg";
import about from "../../../src/assets/image/about.svg";
import warning from "../../../src/assets/image/warning.svg";
import arrow from "../../../src/assets/image/level/arrow_icon.svg";
import useStyles from "./style";
import { useTranslation } from "react-i18next";
import menuDialog from "../../store/actions/menuDialog";
import loginDialog from "../../store/actions/loginDialog";
import * as actionCreators from "../../store/actions";
import Favorite from "@material-ui/icons/Favorite";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import { GoogleLogout, useGoogleLogout } from "react-google-login";
import googleIcon from "../../../src/assets/image/google.svg";

function Menu() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [winnerId, setWinnerId] = useState();
  const navigate = useNavigate();
  const language = useSelector((state) => state.language);
  const isLoggedIn = useSelector((state) => state?.user?.isLoggedIn);
  const { signOut, loaded } = useGoogleLogout({
    clientId:
      "558254100989-penf7u5ea7vk4u7jiluqhugcc03rjojl.apps.googleusercontent.com",
  });
  const logout = () => {
    signOut();
    dispatch({ type: "CLEAR_USER_DATA" });
    navigate("/");
    window.location.reload();
  };
  const closeMenu = (e) => {
    dispatch(menuDialog(null));
  };

  const openLogin = (event) => {
    dispatch(menuDialog(null));
    dispatch(loginDialog(event.currentTarget));
  };

  useEffect(() => {
    dispatch(actionCreators.appInfo())
      .then((res) => {
        setWinnerId(res.recentWinner.ticket_id);
      })
      .catch((error) => {});
  }, []);

  const setLanguage = () => {
    const lang = language === "en" ? "ar" : "en";
    dispatch({ type: "SET_LANGUAGE", payload: lang });
  };
  return (
    <Box className={classes.container}>
      {!isLoggedIn ? (
        <Grid container justifyContent="center">
          <Grid
            container
            md={10}
            sm={10}
            className={classes.menu}
            style={{ padding: "10px" }}
            justifyContent="center"
            alignItems="center"
          >
            <Typography align="center" variant="caption">
              {t("Login to your account")}
            </Typography>
            <img src={warning} alt="" className={classes.icon} />
            <Typography
              variant="caption"
              gutterBottom
              style={{ marginBottom: "10px" }}
            >
              {t(
                "Login to unlock features like reward points, favorites, and many more."
              )}
            </Typography>
            <Hidden smDown>
              <Button variant="contained" fullWidth onClick={openLogin}>
                {t("Login")}
              </Button>
            </Hidden>
            <Hidden mdUp>
              <Button variant="contained" fullWidth href="/login">
                {t("Login")}
              </Button>
            </Hidden>
          </Grid>
        </Grid>
      ) : (
        <>
          <IshroinsPoints />

          <Grid container justifyContent="center">
            <Grid container md={10} sm={10} className={classes.menu}>
              <List className={classes.list}>
                <Link to="/profile" onClick={closeMenu}>
                  <ListItem button>
                    <img src={profileIcon} alt="" className={classes.icon} />
                    <ListItemText primary={t("Profile")} />
                    <ArrowForwardIosIcon
                      fontSize="small"
                      style={
                        language === "ar" ? { transform: "rotate(180deg)" } : {}
                      }
                    />
                  </ListItem>
                </Link>
                <Link to="/ishroins" onClick={closeMenu}>
                  <ListItem button>
                    <img src={storage} alt="" className={classes.icon} />
                    <ListItemText primary={t("Ishroins")} />
                    <ArrowForwardIosIcon
                      fontSize="small"
                      style={
                        language === "ar" ? { transform: "rotate(180deg)" } : {}
                      }
                    />
                  </ListItem>
                </Link>
                <Link to="/ticket" onClick={closeMenu}>
                  <ListItem button>
                    <img src={notes} alt="" className={classes.icon} />
                    <ListItemText primary={t("Tickets")} />
                    <ArrowForwardIosIcon
                      fontSize="small"
                      style={
                        language === "ar" ? { transform: "rotate(180deg)" } : {}
                      }
                    />
                  </ListItem>
                </Link>
                <Link to="/level" onClick={closeMenu}>
                  <ListItem button>
                    <img src={arrow} alt="" className={classes.icon} />
                    <ListItemText primary={t("Levels")} />
                    <ArrowForwardIosIcon
                      fontSize="small"
                      style={
                        language === "ar" ? { transform: "rotate(180deg)" } : {}
                      }
                    />
                  </ListItem>
                </Link>
              </List>
            </Grid>
          </Grid>
        </>
      )}

      <Grid container justifyContent="center">
        <Hidden lgUp>
          <Grid container md={10} sm={10} className={classes.menu}>
            <List className={classes.list}>
              <Link to="/products" onClick={closeMenu}>
                <ListItem button>
                  <img src={notes} alt="" className={classes.icon} />
                  <ListItemText primary={t("Products")} />
                  <ArrowForwardIosIcon
                    fontSize="small"
                    style={
                      language === "ar" ? { transform: "rotate(180deg)" } : {}
                    }
                  />
                </ListItem>
              </Link>
              <Link to="/news" onClick={closeMenu}>
                <ListItem button>
                  <img src={notes} alt="" className={classes.icon} />
                  <ListItemText primary={t("News")} />
                  <ArrowForwardIosIcon
                    fontSize="small"
                    style={
                      language === "ar" ? { transform: "rotate(180deg)" } : {}
                    }
                  />
                </ListItem>
              </Link>
              <Link to={`/winner/details/${winnerId}`} onClick={closeMenu}>
                <ListItem button>
                  <img src={notes} alt="" className={classes.icon} />
                  <ListItemText primary={t("Winners")} />
                  <ArrowForwardIosIcon
                    fontSize="small"
                    style={
                      language === "ar" ? { transform: "rotate(180deg)" } : {}
                    }
                  />
                </ListItem>
              </Link>
              <Link to={""} onClick={() => setLanguage()}>
                <ListItem button>
                  <img src={about} alt="" className={classes.icon} />
                  <ListItemText
                    primary={language === "en" ? t("English") : t("arabic")}
                  />
                  <ArrowForwardIosIcon
                    fontSize="small"
                    style={
                      language === "ar" ? { transform: "rotate(180deg)" } : {}
                    }
                  />
                </ListItem>
              </Link>
            </List>
          </Grid>

          <Grid container md={10} sm={10} className={classes.menu}>
            <List className={classes.list}>
              <Link to="/favourites" onClick={closeMenu}>
                <ListItem button>
                  <Favorite style={{ paddingRight: "2%" }} />
                  <ListItemText primary={t("Favourites")} />
                  <ArrowForwardIosIcon
                    fontSize="small"
                    style={
                      language === "ar" ? { transform: "rotate(180deg)" } : {}
                    }
                  />
                </ListItem>
              </Link>

              <Link to="/cart" onClick={closeMenu}>
                <ListItem button>
                  <ShoppingCart style={{ paddingRight: "2%" }} />
                  <ListItemText primary={t("Cart")} />
                  <ArrowForwardIosIcon
                    fontSize="small"
                    style={
                      language === "ar" ? { transform: "rotate(180deg)" } : {}
                    }
                  />
                </ListItem>
              </Link>
            </List>
          </Grid>
        </Hidden>

        <Grid container md={10} sm={10} className={classes.menu}>
          <List className={classes.list}>
            <Link to="/support" onClick={closeMenu}>
              <ListItem button>
                <img src={support} alt="" className={classes.icon} />
                <ListItemText primary={t("Support")} />
                <ArrowForwardIosIcon
                  fontSize="small"
                  style={
                    language === "ar" ? { transform: "rotate(180deg)" } : {}
                  }
                />
              </ListItem>
            </Link>
            <Link to="/contactUs" onClick={closeMenu}>
              <ListItem button>
                <img src={contact} alt="" className={classes.icon} />
                <ListItemText primary={t("Contact Us")} />
                <ArrowForwardIosIcon
                  fontSize="small"
                  style={
                    language === "ar" ? { transform: "rotate(180deg)" } : {}
                  }
                />
              </ListItem>
            </Link>
            <Link to="/aboutUs" onClick={closeMenu}>
              <ListItem button>
                <img src={about} alt="" className={classes.icon} />
                <ListItemText primary={t("About Us")} />
                <ArrowForwardIosIcon
                  fontSize="small"
                  style={
                    language === "ar" ? { transform: "rotate(180deg)" } : {}
                  }
                />
              </ListItem>
            </Link>
          </List>
        </Grid>

        {isLoggedIn && (
          <Grid container md={10} sm={10}>
            <Button variant="contained" fullWidth onClick={logout}>
              {t("LOGOUT")}
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default Menu;

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    minHeight: "70vh",
  },
  items: {
    margin: "30px 0px",
  },
  summary: {
    background: "white",

    "& .MuiTypography-root ": {
      color: "black",
      textAlign: "start",
    },
  },
  checkout: {
    backgroundColor: theme.palette.background.light,
    padding: "11% 4% 9% 4%",
    color: "black",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .MuiCard-root": {
      margin: "20px 0px",
      color: "#FFFFFF",
    },
  },
  selectorBtn: {
    margin: "10px 0px",
    color: "white",
    width: "35%",
    backgroundColor: theme.palette.background.default,

    "&:hover": {
      backgroundColor: theme.palette.background.blue,
    },
  },
  buttonsBox: {
    height: "auto",
  },
  button: {
    margin: "10px 0px",
  },
  title: {
    fontFamily: "Almarai-Bold",
    fontSize: "30px",
    margin: "7% 0%",
    color: "#494848",
    [theme.breakpoints.down("sm")]: { fontSize: "23px", alignItems: "center" },
  },
}));

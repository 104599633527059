import { Types } from "../actions/actionTypes";

const initialState = {
    news: []
};

const news = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_NEWS:
      return {
        ...state,
        news: payload.news,
      };
    case Types.CLEAR_NEWS:
      return {
        ...state,
        news: [],
      };
    default:
      return state;
  }
};

export default news;

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  card: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    margin: "20px 0px",
    borderRadius: "27px",
    padding: "5%",
    [theme.breakpoints.down("sm")]: {
      margin: "20px",
    },
  },
  divider: {
    borderColor: "#707070",
  },
  imageSide: {
    justifyContent: "center",
    alignContent: "space-between",
    borderLeft: "1px solid #707070",
  },
  spinner: {
    color: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    margin: "10px 0px",
    "& .MuiButtonGroup-groupedContainedPrimary:not(:last-child)": {
      borderColor: theme.palette.secondary.main,
    },
  },
  image: {
    width: "366px",
    height: "204px",
  },
  input: {
    border: "none",
    width: "40px",
    background: theme.palette.secondary.main,
    textAlign: "center",
  },
  button: {
    color: `${theme.palette.primary.main} !important`,
    background: theme.palette.secondary.main,
    border: "none",
    borderRadius: "20px",
    "&:hover": {
      background: theme.palette.secondary.main,
    },
  },
  ticketQtyBox: {
    background: theme.palette.secondary.main,
    display: "inline-block",
    padding: "10px",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    textAlign: "center",
  },
  title: {
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  title2: {
    fontFamily: "Almarai-SemiBold",
    fontSize: "20px",
  },
}));

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
 import Lottie from "react-lottie";
 import loader from "../../lotties/loader.json";
 import loadervip from "../../lotties/loadervip.json";

const useStyles = makeStyles((theme) => ({
  loader: {
    zIndex: 10000,
    position: "fixed",
    inset: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#353434",
    color: "#ffffff",
    height: "100%",
    width: "100%",
  },
}));
function Loader({ state = false }) {
  const classes = useStyles();
  const loading = useSelector((state) => state.loading);
  const user = useSelector((state) => state.user?.user);
  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [loading]);
  return (
    <>
      {loading && (
        <div className={classes.loader}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: user?.switched != "1" ? loader : loadervip,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={250}
            width={250}
          />
        </div>
      )}
    </>
  );
}

export default Loader;

import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  margin: {
    margin: "5% 5%",
  },
  timeTxt: {
    fontFamily: "Almarai-Regular",
    fontSize: "12px",
    color: theme.palette.text.secondary,
  },
  link: {
    fontFamily: "Almarai-Regular",
    fontSize: "14px",
    color: theme.palette.text.secondary,
    paddingBottom: "29px",
  },
  downloadTxt: {
    fontFamily: "Almarai-Regular",
    fontSize: "16px",
    color: theme.palette.text.secondary,
  },
  text: {
    color: "#ffffff",
    fontSize: "14px",
    margin: "10px",
    [theme.breakpoints.up("md")]: {
      display: "inline-block",
    },
  },
}));

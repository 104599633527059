import { makeStyles } from "@material-ui/core/styles";
import ishroins_background from "../../../../assets/image/ishroins_background.jpg";

export default makeStyles((theme) => ({
  checkout: {
    backgroundColor: theme.palette.background.light,
    padding: "8% 2.5% 10% ",
    color: "black",
    "& .MuiCard-root": {
      margin: "20px 0px",
      color: "#FFFFFF",
    },
  },
  donationBox: {
    padding: "0px",
    height: "auto",
    margin: "0px !important",
    marginTop: "10px !important",
    marginBottom: "2px !important",
    boxShadow: "none !important",
  },
  title: {
    fontFamily: "Almarai-Bold",
    fontSize: "30px",
    marginBottom: "5%",
    [theme.breakpoints.down("sm")]: { fontSize: "23px", alignItems: "center" },
  },
  selectorBtn: {
    margin: "10px 0px",
    color: "white",
    width: "35%",
    backgroundImage: "linear-gradient(180deg, #0077BE,#0077BE)",
  },
  discounttext: {
    fontFamily: "Almarai-SemiBold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.primary,
  },
  selectorBtn1: {
    margin: "10px 0px",
    width: "35%",
  },
  notselectorBtn: {
    margin: "10px 0px",
    color: "white",
    width: "35%",
    backgroundImage: "linear-gradient(180deg, #5E5E5E,#5E5E5E)",
  },
  leftDevider: {
    transform: "translateX(-10px)",
    borderStart: "2px solid #DDD",
    padding: "0px 10px",
  },
  buttonsBox: {
    margin: "0px 10%",
  },
  checkout_input: {
    background: "#EBEBEB",
    height: "35px",
    borderRadius: "8px",
    color: "black",
    padding: "9px 16px",
    marginBottom: "12px",
  },
  accordion: {
    marginBottom: "15px",
    borderRadius: "4px !important",
    padding: "0%",
  },
  payBtn: {
    borderRadius: "16px",
  },
  link: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  loginBtn: {
    width: "100%",
    marginBottom: "5%",
  },
  googlePayBtn: {
    width: "100%",
    marginBottom: "15px",
    "& .gpay-card-info-container": {
      width: "100%",
    },
  },
  // ishroinsCard: {
  //   background: `url(${ishroins_background})`,
  //   backgroundPosition:"left",
  //   backgroundRepeat: "no-repeat"
  // },
}));

import React, { useEffect } from "react";
import "./userAgreement.css";
import { useSelector, useDispatch } from "react-redux";
import loading from "../../../store/actions/loading";

import {
  makeStyles,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactGA from "react-ga";
const useStyles = makeStyles((theme) => ({
  paperDiv: {
    margin: "2% 0%",
    color: theme.palette.text.secondary,
    borderRadius: "4px",
  },
  heading: {
    fontFamily: "Almarai-SemiBold",
    paddingLeft: "24px!important",
    fontSize: "14px!important",
    [theme.breakpoints.down("sm")]: { paddingLeft: "8px!important" },
  },
  // Mobilebackground: {
  //   backgroundColor: theme.palette.primary.main,

  //   "& h3": {
  //     color: "white !important",
  //   },
  //   "& h4": {
  //     color: "white !important",
  //   },
  //   "& span": {
  //     color: "white !important",
  //   },
  //   "& p": {
  //     color: "white !important",
  //   },
  //   "& li": {
  //     color: "white !important",
  //   },
  //   "& div": {
  //     color: "white !important",
  //   },
  //   "& strong": {
  //     color: "white !important",
  //   },
  // },
}));

function UserAgreement({ mobile, lang = null }) {
  const dispatch = useDispatch();
  let language = useSelector((state) => state.language);
  if (lang) language = lang;
  const classes = useStyles();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div>
      <div className={`container-fluid mt_sticky pzero_mob`}>
        <div className="container Termcontainer">
          <div
            className={
              language === "en"
                ? "col-sm-12 p-0 wrapper_main_privacy"
                : "col-sm-12 p-0 wrapper_main_privacy wrapper_main_privacy_ar"
            }
          >
            <h3>{language === "en" ? "User Agreement" : "اتفاقية المستخدم"}</h3>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "1. ACCEPTING THIS USER AGREEMENT"
                  ) : (
                    <span dir="rtl">1. قبول اتفاقية المستخدم</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "This document, our general terms and conditions, privacy policy and draw terms and conditions referenced make up our User Agreement. The Terms are a legally binding contract between you and Ishro General Trading LLC. This contract sets out your rights and responsibilities when you use our website and our Mobile App (the “App” includes the “Website”, together referred to as “Platform”). Please read them carefully."
                    ) : (
                      <span dir="rtl">
                        تشكل هذه الوثيقة والشروط والأحكام العامة وسياسة الخصوصية
                        وشروط وأحكام السحب المشار إليها اتفاقية المستخدم الخاصة
                        بنا. الشروط هي عقد ملزم قانوناً بينك وبين "إيشرو للتجارة
                        العامة ش.ذ.م.م."، ويحدد هذا العقد حقوقك ومسؤولياتك عند
                        استخدامك لموقعنا وتطبيقنا للجوال ("التطبيق" يتضمن
                        "الموقع"، ويشار إليهما معاً باسم "المنصة")، لذا يرجى
                        قراءتها بعناية.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "BY USING OUR SERVICE, YOU’RE CREATING A LEGALLY BINDING CONTRACT TO COMPLY WITH ALL THE TERMS."
                    ) : (
                      <span dir="rtl">
                        من خلال استخدام خدمتنا، فإنك تنشئ عقداً ملزماً قانوناً
                        للامتثال لجميع الشروط.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {language === "en" ? (
                    "2. PREAMBLE"
                  ) : (
                    <span dir="rtl">2. تمهيد</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "By participating in the Promotion Draw, You, the User, have affirmatively reviewed, accepted, and agreed to all of the terms herein."
                    ) : (
                      <span dir="rtl">
                        من خلال المشاركة في السحب الترويجي، تكون أنت، المستخدم،
                        قد راجعت على نحو مؤكد جميع الشروط الواردة هنا وقبلتها
                        ووافقت عليها.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "3. CHANGES"
                  ) : (
                    <span dir="rtl">3. التغييرات</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "We may amend this User Agreement at any time by posting a revised version on our website. The revised version will be effective at the time we post it. If we change this User Agreement in a way that reduces your rights or increases your responsibilities, we will provide you with a notification."
                    ) : (
                      <span dir="rtl">
                        يجوز لنا تعديل اتفاقية المستخدم هذه في أي وقت عن طريق
                        نشر نسخة معدلة على موقعنا، على أن تسري النسخة المعدلة
                        وقت نشرها. وإذا قمنا بتغيير اتفاقية المستخدم هذه بطريقة
                        تقلل من حقوقك أو تزيد من مسؤولياتك، فسنقدم لك إشعاراَ.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {language === "en" ? (
                    "4. GENERAL TERMS AND CONDITIONS "
                  ) : (
                    <span dir="rtl">4. الشروط والأحكام العامة</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <div className="col-sm-12 p-0 fl">
                    <span>
                      {language === "en" ? (
                        "4.1 Eligibility for Membership"
                      ) : (
                        <span dir="rtl">4.1 الأهلية للعضوية </span>
                      )}
                    </span>
                  </div>

                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {" "}
                      {language === "en" ? (
                        "The User can only become a member only if:"
                      ) : (
                        <span dir="rtl">
                          يمكن للمستخدم أن يصبح عضواً فقط إذا:
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "(a) User is over the age of 18."
                      ) : (
                        <span dir="rtl">(أ) تجاوز عمر المستخدم 18 عاماً. </span>
                      )}
                    </p>
                  </div>
                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "(b) User only uses the website for lawful purposes. "
                      ) : (
                        <span dir="rtl">
                          (ب) يستخدم المستخدم الموقع فقط لأغراض مشروعة.{" "}
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "(c) User does not engage in any improper, indecent or offensive behavior while using the website. "
                      ) : (
                        <span dir="rtl">
                          (ج) لا يشارك المستخدم في أي سلوك غير لائق أو فاحش أو
                          مسيء أثناء استخدام الموقع.{" "}
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "(d) User is not breaking any law in your relevant jurisdiction by accessing this website."
                      ) : (
                        <span dir="rtl">
                          (د) لا يخالف المستخدم أي قانون في ولايته القضائية ذات
                          الصلة من خلال الوصول إلى هذا الموقع.{" "}
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="col-sm-12 p-0 fl">
                    <span>
                      {language === "en" ? (
                        "4.2 Registration and Membership Obligations "
                      ) : (
                        <span dir="rtl">4.2 التسجيل والعضوية</span>
                      )}
                    </span>
                  </div>

                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "4.2.1 Registration and membership is open to all-natural persons over eighteen years of age who have an interest in the promotion draw."
                      ) : (
                        <span dir="rtl">
                          4.2.1 التسجيل والعضوية مفتوحان لجميع الأشخاص الطبيعيين
                          الذين تزيد أعمارهم عن ثمانية عشر عاماً والذين لديهم
                          مصلحة في السحب الترويجي.{" "}
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "4.2.2 Each User shall only register once. "
                      ) : (
                        <span dir="rtl">
                          4.2.2 يجب على كل مستخدم التسجيل مرة واحدة فقط.
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "4.2.3 The User shall register online to become a Member."
                      ) : (
                        <span dir="rtl">
                          4.2.2 يجب على المستخدم التسجيل عبر الإنترنت ليصبح
                          عضواً.
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "4.2.3 The User shall register online to become a Member."
                      ) : (
                        <span dir="rtl">
                          4.2.2 يجب على المستخدم التسجيل عبر الإنترنت ليصبح
                          عضواً.
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "4.2.4 During the registration process, the User is required to give truthful and accurate information."
                      ) : (
                        <span dir="rtl">
                          4.2.4 أثناء عملية التسجيل، يتعين على المستخدم تقديم
                          معلومات صادقة ودقيقة.{" "}
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "4.2.5 Members shall expressly accept the terms of the Promotion Draw and are bound to comply with them. "
                      ) : (
                        <span dir="rtl">
                          4.2.5 يجب على الأعضاء قبول شروط السحب الترويجي صراحةً
                          ويلتزمون بالامتثال لها.{" "}
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "4.2.6 By registering with Ishro General Trading LLC, the User accepts that most communications with Ishro General Trading LLC and its Members will take place electronically."
                      ) : (
                        <span dir="rtl">
                          4.2.6 من خلال التسجيل في "إيشرو للتجارة العامة
                          ش.ذ.م.م."، يوافق المستخدم على أن معظم المراسلات مع
                          "إيشرو للتجارة العامة ش.ذ.م.م." وأعضائها ستتم
                          إلكترونياً.
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "4.2.7 It is the User’s responsibility to make sure that she/he has sufficient access to Internet to participate to the activities at the desired extent."
                      ) : (
                        <span dir="rtl">
                          4.2.7 تقع على عاتق المستخدم مسؤولية التأكد من أن لديه
                          / لديها سبل وصول كافية إلى الإنترنت للمشاركة في
                          الأنشطة بالقدر المطلوب.
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "4.2.8 Ishro General Trading LLC screens registration applications and reserves itself the right to reject a registration without stating the reasons."
                      ) : (
                        <span dir="rtl">
                          4.2.8 تقوم شركة "إيشرو للتجارة العامة ش.ذ.م.م." بفحص
                          طلبات التسجيل وتحتفظ لنفسها بالحق في رفض التسجيل دون
                          إبداء الأسباب.
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "4.2.9 It is expressly forbidden to place accounts at the disposal of third parties and to disclose access details to third parties."
                      ) : (
                        <span dir="rtl">
                          4.2.9 يحظر صراحة وضع الحسابات تحت تصرف أطراف ثالثة
                          والكشف عن تفاصيل الوصول إلى أطراف ثالثة.
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "4.2.10 Registration as a Member results in a contractual relationship between Ishro General Trading LLC and the Member. By accepting the Terms, they become a contract component. The member receives a personal, non-transferable right, revocable at any time, to use the contents and services provided by the Website and undertakes to comply with these Terms and the restrictions set out in them during such use."
                      ) : (
                        <span dir="rtl">
                          4.2.10 ينتج عن التسجيل كعضو علاقة تعاقدية بين شركة
                          "إيشرو للتجارة العامة ش.ذ.م.م." والعضو. ومن خلال قبول
                          الشروط، فإنها تصبح مكوناً في العقد. يتلقى العضو حقاً
                          شخصياً غير قابل للنقل وقابل للإلغاء في أي وقت لاستخدام
                          المحتويات والخدمات التي يوفرها الموقع ويتعهد بالامتثال
                          لهذه الشروط والقيود المنصوص عليها فيها أثناء هذا
                          الاستخدام.
                        </span>
                      )}
                    </p>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en"
                    ? "5. ELECTRONIC COMMUNICATION"
                    : "5. الاتصالات الالكترونية"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <div className="col-sm-12 p-0 fl">
                    <span>
                      {language === "en" ? (
                        "Ishro General Trading LLC may in its sole discretion, decide to deliver any document or information to the members on its platform by electronic means. The member hereby consents to receive such documents or information by electronic delivery and agrees to participate in the Promotion Draw through an online or electronic system established and maintained by Ishro General Trading LLC."
                      ) : (
                        <span dir="rtl">
                          يجوز لشركة "إيشرو للتجارة العامة ش.ذ.م.م."، وفقاً
                          لتقديرها المطلق، أن تقرر تسليم أي مستند أو معلومات إلى
                          الأعضاء على منصتها بالوسائل الإلكترونية، ويوافق العضو
                          بموجبه على تلقي هذه المستندات أو المعلومات عن طريق
                          التسليم الإلكتروني ويوافق على المشاركة في السحب
                          الترويجي من خلال نظام إلكتروني أو على الإنترنت تقوم
                          على إنشاؤه وصيانته شركة "إيشرو للتجارة العامة
                          ش.ذ.م.م.".
                        </span>
                      )}
                    </span>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? "6. CONDUCT" : "6. السلوك"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "All members using the platform shall adhere to basic rules of conduct. In particular it is prohibited for members to:"
                    ) : (
                      <span dir="rtl">
                        يجب على جميع الأعضاء الذين يستخدمون المنصة الالتزام
                        بقواعد السلوك الأساسية، وعلى وجه الخصوص يحظر على
                        الأعضاء:
                      </span>
                    )}
                  </span>

                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "6.1 openly issue offensive comments and foster or incite racism, bigotry, hatred or physical violence of any kind against other members and Ishro General Trading LLC."
                      ) : (
                        <span dir="rtl">
                          6.1 إبداء تعليقات مسيئة علانيةً والتشجيع أو التحريض
                          على العنصرية أو التعصب أو الكراهية أو العنف الجسدي من
                          أي نوع ضد الأعضاء الآخرين وشركة "إيشرو للتجارة العامة
                          ش.ذ.م.م.".
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "6.2 issue comments that are offensive, threatening, obscene, disrespectful, indecent, defamatory or libelous or causes harassment in another way or supports such undesirable forms of behavior."
                      ) : (
                        <span dir="rtl">
                          6.2 إبداء تعليقات مسيئة أو مهددة أو فاحشة أو غير
                          محترمة أو غير لائقة أو تشهيرية أو مشوهة للسمعة أو تسبب
                          المضايقة بأي طريقة أخرى أو تدعم مثل هذه الأشكال غير
                          المرغوب فيها من السلوك.
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "6.3 publish information which poses a risk to the protection of personal data or security."
                      ) : (
                        <span dir="rtl">
                          6.3 نشر معلومات تشكل خطراً على حماية البيانات الشخصية
                          أو الأمان.
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "6.4 contain or endorse information which, to the best of the member’s knowledge, is false or misleading."
                      ) : (
                        <span dir="rtl">
                          6.4 تضمين أو تأييد معلومات خاطئة أو مضللة على حد علم
                          العضو.
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "6.5 infringe, or attempt to infringe, in particular privacy laws, contract law or the rights of third parties."
                      ) : (
                        <span dir="rtl">
                          6.5 إنتهاك أو محاولة انتهاك قوانين الخصوصية أو قانون
                          العقود أو حقوق الأطراف الثالثة على وجه الخصوص.
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "6.6 Attempting to or actually manipulating or misusing the feedback system. "
                      ) : (
                        <span dir="rtl">
                          6.6 محاولة التلاعب أو إساءة استخدام نظام التغذية
                          الراجعة.{" "}
                        </span>
                      )}
                    </p>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? "7. PAYMENT GATEWAY" : "7. بوابة الدفع"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "7.1 All Users who pay for services on our platform shall do so through a direct transfer to our payment provider via a secured connection."
                      ) : (
                        <span dir="rtl">
                          7.1 يجب على جميع المستخدمين الذين يدفعون مقابل الخدمات
                          على منصتنا القيام بذلك من خلال تحويل مباشر إلى مزود
                          الدفع الخاص بنا عبر اتصال آمن.
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "7.2 The person making the payment will be mandated to have copies of the merchant policies and rules as well as a copy of the transaction records."
                      ) : (
                        <span dir="rtl">
                          7.2 سيكون الشخص الذي يقوم بالدفع مكلفاً بالحصول على
                          نسخ من سياسات وقواعد التاجر بالإضافة إلى نسخة من سجلات
                          المعاملات.{" "}
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "7.3 It is important to note that the cardholder’s monthly statement may contain multiple postings because of multiple transactions."
                      ) : (
                        <span dir="rtl">
                          7.3 من المهم ملاحظة أن كشف الحساب الشهري لحامل البطاقة
                          قد يحتوي على إشارات متعددة بسبب معاملات متعددة.
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "7.4 The product prices per unit displayed on our platform are inclusive of VAT."
                      ) : (
                        <span dir="rtl">
                          7.4 تشمل أسعار المنتج لكل وحدة معروضة على منصتنا ضريبة
                          القيمة المضافة.
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "7.5 Due to the fact that our platform is accessible worldwide, users will be charged either in US dollars or the currency of their preference based on their credit card type and location."
                      ) : (
                        <span dir="rtl">
                          7.5 نظراً لحقيقة أن منصتنا يمكن الوصول إليه في جميع
                          أنحاء العالم، فسيتم فرض رسوم على المستخدمين إما
                          بالدولار الأمريكي أو بالعملة التي يفضلونها بناءً على
                          نوع بطاقة الائتمان الخاصة بهم ومكانهم.
                        </span>
                      )}
                    </p>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "8. REFUND AND CANCELLATION POLICY"
                  ) : (
                    <span dir="rtl">8. سياسة الاسترداد والإلغاء</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "8.1 There is no cancellation option once a sale has been completed, it is deemed to be final."
                      ) : (
                        <span dir="rtl">
                          8.1 لا يوجد خيار للإلغاء، وبمجرد إتمام عملية البيع
                          تعتبر نهائية.
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "8.2 In the event of any defective products, Ishro General Trading LLC will offer the user a replacement of the defective product on condition that there are warranties and/or guarantees offered by the manufacturer of the product. Ishro General Trading LLC shall not be liable for any defective nature of the prize awarded from the Promotion Draw."
                      ) : (
                        <span dir="rtl">
                          8.2 في حالة استلام أي منتجات معيبة، ستقدم شركة "إيشرو
                          للتجارة العامة ش.ذ.م.م." للمستخدم بديلاً للمنتج المعيب
                          بشرط وجود ضمانات و / أو كفالات مقدمة من الشركة المصنعة
                          للمنتج. لن تكون شركة "إيشرو للتجارة العامة ش.ذ.م.م."
                          مسؤولة عن أي طبيعة معيبة للجائزة الممنوحة من السحب
                          الترويجي.
                        </span>
                      )}
                    </p>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "9. INTELLECTUAL PROPERTY RIGHTS "
                  ) : (
                    <span dir="rtl">9. حقوق الملكية الفكرية</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "Ishro General Trading LLC retains all right, title, and interest in and to all Intellectual Property Rights related in and to the Platform and the Promotion Draw. The Ishro General Trading LLC logos and names are trademarks of Ishro General Trading LLC and are registered in UAE."
                      ) : (
                        <span dir="rtl">
                          تحتفظ شركة "إيشرو للتجارة العامة ش.ذ.م.م." بجميع
                          الحقوق والملكيات والمصالح في ولجميع حقوق الملكية
                          الفكرية المتعلقة بالمنصة والسحب الترويجي. شعارات
                          وأسماء شركة "إيشرو للتجارة العامة ش.ذ.م.م." هي علامات
                          تجارية لشركة "إيشرو للتجارة العامة ش.ذ.م.م." ومسجلة في
                          الإمارات العربية المتحدة.
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "When you post content on the Platform, you understand and acknowledge that you are solely responsible for such content. Further, you represent and warrant that you have the right, power, and authority to post that content without violating the rights of third parties."
                      ) : (
                        <span dir="rtl">
                          عندما تنشر محتوى على المنصة، فإنك تدرك وتقر أنك
                          المسؤول الوحيد عن هذا المحتوى. علاوة على ذلك، أنت تقر
                          وتضمن أن لديك الحق والسلطة والصلاحية لنشر هذا المحتوى
                          دون انتهاك حقوق الأطراف الثالثة.
                        </span>
                      )}
                    </p>
                  </div>

                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "You acknowledge and agree that the poster of content, and not Ishro General Trading LLC, is responsible for any User content including any harms caused to you, another User, or a third party by such User Content."
                      ) : (
                        <span dir="rtl">
                          أنت تقر وتوافق على أن ناشر المحتوى، وليس شركة "إيشرو
                          للتجارة العامة ش.ذ.م.م."، مسؤولاً عن أي محتوى مستخدم
                          بما في ذلك أي ضرر يلحق بك أو بمستخدم آخر أو لطرف ثالث
                          بسبب محتوى المستخدم هذا.
                        </span>
                      )}
                    </p>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "10. USER SUBMISSIONS"
                  ) : (
                    <span dir="rtl">10. المواد المقدمة من المستخدم</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "You agree that you will be responsible for your use of the Platform, and you agree to defend and indemnify Ishro General Trading LLC from and against every claim, liability, damage, loss, and expense, including reasonable attorneys’ fees and costs, arising out of or in any way connected with: "
                      ) : (
                        <span dir="rtl">
                          أنت توافق على أنك ستكون مسؤولاً عن استخدامك للمنصة،
                          وتوافق على الدفاع عن شركة "إيشرو للتجارة العامة
                          ش.ذ.م.م." وتعويضها عن وضد كل مطالبة ومسؤولية وضرر
                          وخسارة ونفقة، بما في ذلك أتعاب المحاماة المعقولة
                          والتكاليف الناشئة عن أو مرتبطة بأي شكل من الأشكال بما
                          يلي:{" "}
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "(i) your access to, use of, or alleged use of, the Platform. (ii) your violation of any portion of these Terms, any representation, warranty, or agreement referenced in these Terms, or any applicable law or regulation. (iii) your violation of any third party right, including any intellectual property right or publicity, confidentiality, other property, or privacy right. or (iv) any dispute or issue between you and any third party. "
                      ) : (
                        <span dir="rtl">
                          (أ) وصولك إلى المنصة أو استخدامها أو استخدامها
                          المزعوم؛ أو (ب) انتهاكك لأي جزء من هذه الشروط أو أي
                          إقرار أو ضمان أو اتفاقية مشار إليها في هذه الشروط أو
                          أي قانون أو لائحة معمول بها؛ أو (ج) انتهاكك لأي حق
                          لطرف ثالث، بما في ذلك أي حق من حقوق الملكية الفكرية أو
                          الدعاية أو السرية أو الملكية الأخرى أو حق الخصوصية؛ أو
                          (د) أي نزاع أو مسألة بينك وبين أي طرف ثالث.
                        </span>
                      )}
                    </p>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "11. DIGITAL MEDIA"
                  ) : (
                    <span dir="rtl">11. الوسائط الرقمية </span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "You consent that Ishro General Trading LLC can use your name at its own discretion in the event you emerge as the winner of any of the Promotion Draws. The consent is broad in the sense that it includes local and regional press as well as any social media outlet associated with Ishro General Trading LLC. "
                    ) : (
                      <span dir="rtl">
                        أنت توافق على أن شركة "إيشرو للتجارة العامة ش.ذ.م.م."
                        يمكنها استخدام اسمك وفقاً لتقديرها المطلق في حالة ظهورك
                        كفائز في أي من سحوبات الترويج. هذه الموافقة واسعة بمعنى
                        أنها تشمل الصحافة المحلية والإقليمية وكذلك أي منفذ وسائل
                        تواصل اجتماعي مرتبط بشركة "إيشرو للتجارة العامة
                        ش.ذ.م.م."
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "You consent that We have the right to use any and all digital records made by Us including, but not limited to, photographs, videos and call recordings of, the Prize, the Promotion Draw, the winning phone call and the collection of the Prize, on the Platform and on any social (online) media profile associated with Us. These digital records may include imagery and/or voice recordings of the Winner and/or their name."
                    ) : (
                      <span dir="rtl">
                        أنت توافق على أن لدينا الحق في استخدام أي وجميع السجلات
                        الرقمية التي أنشأناها بما في ذلك، على سبيل المثال لا
                        الحصر، الصور ومقاطع الفيديو وتسجيلات المكالمات والجائزة
                        والسحب الترويجي والمكالمة الهاتفية الفائزة وتحصيل
                        الجائزة على المنصة وفي أي تغطية إعلامية على وسائل
                        التواصل الاجتماعي (عبر الإنترنت) المرتبطة بنا، وقد تتضمن
                        هذه السجلات الرقمية صوراً و / أو تسجيلات صوتية للفائز و
                        / أو اسمه.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "12. DISCLAIMER FOR THIRD PARTY APPLICATIONS"
                  ) : (
                    <span dir="rtl">
                      12. إخلاء المسؤولية عن تطبيقات الأطراف الثالثة{" "}
                    </span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "Any links to third-party applications available on our platform are provided “as is” without warranty of any kind, either expressed or implied and such application is to be used at your own risk."
                    ) : (
                      <span dir="rtl">
                        يتم توفير أي روابط لتطبيقات الأطراف الثالثة المتاحة على
                        منصتنا "كما هي" دون أي ضمان من أي نوع، سواء أكان صريحاً
                        أم ضمنياً، ويجب استخدام مثل هذه التطبيقات على مسؤوليتك
                        الخاصة.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "The use of the third-party application links on the platform is done at your own discretion and risk and with agreement that you will be solely responsible for any damage to your computer system or loss of data that results from such activities. You are solely responsible for adequate protection and backup of the data and equipment used in connection with any of the application linked to our platform, and we will not be liable for any damages that you may suffer connection with downloading, installing, using, modifying or distributing such applications. No advice or information, whether oral or written, obtained by you from us or from our platform shall create any warranty for the application."
                    ) : (
                      <span dir="rtl">
                        يجري استخدام روابط تطبيقات الأطراف الثالثة على المنصة
                        وفقاً لتقديرك الخاص وعلى مسؤوليتك وباتفاق على أنك ستكون
                        مسؤولاً وحدك عن أي ضرر يلحق بنظام الحاسوب الخاص بك أو
                        فقدان البيانات الناتج عن هذه الأنشطة. أنت وحدك مسؤولاً
                        عن توفر الحماية الكافية والنسخ الاحتياطي للبيانات
                        والمعدات المستخدمة فيما يتعلق بأي تطبيق مرتبط بمنصتنا،
                        ولن نتحمل مسؤولية أي أضرار قد تتعرض لها أثناء التنزيل أو
                        التثبيت أو الاستخدام أو التعديل أو التوزيع لمثل هذه
                        التطبيقات. ولن تنشئ أي نصيحة أو معلومة، سواء كانت شفهية
                        أو مكتوبة، تحصل عليها منا أو من منصتنا، أي ضمان للتطبيق.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "Additionally, we make no warranty that:"
                    ) : (
                      <span dir="rtl">
                        بالإضافة إلى ذلك، لا نقدم أي ضمانات على ما يلي:
                      </span>
                    )}
                  </span>
                  <ul className="col-sm-12 p-0 fl">
                    <li>
                       
                      {language === "en" ? (
                        "The third-party application will meet your requirements."
                      ) : (
                        <span dir="rtl">
                          أن تطبيق الطرف الثالث سيلبي متطلباتك.
                        </span>
                      )}
                    </li>
                    <li>
                      {" "}
                      {language === "en" ? (
                        "The third-party application will be uninterrupted, timely, secure or error-free."
                      ) : (
                        <span dir="rtl">
                          أن تطبيق الطرف الثالث سيعمل دون انقطاع أو في الوقت
                          المناسب أو يكون آمن أو خالي من الأخطاء.
                        </span>
                      )}
                    </li>
                    <li>
                      {" "}
                      {language === "en" ? (
                        "The results from the use of the third-party application will be effective, accurate or reliable."
                      ) : (
                        <span dir="rtl">
                          أن نتائج استخدام تطبيق الطرف الثالث ستكون فعالة ودقيقة
                          وموثوقة.
                        </span>
                      )}
                    </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "13. LIMITATIONS OF LIABILITY"
                  ) : (
                    <span dir="rtl">13. حدود المسؤولية</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "In no event will Ishro General Trading LLC be liable to you for any indirect, incidental, special, consequential or punitive damages arising out of or relating to your access to or use of, or your inability to access or use, the Platform or any materials or content available through the Platform, whether based on warranty, contract, tort (including negligence), statute, or any other legal theory, and whether or not Ishro General Trading LLC has been informed of the possibility of damage."
                    ) : (
                      <span dir="rtl">
                        لن تكون شركة "إيشرو للتجارة العامة ش.ذ.م.م." مسؤولة بأي
                        حال من الأحوال تجاهك عن أي أضرار غير مباشرة أو عرضية أو
                        خاصة أو تبعية أو جزائية تنشأ عن أو تتعلق بوصولك إلى أو
                        استخدامك أو عدم قدرتك على الوصول إلى المنصة أو أي مواد
                        أو محتوى متاح من خلال المنصة، سواء استناداً إلى الضمان
                        أو العقد أو المسؤولية التقصيرية (بما في ذلك الإهمال) أو
                        التشريع أو أي نظرية قانونية أخرى، وسواء تم إبلاغ شركة
                        "إيشرو للتجارة العامة ش.ذ.م.م." بإمكانية حدوث هذا الضرر
                        أم لا.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "Ishro General Trading LLC shall not be responsible for any service interruptions, including, but not limited to, system failures or other interruptions that may affect purchases or sales, the receipt, processing, acceptance, completion or settlement of payment transactions, bank direct deposit transfers, or the Platform. "
                    ) : (
                      <span dir="rtl">
                        لن تكون شركة "إيشرو للتجارة العامة ش.ذ.م.م." مسؤولة عن
                        أي انقطاع في الخدمة، بما في ذلك، على سبيل المثال لا
                        الحصر، أعطال النظام أو الانقطاعات الأخرى التي قد تؤثر
                        على المشتريات أو المبيعات، أو استلام معاملات الدفع أو
                        معالجتها أو قبولها أو إكمالها أو تسويتها، أو تحويلات
                        الإيداع المصرفي المباشر، أو المنصة.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "14. INDEMNITY"
                  ) : (
                    <span dir="rtl">14. التعويض</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "You agree that you will be responsible for your use of the Platform, and you agree to defend and indemnify Ishro General Trading LLC from and against every claim, liability, damage, loss, and expense, including reasonable attorneys’ fees and costs, arising out of or in any way connected with: "
                    ) : (
                      <span dir="rtl">
                        أنت توافق على أنك ستكون مسؤولاً عن استخدامك للمنصة،
                        وتوافق على الدفاع عن شركة "إيشرو للتجارة العامة
                        ش.ذ.م.م." وتعويضها عن وضد كل مطالبة ومسؤولية وضرر وخسارة
                        ونفقة، بما في ذلك أتعاب المحاماة المعقولة والتكاليف
                        الناشئة عن أو مرتبطة بأي شكل من الأشكال بما يلي:{" "}
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "(i) your access to, use of, or alleged use of, the Platform."
                    ) : (
                      <span dir="rtl">
                        (أ) وصولك إلى المنصة أو استخدامها أو استخدامها المزعوم؛
                        أو{" "}
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "(ii) your violation of any portion of these Terms, any representation, warranty, or agreement referenced in these Terms, or any applicable law or regulation. "
                    ) : (
                      <span dir="rtl">
                        (ب) انتهاكك لأي جزء من هذه الشروط أو أي إقرار أو ضمان أو
                        اتفاقية مشار إليها في هذه الشروط أو أي قانون أو لائحة
                        معمول بها؛ أو{" "}
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "(iii) your violation of any third party right, including any intellectual property right or publicity, confidentiality, other property, or privacy right. or "
                    ) : (
                      <span dir="rtl">
                        (ج) انتهاكك لأي حق لطرف ثالث، بما في ذلك أي حق من حقوق
                        الملكية الفكرية أو الدعاية أو السرية أو الملكية الأخرى
                        أو حق الخصوصية؛ أو{" "}
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "(iv) any dispute or issue between you and any third party. "
                    ) : (
                      <span dir="rtl">
                        (د) أي نزاع أو مسألة بينك وبين أي طرف ثالث.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "15. NO SPAM POLICY "
                  ) : (
                    <span dir="rtl">15. سياسة عدم السماح بالبريد المزعج</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "You comprehend and concur that sending spontaneous email promotions or other spontaneous interchanges to Ishro email addresses or through Ishro data frameworks are explicitly restricted by these Terms. Any unapproved utilization of Ishro PC frameworks is an infringement of this User Agreement and certain relevant laws, specifically the UAE Cybercrimes enactment. Such infringement may subject the sender and their representatives to common and criminal punishments. If it's not too much trouble notes that the UAE Cybercrimes enactment conveys critical punishments including detainment. On the off chance that you plan to request or contact Our Users by getting their email or telephone numbers from Our Platform, we may report this conduct to the important specialists, who at that point may choose to indict you under the applicable UAE laws."
                    ) : (
                      <span dir="rtl">
                        أنت تدرك وتوافق على أن إرسال رسائل البريد الإلكتروني
                        الترويجية التلقائية أو غيرها من المراسلات التلقائية إلى
                        عناوين البريد الإلكتروني لإيشرو أو من خلال أنظمة بيانات
                        إيشرو مقيد صراحةً بهذه الشروط، ويعد أي استخدام غير مصرح
                        به لأنظمة الحواسيب الخاصة بإيشرو انتهاكاً لاتفاقية
                        المستخدم هذه وبعض القوانين المعمول بها، ولا سيما تشريعات
                        الجرائم الإلكترونية في دولة الإمارات العربية المتحدة. قد
                        تعرض هذه الانتهاكات المرسل وممثليه لعقوبات مدنية
                        وجنائية. يرجى العلم بأن تشريعات الجرائم الإلكترونية في
                        الإمارات العربية المتحدة تفرض عقوبات مغلظة، بما في ذلك
                        السجن. في حال كنت تنوي الوصول إلى مستخدمينا أو الاتصال
                        بهم من خلال الحصول على بريدهم الإلكتروني أو أرقام
                        هواتفهم من منصتنا، فقد نبلغ السلطات المعنية بهذا السلوك،
                        والتي قد تقرر بعد ذلك مقاضاتك بموجب القوانين ذات الصلة
                        لدولة الإمارات العربية المتحدة.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "16. LIMITATION AND TERMINATION OF SERVICE "
                  ) : (
                    <span dir="rtl">16. تقييد وإنهاء الخدمة</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "Without limiting Ishro Trading General LLC’s other rights or remedies, we may, but are not obligated to, temporarily or indefinitely revoke or limit access to the Platform, deny your registration, or permanently revoke your access to the Platform if: "
                    ) : (
                      <span dir="rtl">
                        دون تقييد للحقوق أو التعويضات الأخرى لشركة "إيشرو
                        للتجارة العامة ش.ذ.م.م."، يجوز لنا، ولكننا لسنا ملزمين،
                        مؤقتاً أو إلى أجل غير مسمى إلغاء أو تقييد الوصول إلى
                        المنصة، أو رفض تسجيلك، أو إلغاء وصولك إلى المنصة بشكل
                        دائم إذا:{" "}
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "(i) you breach the letter or spirit of any terms of this User Agreement. "
                    ) : (
                      <span dir="rtl">
                        (أ) انتهكت نص أو روح أي من شروط اتفاقية المستخدم هذه؛ أو
                        (ب) شككنا أو علمنا أنك قدمت لنا معلومات خاطئة أو مضللة؛
                        أو{" "}
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "(ii) we suspect or become aware that you have provided false or misleading information to us."
                    ) : (
                      <span dir="rtl">
                        (ب) شككنا أو علمنا أنك قدمت لنا معلومات خاطئة أو مضللة؛
                        أو{" "}
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "(iii) we believe, in our sole discretion, that your actions may cause legal liability for you, our Users or Us. or may involve illicit or illegal activity. or "
                    ) : (
                      <span dir="rtl">
                        (ج) أعتقدنا، وفقاً لتقديرنا المطلق، أن أفعالك قد تستتبع
                        مسؤولية قانونية عليك أو على مستخدمينا أو علينا؛ أو قد
                        تنطوي على نشاط غير مشروع أو غير قانوني؛ أو
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "(iv) we are required to by law, legal process, or law enforcement. "
                    ) : (
                      <span dir="rtl">
                        (د) كنا مطالبين بموجب القانون أو الإجراءات القانونية أو
                        سلطات إنفاذ القانون.
                      </span>
                    )}
                  </span>
                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "If your Account is temporarily or permanently closed, you may not use the Platform under the same Account or a different Account or re-register under a new Account without our prior written consent. "
                      ) : (
                        <span dir="rtl">
                          إذا تعرض حسابك للإغلاق بشكل مؤقت أو دائم، فلا يجوز لك
                          استخدام المنصة تحت نفس الحساب أو حساب مختلف أو إعادة
                          التسجيل تحت حساب جديد دون الحصول على موافقتنا الخطية
                          المسبقة.
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="col-sm-12 p-0 fl">
                    <p>
                      {language === "en" ? (
                        "Ishro Trading General LLC reserves the right to change, suspend, limit or discontinue any of its Services, in whole or in part at any time for any reason, without notice (unless required by law)."
                      ) : (
                        <span dir="rtl">
                          تحتفظ شركة "إيشرو للتجارة العامة ش.ذ.م.م." بالحق في
                          تغيير أو تعليق أو تقييد أو وقف أي من خدماتها، كلياً أو
                          جزئياً في أي وقت ولأي سبب، دون إشعار (ما لم يقتضيه
                          القانون).
                        </span>
                      )}
                    </p>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {language === "en" ? (
                    "17. ASSIGNMENT"
                  ) : (
                    <span dir="rtl">17. التنازل</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "User may not assign the User Agreement or any of rights or obligations hereunder, without Ishro General Trading LLC’s prior written consent in the form of a written instrument signed by a duly authorized representative of Ishro General Trading LLC. Ishro General Trading LLC may freely assign this Agreement without User’s consent. Any attempted assignment or transfer in violation of this subsection will be null and void. Subject to the foregoing restrictions, this User Agreement is binding upon and will inure to the benefit of the successors, heirs, and permitted assigns of the parties."
                    ) : (
                      <span dir="rtl">
                        لا يجوز للمستخدم التنازل عن اتفاقية المستخدم أو أي من
                        الحقوق أو الالتزامات الواردة أدناه، بدون موافقة خطية
                        مسبقة من شركة "إيشرو للتجارة العامة ش.ذ.م.م." في شكل سند
                        مكتوب وموقع من قبل ممثل مفوض حسب الأصول لشركة "إيشرو
                        للتجارة العامة ش.ذ.م.م.". ويجوز لشركة "إيشرو للتجارة
                        العامة ش.ذ.م.م." التنازل عن هذه الاتفاقية دون قيد ودون
                        موافقة المستخدم. أي محاولة للتنازل أو النقل تنتهك هذا
                        المادة الفرعية ستكون باطلة ولاغية. ومع مراعاة القيود
                        السابقة، تعتبر اتفاقية المستخدم هذه ملزمة وتؤول إلى
                        مصلحة الخلفاء والورثة والمتنازل لهم من الأطراف.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "18. GENERAL INFORMATION"
                  ) : (
                    <span dir="rtl">18. معلومات عامة</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "No waiver of any provision hereof shall be effective unless made in writing and signed by the waiving party. The failure of any party to require the performance of any term or obligation of this User Agreement, or the waiver by any party of any breach of this User Agreement, shall not prevent any subsequent enforcement of such term or obligation or be deemed a waiver of any subsequent breach."
                    ) : (
                      <span dir="rtl">
                        لن يكون أي تنازل عن أي حكم من أحكام هذه الاتفاقية ساريًا
                        ما لم يتم كتابياً ويوقع من الطرف المتنازل. عدم مطالبة أي
                        طرف بأداء أي بند أو التزام في اتفاقية المستخدم هذه، أو
                        تنازل أي طرف عن أي خرق لاتفاقية المستخدم هذه، لن يمنع أي
                        إنفاذ لاحق لهذا الشرط أو الالتزام أو يعتبر تنازلاً عن أي
                        خرق لاحق.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "This User Agreement contains the entire agreement and understanding among the parties hereto with respect to the subject matter hereof, and supersedes all prior and contemporaneous agreements, understandings, inducements and conditions, express or implied, oral or written, of any nature whatsoever with respect to the subject matter hereof. The express terms hereof control and supersede any course of performance and/or usage of the trade inconsistent with any of the terms hereof."
                    ) : (
                      <span dir="rtl">
                        تحتوي اتفاقية المستخدم هذه على الاتفاقية الكاملة
                        والتفاهم بين الأطراف فيما يتعلق بالموضوع المذكور هنا،
                        وتحل محل جميع الاتفاقيات والتفاهمات والحوافز والشروط
                        السابقة والحالية، سواء كانت صريحة أو ضمنية، شفهية أو
                        مكتوبة، من أي نوع كانت فيما يتعلق بموضوع هذه الاتفاقية.
                        وتحكم الشروط الصريحة في هذه الاتفاقية وتحل محل أي مسار
                        أداء و / أو استخدام تجاري لا يتوافق مع أي من الشروط
                        الواردة في هذه الاتفاقية.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {language === "en" ? (
                    "19. GOVERNING LAW AND DISPUTE RESOLUTION"
                  ) : (
                    <span dir="rtl">19. القانون الحاكم وتسوية النزاعات</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "This User Agreement shall be construed in accordance with the laws of the Courts of the Dubai International Financial Centre, without reference to its conflict of law provisions, and the obligations, rights and remedies of the parties hereunder shall be determined in accordance with such laws."
                    ) : (
                      <span dir="rtl">
                        تُفسر اتفاقية المستخدم هذه وفقاً لقوانين محاكم مركز دبي
                        المالي العالمي، بغض النظر عن أحكامها فيما بتعلق بتنازع
                        القوانين، وتتحدد التزامات وحقوق وتعويضات الأطراف بموجب
                        هذه الاتفاقية وفقاً لهذه القوانين.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserAgreement;

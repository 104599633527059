import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Slider from "react-slick";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import "./home.css";

const useStyles = makeStyles((theme) => ({
  closePaper: {
    borderRadius: "18px",
    padding: "20px",
  },
  promotitle: {
    fontFamily: "Almarai-Bold",
    whiteSpace: "nowrap",
    width: "99%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "23px",
  },
  promoPrice: {
    fontFamily: "Almarai-Bold",
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: "30px",
    fontFamily: "Almarai-Bold",
    padding: "10px 138px",
    [theme.breakpoints.down("sm")]: { fontSize: "23px", alignItems: "center" },
  },
}));

function MainBanner(props) {
  const classes = useStyles();
  var language = useSelector((state) => state.language);
  const isVip = useSelector((state) => state?.user?.user?.switched) ?? "0";
  var componentRef = useRef();
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  function Arrow(props) {
    let className = props.type === "next" ? "bannernextArrow" : "prevArrow";
    className += " bannerarrow";
    return (
      <span className={className} onClick={props.onClick}>
        <div
          style={{
            position: "relative",
            top: "calc(45%)",
            textAlign: "center",
          }}
        >
          {props.type === "next" ? (
            <ChevronRightIcon style={{ fontSize: "50px" }} />
          ) : (
            <ChevronLeftIcon style={{ fontSize: "50px" }} />
          )}
        </div>
      </span>
    );
  }
  return (
    <div className="mainBanner">
      <Slider
        nextArrow={<Arrow type="next" />}
        prevArrow={<Arrow type="prev" />}
        {...settings}
        ref={(el) => (componentRef = el)}
      >
        {/* <div style={{ position: "relative" }}>
          <img
            src={
              language == "en"
                ? "https://storage.googleapis.com/ishro/uploads/62f24f434c049.png"
                : "https://storage.googleapis.com/ishro/uploads/62f24f5d4d359.jpg"
            }
            alt="First slide"
          />
          <a
            href={"https://ishro.com/promotion/details/174"}
            className={"discount_btn"}
          >
            <img
              src={
                language == "en"
                  ? require("../../../../src/assets/image/EN.svg")
                  : require("../../../../src/assets/image/AR.svg")
              }
            />
            <div>
              <img
                src={
                  language == "en"
                    ? require("../../../../src/assets/image/EN hover.svg")
                    : require("../../../../src/assets/image/AR hover.svg")
                }
              />
            </div>
          </a>
        </div>
        <div style={{ position: "relative" }}>
          <img
            src={
              language == "en"
                ? "https://storage.googleapis.com/ishro/uploads/uploads/6287452ef3cb2.jpg"
                : "https://storage.googleapis.com/ishro/uploads/uploads/628744f6e294d.jpg"
            }
            alt="First slide"
          />
          <a
            href={"#promotionDiv"}
            className={
              language == "en" ? "iphone_13_mini_btn" : "iphone_13_mini_btn_ar"
            }
          >
            <img
              src={
                language == "en"
                  ? require("../../../../src/assets/image/shopyellow_en.svg")
                  : require("../../../../src/assets/image/shopyellow_ar.svg")
              }
            />
            <div>
              <img
                src={
                  language == "en"
                    ? require("../../../../src/assets/image/shopyellow_en_hover.svg")
                    : require("../../../../src/assets/image/shopyellow_ar_hover.svg")
                }
              />
            </div>
          </a>
        </div> */}

        <div>
          <img
            src={
              language == "en"
                ? "https://storage.googleapis.com/ishro/uploads/uploads/628b73e50040d.png"
                : "https://storage.googleapis.com/ishro/uploads/uploads/628b73fe2a33c.png"
            }
            alt="First slide"
          />
          <a
            href={
              language == "en"
                ? "https://www.youtube.com/watch?v=4rjUIHWJdVo"
                : "https://www.youtube.com/watch?v=nCT_0jXnHWg"
            }
            className={"youtube_btn"}
          >
            <img src={require("../../../../src/assets/image/You_tube.svg")} />
            <div>
              <img
                src={require("../../../../src/assets/image/You_tube_ho.svg")}
              />
            </div>
          </a>
        </div>
      </Slider>
    </div>
  );
}
export default MainBanner;

import axios from "axios";
import { getToken } from "../authService";
import { getUser, clearUser } from "../../services/storage/user";
import { saveUser } from "../../services/storage/user";
import {
  saveToken,
  saveSwitch,
  saveUserData,
  saveRegisterToken,
  saveRegisterMobile,
} from "../../services/authService";

require("dotenv").config();

var instance = axios.create({
  baseURL: process.env.REACT_APP_LIVE_URL,
  timeout: 15000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
  },
});

instance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = getToken();
    return config;
  },
  (request) => request,
  (error) => new Error(error)
);

// Response interceptor
instance.interceptors.response.use(
  (response) => {
    if (
      response?.data?.token != undefined &&
      response?.data?.token == "token_expired"
    ) {
      clearUser();
      window.location.reload();
    } else {
      return response;
    }
  },
  (error) => new Error(error)
);

export default instance;

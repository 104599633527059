import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Button, Hidden } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { saveUser } from "../../../services/storage/user";
import { saveToken } from "../../../services/authService";
import { useTranslation } from "react-i18next";
import loading from "../../../store/actions/loading";
import registrationApi from "../../../Api/auth/registration";
import useStyles from "./style";
import logo from "../../../../src/assets/image/logo.svg";
import logo_vip from "../../../../src/assets/image/logo_vip.svg";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { validateEmail } from "../../../utils/validateEmail";
import moment from "moment";
import ReactPixel from "react-facebook-pixel";
import analyticsEventTracker from "../../common/analyticsEventTracker";
const validConsecutiveChar = new RegExp(
  "^(?!\\b([a-zA-Z0-9])\\1\\1\\1\\1+\\b)"
);

function Registration() {
  const queryParams = new URLSearchParams(window.location.search);
  const referal = queryParams.get("referal");

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector((state) => state.language);
  const user = useSelector((state) => state.user?.user);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [solvedCaptcha, setSolvedCaptcha] = useState(null);
  const gaEventTracker = analyticsEventTracker("Registration");
  var vipmsg = localStorage.getItem("ishro_vip_msg");
  const [step, setStep] = useState(1);
  const classes = useStyles();
  const [User, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    country_code: "",
    mobile: "",
    dob: "",
    gender: "",
    // city: "",
    // address: "",
    // country: "",
    signed_up_source: "Other",
    signed_up_referral_code: referal,
  });

  const handleNextClick = () => {
    if (step === 1) {
      if (
        User.first_name === "" ||
        User.last_name === "" ||
        User.email === "" ||
        User.password === "" ||
        User.country_code === "" ||
        User.mobile === "" ||
        User.dob === "" ||
        User.gender === "" ||
        User.signed_up_source === ""
        // User.city === "" ||
        // User.address === "" ||
        // User.country === ""
        // check if user solve caption
      ) {
        return toast(t("Kindly fill all the mandatory fields"));
      }
      if (
        !validConsecutiveChar.test(User.first_name) ||
        !validConsecutiveChar.test(User.last_name)
      ) {
        return toast("Remove Consecutive character");
      }
      if (!validateEmail(User.email)) {
        // handle email address is not valid
        return toast(t("Email not valid"));
      }
      if (User.first_name.length < 2)
        return toast(t("First name should be more than 2 character"));
      if (User.last_name.length < 2)
        return toast(t("Last name should be more than 2 character"));
      // if (!passwordPolicy)
      //   return toast(
      //     t(
      //       "Password must be at least 8 characters long contain a number and an uppercase letter and a special character"
      //     )
      //   );
      if (!solvedCaptcha) {
        toast(t("Kindly, solve ` I'm not robot challenge `"));
        return;
      }
      setStep(2);
    } else if (step === 2) {
      dispatch(loading(true));
      // call the api
      registrationApi({
        first_name: User.first_name,
        last_name: User.last_name,
        email: User.email,
        password: User.password,
        country_code: User.country_code,
        mobile: User.mobile,
        dob: User.dob,
        gender: User.gender,
        signed_up_source: User.signed_up_source,
        signed_up_referral_code: User.signed_up_referral_code,
        vipmsg,
      }).then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            ReactPixel.track("CompleteRegistration", {
              user_id: res.data.data.user_id,
            });
            gaEventTracker("CompleteRegistration", res.data.data.user_id);
            dispatch({
              type: "SET_USER_DATA",
              payload: {
                user: res.data.data,
                token: res.data.token,
                email: res.data.data.email,
                ishroins: res.data.data.total_reward_points,
              },
            });
            saveUser({
              user: res.data.data,
              token: res.data.token,
              email: res.data.data.email,
              ishroins: res.data.data.total_reward_points,
              mobile: User.mobile,
            });
            saveToken(res.data.token);
            if (vipmsg != undefined && vipmsg == "true") {
              localStorage.setItem("ishro_vip_msg", "false");
            }
            dispatch(loading(false));
            navigate("/otp");
          } else {
            dispatch(loading());
            if (
              res.data.message ===
              "Already registed account with this mobile number"
            ) {
              toast(t("Already registed account with this mobile number"));
            } else {
              toast(t("error, please try again"));
            }
          }
        } else {
          dispatch(loading());
          toast("error, please try again");
        }
        dispatch(loading(false));
      });
    }
  };

  const handleInput = (e, val) => {
    if (e == "dob") {
      setUser((prev) => ({
        ...prev,
        ["dob"]: moment(val).format("YYYY-MM-DD"),
      }));
    } else {
      let { name, value } = e.target;
      if (name === "first_name" || name === "last_name")
        value = value.replace(/[^A-Za-z]/gi, "");

      if (!validConsecutiveChar.test(value)) {
        toast("Don't Enter Consecutive character");
      }
      setUser((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSelect = (name, value) => {
    console.log({ name, value });
    setUser((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Grid container className={classes.container} justifyContent="center">
      <Grid container md={8} xs={12} className={classes.panel}>
        <Hidden smDown>
          <Grid item md={4} xs={12} className={classes.logoGrid}>
            <img
              src={user?.switched !== "1" ? logo : logo_vip}
              justifyContent="center"
              alt=""
            />
          </Grid>
        </Hidden>
        <Grid item md={8} xs={12} className={classes.formGrid}>
          <div className={classes.header}>
            {step === 2 ? (
              <ArrowBackIosIcon
                className={classes.icon}
                fontSize={"small"}
                style={
                  language === "ar"
                    ? { transform: "rotate(180deg)", cursor: "pointer" }
                    : { cursor: "pointer" }
                }
                onClick={() => setStep(1)}
              />
            ) : (
              <Typography> </Typography>
            )}

            <Typography
              style={{ fontFamily: "Almarai-SemiBold", fontSize: "20px" }}
            >
              {step === 1
                ? t("REGISTER NEW USER")
                : t("Where did you hear about us?")}
            </Typography>
            <Typography> </Typography>
          </div>
          <Grid container justifyContent="center">
            {step === 1 && (
              <Step1
                User={User}
                selectedGender={selectedGender}
                setSelectedGender={setSelectedGender}
                handleInput={handleInput}
                handleSelect={handleSelect}
                setSolvedCaptcha={setSolvedCaptcha}
                // isPasswordPolicyMeet={isPasswordPolicyMeet}
              />
            )}
            {step === 2 && (
              <Step2
                handleSelect={handleSelect}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            )}
            <Grid container md={12} xs={10} justifyContent="center">
              <Grid item md={5} xs={12}>
                <Button variant="contained" fullWidth onClick={handleNextClick}>
                  {step === 1 ? t("NEXT") : t("Finish registration")}
                </Button>
              </Grid>
            </Grid>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Registration;

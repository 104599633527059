import { Types } from "../actions/actionTypes";

const initialState = {
  promotions: [],
  categories: [],
  promotionswinners: [],
  winner: [],
  soldOutPromotion: [],
  promotionsDetails: [],
  upcomingPromotion: [],
};

const promotions = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_PROMOTIONS:
      return {
        ...state,
        promotions: payload.promotions,
      };
    case Types.SET_PROMOTION_CATEGORIES:
      return {
        ...state,
        categories: payload.categories,
      };
    case Types.CLEAR_PROMOTIONS:
      return {
        ...state,
        promotions: [],
      };
    case Types.CLEAR_PROMOTION_CATEGORIES:
      return {
        ...state,
        categories: [],
      };
    case Types.SET_PROMOTIONS_WINNERS:
      return {
        ...state,
        promotionswinners: payload.promotions,
      };
    case Types.CLEAR_PROMOTION_WINNERS:
      return {
        ...state,
        promotionswinners: [],
      };
    case Types.SET_HIGHLIGHT_PROMOTIONS:
      return {
        ...state,
        promotionsDetails: payload.promotions,
      };
    case Types.CLEAR_HIGHLIGHT_PROMOTIONS:
      return {
        ...state,
        promotionsDetails: [],
      };
    case Types.SET_SOLD_OUT_PROMOTIONS:
      return {
        ...state,
        soldOutPromotion: payload.promotions,
      };
    case Types.CLEAR_SOLD_OUT_PROMOTIONS:
      return {
        ...state,
        soldOutPromotion: [],
      };
    case Types.SET_UPCOMING_PROMOTIONS:
      return {
        ...state,
        upcomingPromotion: payload.promotions,
      };
    case Types.CLEAR_UPCOMING_PROMOTIONS:
      return {
        ...state,
        upcomingPromotion: [],
      };

    case Types.SET_WINNERS_DETAILS:
      return {
        ...state,
        winner: payload.promotions,
      };
    case Types.CLEAR_WINNERS_DETAILS:
      return {
        ...state,
        winner: [],
      };
    default:
      return state;
  }
};

export default promotions;

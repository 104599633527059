import { Types } from "../actions/actionTypes";

const initialState = {
  sections: [],
};

const sections = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_SECTIONS:
      return {
        ...state,
        sections: payload.sections,
      };
    case Types.CLEAR_SECTIONS:
      return {
        ...state,
        sections: [],
      };
    default:
      return state;
  }
};

export default sections;

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    minHeight: "70vh",
  },
  items: {
    margin: "30px 0px",
  },
  summary: {
    background: "#F2F2F2",
    boxShadow: "none",
    "& .MuiTypography-root ": {
      color: "black",
      textAlign: "start",
    },
  },
  checkout: {
    backgroundColor: theme.palette.background.light,
    padding: "10% 35px 30px 35px",
    color: "black",
    "& .MuiCard-root": {
      margin: "20px 0px",
      color: "#FFFFFF",
    },
  },
  selectorBtn: {
    margin: "10px 0px",
    color: "white",
    width: "35%",
    backgroundColor: theme.palette.background.default,

    "&:hover": {
      backgroundColor: theme.palette.background.blue,
    },
  },
  button: {
    margin: "10px 0px",
  },
  title: { fontFamily: "Almarai-Bold", fontSize: "22px", margin: "7%" },
  title1: {
    fontFamily: "Almarai-Bold",
    fontSize: "30px",
    margin: "15% 0% 5%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "23px",
      alignItems: "center",
      margin: "15% 4% 5%",
    },
  },
}));

import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as actionCreators from "../../../store/actions";
import { Box, Paper, makeStyles, Typography } from "@material-ui/core";
import { isValid, decode } from "js-base64";
import Slider from "react-slick";
import "./Winner.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const useStyles = makeStyles((theme) => ({
  closePaper: {
    borderRadius: "18px",
    cursor: "pointer",
  },
  title: {
    fontSize: "30px",
    fontFamily: "Almarai-Bold",
    margin: "1% 9.5%",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: { fontSize: "23px", alignItems: "center" },
  },
  textm: {
    color: "rgb(36, 205, 230)",
    padding: "5% 3%",
    fontFamily: "Almarai-Bold",
    height: "60px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: { lineHeight: "1.2rem", fontSize: "12px" },
  },
}));

var pageType;
function WinnerList(props) {
  pageType = props.page;
  const classes = useStyles();
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const promotionsWinners = useSelector(
    (state) => state.promotions.promotionswinners
  );
  var componentRef = useRef();

  useEffect(() => {
    dispatch(actionCreators.loadPromotionsWinner());
  }, []);

  const loadWinner = (item) => {
    if (item?.promotion?.draw_video) {
      dispatch(actionCreators.loadWinnerDetails(item.ticket_id));
      debugger;
      setTimeout(function () {
        navigate(`/winner/details/${item.ticket_id}`);
      }, 2000);
    }
  };

  var settings = {
    infinite: promotionsWinners?.length > 3 ? true : false,
    speed: 500,
    centerMode: isMobile ? true : false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: promotionsWinners?.length > 3 ? true : false,
        },
      },
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: promotionsWinners?.length > 2 ? true : false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: promotionsWinners?.length > 2 ? true : false,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: promotionsWinners?.length > 1 ? true : false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: promotionsWinners?.length > 1 ? true : false,
        },
      },
    ],
  };
  function Arrow(props) {
    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " winnerlistarrow";
    return (
      <span className={className} onClick={props.onClick}>
        <div
          style={{
            position: "relative",
            top: "calc(45%)",
            textAlign: "center",
          }}
        >
          {props.type === "next" ? (
            <ChevronRightIcon style={{ fontSize: "50px", color: "white" }} />
          ) : (
            <ChevronLeftIcon style={{ fontSize: "50px", color: "white" }} />
          )}
        </div>
      </span>
    );
  }
  return (
    promotionsWinners.length > 0 && (
      <div style={{ paddingTop: "1.5%", backgroundColor: "#3E3E3E" }}>
        <Box display="flex" className={classes.title}>
          <Box flexGrow={1}>
            <p>{language == "en" ? "Winners" : "الفائزون"}</p>
          </Box>
        </Box>
        <Slider
          nextArrow={<Arrow type="next" />}
          prevArrow={<Arrow type="prev" />}
          {...settings}
          ref={(el) => (componentRef = el)}
          style={{ margin: "0% 9%" }}
        >
          {promotionsWinners.map((item, index) => {
            return (
              <Paper key={index} className={classes.closePaper}>
                <div
                  className="wrap_promotion1"
                  onClick={() => loadWinner(item)}
                  key={index}
                >
                  <img
                    src={
                      item?.promotion?.winner_details?.winner_image
                        ? item?.promotion?.winner_details?.winner_image
                        : item?.promotion?.draw_image
                    }
                    style={{ width: "100%" }}
                  />

                  <div className="centered">
                    <img
                      src={require("../../../../src/assets/image/youtude.svg")}
                      width={isMobile ? "32px" : "68px"}
                    />
                  </div>
                  <Typography
                    variant="p"
                    component="p"
                    className={classes.textm}
                    dir={language == "en" ? "rtr" : "rtl"}
                    dangerouslySetInnerHTML={{
                      __html:
                        language === "en"
                          ? isValid(item?.promotion?.title)
                            ? decode(item?.promotion?.title)
                            : decode(item?.promotion?.title)
                          : isValid(item?.promotion?.title_ar)
                          ? decode(item?.promotion?.title_ar)
                          : decode(item?.promotion?.title_ar),
                    }}
                  />
                </div>
              </Paper>
            );
          })}
        </Slider>
      </div>
    )
  );
}
export default WinnerList;

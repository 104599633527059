import React, { useEffect, Component } from "react";

import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
export class MapContainer extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={17}
        className="mapDiv"
        disableDefaultUI={true}
        containerStyle={{
          position: "absolute",
          width: "92%",
          height: "94%",
          top: "3%",
          left: "4%",
        }}
        initialCenter={{
          lat: 25.178158206234983,
          lng: 55.27271373069306,
        }}
      >
        <Marker title={"Ishro"} position={{ lat: 25.178974, lng: 55.272628 }} />
      </Map>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyA8KvOoxoOMUW6SQRyN6DFCIkLZ8lfgS4A",
})(MapContainer);

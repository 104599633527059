import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      //   flexDirection: "row",
      alignItems: "center",
    },
  },
}));

import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import * as actionCreators from "../../../store/actions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Box, Paper, makeStyles, Typography } from "@material-ui/core";
import Slider from "react-slick";
import moment from "moment";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { decode } from "js-base64";
const useStyles = makeStyles((theme) => ({
  closePaper: {
    borderRadius: "18px",
    padding: "20px",
    textAlign: "center",
  },
  promotitle: {
    fontFamily: "Almarai-Bold",
    height: "23px",
    color: theme.palette.text.secondary,
    lineHeight: "1.2rem",
  },
  promotitle_en: {
    fontFamily: "Almarai-Bold",
    height: "23px",
    color: theme.palette.text.primary,
    lineHeight: "1.2rem",
    textAlign: "left",
  },
  title: {
    fontSize: "30px",
    fontFamily: "Almarai-Bold",
    padding: "3% 0%",
    [theme.breakpoints.down("sm")]: { fontSize: "23px", alignItems: "center" },
  },
}));

function UpcomingPromotions(props) {
  const classes = useStyles();
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const sections = useSelector((state) => state.sections).sections;
  const navigate = useNavigate();
  const [name, setName] = useState([]);

  const isMobile = useMediaQuery("(max-width:600px)");
  const upcomingPromotion = useSelector(
    (state) => state.promotions.upcomingPromotion
  );
  var userData = "";
  var componentRef = useRef();
  const user = useSelector((state) => state.user?.user);
  useEffect(() => {
    dispatch(actionCreators.loadUpcomingPromotion());
  }, [user]);

  useEffect(() => {
    setName(sections.filter((sec, index) => sec.section == "upcoming"));
  }, []);

  var settings = {
    infinite: upcomingPromotion?.length > 2 ? true : false,
    centerMode: isMobile ? true : false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: upcomingPromotion?.length > 2 ? true : false,
        },
      },
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: upcomingPromotion?.length > 1 ? true : false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: upcomingPromotion?.length > 1 ? true : false,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: upcomingPromotion?.length > 1 ? true : false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: upcomingPromotion?.length > 1 ? true : false,
        },
      },
    ],
  };

  const timeDiffCalc = (date1) => {
    return new Date(date1.replace(/-/g, "/")).getTime() > new Date().getTime();
  };

  const isBase64 = (str) => {
    if (str === "" || str.trim() === "") {
      return false;
    }
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  };
  function Arrow(props) {
    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " arrow";
    return (
      <span className={className} onClick={props.onClick}>
        <div
          style={{
            position: "relative",
            top: "calc(45%)",
            textAlign: props.type === "next" ? "left" : "right",
          }}
        >
          {props.type === "next" ? (
            <ChevronRightIcon style={{ fontSize: "50px" }} />
          ) : (
            <ChevronLeftIcon style={{ fontSize: "50px" }} />
          )}
        </div>
      </span>
    );
  }
  return (
    upcomingPromotion.length > 0 && (
      <div style={{ paddingTop: "1.5%" }}>
        <Box display="flex" className={classes.title}>
          <Box flexGrow={1}>
            <p>
              {language === "en"
                ? name[0]?.section_title_en
                : name[0]?.section_title_ar}
            </p>
          </Box>
        </Box>
        <Slider
          nextArrow={<Arrow type="next" />}
          prevArrow={<Arrow type="prev" />}
          {...settings}
          ref={(el) => (componentRef = el)}
        >
          {upcomingPromotion.map((item, index) => {
            var showTxt = timeDiffCalc(item.draw_date);
            return (
              <Paper key={index} className={classes.closePaper}>
                <div>
                  <Typography
                    variant="p"
                    component="p"
                    className={classes.promotitle_en}
                    dir={language == "en" ? "rtr" : "rtl"}
                    dangerouslySetInnerHTML={{
                      __html:
                        language === "en"
                          ? isBase64(item.title)
                            ? decode(item.title)
                            : decode(item.title)
                          : isBase64(item.title_ar)
                          ? decode(item.title_ar)
                          : decode(item.title_ar),
                    }}
                  />
                  <img
                    alt=""
                    src={
                      item?.draw_image ? item?.draw_image : item?.soldout_image
                    }
                    style={{ width: "100%", padding: "8% 10%" }}
                  />

                  <p className={classes.promotitle}>
                    {item?.start_date &&
                      moment(item?.start_date).format("MMMM DD, YYYY")}
                  </p>
                  <div className="winnerticket"></div>
                </div>
              </Paper>
            );
          })}
        </Slider>
      </div>
    )
  );
}
export default UpcomingPromotions;

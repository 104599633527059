import i18next from "i18next";
import { Types } from "../actions/actionTypes";
import { saveLanguage, getLanguage } from "../../services/storage/language";
const initialState = getLanguage();

const language = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_LANGUAGE:
      saveLanguage(payload);
      i18next.changeLanguage(payload);
      return payload;
    default:
      return state;
  }
};

export default language;

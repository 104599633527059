import { makeStyles } from "@material-ui/core/styles";


export default makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0px 0px 24px 24px",
    color: "#FFFFFF",
    paddingBottom: "30px",
    transform: "translateY(-5px)",
    width: "421px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      borderRadius: "0px 0px",
      marginBottom: "-5px",
      padding: "21% 3% 7% 3%",
    },
  },
  icon: {
    margin: "10px",
  },
  menu: {
    backgroundColor: theme.palette.background.lightgray,
    margin: "10px 0px",
    borderRadius: "24px",
  },
  list: {
    width: "100%",
  },
}));

import { Types } from "../actions/actionTypes";

const loginDialog = (state = null, action) => {
  switch (action.type) {
    case Types.SET_LOGIN_STATUS:
      return action.state;
    default:
      return state;
  }
};

export default loginDialog;

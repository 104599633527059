import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Button, Grid } from "@material-ui/core";
import useStyles from "./style";
import { useSelector, useDispatch } from "react-redux";
import "react-phone-input-2/lib/style.css";
import { getSavedCards, deleteSavedCard } from "../../../../store/actions";
import { makeStyles } from "@material-ui/core/styles";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import card from "../../../../../src/assets/image/card.svg";
import card1 from "../../../../../src/assets/image/card1.svg";
import * as actionCreators from "../../../../store/actions";
const innerStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.background.lightgray,
    padding: "23px",
  },
}));
function Payments({ setUserDetails }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const innerClasses = innerStyles();
  const user = useSelector((state) => state.user?.user);
  const [cards, setSetCards] = React.useState(null);

  React.useEffect(() => {
    dispatch(actionCreators.loading(true));
    dispatch(getSavedCards())
      .then((cards) => {
        setSetCards(cards);
        dispatch(actionCreators.loading(false));
      })
      .catch((err) => console.error(err));
  }, [dispatch]);

  const handleDeleteCard = (id) => {
    dispatch(deleteSavedCard(id))
      .then(
        (res) => setSetCards((prev) => prev.filter((card) => card.id !== id))
        // toast("cards");
      )
      .catch((err) => console.error(err));
  };
  return (
    <>
      <Box className={classes.boxDiv}>
        <Typography variant="h4" gutterBottom className={classes.title}>
          {t("Manage Payment Options")}
        </Typography>

        {cards ? (
          cards?.map((card) => (
            <Grid container className={innerClasses.card} key={card.id}>
              <Grid item md={6}>
                <Typography
                  style={{ fontFamily: "Almarai-SemiBold", fontSize: "14px" }}
                >
                  {`${card.scheme} **** ${card.last4}`}
                </Typography>
                {/* <Typography
                style={{ fontFamily: "Almarai-SemiBold", fontSize: "14px" }}
              >
                {`Anna Smith`}
              </Typography> */}
                <Typography
                  style={{
                    fontFamily: "Almarai-SemiBold",
                    fontSize: "14px",
                    color: "#707070",
                  }}
                >
                  {`Expires ${card.expiry_month}/${card.expiry_year}`}
                </Typography>
              </Grid>
              <Grid
                container
                md={6}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  style={{ color: "white" }}
                  onClick={() => handleDeleteCard(card.id)}
                >
                  <DeleteOutlineIcon />
                </Button>
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid
            container
            style={{ flexDirection: "column", margin: "20% 0%" }}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontFamily: "Almarai-SemiBold",
                fontSize: "22px",
              }}
            >
              {t("No Saved Cards")}
            </Typography>

            <img
              src={user?.switched !== "1" ? card1 : card}
              alt="not found"
              style={{
                padding: "6% 0%",
              }}
            />
            <Typography> {t("You don't have any saved cards")} </Typography>
          </Grid>
        )}
      </Box>
    </>
  );
}

export default Payments;

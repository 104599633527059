import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Slider from "react-slick";
import howitworks_ar from "../../../assets/image/how_it_works_ar.jpg";
import howitworks_en from "../../../assets/image/how_it_works_en.jpg";
import project_maji_ar from "../../../assets/image/project_maji_ar.png";
import project_maji_en from "../../../assets/image/project_maji_en.png";
import useStyles from "./SubBannerStyle";

var items = [
  {
    src_en: project_maji_en,
    src_ar: project_maji_ar,
    link_en: "https://www.projectmaji.org/",
    link_ar: "https://www.projectmaji.org/",
  },
];
function SubBanner(props) {
  const classes = useStyles();
  var language = useSelector((state) => state.language);
  var componentRef = useRef();
  var settings = {
    infinite: items?.length > 1 ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  return (
    <a href={"https://www.projectmaji.org/"} target="_blank">
      <img
        src={language == "en" ? project_maji_en : project_maji_ar}
        style={{ width: "100%" }}
      />
    </a>
    // items.length > 0 && (
    //   <div className={`subBanner`}>
    //     <Slider
    //       {...settings}
    //       ref={(el) => (componentRef = el)}
    //       className={classes.container}
    //     >
    //       {items.map((item, index) => {
    //         return (
    //           <div key={index} className="banner">
    //             <a
    //               href={language == "en" ? item.link_en : item.link_ar}
    //               target="_blank"
    //             >
    //               <img
    //                 src={language == "en" ? item.src_en : item.src_ar}
    //                 style={{ width: "100%" }}
    //               />
    //             </a>
    //           </div>
    //         );
    //       })}
    //     </Slider>
    //   </div>
    // )
  );
}
export default SubBanner;

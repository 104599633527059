import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as actionCreators from "../../../store/actions";
import "./products.css";
import {
  Box,
  makeStyles,
  Grid,
  Paper,
  Typography,
  Tabs,
  Tab,
  Hidden,
  IconButton,
} from "@material-ui/core";
import { isValid, decode } from "js-base64";
import loading from "../../../store/actions/loading";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ReactGA from "react-ga";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "30px",
    fontFamily: "Almarai-Bold",
    padding: "10px 15%",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: "23px",
      alignItems: "center",
      padding: "10px 1%",
    },
  },
  subtitle: {
    fontSize: "16px",
    fontFamily: "Almarai-SemiBold",
    color: theme.palette.text.secondary,
    marginBottom: "4%",
  },
  paperDiv: {
    margin: "4%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "3% 0%",
    borderRadius: "27px",
    transition: "-webkit-transform .3s ease-in-out",
    transition: "transform .3s ease-in-out",
    transition: "transform .3s ease-in-out,-webkit-transform .3s ease-in-out",
    "&:hover": {
      WebkitTransform: "scale3d(1.03,1.03,1.03)",
      transform: "scale3d(1.03,1.03,1.03)",
    },
    [theme.breakpoints.down("sm")]: { padding: "0%" },
  },
  tabs: {
    alignItems: "center",
  },
}));

const Products = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const products = useSelector((state) => state.products);
  const language = useSelector((state) => state.language);
  const [value, setValue] = React.useState("");
  const [proName, setProName] = React.useState(
    language === "en" ? "All" : "جميع الفئات"
  );
  useEffect(() => {
    dispatch(loading(true));
    dispatch(actionCreators.loadProductCategories());
    dispatch(actionCreators.getProducts(1, ""));
    setTimeout(function () {
      dispatch(loading());
    }, 2000);
  }, [dispatch]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const handleChange = (event, category_id) => {
    setValue(category_id);
    dispatch(actionCreators.getProducts(1, category_id))
      .then((res) => {})
      .catch((error) => {});
  };

  return (
    <div>
      <div style={{ paddingTop: "5.5%" }}>
        <Box display="flex" className={classes.title}>
          <Box flexGrow={1}>
            <p>
              <IconButton href="/">
                <ArrowBackIosIcon
                  style={
                    language === "ar"
                      ? { transform: "rotate(180deg)", color: "#353434" }
                      : { color: "#353434" }
                  }
                />
              </IconButton>
              {language === "en" ? "Products" : "منتجات"}
            </p>
          </Box>
        </Box>
        <Grid container className="productDetail">
          <Hidden smDown>
            <Grid item xs={2} sm={1}>
              <Tabs
                orientation="vertical"
                aria-label="Vertical tabs example"
                indicatorColor="#e2e2e3"
                value={value}
                className={classes.tabs}
                onChange={handleChange}
                style={{
                  paddingTop: "54%",
                }}
              >
                {products?.productCategories.length ? (
                  <Tab
                    value=""
                    label={
                      <>
                        <p>{language === "en" ? "All" : "جميع الفئات"}</p>
                        <FiberManualRecordIcon fontSize="small" />
                      </>
                    }
                    className={language === "en" ? "prodtab" : "prodtab_ar"}
                  ></Tab>
                ) : (
                  ""
                )}
                {products?.productCategories.map((item, index) => {
                  return (
                    <Tab
                      key={index}
                      value={item?.category_id}
                      label={
                        <>
                          <p>
                            {language == "en"
                              ? item?.category_name_en
                              : item?.category_name_ar}
                          </p>
                          <FiberManualRecordIcon fontSize="small" />
                        </>
                      }
                      className={language == "en" ? "prodtab" : "prodtab_ar"}
                    ></Tab>
                  );
                })}
              </Tabs>
            </Grid>

            <Grid item xs={6} sm={11} md={11} lg={11}>
              {products.products.map((item, index) => {
                return (
                  <Paper className={classes.paperDiv} key={index}>
                    <Grid
                      item
                      xs={9}
                      sm={9}
                      md={9}
                      lg={9}
                      style={{
                        borderRight: "1px solid #707070",
                        padding: "0% 4%",
                      }}
                    >
                      <>
                        <strong>
                          <Typography
                            variant="p"
                            component="h1"
                            className={classes.subtitle}
                            dir={language == "en" ? "rtr" : "rtl"}
                            dangerouslySetInnerHTML={{
                              __html: item
                                ? language === "en"
                                  ? isValid(item?.title)
                                    ? decode(item?.title)
                                    : decode(item?.title)
                                  : isValid(item?.title_ar)
                                  ? decode(item?.title_ar)
                                  : decode(item?.title_ar)
                                : "",
                            }}
                          />
                        </strong>
                        <Typography
                          variant="h6"
                          component="h6"
                          style={{
                            marginBottom: "1.35em",
                            color: "#FFFFFF",
                          }}
                          dir={language == "en" ? "rtr" : "rtl"}
                          dangerouslySetInnerHTML={{
                            __html: item
                              ? language === "en"
                                ? isValid(item?.description)
                                  ? decode(item?.description)
                                  : decode(item?.description)
                                : isValid(item?.description_ar)
                                ? decode(item?.description_ar)
                                : decode(item?.description_ar)
                              : language === "en"
                              ? isValid(item?.description)
                                ? decode(item?.description)
                                : decode(item?.description)
                              : isValid(item?.description_ar)
                              ? decode(item?.description_ar)
                              : decode(item?.description_ar),
                          }}
                        />
                      </>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={3}
                      lg={3}
                      style={{ textAlign: "center" }}
                    >
                      <img src={item?.files[0]?.file} width="100%" alt="" />
                    </Grid>
                  </Paper>
                );
              })}
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item xs={12}>
              <Tabs
                aria-label="Vertical tabs example"
                indicatorColor="#e2e2e3"
                value={value}
                className={classes.tabs}
                onChange={handleChange}
                variant="scrollable"
              >
                {products?.productCategories.length ? (
                  <Tab
                    value=""
                    label={
                      <>
                        <p>{language == "en" ? "All" : "جميع الفئات"}</p>
                        <FiberManualRecordIcon fontSize="small" />
                      </>
                    }
                    className="prodmob"
                  ></Tab>
                ) : (
                  ""
                )}
                {products?.productCategories.map((item, index) => {
                  return (
                    <Tab
                      key={index}
                      value={item?.category_id}
                      label={
                        <>
                          <p>
                            {language == "en"
                              ? item?.category_name_en
                              : item?.category_name_ar}
                          </p>
                          <FiberManualRecordIcon fontSize="small" />
                        </>
                      }
                      className="prodmob"
                    ></Tab>
                  );
                })}
              </Tabs>
            </Grid>

            {products.products.map((item, index) => {
              return (
                <Grid item xs={6} key={index}>
                  <Paper className={classes.paperDiv}>
                    <img src={item?.files[0]?.file} width="78%" alt="" />
                  </Paper>
                </Grid>
              );
            })}
          </Hidden>
        </Grid>
      </div>
    </div>
  );
};

export default Products;

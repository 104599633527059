import React, { useEffect } from "react";
import "./Support.css";
import { useSelector, useDispatch } from "react-redux";
import * as actionCreators from "../../../store/actions";
import loading from "../../../store/actions/loading";
import { useTranslation } from "react-i18next";
import {
  Box,
  makeStyles,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputBase,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import ReactGA from "react-ga";
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "30px",
    fontFamily: "Almarai-SemiBold",
    color: theme.palette.primary.main,
    textAlign: "center",
    marginTop: "2%",
    [theme.breakpoints.down("sm")]: { fontSize: "23px", alignItems: "center" },
  },
  subtitle: {
    fontSize: "24px",
    fontFamily: "Almarai-SemiBold",
    color: theme.palette.text.main,
    marginTop: "6%",
  },
  paperDiv: {
    margin: "2% 0%",
    color: theme.palette.text.secondary,
    borderRadius: "4px",
  },
  search: {
    position: "relative",
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    margin: "0 10%",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      margin: "0",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.secondary,
  },
  inputRoot: {
    color: theme.palette.text.secondary,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    color: theme.palette.text.secondary,
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    height: "2.1876em",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  container: {
    padding: "2% 17%",
    [theme.breakpoints.down("sm")]: { padding: "2%" },
  },
}));
const Support = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const language = useSelector((state) => state.language);
  const faq = useSelector((state) => state.faq).faq;
  useEffect(() => {
    dispatch(loading(true));
    var data = {
      page: 1,
    };
    dispatch(actionCreators.getFaq(data))
      .then(() => {
        dispatch(loading());
      })
      .catch(() => {
        dispatch(loading());
      });
  }, []);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const handleSearch = (value) => {
    var data = {
      page: 1,
      search: value,
    };
    setTimeout(
      function () {
        dispatch(actionCreators.getFaq(data))
          .then(() => {
            dispatch(loading());
          })
          .catch(() => {
            dispatch(loading());
          });
      }.bind(this),
      1000
    );
  };

  return (
    <div>
      <div style={{ paddingTop: "114px" }}>
        <Box display="flex" className={classes.title}>
          <Box flexGrow={1}>
            <p>{t("fq")}</p>
          </Box>
        </Box>
        <Grid container className={classes.container}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder={t("search")}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onChange={(e) => handleSearch(e.target.value)}
                inputProps={{ "aria-label": "search" }}
              />
            </div>

            {faq?.length
              ? faq.map((item, index) => {
                  return (
                    <div key={index}>
                      <Typography className={classes.subtitle}>
                        {language == "en"
                          ? item.category_name
                          : item.category_name_ar}
                      </Typography>
                      {item.informations?.map((information, index) => {
                        return (
                          <Accordion className={classes.paperDiv} key={index}>
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon style={{ color: "#FFFFFF" }} />
                              }
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.heading}>
                                {language == "en"
                                  ? information.title
                                  : information.title_ar}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography style={{ paddingLeft: "24px" }}>
                                {language == "en"
                                  ? information.description
                                  : information.description_ar}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                    </div>
                  );
                })
              : ""}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Support;

import React, { useEffect, useState } from "react";
import { Grid, Button, TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FilledInput from "@material-ui/core/OutlinedInput";
import useStyles from "./style";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GetPram from "../../../utils/GetPrams";
import { resetPassword } from "../../../store/actions/auth";
import reset_password_banner from "../../../assets/image/reset_password_banner.svg";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import ReactGA from "react-ga";
function ChangePassword(props) {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    password: "",
    password_confirmation: "",
  });
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [passwordPolicy, isPasswordPolicyMeet] = useState(false);
  const handleInput = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    setToken(GetPram("token"));
  }, []);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const handleResetPassword = () => {
    if (data.password === "" || data.password_confirmation === "") {
      toast(t("Please fill the required fields"));
      return;
    }
    // if (!passwordPolicy) {
    //   toast(
    //     t(
    //       "Password must be at least 8 characters long contain a number and an uppercase letter and a special character"
    //     )
    //   );
    //   return;
    // }
    if (data.password === data.password_confirmation) {
      dispatch(
        resetPassword({
          token,
          password: data.password,
        })
      )
        .then((res) => navigate("/"))
        .catch((err) => {
          toast(t("error, please try again"));
          console.error(err);
        });
    } else {
      toast(t("password and password confirmation doesn't match"));
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.container}
    >
      <Grid item md={4} xs={12} className={classes.card}>
        {/* <PasswordChecklist
          style={{ display: "none" }}
          rules={["minLength", "specialChar", "number", "capital", "match"]}
          minLength={5}
          value={data.password}
          valueAgain={data.password_confirmation}
          onChange={(isValid) => isPasswordPolicyMeet(isValid)}
        /> */}
        <img src={reset_password_banner} alt="" width="100%" />

        <Typography className={classes.title}>
          {t("Reset your password")}
        </Typography>
        <FilledInput
          variant="contained"
          onChange={handleInput}
          name="password"
          type={showPassword ? "text" : "password"}
          placeholder={t("New Password")}
          className={classes.input}
          InputProps={{ disableUnderline: true }}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                style={{ color: "white" }}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />

        <FilledInput
          onChange={handleInput}
          type="password"
          name="password_confirmation"
          placeholder={t("Confirm Password")}
          className={classes.input}
          fullWidth
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                style={{ color: "white" }}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />

        <Button fullWidth variant="contained" onClick={handleResetPassword}>
          {t("Change Password")}
        </Button>
      </Grid>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Grid>
  );
}

export default ChangePassword;

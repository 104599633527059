import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  emailBox: {
    marginTop: "20px",
    marginBottom: "20px",
    padding: "5px 45px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.background.lightgray,
    "& .MuiTypography-root": {
      padding: "10px 0px",
    },
  },
  emailInput: {
    marginTop: "15px",
  },
  btnSave: {
    borderRadius: "16px",
  },
  title: {
    fontFamily: "Almarai-SemiBold",
    fontSize: "20px",
    textTransform: "uppercase",
    marginBottom: "20px",
  },
  phoneInput: {
    backgroundColor: `${theme.palette.background.lightgray} !important;`,
    border: "none !important",
    color: "white",
  },
  buttonClass: {
    backgroundColor: `${theme.palette.background.lightgray} !important;`,
    border: "none !important",
    "&:hover": {
      backgroundColor: `${theme.palette.background.lightgray} !important;`,
    },
  },
  dropdownClass: {
    backgroundColor: `${theme.palette.background.lightgray} !important;`,
  },
  boxDiv: {
    margin: "0px 50px",
  },
  btnDiv: {
    marginTop: "auto",
    padding: "0px 50px",
  },
}));

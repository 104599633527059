import React, { Component } from "react";
import { Provider } from "react-redux";
import "../src/assets/css/app.css";
import { store } from "./store";
import Routes from "./routes";
import GlobalStyles from "../src/assets/styles/GlobalStyles";
import Jss from "./Jss";
import "./i18n";
import Loader from "./components/common/Loader";
import ScrollToTop from "./utils/ScrollToTop";
import ReactGA from "react-ga";
const TRACKING_ID = "UA-188553072-1";
ReactGA.initialize(TRACKING_ID);
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Jss>
          <ScrollToTop />
          <GlobalStyles />
          <Loader />
          <Routes />
        </Jss>
      </Provider>
    );
  }
}

export default App;

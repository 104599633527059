/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { isValid, decode } from "js-base64";
import * as actionCreators from "../../../store/actions";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import {
  Typography,
  Grid,
  makeStyles,
  withStyles,
  Button,
  IconButton,
  Paper,
  Badge,
  Dialog,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MorePromotion from "./MorePromotion";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ProgressBar from "../../common/ProgressBar";
import "react-toastify/dist/ReactToastify.css";
import "./Promotion.css";
import ShoppingCart from "@material-ui/icons/ShoppingCartOutlined";
import AddShoppingCartIcon from "../../../../src/assets/image/cart.svg";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import axios from "../../../services/axios";
import loading from "../../../store/actions/loading";
import { ToastContainer, toast } from "react-toastify";
import CheckIcon from "@material-ui/icons/Check";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { getToken, getUserLocalCarts } from "../../../services/authService";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Maximize2 } from "react-feather";
import CloseIcon from "@material-ui/icons/Clear";
import CountdownTimer from "react-component-countdown-timer";
import moment from "moment";
import ShareIcon from "@material-ui/icons/Share";
import SocialPopup from "../../common/socialPopup";
import Helmet from "react-helmet";
import loginDialog from "../../../store/actions/loginDialog";
import CalcCurrency from "../../../utils/CalcCurrency";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { Scrollbars } from "react-custom-scrollbars";
// import Lottie from "react-lottie";
// import loader from "../../../lotties/loader.json";
// import loadervip from "../../../lotties/loadervip.json";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import analyticsEventTracker from "../../common/analyticsEventTracker";
const useStyles = makeStyles((theme) => ({
  check: {
    padding: "3px",
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "13px",
  },
  banner: {
    opacity: "1",
    padding: "0% 0%",
  },
  promotitle1: {
    fontSize: "30px",
    height: "35px",
    fontFamily: "Almarai-Bold",
    marginBottom: "8px!important",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: { fontSize: "23px", alignItems: "center" },
  },
  promotitle2: {
    fontSize: "22px",
    fontFamily: "Almarai-SemiBold",
    height: "35px",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: { fontSize: "23px", alignItems: "center" },
  },
  proimg: {
    display: "flex",
    // padding: "1% 4%",
    // alignItems: "flex-start",
    // justifyContent: "center",
    flexDirection: "column",
  },
  promoPrice: {
    fontFamily: "Almarai-SemiBold",
    fontSize: "30px",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: { fontSize: "23px", alignItems: "center" },
  },
  promoPrice1: {
    fontFamily: "Almarai-SemiBold",
    fontSize: "22px",
    color: theme.palette.secondary.main,
    textDecoration: "line-through",
    textDecorationThickness: "2px",
  },
  discountPer: {
    padding: "6px",
    margin: "0% 4%",
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.background.paper,
    borderRadius: "3px",
    fontFamily: "Almarai-SemiBold",
    fontSize: "12px",
  },
  btn: {
    fontFamily: "Almarai-SemiBold",
    fontSize: "18px",
    color: theme.palette.primary.main,
    width: "56%",
    borderRadius: "16px",
    marginRight: "9%",
  },
  addedbtn: {
    fontFamily: "Almarai-SemiBold",
    fontSize: "18px",
    backgroundColor: "#2ECC71",
    width: "56%",
    marginRight: "9%",
    "&:hover": {
      backgroundColor: "#2ECC71",
    },
  },
  removebtn: {
    fontFamily: "Almarai-SemiBold",
    fontSize: "18px",
    backgroundColor: "#E74C3C",
    width: "56%",
    marginRight: "9%",
    "&:hover": {
      backgroundColor: "#E74C3C",
    },
  },
  badgediv: {
    border: "2px solid #26cde6",
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    padding: "2px",
    width: "26px",
    height: "26px",
    borderRadius: "17px",
  },
  badgedivnotadd: {
    border: "2px solid #26cde6",
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    padding: "2px",
    width: "26px",
    height: "26px",
    right: "-22px",
    borderRadius: "17px",
  },
  cart: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "12px",
    padding: "10px",
    margin: "0px 9px",
    height: "48px",
    "&:hover": {
      backgroundColor: "#1dafc5",
    },
  },
  cart1: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "16px",
    padding: "10px 23px",
    marginLeft: "9px",
    height: "48px",
    "&:hover": {
      backgroundColor: "#1dafc5",
    },
  },
  countDiv: {
    backgroundColor: "#D2DADE",
    borderRadius: "14px",
    padding: "10px",
    margin: "0px 9px",
    width: "40%",
    "&:hover": {
      backgroundColor: "#D2DADE",
    },
  },
  addDiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: "12%",
  },
  paper: {
    display: "flex",
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: "wrap",
    backgroundColor: "#565656",
    justifyContent: "center",
  },
  optbtn: {
    fontFamily: "Almarai-SemiBold",
    fontSize: "16px",
    color: theme.palette.text.secondary,
    textTransform: "capitalize",
    padding: "1% 28px",
    width: "182px",
    [theme.breakpoints.down("md")]: { width: "134px" },
  },
  title: {
    fontSize: "30px",
    fontFamily: "Almarai-Bold",
    paddingTop: "25px",
    paddingBottom: "10px",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: { fontSize: "23px", alignItems: "center" },
  },
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

var tagImage;
var tagColor;

function PromotionDialog(props) {
  const [progressColor] = React.useState({ color: "red" });
  const classes = useStyles(progressColor);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector((state) => state.language);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);
  const currency = useSelector((state) => state.currency);
  const [pageType, setPageType] = React.useState("Promotion");
  const [promotionDetails, setPromotionDetails] = useState();
  const [promotionData, setPromotionData] = useState();
  const [showtime, settime] = useState(1);
  const [socialPopup, setSocialPopup] = useState(false);
  const [banners, setBanners] = useState([]);
  const [mobileBanners, setMobileBanners] = useState([]);
  const [barColor, setBarColor] = useState();
  const [promotionCount, setPromotionCount] = useState(0);
  const [suggestions, setSuggestions] = useState([]);
  const [loadedMore, setLoadedMore] = useState(false);
  const [loadedMoreDiv, setLoadedMoreDiv] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);
  const [removeToCart, setRemoveToCart] = useState(false);
  const [fav, setFavUpdate] = React.useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [url, setUrl] = useState();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user?.user);
  const carts = useSelector((state) => state?.carts).carts;
  const [totalProduact, setTotalProducat] = useState(0);
  var prevCarts = JSON.parse(localStorage.getItem("ishro_user_carts_v3"));
  const gaEventTracker = analyticsEventTracker("Promotion Details Dialog");
  useEffect(() => {
    var promotionId;
    const url1 = window.location.href;
    if (props?.promotionId) {
      promotionId = props?.promotionId;
    } else {
      promotionId = url1.substring(url1.lastIndexOf("/") + 1);
    }
    setBanners([]);
    setMobileBanners([]);
    if (promotionId !== undefined) {
      getPromotionDetails(promotionId);
    }
  }, [url]);

  useEffect(() => {
    var total = 0;
    if (carts != null) {
      carts.map((cart) => {
        total += Number(cart?.product_quantity);
      });
    }

    setTotalProducat(total);
  }, [carts]);
  useEffect(() => {
    ReactGA.pageview("promotion/details/" + props?.promotionId, [
      isLoggedIn ? user.user_id : "Guest",
    ]);
  }, []);
  const handleSocialShare = () => {
    var data = {
      promotion_id: promotionDetails.promotion_id,
      title:
        language === "en"
          ? ReactHtmlParser(
              decode(promotionDetails?.draw_title).replace(/<[^>]+>/g, "")
            ) +
            "\n" +
            ReactHtmlParser(
              decode(promotionDetails?.draw_detail).replace(/<[^>]+>/g, "")
            )
          : ReactHtmlParser(
              decode(promotionDetails?.draw_title_ar).replace(/<[^>]+>/g, "")
            ) +
            "\n" +
            ReactHtmlParser(
              decode(promotionDetails?.draw_detail_ar).replace(/<[^>]+>/g, "")
            ),
    };

    setPromotionData(data);
    setSocialPopup(true);
  };

  const setPromotionPurchaseCount = (promotion_id) => {
    if (prevCarts != null) {
      var promotionDetails = prevCarts.filter(
        (el) => el.promotion_id === promotion_id
      );
      promotionDetails.length > 0
        ? setPromotionCount(promotionDetails[0].product_quantity)
        : setPromotionCount(0);
    }
  };

  const getPromotionDetails = async (promotionId) => {
    try {
      const resp = await axios.post(
        "home-promotion",
        JSON.stringify({
          promotion_id: promotionId,
        })
      );
      const { success } = resp.data;
      if (success) {
        setSuggestions(resp.data.suggestions);
        setPromotionDetails(resp.data.data);
        dispatch(
          actionCreators.loadPage(
            "web",
            "ViewContent",
            resp.data.data.promotion_id
          )
        );
        ReactPixel.track("ViewContent", {
          content_type: "Product",
          content_ids: resp.data.data.promotion_id,
          value: Number(resp.data.data.ticket_price_with_tax),
          currency: "AED",
          Email: isLoggedIn ? user.email : "Guest",
          External_id: isLoggedIn ? user.user_id : "Guest",
        });

        gaEventTracker("ViewContent", isLoggedIn ? user.user_id : "Guest");
        settime(
          timeDiffCalc(resp.data.data?.expiry_date, resp.data.data?.show_timer)
        );
        setBarColor(
          getBarColor(
            parseInt(resp.data.data.sold_out_tickets),
            parseInt(resp.data.data.total_tickets_alloted)
          )
        );
        setBanners((banners) => [
          ...banners,
          parseInt(resp.data.data.sold_out_tickets) >
            parseInt(resp.data.data.total_tickets_alloted) ||
          new Date(resp.data.data.expiry_date.replace(/-/g, "/")).getTime() <=
            new Date().getTime()
            ? resp.data.data.soldout_image
              ? resp.data.data.soldout_image
              : resp.data.data.draw_image
            : resp.data.data.draw_image,
        ]);
        setBanners((banners) => [...banners, resp.data.data.files[0].file]);
        setBanners((banners) => [
          ...banners,
          resp.data.data.product.files[0].file,
        ]);

        if (
          parseInt(resp.data.data.sold_out_tickets) >=
            parseInt(resp.data.data.total_tickets_alloted) ||
          new Date(resp.data.data.expiry_date.replace(/-/g, "/")).getTime() <=
            new Date().getTime()
        ) {
          setMobileBanners((mobileBanners) => [
            ...mobileBanners,
            resp.data.data.soldout_image
              ? resp.data.data.total_tickets_alloted !== 0
                ? resp.data.data.soldout_image
                : resp.data.data.draw_image
              : resp.data.data.draw_image,
          ]);
        } else {
          setMobileBanners((mobileBanners) => [
            ...mobileBanners,
            resp.data.data.files[0].file,
          ]);
          setMobileBanners((mobileBanners) => [
            ...mobileBanners,
            resp.data.data.product.files[0].file,
          ]);
        }

        tagImage = getTagImage(resp.data.data);
        tagColor = getTagColor(resp.data.data);
        setPromotionPurchaseCount(promotionId);
        setLoading(false);
        return;
      } else {
        navigate("/");
      }
      return;
    } catch (error) {
      return;
    }
  };
  const getBarColor = (ticketNow, totalTicket) => {
    if ((ticketNow / totalTicket) * 100 <= 25) {
      return "linear-gradient(0deg, #4dde89, #d1ffe4)";
    } else if (
      (ticketNow / totalTicket) * 100 > 25 &&
      (ticketNow / totalTicket) * 100 <= 75
    ) {
      return "linear-gradient(0deg, #EF942C, #F9D3A2)";
    } else if ((ticketNow / totalTicket) * 100 > 75) {
      return "linear-gradient(0deg, #E74C3C, #FFB3AB)";
    }
  };
  const timeDiffCalc = (date1, show_timer) => {
    var dateNow = new Date();
    var dateFuture1 = moment(date1).format("YYYY/MM/DD HH:mm:ss");
    var dateFuture = new Date(dateFuture1);
    var dif = dateFuture - dateNow;
    var minutes = Math.round(dif / 1000 / 60);
    var milesec = minutes * 60;
    var hours = minutes / 60;
    if (minutes > 0 && hours < 48) {
      var showtime = {
        show: show_timer,
        time: milesec,
      };
      return showtime;
    } else {
      var showtime = {
        show: false,
        time: "",
      };
      return showtime;
    }
  };
  const getTagImage = (promotion) => {
    if (promotion.draw_date >= new Date()) {
      return (
        <img
          alt=""
          src={
            language === "en"
              ? promotion.ticket_type.image_en
              : promotion.ticket_type.image_ar
          }
        />
      );
    }
  };

  const getTagColor = (promotion) => {
    if (
      parseInt(promotion.sold_out_tickets) >=
      parseInt(promotion.total_tickets_alloted)
    ) {
      return "#d2dade";
    } else if (promotion.draw_date >= new Date()) {
      return "#353434";
    } else {
      return promotion.ticket_type.color_code;
    }
  };

  const handleAddToCart = (event) => {
    var data;
    if (isLoggedIn)
      data = {
        product_id: promotionDetails.product_id,
        promotion_id: promotionDetails.promotion_id,
        product_quantity: promotionDetails.product.product_quantity,
      };
    else
      var data = {
        product_id: promotionDetails.product_id,
        promotion_id: promotionDetails.promotion_id,
        product_quantity: promotionDetails.product.product_quantity,
        product: promotionDetails.product,
        promotion: promotionDetails,
      };
    dispatch(
      actionCreators.loadPage("web", "AddToCart", promotionDetails.promotion_id)
    );
    dispatch(actionCreators.addToCart(data))
      .then((res) => {
        if (res) {
          toast(t("Added to Cart"));
          if (promotionDetails.product.product_quantity > 0) {
            ReactPixel.track("AddToCart", {
              content_type: "Product",
              content_ids: promotionDetails.promotion_id,
              value:
                Number(promotionDetails.product.product_quantity) *
                Number(promotionDetails.ticket_price_with_tax),
              currency: "AED",
              Email: isLoggedIn ? user.email : "Guest",
              External_id: isLoggedIn ? user.user_id : "Guest",
            });
            gaEventTracker("AddToCart", isLoggedIn ? user.user_id : "Guest");
          }
          setAddedToCart(true);
          setTimeout(() => {
            setPromotionCount(promotionDetails.product.product_quantity);
          }, 1000);
        }
      })
      .catch(() => {});
  };

  const handleAddFavourite = (event, value) => {
    event.preventDefault();
    if (getToken()) {
      if (value.favourite === "true") {
        value.favourite = "false";
      } else {
        value.favourite = "true";
        dispatch(
          actionCreators.loadPage("web", "AddToWishlist", value.promotion_id)
        );
        ReactPixel.track("AddToWishlist", {
          content_ids: value.promotion_id,
          Email: isLoggedIn ? user.email : "Guest",
          External_id: isLoggedIn ? user.user_id : "Guest",
        });
        gaEventTracker("AddToWishlist", isLoggedIn ? user.user_id : "Guest");
      }
      setFavUpdate(value.favourite);
      var data = {
        action: value.favourite === "true" ? "add" : "remove",
        product_id: value.product_id,
        promotion_id: value.promotion_id,
      };
      dispatch(actionCreators.addToFavourite(data));
    } else {
      if (isMobile) navigate("/login");
      else dispatch(loginDialog(event.currentTarget));
    }
  };

  const handlePageType = (event, pageType) => {
    setPageType(pageType);
  };

  const handleChangeQuantity = (promotionCount) => {
    if (promotionCount == 0) {
      setRemoveToCart(true);
    }
    if (promotionCount != 0) {
      promotionDetails.product.product_quantity = promotionCount;
    }

    if (isLoggedIn)
      var data = {
        product_id: promotionDetails.product_id,
        promotion_id: promotionDetails.promotion_id,
        product_quantity: promotionCount.toString(),
      };
    else
      var data = {
        product_id: promotionDetails.product_id,
        promotion_id: promotionDetails.promotion_id,
        product_quantity: promotionDetails.product.product_quantity,
        product: promotionDetails.product,
        promotion: promotionDetails,
      };
    setPromotionCount(promotionCount);
    dispatch(
      actionCreators.loadPage("web", "AddToCart", promotionDetails.promotion_id)
    );
    dispatch(actionCreators.addToCart(data)).then((res) => {
      ReactPixel.track("AddToCart", {
        content_type: "Product",
        content_ids: promotionDetails.promotion_id,
        value:
          Number(promotionDetails.product.product_quantity) *
          Number(promotionDetails.ticket_price_with_tax),
        currency: "AED",
        Email: isLoggedIn ? user.email : "Guest",
        External_id: isLoggedIn ? user.user_id : "Guest",
      });

      gaEventTracker("AddToCart", isLoggedIn ? user.user_id : "Guest");
    });
  };
  useEffect(() => {
    if (addedToCart) {
      setTimeout(() => {
        setAddedToCart(false);
      }, 1000);
    }
  }, [addedToCart]);
  useEffect(() => {
    if (removeToCart) {
      setTimeout(() => {
        setRemoveToCart(false);
      }, 1000);
    }
  }, [removeToCart]);

  const loadPromotion = (item) => {
    navigate(`promotion/details/${item.promotion_id}`);
  };
  return (
    <div>
      {promotionDetails !== undefined ? (
        <div className="wrap_content_promotion">
          <Helmet>
            <title>
              {language === "en"
                ? ReactHtmlParser(
                    decode(promotionDetails?.title).replace(/<[^>]+>/g, "")
                  )
                : ReactHtmlParser(
                    decode(promotionDetails?.title).replace(/<[^>]+>/g, "")
                  )}
            </title>
            <meta
              name="description"
              content={
                language === "en"
                  ? ReactHtmlParser(
                      decode(promotionDetails?.description).replace(
                        /(<p[^>]+?>|<p>|<\/p>)/gim,
                        ""
                      )
                    )
                  : ReactHtmlParser(
                      decode(promotionDetails?.description_ar).replace(
                        /(<p[^>]+?>|<p>|<\/p>)/gim,
                        ""
                      )
                    )
              }
            />
            <link
              rel="canonical"
              href={`https://ishro.com/promotion/details/${promotionDetails.promotion_id}`}
            />
            <meta
              property="og:title"
              content={
                language === "en"
                  ? ReactHtmlParser(
                      decode(promotionDetails?.title).replace(
                        /(<p[^>]+?>|<p>|<\/p>)/gim,
                        ""
                      )
                    )
                  : ReactHtmlParser(
                      decode(promotionDetails?.title_ar).replace(
                        /(<p[^>]+?>|<p>|<\/p>)/gim,
                        ""
                      )
                    )
              }
            />
            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content={`https://ishro.com/promotion/details/${promotionDetails.promotion_id}`}
            />
            <meta
              property="og:image"
              content={promotionDetails.files[0].file}
            />
            <meta
              property="og:description"
              content={
                language === "en"
                  ? ReactHtmlParser(
                      decode(promotionDetails?.description).replace(
                        /(<p[^>]+?>|<p>|<\/p>)/gim,
                        ""
                      )
                    )
                  : ReactHtmlParser(
                      decode(promotionDetails?.description_ar).replace(
                        /(<p[^>]+?>|<p>|<\/p>)/gim,
                        ""
                      )
                    )
              }
            />
            <meta
              itemprop="name"
              content={
                language === "en"
                  ? ReactHtmlParser(
                      decode(promotionDetails?.title).replace(
                        /(<p[^>]+?>|<p>|<\/p>)/gim,
                        ""
                      )
                    )
                  : ReactHtmlParser(
                      decode(promotionDetails?.title_ar).replace(
                        /(<p[^>]+?>|<p>|<\/p>)/gim,
                        ""
                      )
                    )
              }
            />
            <meta
              itemprop="description"
              content={
                language === "en"
                  ? ReactHtmlParser(
                      decode(promotionDetails?.description).replace(
                        /(<p[^>]+?>|<p>|<\/p>)/gim,
                        ""
                      )
                    )
                  : ReactHtmlParser(
                      decode(promotionDetails?.description_ar).replace(
                        /(<p[^>]+?>|<p>|<\/p>)/gim,
                        ""
                      )
                    )
              }
            />
            <meta itemprop="image" content={promotionDetails.files[0].file} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:description"
              content={
                language === "en"
                  ? decode(promotionDetails?.description).replace(
                      /(<p[^>]+?>|<p>|<\/p>)/gim,
                      ""
                    )
                  : decode(promotionDetails?.description_ar).replace(
                      /(<p[^>]+?>|<p>|<\/p>)/gim,
                      ""
                    )
              }
            />
            <meta
              name="twitter:image:src"
              content={promotionDetails.files[0].file}
            />
            <meta
              name="price"
              content={promotionDetails?.ticket_price_with_tax}
            />
          </Helmet>

          <Grid container style={{ padding: "3% 5%" }}>
            {/* <Grid item xs={12} sm={12} md={12} lg={12}></Grid> */}
            <Grid item xs={12} sm={12} md={8} lg={8} className={classes.proimg}>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "33px",
                }}
              >
                <IconButton
                  onClick={(e) => props.promotionDetailsCloseCallback()}
                  style={{ padding: "2px 4% 9px 0px" }}
                >
                  <CloseIcon style={{ color: "white", fontSize: "32px" }} />
                </IconButton>
                <IconButton
                  style={{ padding: "2px 4% 9px 0px" }}
                  onClick={(e) => loadPromotion(promotionDetails)}
                >
                  <Maximize2 style={{ color: "white" }} />
                </IconButton>

                {promotionDetails?.favourite === "true" ? (
                  <IconButton
                    style={{ padding: "2px 4% 9px 0px" }}
                    onClick={(e) => handleAddFavourite(e, promotionDetails)}
                  >
                    <FavoriteIcon style={{ color: "#E62483" }} />
                  </IconButton>
                ) : (
                  <IconButton
                    style={{ padding: "2px 4% 9px 0px" }}
                    onClick={(e) => handleAddFavourite(e, promotionDetails)}
                  >
                    <FavoriteBorderIcon style={{ color: "#E62483" }} />
                  </IconButton>
                )}
                <IconButton
                  style={{ padding: "2px 4% 9px 0px" }}
                  onClick={(e) => handleSocialShare(e, promotionDetails)}
                >
                  <ShareIcon color="secondary" />
                </IconButton>
              </p>
              {pageType === "Promotion" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "4%",
                  }}
                >
                  <Typography
                    variant="p"
                    component="h1"
                    className={classes.promotitle1}
                    dir={language == "en" ? "rtr" : "rtl"}
                    dangerouslySetInnerHTML={{
                      __html: promotionDetails
                        ? language === "en"
                          ? decode(promotionDetails?.draw_title)
                          : decode(promotionDetails?.draw_title_ar)
                        : "",
                    }}
                  />
                  <Typography
                    variant="p"
                    component="h2"
                    className={classes.promotitle2}
                    dir={language == "en" ? "rtr" : "rtl"}
                    dangerouslySetInnerHTML={{
                      __html: promotionDetails
                        ? language === "en"
                          ? isValid(promotionDetails?.draw_detail)
                            ? decode(promotionDetails?.draw_detail)
                            : decode(promotionDetails?.draw_detail)
                          : isValid(promotionDetails?.draw_detail_ar)
                          ? decode(promotionDetails?.draw_detail_ar)
                          : decode(promotionDetails?.draw_detail_ar)
                        : "",
                    }}
                  />
                  {showtime.show == "true" && (
                    <div style={{ padding: "1% 0% 0%" }}>
                      <CountdownTimer
                        count={showtime.time}
                        size={20}
                        hideDay={showtime.time > 86399 ? false : true}
                        color="#353434"
                        backgroundColor="#F5F8F8"
                      />
                    </div>
                  )}
                  <div className={classes.proimg}>
                    <img
                      alt=""
                      src={
                        parseInt(promotionDetails.sold_out_tickets) <
                        parseInt(promotionDetails.total_tickets_alloted)
                          ? promotionDetails?.draw_image
                          : promotionDetails?.soldout_image
                          ? promotionDetails?.soldout_image
                          : promotionDetails?.draw_image
                      }
                      style={{ width: "100%", marginTop: "10px" }}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <img
                    src={promotionDetails?.product?.files[0].file}
                    alt=""
                    width="100%"
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} className={classes.banner}>
              <Paper elevation={0} className={classes.paper}>
                <StyledToggleButtonGroup
                  size="small"
                  value={pageType}
                  exclusive
                  onChange={handlePageType}
                  aria-label="text alignment"
                >
                  <ToggleButton
                    value="Promotion"
                    aria-label="left aligned"
                    className={classes.optbtn}
                  >
                    {language === "en" ? "Prize Details" : "تفاصيل الجائزة"}
                  </ToggleButton>
                  <ToggleButton
                    value="Product"
                    aria-label="centered"
                    className={classes.optbtn}
                  >
                    {language === "en" ? "Product Details" : "تفاصيل المنتج"}
                  </ToggleButton>
                </StyledToggleButtonGroup>
              </Paper>

              {pageType === "Promotion" ? (
                <>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "7.8% 0% 0px",
                    }}
                  >
                    <CurrencyFormat
                      value={CalcCurrency(
                        promotionDetails?.ticket_price_with_tax,
                        currency.value
                      )}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={` ${currency.label} `}
                      renderText={(value) => (
                        <div className={classes.promoPrice}> {value} </div>
                      )}
                    />
                    {promotionDetails?.original_price > 0 && (
                      <>
                        <span className={classes.discountPer}>
                          {(
                            100 *
                            ((parseInt(promotionDetails?.original_price) -
                              parseInt(
                                promotionDetails?.ticket_price_with_tax
                              )) /
                              parseInt(promotionDetails?.original_price))
                          ).toFixed(0)}
                          % OFF
                        </span>

                        <CurrencyFormat
                          value={CalcCurrency(
                            promotionDetails?.original_price,
                            currency.value
                          )}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={` ${currency.label} `}
                          renderText={(value) => (
                            <div className={classes.promoPrice1}> {value} </div>
                          )}
                        />
                      </>
                    )}
                  </p>

                  <p style={{ margin: "7px 3px", color: "#FFFEFE" }}>
                    <span>
                      {language === "en"
                        ? "Tickets Sold"
                        : "عدد التذاكر المباعة"}{" "}
                    </span>

                    {language === "en" ? (
                      <span style={{ float: "right" }}>
                        {promotionDetails?.sold_out_tickets} /
                        {promotionDetails?.total_tickets_alloted}
                      </span>
                    ) : (
                      <span style={{ float: "left" }}>
                        {promotionDetails?.sold_out_tickets}/
                        {promotionDetails?.total_tickets_alloted}
                      </span>
                    )}
                  </p>

                  <ProgressBar
                    bgcolor={barColor}
                    completed={
                      (promotionDetails?.sold_out_tickets /
                        promotionDetails?.total_tickets_alloted) *
                      100
                    }
                  />
                  {promotionDetails?.sponsor !== undefined && (
                    <div>
                      <p style={{ color: "rgb(255, 255, 255)" }}>
                        {language === "en" ? "Sponsored by:" : " برعاية:"}
                      </p>
                      <p
                        style={{
                          textAlign: "center",
                          backgroundColor: "#F3F3F3",
                          borderRadius: "18px",
                          padding: "19px 0px",
                        }}
                      >
                        <img
                          alt=""
                          src={
                            language === "en"
                              ? promotionDetails?.sponsor?.image
                              : promotionDetails?.sponsor?.image_ar
                          }
                        />
                      </p>
                    </div>
                  )}
                  <Scrollbars
                    renderTrackVertical={(props) => (
                      <div
                        {...props}
                        className={loadedMore ? "track-vertical" : ""}
                      />
                    )}
                    renderThumbVertical={(props) => (
                      <div
                        {...props}
                        className={loadedMore ? "thumb-vertical" : ""}
                      />
                    )}
                    renderView={(props) => (
                      <div
                        {...props}
                        className={language == "ar" ? "scroll-view" : ""}
                      />
                    )}
                    style={{ height: 380, marginBottom: "3%" }}
                  >
                    <Typography
                      variant="p"
                      component="p"
                      className="descripDiv"
                      style={{
                        marginBottom: "1.35em",
                        color: "#FFFFFF",
                        lineHeight: "1.6",
                        marginRight: "21px",
                      }}
                      dir={language == "en" ? "rtr" : "rtl"}
                      dangerouslySetInnerHTML={{
                        __html: promotionDetails
                          ? language === "en"
                            ? isValid(promotionDetails?.description)
                              ? loadedMore
                                ? decode(promotionDetails?.description)
                                : decode(
                                    promotionDetails?.description
                                  ).substring(0, 1020)
                              : decode(promotionDetails?.description)
                            : isValid(promotionDetails?.description_ar)
                            ? loadedMore
                              ? decode(promotionDetails?.description_ar)
                              : decode(
                                  promotionDetails?.description_ar
                                ).substring(0, 1020)
                            : decode(promotionDetails?.description_ar)
                          : "",
                      }}
                    />
                  </Scrollbars>
                  <span
                    style={{
                      color: "#26cde6",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                    onClick={() =>
                      loadedMore ? setLoadedMore(false) : setLoadedMore(true)
                    }
                  >
                    <a href="#">
                      {loadedMore
                        ? t("SHOW LESS")
                        : language === "en"
                        ? decode(promotionDetails?.description).length > 1020
                          ? t("SHOW MORE")
                          : ""
                        : decode(promotionDetails?.description_ar).length > 1020
                        ? t("SHOW MORE")
                        : ""}
                    </a>
                  </span>
                </>
              ) : (
                <>
                  <Typography
                    variant="p"
                    component="h1"
                    className={classes.title}
                    dir={language == "en" ? "rtr" : "rtl"}
                    dangerouslySetInnerHTML={{
                      __html: promotionDetails
                        ? language === "en"
                          ? decode(promotionDetails?.product?.title)
                          : decode(promotionDetails?.product?.title_ar)
                        : "",
                    }}
                  />
                  <Typography
                    variant="h5"
                    component="h5"
                    style={{ marginBottom: "1.35em", color: "#FFFFFF" }}
                  >
                    {language === "en" ? "Product" : "المنتج"}
                  </Typography>

                  <Typography
                    variant="p"
                    component="p"
                    className="descripDiv"
                    style={{
                      marginBottom: "1.35em",
                      color: "#FFFFFF",
                      lineHeight: "1.6",
                      marginRight: "21px",
                    }}
                    dir={language == "en" ? "rtr" : "rtl"}
                    dangerouslySetInnerHTML={{
                      __html: promotionDetails
                        ? language === "en"
                          ? isValid(promotionDetails?.product?.description)
                            ? decode(promotionDetails?.product?.description)
                            : decode(promotionDetails?.product?.description)
                          : isValid(promotionDetails?.product?.description_ar)
                          ? decode(promotionDetails?.product?.description_ar)
                          : decode(promotionDetails?.product?.description_ar)
                        : "",
                    }}
                  />
                  {/* <span
                    style={{
                      color: "#26cde6",
                      cursor: "pointer",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                    onClick={() =>
                      loadedMoreDiv
                        ? setLoadedMoreDiv(false)
                        : setLoadedMoreDiv(true)
                    }
                  >
                    <a href="#">
                      {loadedMoreDiv
                        ? t("SHOW LESS")
                        : language === "en"
                        ? decode(promotionDetails?.product?.description)
                            .length > 1020
                          ? t("SHOW MORE")
                          : ""
                        : decode(promotionDetails?.product?.description_ar)
                            .length > 1020
                        ? t("SHOW MORE")
                        : ""}
                    </a>
                  </span> */}
                  {/* <Scrollbars
                    renderTrackVertical={(props) => (
                      <div
                        {...props}
                        className={loadedMoreDiv ? "track-vertical" : ""}
                      />
                    )}
                    renderThumbVertical={(props) => (
                      <div
                        {...props}
                        className={loadedMoreDiv ? "thumb-vertical" : ""}
                      />
                    )}
                    renderView={(props) => (
                      <div
                        {...props}
                        className={language == "ar" ? "scroll-view" : ""}
                      />
                    )}
                    style={{ height: 380, marginBottom: "3%" }}
                  >
                 
                  </Scrollbars> */}
                </>
              )}
              <p className={classes.addDiv}>
                {promotionCount > 0 ? (
                  <>
                    <IconButton
                      color="primary"
                      className={classes.cart}
                      onClick={() =>
                        handleChangeQuantity(parseInt(promotionCount) - 1)
                      }
                    >
                      <RemoveIcon />
                    </IconButton>
                    <IconButton color="primary" className={classes.countDiv}>
                      {promotionCount}
                    </IconButton>
                    <IconButton
                      color="primary"
                      className={classes.cart}
                      onClick={() =>
                        handleChangeQuantity(parseInt(promotionCount) + 1)
                      }
                    >
                      <AddIcon />
                    </IconButton>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    className={
                      removeToCart
                        ? classes.removebtn
                        : addedToCart
                        ? classes.addedbtn
                        : classes.btn
                    }
                    onClick={handleAddToCart}
                    startIcon={
                      removeToCart ? (
                        <CheckIcon className={classes.check} />
                      ) : (
                        addedToCart && <CheckIcon className={classes.check} />
                      )
                    }
                  >
                    {removeToCart
                      ? t("REMOVED")
                      : addedToCart
                      ? t("ADDED")
                      : t("Add to Cart")}
                  </Button>
                )}

                <Link to="/cart">
                  <Badge
                    badgeContent={totalProduact}
                    color="primary"
                    classes={{
                      badge:
                        totalProduact == 0 && language == "ar"
                          ? classes.badgedivnotadd
                          : classes.badgediv,
                    }}
                  >
                    <IconButton
                      color="primary"
                      aria-label="add to shopping cart"
                      className={classes.cart1}
                    >
                      <ShoppingCart />
                      {/* <img src={AddShoppingCartIcon} alt="" /> */}
                    </IconButton>
                  </Badge>
                </Link>
              </p>
            </Grid>
          </Grid>
        </div>
      ) : (
        <>
          {/* {loading && (
            <div className={classes.loader}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: user?.switched != "1" ? loader : loadervip,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={250}
                width={250}
              />
            </div>
          )} */}
        </>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <SocialPopup
        HandlePopup={setSocialPopup}
        show={socialPopup}
        url={
          "https://ishro.com/promotion/details/" + promotionData?.promotion_id
        }
        title={promotionData?.title}
      />
    </div>
  );
}
export default PromotionDialog;

import { Types } from "../actions/actionTypes";

const language = (state = false, action) => {
  switch (action.type) {
    case Types.SET_LOADING_STATUS:
      return action.state;
    default:
      return state;
  }
};

export default language;

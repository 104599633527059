import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    maxHeight: "600px",
    maxWidth: "600px",
    height: "600px",
    width: "600px",
    position: "relative",
    borderRadius: "20px",
    overflow: "hidden",
    margin: "10px",
    [theme.breakpoints.down("md")]: {
      width: "300px",
      height: "300px",
      margin: "10px",
    },
  },
  maindiv: {
    justifyContent: "center",
  },
  portrait: {
    width: "300px",
    height: "600px",
    [theme.breakpoints.down("lg")]: { width: "300px", height: "300px" },
  },
  title: { fontSize: "24px", fontFamily: "Almarai-SemiBold" },
  portraitTitle: {
    fontSize: "22px",
    fontFamily: "Almarai-SemiBold",
  },
  portraitDescription: {
    fontSize: "12px",
    fontFamily: "Almarai-SemiBold",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  description: {
    fontSize: "14px",
    fontFamily: "Almarai-SemiBold",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  small: {
    width: "375px",
    height: "375px",
    [theme.breakpoints.down("md")]: { width: "300px", height: "300px" },
  },
  overlay: {
    background: "linear-gradient(360deg, #353434, transparent)",
    position: "absolute",
    inset: "0",
    color: "#FFFFFF",
    display: "flex",
    alignItems: "end",
    padding: "30px 4%",
    cursor: "pointer",
  },
  tag: {
    backgroundColor: theme.palette.secondary.main,
    padding: "5px",
    margin: "5px",
    borderRadius: "10px",
  },
  image: {
    objectFit: "cover",
  },
}));

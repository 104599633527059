import React, { useEffect } from "react";
import "./termsAndConditions.css";
import { useSelector, useDispatch } from "react-redux";
import loading from "../../../store/actions/loading";

import {
  makeStyles,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactGA from "react-ga";
const useStyles = makeStyles((theme) => ({
  paperDiv: {
    margin: "2% 0%",
    color: theme.palette.text.secondary,
    borderRadius: "4px",
  },
  heading: {
    fontFamily: "Almarai-SemiBold",
    paddingLeft: "24px!important",
    fontSize: "14px!important",
    [theme.breakpoints.down("sm")]: { paddingLeft: "8px!important" },
  },
  // Mobilebackground: {
  //   backgroundColor: theme.palette.primary.main,
  //   "& h3": {
  //     color: "white !important",
  //   },
  //   "& h4": {
  //     color: "white !important",
  //   },
  //   "& span": {
  //     color: "white !important",
  //   },
  //   "& p": {
  //     color: "white !important",
  //   },
  //   "& li": {
  //     color: "white !important",
  //   },
  //   "& div": {
  //     color: "white !important",
  //   },
  //   "& strong": {
  //     color: "white !important",
  //   },
  // },
}));

function TermsAndConditions({ mobile, lang = null }) {
  let language = useSelector((state) => state.language);
  const dispatch = useDispatch();
  if (lang) language = lang;
  const classes = useStyles();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div>
      <div className={`container-fluid mt_sticky pzero_mob`}>
        <div className="container Termcontainer">
          <div
            className={
              language === "en"
                ? "col-sm-12 p-0 wrapper_main_privacy"
                : "col-sm-12 p-0 wrapper_main_privacy"
            }
          >
            <h3>
              {language === "en" ? "Terms and Conditions" : "الشروط والأحكام"}
            </h3>

            <h4>
              {language === "en" ? (
                "Ishro is owned and operated by Ishro General Trading LLC."
              ) : (
                <span dir="rtl">
                  تمتلك "إيشرو للتجارة العامة ش.ذ.م.م." موقع Ishro وتديره.
                </span>
              )}
            </h4>
            <span>
              {language === "en" ? (
                "These are the terms and conditions for: https://ishro.com (Hereby “Ishro”)"
              ) : (
                <span dir="rtl">
                  هذه هي الشروط والأحكام الخاصة بـ: https://ishro.com (المُشار
                  إليه فيما يلي باسم "Ishro").
                </span>
              )}
            </span>

            <p>
              {language === "en" ? (
                "The following terms and conditions apply to the website, products and services offered by Ishro. This includes the mobile and tablet versions as well as any other version of Ishro accessible via desktop, mobile, tablet, social media or other devices. "
              ) : (
                <span dir="rtl">
                  تنطبق الشروط والأحكام التالية على الموقع والمنتجات والخدمات
                  التي يقدمها Ishro، ويتضمن ذلك إصدارات الهواتف الجوالة
                  والحواسيب اللوحية بالإضافة إلى أي إصدار آخر من Ishro يمكن
                  الوصول إليه عبر الحواسيب المكتبية أو الهواتف الجوالة أو
                  الحواسيب اللوحية أو وسائل التواصل الاجتماعي أو غيرها من
                  الأجهزة.
                </span>
              )}
            </p>

            <p>
              {language === "en" ? (
                'The use of the website and the purchase of the products (hereinafter referred to as “Products”) from such website, means that you agree to these terms and conditions as set out below (hereinafter referred to as "Terms").'
              ) : (
                <span dir="rtl">
                  استخدامك للموقع وشراءك للمنتجات (المشار إليها فيما يلي باسم
                  "المنتجات") من هذا الموقع يعني أنك توافق على هذه الشروط
                  والأحكام على النحو المبين أدناه (يشار إليها فيما بعد باسم
                  "الشروط").
                </span>
              )}
            </p>
            <p>
              {language === "en" ? (
                "PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE ACCESSING, USING OR OBTAINING ANY MATERIALS, INFORMATION OR PRODUCTS."
              ) : (
                <span dir="rtl">
                  يرجى قراءة هذه الشروط والأحكام بعناية قبل الدخول إلى أي مواد
                  أو معلومات أو منتجات أو استخدامها أو الحصول عليها.
                </span>
              )}
            </p>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "1. ELIGIBLITY"
                  ) : (
                    <span dir="rtl">1. الأهلية</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "This agreement sets forth legally binding terms for the use of the website. By using and visiting the website, you agree to be bound by this agreement whether you are a 'visitor' (meaning that you are simply browsing the website) or a 'User' (meaning that you have registered on the website as a user). If you do not accept the terms of this agreement, you should stop using the website immediately. We may modify this agreement from time to time, and such modification shall be effective when posted on the website. You agree to be bound by any modifications to this agreement when you use the website after such modification is posted. therefore, it is important that you review this agreement regularly."
                    ) : (
                      <span dir="rtl">
                        تحدد هذه الاتفاقية الشروط الملزمة قانوناً لاستخدام
                        الموقع. باستخدامك للموقع وزيارته، فإنك توافق على
                        الالتزام بهذه الاتفاقية سواء كنت "زائراً" (بمعنى أنك
                        تتصفح الموقع فقط) أو "مستخدماً" (بمعنى أنك قمت بالتسجيل
                        على الموقع كمستخدم). إذا كنت لا تقبل شروط هذه الاتفاقية،
                        يجب عليك التوقف عن استخدام الموقع على الفور. يجوز لنا
                        تعديل هذه الاتفاقية من وقت لآخر، ويسري هذا التعديل عند
                        نشره على الموقع. أنت توافق على الالتزام بأي تعديلات على
                        هذه الاتفاقية عند استخدامك للموقع بعد نشر هذا التعديل؛
                        لذا يتعين عليك أن تقوم بمراجعة هذه الاتفاقية بانتظام.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "You may use the website and purchase the products only in compliance with these terms and all applicable local, state, national, and international laws, rules, and regulations."
                    ) : (
                      <span dir="rtl">
                        لا يجوز لك استخدام الموقع وشراء المنتجات إلا بما يتماشى
                        مع هذه الشروط وجميع القوانين والقواعد واللوائح المعمول
                        بها على المستوى المحلي والولاية والوطني والدولي.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "The use of this website by persons under the age of 18 is prohibited. Ishro reserves the right to make the necessary age verifications during registration. It is the responsibility of parents and legal guardians of children under 18 to determine whether any of the content and products offered through this website are appropriate for their child."
                    ) : (
                      <span dir="rtl">
                        يحظر استخدام هذا الموقع من قبل الأشخاص الذين تقل أعمارهم
                        عن 18 عاماً، ويحتفظ Ishro بالحق في إجراء عمليات التحقق
                        من العمر اللازمة أثناء التسجيل، وتقع على عاتق الآباء
                        والأوصياء القانونيين للأطفال الذين تقل أعمارهم عن 18
                        عاماً تحديد ما إذا كان أي من المحتوى والمنتجات المعروضة
                        من خلال هذا الموقع مناسبة لأطفالهم.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "You represent and warrant that all registration information you submit is accurate and truthful. and that your use of the website does not violate any applicable law or regulation. Ishro may, in its sole discretion, refuse use of the website to any user and change its eligibility criteria at any time. This provision is void where prohibited by law and the right to access the website is revoked in such jurisdictions."
                    ) : (
                      <span dir="rtl">
                        أنت تقر وتتعهد بأن جميع معلومات التسجيل التي تقدمها
                        دقيقة وصادقة؛ وأن استخدامك للموقع لا ينتهك أي قانون أو
                        لائحة معمول بها. ويجوز لموقع Ishro، وفقاً لتقديره
                        المطلق، رفض استخدام الموقع لأي مستخدم وتغيير معايير
                        الأهلية في أي وقت، على أن هذا الحكم يعد باطلاً حيثما
                        يكون ذلك محظوراً بموجب القانون، ويُلغى الحق في الوصول
                        إلى الموقع في تلك الولايات القضائية.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "By using the website and purchase the products, you represent and warrant that you have the full right, power and authority to enter into these terms and to fully perform all of your obligations hereunder. You further represent and warrant that you are under no legal disability or contractual restriction that prevents you from entering into these terms."
                    ) : (
                      <span dir="rtl">
                        باستخدامك للموقع وشراء المنتجات، فإنك تقر وتتعهد بأن
                        لديك الحق الكامل والصلاحية والسلطة للدخول في هذه الشروط
                        وتنفيذ جميع التزاماتك الواردة أدناه بشكل كامل، كما تقر
                        وتتعهد بأنك لست خاضعاً لعبء أي إعاقة قانونية أو قيود
                        تعاقدية تمنعك من الدخول في هذه الشروط.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {language === "en" ? (
                    "2. ACCOUNT"
                  ) : (
                    <span dir="rtl">2. الحساب</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "If you register on Ishro, you will be required to choose a password and username and you may be asked for additional information regarding your account. You are responsible for maintaining the confidentiality of your password and account information, and are fully responsible for all activities that occur under your password or account. You agree to (a) immediately notify Ishro of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you log out from your account at the end of each session. You may never use another user’s account without prior authorization from Ishro. Ishro will not be liable for any loss or damage arising from your failure to comply with this agreement."
                    ) : (
                      <span dir="rtl">
                        إذا قمت بالتسجيل في Ishro، فسيُطلب منك اختيار كلمة مرور
                        واسم مستخدم، وقد يُطلب منك معلومات إضافية بخصوص حسابك.
                        أنت مسؤول عن الحفاظ على سرية كلمة المرور ومعلومات
                        الحساب، كما تتحمل المسؤولية الكاملة عن جميع الأنشطة التي
                        تحدث باستخدام كلمة المرور أو الحساب الخاص بك، وتوافق على
                        (أ) إخطار Ishro على الفور بأي استخدام غير مصرح به لكلمة
                        مرورك أو حسابك أو أي خرق أمني آخر، و(ب) التأكد من تسجيل
                        الخروج من حسابك في نهاية كل جلسة. ولا يجوز لك أبداً
                        استخدام حساب مستخدم آخر دون إذن مسبق من Ishro. ولن يكون
                        Ishro مسؤولاً عن أي خسارة أو ضرر ناتج عن عدم امتثالك
                        لهذه الاتفاقية.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "By providing Ishro with your email address and phone number you consent to our use of your email address to send you notices about the service and products, including those required by law. We may also use your email address and phone number to send you notifications and other messages, such as changes to service features, news, and special offers on our products. If you do not wish to receive these emails, you may opt-out of receiving them by sending us your withdrawal request via the contact information or by using the 'unsubscribe' option in the emails or mobile notifications. Opting out may prevent you from receiving emails about updates, news or offers."
                    ) : (
                      <span dir="rtl">
                        من خلال تزويد Ishro بعنوان بريدك الإلكتروني ورقم هاتفك،
                        فإنك توافق على استخدامنا لعنوان بريدك الإلكتروني لإرسال
                        الإشعارات إليك حول الخدمة والمنتجات، بما في ذلك تلك
                        الإشعارات التي يتطلبها القانون، كما يجوز لنا استخدام
                        عنوان بريدك الإلكتروني ورقم هاتفك لإرسال إشعارات ورسائل
                        أخرى إليك، مثل التغييرات في ميزات الخدمة والأخبار
                        والعروض الخاصة على منتجاتنا. إذا كنت لا ترغب في تلقي
                        رسائل البريد الإلكتروني هذه، فيمكنك إلغاء الاشتراك فيها
                        عن طريق إرسال طلبك بالإنسحاب إلينا عبر معلومات الاتصال
                        أو باستخدام خيار "إلغاء الاشتراك" في رسائل البريد
                        الإلكتروني أو إشعارات الهاتف الجوال، مع العلم أن إلغاء
                        الاشتراك قد يمنعك من تلقي رسائل البريد الإلكتروني حول
                        التحديثات أو الأخبار أو العروض.
                      </span>
                    )}
                  </span>

                  <span>
                    {language === "en" ? (
                      "The users may terminate their accounts any time, for any reason, by following the instructions on Ishro. That termination will only result in the deletion of the account and the deletion of all the personal data granted to Ishro."
                    ) : (
                      <span dir="rtl">
                        يمكن للمستخدمين إنهاء حساباتهم في أي وقت ولأي سبب باتباع
                        التعليمات الموجودة على Ishro، ولن يؤدي هذا الإنهاء إلا
                        إلى حذف الحساب وحذف جميع البيانات الشخصية الممنوحة لموقع
                        Ishro.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "Ishro reserves the right to terminate your account or your access immediately, with or without notice to you, and without liability to you, if Ishro believes that you have breached any of these terms, furnished Ishro with false or misleading information, or interfered with use of the website or the service by others."
                    ) : (
                      <span dir="rtl">
                        يحتفظ Ishro بالحق في إنهاء حسابك أو وصولك على الفور، بعد
                        إخطارك بذلك أو بدونه، ودون تحمل أي مسؤولية تجاهك، إذا
                        اعتقد Ishro أنك انتهكت أياً من هذه الشروط، أو قدمت إلى
                        Ishro معلومات خاطئة أو مضللة، أو تدخلت في استخدام
                        الآخرين للموقع أو الخدمة.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "3. SHOPPING ON THE WEBSITE"
                  ) : (
                    <span dir="rtl">3. التسوق على الموقع</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "When you place an order, you offer to buy the products for the price advertised and indicated on the website at the time of purchase. Please check correctly the variants, sizes, colors and features of the products before placing an order."
                    ) : (
                      <span dir="rtl">
                        عند تقديمك لطلب، فإنك تعرض شراء المنتجات بالسعر المعلن
                        عنه والمشار إليه على الموقع وقت الشراء، لذا يرجى التحقق
                        من صحة البدائل المتاحة والأحجام والألوان والميزات الخاصة
                        بالمنتجات قبل تقديم الطلب.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "When a customer places an order, Ishro will send that customer an email which aims to confirm the purchase and payment. This email confirmation will be produced automatically so that the user has the confirmation of his purchase and the details of the order. If the product is not available, the buyer will be informed by email or phone."
                    ) : (
                      <span dir="rtl">
                        عندما يقدم العميل طلباً، سيرسل Ishro رسالة بالبريد
                        الإلكتروني للعميل بهدف تأكيد عملية الشراء والدفع، وسيتم
                        إصدار هذا التأكيد عبر البريد الإلكتروني تلقائياً بحيث
                        يحصل المستخدم على تأكيد لعملية الشراء وتفاصيل الطلب،
                        وإذا لم يكن المنتج متاحاً، فسيتم إبلاغ المشتري عبر
                        البريد الإلكتروني أو الهاتف.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "Ishro may cancel any order and not supply products if it is reasonable to do so and may change or discontinue the availability of products at any time in its sole discretion. If an order is cancelled, any payments made for products will be refunded in full. This does not affect your statutory rights."
                    ) : (
                      <span dir="rtl">
                        يجوز لموقع Ishro إلغاء أي طلب وعدم توريد المنتجات إذا
                        كان من المعقول القيام بذلك، وقد يغير أو يتوقف عن توفير
                        المنتجات في أي وقت وفقاً لتقديره المطلق، وإذا تم إلغاء
                        أحد الطلبات، فسيتم رد أي مدفوعات تم سدادها للمنتجات
                        بالكامل، مع العلم أن هذا لا يؤثر على حقوقك القانونية.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "All new orders are considered separately and each is treated individually."
                    ) : (
                      <span dir="rtl">
                        يتم النظر في جميع الطلبات الجديدة بشكل منفصل ويتم
                        التعامل مع كل منها على حدة.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "4. PRICES"
                  ) : (
                    <span dir="rtl">4. الأسعار</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "The prices of the products available on the website include VAT."
                    ) : (
                      <span dir="rtl">
                        تشمل أسعار المنتجات المتوفرة على الموقع ضريبة القيمة
                        المضافة.
                      </span>
                    )}
                  </span>

                  <span>
                    {language === "en" ? (
                      "The main currency of the website is AED Emirati dirham. The currency displayed on the site will depend on the location since buyers use the website and buy the products. The currency may also depend on the type of card used by the buyer to process the transactions. Please note that some fees may apply for currency exchange."
                    ) : (
                      <span dir="rtl">
                        العملة الرئيسية للموقع هي الدرهم الإماراتي، وتعتمد
                        العملة المعروضة على الموقع على المكان الذي يستخدم منه
                        المشترون الموقع ويشترون المنتجات، كما تعتمد العملة على
                        نوع البطاقة التي يستخدمها المشتري لمعالجة المعاملات، مع
                        ملاحظة أنه قد يتم تطبيق بعض الرسوم على صرف العملات.
                      </span>
                    )}
                  </span>

                  <span>
                    {language === "en" ? (
                      "Ishro reserves the right to determine the price for the products. Ishro will make reasonable efforts to keep the price information published on the website up to date. We encourage you to check our website periodically for current pricing information."
                    ) : (
                      <span dir="rtl">
                        يحتفظ Ishro بالحق في تحديد سعر المنتجات، وسيبذل Ishro كل
                        جهداً ممكن لتحديث معلومات الأسعار المنشورة على الموقع
                        باستمرار، لذا نحن نشجعك على مراجعة موقعنا بشكل دوري
                        للحصول على معلومات الأسعار الحالية.
                      </span>
                    )}
                  </span>

                  <span>
                    {language === "en" ? (
                      "Will always try to make sure that the prices on the website is accurate. However, some errors in terms of price may occur from time to time, including but not limited to human error, mechanical error or the like. Should an error in pricing be discovered, the customer will be informed of such error. The option to reconfirm the order will be given to the user at the correct price. The user also has the option to cancel the order should the user not be satisfied with the correct price communicated on the said product order."
                    ) : (
                      <span dir="rtl">
                        سنحاول دائماً التأكد من دقة الأسعار على الموقع، ومع ذلك
                        قد تحدث بعض الأخطاء فيما يتعلق بالسعر من وقت لآخر، بما
                        في ذلك على سبيل المثال لا الحصر الخطأ البشري أو الخطأ
                        الميكانيكي أو ما شابه، وفي حالة اكتشاف خطأ في الأسعار،
                        سيتم إبلاغ العميل بهذا الخطأ، مع منح المستخدم خيار إعادة
                        تأكيد الطلب بالسعر الصحيح، كما أنه متاح للمستخدم خيار
                        إلغاء الطلب في حالة عدم رضا المستخدم عن السعر الصحيح
                        الذي يتم إبلاغه به بشأن طلب المنتج المذكور.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "5. PAYMENTS"
                  ) : (
                    <span dir="rtl">5. المدفوعات</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "Transactions within the website will be made through the payment platforms available on the website (accepts all major credit and debit cards). The customer must pay the price of the product as stipulated on the order before, the product is shipped and delivered to the customer. Payment will be debited from your credit/debit card immediately on you placing the order for the product you have purchased. Upon processing a transaction, we will issue you with an electronic transaction receipt which will be sent to the email address you provide."
                    ) : (
                      <span dir="rtl">
                        سيتم إجراء المعاملات داخل الموقع من خلال منصات الدفع
                        المتاحة على الموقع (نقبل جميع بطاقات الائتمان والخصم
                        الرئيسية)، ويجب على العميل دفع ثمن المنتج على النحو
                        المنصوص عليه في الطلب من قبل ليتم شحن المنتج وتسليمه
                        للعميل، وسيتم خصم المدفوعات من بطاقة الائتمان / الخصم
                        الخاصة بك على الفور عند تقديمك لطلب المنتج الذي اشتريته.
                        وعند معالجة أي معاملة، سنصدر لك إيصالاً إلكترونياً
                        للمعاملة سيتم إرساله إلى عنوان البريد الإلكتروني الذي
                        قمت بتقديمه.
                      </span>
                    )}
                  </span>

                  <span>
                    {language === "en" ? (
                      "Your payment information will be treated and safeguarded with total security and with the exclusive purpose of processing the purchase of the products. Ishro reserves the right to contract any payment platform available in the market, which treats your data for the exclusive purpose of processing the purchase of the products."
                    ) : (
                      <span dir="rtl">
                        سيتم التعامل مع معلومات الدفع الخاصة بك وحمايتها بأمان
                        تام وبغرض وحيد هو معالجة شراء المنتجات، ويحتفظ Ishro
                        بالحق في التعاقد مع أي منصة دفع متاحة في السوق للتعامل
                        مع بياناتك بغرض وحيد هو معالجة شراء المنتجات.
                      </span>
                    )}{" "}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "6. REFUND POLICY"
                  ) : (
                    <span dir="rtl">6. سياسة الاسترداد</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "All sales of our products are final, non-refundable and without cancellation options. Please check product descriptions, variations and prices before placing an order."
                    ) : (
                      <span dir="rtl">
                        جميع عمليات البيع لمنتجاتنا نهائية وغير قابلة للاسترداد
                        وبدون خيارات إلغاء، لذا يرجى التحقق من أوصاف المنتج
                        والبدائل المتاحة منه والأسعار قبل تقديم الطلب.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "We do our best to avoid any damage or defect in your products, however, if upon receiving the product, it is defective or damaged and you need to exchange the product for it or need a refund, please contact us through our contact information. Only in these cases we will make returns or refunds and assume the value of the shipment. In case you request a refund for a damaged or defective product, the value of the shipment will also be refunded."
                    ) : (
                      <span dir="rtl">
                        نحن نبذل قصارى جهدنا لتجنب أي ضرر أو عيب في المنتجات
                        المقدمة لك، ومع ذلك إذا كان المنتج معيباً أو تالفاً عند
                        استلامه وتريد استبدال المنتج أو استرداد المبلغ، يرجى
                        الاتصال بنا من خلال معلومات الاتصال الخاصة بنا. فقط في
                        هذه الحالات سنقوم بإرجاع المنتجات أو رد المبلغ وتحمل
                        قيمة الشحنة، وفي حالة طلبك استرداد ثمن منتج تالف أو
                        معيب، سيتم أيضاً رد قيمة الشحنة.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "The request for a refund or return for a damaged or defective product must contain the following:"
                    ) : (
                      <span dir="rtl">
                        يجب أن يحتوي طلب رد المبلغ أو إرجاع المنتج التالف أو
                        المعيب على ما يلي:
                      </span>
                    )}
                  </span>
                  <ul className="col-sm-12 p-0 fl">
                    <li>
                      {language === "en" ? (
                        "Full name of the person who placed the order."
                      ) : (
                        <span dir="rtl">اسم الشخص الذي قدم الطلب بالكامل.</span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Order number."
                      ) : (
                        <span dir="rtl">رقم الطلب.</span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Proof of damage or defect of the product (photo or video)."
                      ) : (
                        <span dir="rtl">
                          ما يثبت تلف أو عيب المنتج (صورة أو فيديو).
                        </span>
                      )}
                    </li>
                  </ul>

                  <span>
                    {language === "en" ? (
                      "No other form of refund or return is allowed."
                    ) : (
                      <span dir="rtl">
                        غير مسموح بأي شكل آخر من أشكال الاسترداد أو الإرجاع.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "7. PRODUCT DESCRIPTIONS"
                  ) : (
                    <span dir="rtl">7. أوصاف المنتج</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "Ishro attempts to be as accurate as possible. However, Ishro does not warrant that product descriptions, product prices or other content of this website is accurate, complete, reliable, current, or error-free. Product descriptions and images may vary according to the color resolution of the user's device screen."
                    ) : (
                      <span dir="rtl">
                        يحاول Ishro أن يكون دقيقاً قدر الإمكان. ومع ذلك، لا يضمن
                        Ishro أن تكون أوصاف المنتجات أو أسعار المنتجات أو أي
                        محتوى آخر في هذا الموقع دقيقاً أو كاملاً أو يعتمد عليه
                        أو حديثاً أو خالياً من الأخطاء، كما قد تختلف أوصاف
                        المنتج وصورته حسب دقة ألوان شاشة جهاز المستخدم.
                      </span>
                    )}{" "}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "8. LICENSE TO USE THE PLATFORM"
                  ) : (
                    <span dir="rtl">8. ترخيص استخدام المنصة</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "Ishro gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software provided to you by Ishro as part of the services. This license is for the sole purpose of enabling you to use and enjoy the benefit of the services as provided by Ishro, in the manner permitted by these terms. You may not copy, modify, distribute, sell, or lease any part of our services or included software, nor may you reverse engineer or attempt to extract the source code of that software, unless laws prohibit those restrictions or you have our written permission."
                    ) : (
                      <span dir="rtl">
                        يمنحك Ishro ترخيصاً شخصياً عالمياً بدون أي رسوم وغير
                        قابل للتنازل عنه وغير حصري لاستخدام البرمجيات المقدمة لك
                        من Ishro كجزء من الخدمات. الغرض الوحيد من هذا الترخيص هو
                        تمكينك من استخدام الخدمات والاستمتاع بها على النحو الذي
                        توفره Ishro، بالطريقة التي تسمح بها هذه الشروط. لا يجوز
                        لك نسخ أو تعديل أو توزيع أو بيع أو تأجير أي جزء من
                        خدماتنا أو البرمجيات المضمنة، كما لا يجوز لك إجراء هندسة
                        عكسية أو محاولة استخراج الكود المصدري لهذه البرمجيات، ما
                        لم تحظر القوانين هذه القيود أو تحصل على إذن كتابي منا.
                      </span>
                    )}
                  </span>

                  <span>
                    {language === "en" ? (
                      "The user agrees not to use the platform and the services negligently, for fraudulent purposes or in an unlawful manner. Likewise, the user agrees not to partake in any conduct or action that could damage the image, interests or rights of the Ishro website or third parties."
                    ) : (
                      <span dir="rtl">
                        يوافق المستخدم على عدم استخدام المنصة والخدمات بإهمال أو
                        لأغراض احتيالية أو بطريقة غير قانونية، وبالمثل يوافق
                        المستخدم على عدم المشاركة في أي سلوك أو إجراء من شأنه
                        الإضرار بصورة أو مصالح أو حقوق موقع Ishro أو أي طرف
                        ثالث.
                      </span>
                    )}
                  </span>

                  <span>
                    {language === "en" ? (
                      "This service prohibits sending of messages, that:"
                    ) : (
                      <span dir="rtl">
                        تمنع هذه الخدمة إرسال انواع الرسائل الاتية:
                      </span>
                    )}
                  </span>
                  <ul className="col-sm-12 p-0 fl">
                    <li>
                      {language === "en" ? (
                        "Any kind of messages that are catalogued as SPAM."
                      ) : (
                        <span dir="rtl">
                          أي نوع من الرسائل التي تم تصنيفها على أنها بريد
                          عشوائي.
                        </span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Are harassing, abusive, defamatory, obscene, in bad faith, unethical or otherwise illegal content"
                      ) : (
                        <span dir="rtl">
                          {" "}
                          تتضمّن مضايقة أو مسيئة أو تشهيرية أو فاحشة أو بسوء نية
                          أو محتوى غير أخلاقي أو غير قانوني بأي شكل آخر
                        </span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "distribute trojans, viruses or other malicious computer software"
                      ) : (
                        <span dir="rtl">
                          توزيع أحصنة طروادة أو الفيروسات أو برامج الكمبيوتر
                          الضارة الأخرى
                        </span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Any message with political or religious content"
                      ) : (
                        <span dir="rtl">أي رسالة ذات محتوى سياسي أو ديني</span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Any message with obscene or offensive content"
                      ) : (
                        <span dir="rtl"> أي رسالة ذات محتوى فاحش أو مسيء</span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Are intending to commit fraud, impersonating other persons, phishing, scams, or related crime"
                      ) : (
                        <span dir="rtl">
                          بنية ارتكاب احتيال أو انتحال صفة أشخاص آخرين أو التصيد
                          الاحتيالي أو الاحتيال أو الجرائم ذات الصلة
                        </span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Breach, in any way, the terms of service, privacy policy or rules of this website or the recipients."
                      ) : (
                        <span dir="rtl">
                          خرق ، بأي شكل من الأشكال ، شروط الخدمة أو سياسة
                          الخصوصية أو قواعد هذا الموقع أو المستلمين
                        </span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "distribute trojans, viruses or other malicious computer software"
                      ) : (
                        <span dir="rtl">
                          توزيع الملكية الفكرية دون ملكية أو ترخيص لتوزيع هذه
                          الممتلكات
                        </span>
                      )}
                    </li>
                  </ul>
                  <span>
                    <b style={{ fontFamily: "Almarai-SemiBold" }}>
                      {language === "en" ? (
                        "Ishro reserves the right to terminate your access immediately, with or without notice, and without liability to you, if Ishro believes that you have violated any of these terms or interfered with the use of the website or service by others."
                      ) : (
                        <span dir="rtl">
                          يحتفظ Ishro بالحق في إنهاء وصولك على الفور، بعد إشعارك
                          بذلك أو بدون إشعارك، ودون تحمل أي مسؤولية تجاهك، إذا
                          اعتقد Ishro أنك انتهكت أياً من هذه الشروط أو تدخلت في
                          استخدام الآخرين للموقع أو الخدمة.
                        </span>
                      )}
                    </b>
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "9. USER CONTENT"
                  ) : (
                    <span dir="rtl">9. محتوى المستخدم</span>
                  )}{" "}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "Certain features of the website may allow users to upload content, which may consist of messages, photos, images, videos, text, products, posts and others, and to publish that user content on the website. You retain any copyright you may have in user content you post through the listings available on the website. Nevertheless, we need some permission from you in order to publish the content. Ishro is not responsible for the accuracy, safety, or legality of user content published on the website. User are solely and exclusively responsible for their content and the consequences of publishing their content."
                    ) : (
                      <span dir="rtl">
                        قد تسمح بعض ميزات موقع الويب للمستخدمين بتحميل المحتوى،
                        والذي قد يتكون من الرسائل والصور ومقاطع الفيديو والنصوص
                        والمنتجات والمنشورات وغيرها، ونشر محتوى المستخدم هذا على
                        الموقع. أنت تحتفظ بأي حقوق طبع ونشر قد تكون لديك في
                        محتوى المستخدم الذي تنشره من خلال القوائم المتاحة على
                        الموقع، ومع ذلك نحتاج إلى بعض الإذن منك لنشر المحتوى.
                        Ishro ليس مسؤولاً عن دقة أو سلامة أو قانونية محتوى
                        المستخدم المنشور على الموقع. المستخدم هو المسؤول وحده
                        وبصورة خالصة عن محتواه وعواقب نشره.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "By providing user content to or through the website, you grant Ishro a worldwide, non-exclusive, royalty-free, fully paid right and license to host, store, transfer, display, perform, reproduce, modify for the purpose of formatting to display and publish your user content on the website, for the sole purpose of providing the services."
                    ) : (
                      <span dir="rtl">
                        من خلال توفير محتوى المستخدم إلى أو من خلال الموقع، فإنك
                        تمنح Ishro حقاً وترخيصاً عالمياً، غير حصرياً، بدون أي
                        رسوم، مدفوعاً بالكامل للاستضافة والتخزين والنقل والعرض
                        والأداء وإعادة الإنتاج والتعديل لغرض التنسيق للعرض
                        والنشر لمحتوى المستخدم الخاص بك على الموقع بغرض وحيد هو
                        تقديم الخدمات.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "10. USER CONTENT REPRESENTATIONS AND WARRANTIES"
                  ) : (
                    <span dir="rtl">10. إقرارات وضمانات محتوى المستخدمين</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "Ishro disclaims any and all liability in connection with user content. You are solely responsible for your user content and the consequences of providing user content via the service. By providing user content via the service, you affirm, represent, and warrant that:"
                    ) : (
                      <span dir="rtl">
                        يخلي Ishro نفسه من أي وجميع المسؤوليات فيما يتعلق بمحتوى
                        المستخدمين. أنت وحدك المسؤول عن محتوى المستخدم الخاص بك
                        وعواقب تقديم محتوى المستخدم عبر الخدمة. ومن خلال تقديم
                        محتوى المستخدم عبر الخدمة، فإنك تؤكد وتقر وتضمن ما يلي:
                      </span>
                    )}
                  </span>

                  <p>
                    {language === "en" ? (
                      "a) You are the creator and owner of the user content, or have the necessary licenses, rights, consents, and permissions to authorize Ishro and users of the service to use and distribute your user content as necessary to exercise the licenses granted by you in this section, in the manner contemplated by Ishro, the service, and these Terms."
                    ) : (
                      <span dir="rtl">
                        أ) أنك منشئ محتوى المستخدم ومالكه، أو لديك التراخيص
                        والحقوق والموافقات والأذونات اللازمة لتفويض Ishro
                        ومستخدمي الخدمة لاستخدام وتوزيع محتوى المستخدم الخاص بك
                        عند الضرورة لممارسة التراخيص الممنوحة من قبلك في هذا
                        الباب، بالطريقة التي تتوخاها Ishro والخدمة وهذه الشروط.
                      </span>
                    )}{" "}
                  </p>
                  <p>
                    {language === "en" ? (
                      "b) Your user content, and the use of your user content as contemplated by these Terms, does not and will not:  (i) infringe, violate, or misappropriate any third party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right. (ii) slander, defame, libel, or invade the right of privacy, publicity or other property rights of any other person. or (iii) cause  Ishro to violate any law or regulation."
                    ) : (
                      <span dir="rtl">
                        ب) أن محتوى المستخدم الخاص بك واستخدام محتوى المستخدم
                        الخاص بك على النحو المنصوص عليه في هذه الشروط لا ولن:
                        (1) ينتهك أو يخرق أو يسيء استخدام أي حق لأي طرف ثالث،
                        بما في ذلك أي حقوق نشر أو علامة تجارية أو براءة اختراع
                        أو سر تجاري أو حق معنوي أو حق في الخصوصية أو حق في
                        الدعاية أو أي ملكية فكرية أو حقوق ملكية أخرى؛ (2) يمثل
                        قذف أو تشهير أو تعدي على حق الخصوصية أو الدعاية أو حقوق
                        الملكية الأخرى لأي شخص آخر؛ أو (ثالثًا) يتسبب في انتهاك
                        Ishro لأي قانون أو لائحة.
                      </span>
                    )}{" "}
                  </p>

                  <p>
                    {language === "en" ? (
                      "c) Your user content should not contain information or content about politics or religion."
                    ) : (
                      <span dir="rtl">
                        ج) يجب ألا يحتوي محتوى المستخدم الخاص بك على معلومات أو
                        محتوى عن السياسة أو الدين.
                      </span>
                    )}
                  </p>
                  <p>
                    {language === "en" ? (
                      "d) Your user content should not contain negative information about the business published on the website."
                    ) : (
                      <span dir="rtl">
                        د) يجب ألا يحتوي محتوى المستخدم الخاص بك على معلومات
                        سلبية حول النشاط التجاري المنشور على الموقع.
                      </span>
                    )}
                  </p>
                  <p>
                    {language === "en" ? (
                      "e) Your user content must be related only to the business published on the website. No other content is allowed."
                    ) : (
                      <span dir="rtl">
                        هـ) يجب أن يرتبط محتوى المستخدم الخاص بك فقط بالأعمال
                        المنشورة على الموقع، وغير مسموح بأي محتوى آخر.
                      </span>
                    )}
                  </p>
                  <p>
                    {language === "en" ? (
                      "f) Your user content could not be deemed by a reasonable person to be objectionable, profane, indecent, pornographic, harassing, threatening, embarrassing, hateful, or otherwise inappropriate."
                    ) : (
                      <span dir="rtl">
                        و) لا يمكن لأي شخص عاقل أن يعتبر محتوى المستخدم الخاص بك
                        مرفوضاً أو بذيئاً أو غير لائق أو إباحياً أو مزعجاً أو
                        مهدداً أو محرجاً أو بغيضاً أو غير مناسب بأي شكل آخر من
                        الأشكال.
                      </span>
                    )}
                  </p>
                  <p>
                    {language === "en" ? (
                      "g) Your user content does not and will not contain hateful content, a threat of physical harm, or harassment."
                    ) : (
                      <span dir="rtl">
                        ز) محتوى المستخدم الخاص بك لا ولن يحتوي على محتوى بغيض
                        أو تهديد بإيذاء جسدي أو مضايقة.
                      </span>
                    )}
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "11. USER CONTENT DISCLAIMER"
                  ) : (
                    <span dir="rtl">
                      11. إخلاء المسؤولية عن محتوى المستخدمين
                    </span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "Ishro may, at any time and without prior notice, screen, remove, edit, or block any user content that in our sole judgment violates these Terms or is otherwise objectionable. You understand that when using the service, you will be exposed to user content from a variety of sources and acknowledge that user content may be inaccurate, offensive, indecent, or objectionable. You agree to waive, and do waive, any legal or equitable right or remedy you have or may have against Ishro with respect to user content. If notified by a user or content owner that user content allegedly does not conform to these Terms, we may investigate the allegation and determine in our sole discretion whether to remove the user content, which we reserve the right to do at any time and without notice. For clarity, Ishro does not permit copyright-infringing activities on the Service."
                    ) : (
                      <span dir="rtl">
                        يجوز لموقع Ishro، في أي وقت وبدون إشعار مسبق، فحص أو
                        إزالة أو تعديل أو حظر أي محتوى مستخدم ينتهك هذه الشروط
                        أو يكون غير مقبول وفقاً لتقديرنا المطلق. أنت تدرك أنه
                        عند استخدامك للخدمة، ستتعرض لمحتوى مستخدمين من مجموعة
                        متنوعة من المصادر وتقر بأن محتوى المستخدمين قد يكون غير
                        دقيق أو مسيء أو غير لائق أو مرفوض. أنت توافق على التنازل
                        وتتنازل بالفعل عن أي حق أو تعويض قانوني أو عادل يكون
                        لديك أو قد يكون لديك في مواجهة Ishro فيما يتعلق بمحتوى
                        المستخدمين. إذا تم إخطارنا من قبل أحد المستخدمين أو مالك
                        المحتوى بأن محتوى المستخدم زعماً لا يتوافق مع هذه
                        الشروط، فيجوز لنا التحقيق في الادعاء وتحديد ما إذا كنا
                        سنزيل محتوى المستخدم، وهو ما نحتفظ بالحق في القيام به في
                        أي وقت ودون إشعار. توخياً للوضوح، لا يسمح Ishro بأي
                        أنشطة تنتهك حقوق النشر على الخدمة.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "You acknowledge and agree that Ishro reserves the right to, and may from time to time, monitor any and all information transmitted or received through the service for operational and other purposes. If at any time Ishro chooses to monitor the content, Ishro still assumes no responsibility or liability for content or any loss or damage incurred as a result of the use of content. During monitoring, information may be examined, recorded, copied, and used in accordance with our Privacy Policy."
                    ) : (
                      <span dir="rtl">
                        أنت تقر وتوافق على أن Ishro يحتفظ بالحق في، ويجوز له من
                        وقت لآخر، مراقبة أي وجميع المعلومات المنقولة أو المستلمة
                        من خلال الخدمة للأغراض التشغيلية وغيرها. إذا اختار Ishro
                        في أي وقت مراقبة المحتوى، فإن Ishro لا يزال لا يتحمل أي
                        مسؤولية أو تبعة عن المحتوى أو أي خسارة أو ضرر يحدث نتيجة
                        لاستخدام المحتوى، وقد يتم فحص المعلومات وتسجيلها ونسخها
                        واستخدامها أثناء المراقبة وفقاً لسياسة الخصوصية الخاصة
                        بنا.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "12. ACCURACY AND TIMELINESS OF INFORMATION"
                  ) : (
                    <span dir="rtl">12. دقة المعلومات وتحديثها</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "We do not guarantee that the information available on the website is accurate, complete or updated. The content of this website is provided for general information and should not be taken as a professional advice. Any use of the material provided on this website is at your own risk."
                    ) : (
                      <span dir="rtl">
                        نحن لا نضمن أن المعلومات المتوفرة على الموقع دقيقة أو
                        كاملة أو محدثة، حيث يتم توفير محتوى هذا الموقع للحصول
                        على معلومات عامة ولا ينبغي اعتباره نصيحة مهنية. أي
                        استخدام للمواد المتوفرة على هذا الموقع يقع على مسؤوليتك
                        الخاصة.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {language === "en" ? (
                    "13. DISCLAIMER"
                  ) : (
                    <span dir="rtl">13. إخلاء المسؤولية</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "By accessing the website and the content available on the website, you accept personal responsibility for the results of using the information available on the content.  You agree that Ishro has not guaranteed the results of any actions taken, whether or not advised by this website or the content. Ishro provides resources and content for informational purposes only. However, you acknowledge that your ultimate success or failure will be the result of your own efforts, your particular situation, and a number of other circumstances that are beyond Ishro’ control."
                    ) : (
                      <span dir="rtl">
                        من خلال الدخول إلى الموقع والمحتوى المتاح على الموقع،
                        فإنك تقبل المسؤولية الشخصية عن نتائج استخدام المعلومات
                        المتوفرة في المحتوى، كما توافق على أن Ishro لم تضمن
                        نتائج أي إجراءات يتم اتخاذها، سواء نصح بها هذا الموقع أو
                        المحتوى أم لا. يوفر Ishro الموارد والمحتوى لأغراض العلم
                        فقط. ومع ذلك، فإنك تقر بأن نجاحك أو فشلك النهائي سيكون
                        نتيجة لجهودك الخاصة ووضعك الخاص وعدد من الظروف الأخرى
                        الخارجة عن سيطرة Ishro.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {language === "en" ? (
                    "14. COPYRIGHT"
                  ) : (
                    <span dir="rtl">14. حقوق الطبع والنشر</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "All materials on Ishro, including, without limitation, names, logos, trademarks, images, text, columns, graphics, videos, photographs, illustrations, artwork, software and other elements are protected by copyrights, trademarks and/or other intellectual property rights owned and controlled by Ishro or by third parties that have licensed or otherwise provided their material to the website. You acknowledge and agree that all Materials on Ishro are made available for limited, non-commercial, personal use only. Except as specifically provided herein. No material may be copied, reproduced, republished, sold, downloaded, posted, transmitted, or distributed in any way, or otherwise used for any purpose, by any person or entity, without Ishro prior express written permission. You may not add, delete, distort, or otherwise modify the material. Any unauthorized attempt to modify any material, to defeat or circumvent any security features, or to utilize Ishro or any part of the material for any purpose other than its intended purposes is strictly prohibited. Please do not copy any content and pass it off as your own, as a copyright infringement will occur."
                    ) : (
                      <span dir="rtl">
                        جميع المواد الموجودة على Ishro، بما في ذلك على سبيل
                        المثال لا الحصر، الأسماء والشعارات والعلامات التجارية
                        والصور والنصوص والأعمدة والرسومات ومقاطع الفيديو والرسوم
                        التوضيحية والأعمال الفنية والبرمجيات والعناصر الأخرى
                        محمية بحقوق الطبع والنشر و / أو العلامات التجارية و / أو
                        حقوق الملكية الفكرية الأخرى المملوكة والخاضعة لسيطرة
                        Ishro أو أي طرف ثالث قام بترخيص أو توفير مواده إلى
                        الموقع. أنت تقر وتوافق على أن جميع المواد الموجودة على
                        Ishro متاحة للاستخدام الشخصي المحدود وغير التجاري فقط.
                        باستثناء ما هو منصوص عليه تحديداً هنا، لا يجوز نسخ أي
                        مادة أو إعادة إنتاجها أو إعادة نشرها أو بيعها أو تنزيلها
                        أو نشرها أو نقلها أو توزيعها بأي شكل من الأشكال، أو
                        استخدامها بأي شكل من الأشكال لأي غرض، من قبل أي شخص أو
                        كيان، دون إذن كتابي صريح مسبق من Ishro. ولا يجوز لك
                        إضافة أو حذف أو تشويه أو تعديل المواد بأي شكل من
                        الأشكال. ويُحظر تماماً أي محاولة غير مصرح بها لتعديل أي
                        مادة، أو لإبطال أو التحايل على أي وظائف أمنية، أو
                        استخدام Ishro أو أي جزء من المواد لأي غرض بخلاف الأغراض
                        المقصودة. يرجى عدم نسخ أي محتوى وتمريره على أنه خاص بك،
                        حيث سينتج عن ذلك انتهاك لحقوق الطبع والنشر.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "15. COPYRIGHT COMPLAINTS"
                  ) : (
                    <span dir="rtl">15. شكاوى حقوق الطبع والنشر</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "Ishro respects the intellectual property of others, and expects users to do the same.  If you believe, in good faith, that any materials provided on or in connection with the “Ishro” website infringe upon your copyright or other intellectual property right, please send the following information to:"
                    ) : (
                      <span dir="rtl">
                        يحترم Ishro الملكية الفكرية للآخرين، ويتوقع من
                        المستخدمين أن يفعلوا الشيء نفسه. إذا كنت تعتقد، بحسن
                        نية، أن أي مواد يتم توفيرها على أو فيما يتعلق بـموقع
                        Ishro تنتهك حقوق الطبع والنشر أو حقوق الملكية الفكرية
                        الأخرى الخاصة بك، يرجى إرسال المعلومات التالية إلينا:
                      </span>
                    )}
                  </span>
                  <p>
                    {language === "en" ? (
                      "i. Identification of the intellectual property right that is allegedly infringed. All relevant registration numbers, or a statement concerning the ownership of the work, should be included."
                    ) : (
                      <span dir="rtl">
                        . تحديد حق الملكية الفكرية المزعوم انتهاكه. يجب تضمين
                        جميع أرقام التسجيل ذات الصلة، أو إفادة خاصة بملكية
                        العمل.
                      </span>
                    )}
                  </p>
                  <p>
                    {language === "en" ? (
                      "ii. A statement specifically identifying the location of the infringing material, with enough detail that Ishro may find it on the “Ishro” website.  Please note: it is not sufficient to merely provide a top-level URL."
                    ) : (
                      <span dir="rtl">
                        ب. إفادة تعرض على وجه التحديد موقع المادة المخالفة، مع
                        تفاصيل كافية تمكن Ishro من على العثور عليها على موقع
                        "Ishro". يرجى ملاحظة أنه لا يكفي مجرد تقديم رابط النطاق
                        الأعلى.
                      </span>
                    )}
                  </p>
                  <p>
                    {language === "en" ? (
                      "iii. Your name, address, telephone number and e-mail address."
                    ) : (
                      <span dir="rtl">
                        ج. عنوانك ورقم هاتفك وعنوان بريدك الإلكتروني؛
                      </span>
                    )}
                  </p>
                  <p>
                    {language === "en" ? (
                      "iv. A statement by you that you have a good faith belief that the use of the allegedly infringing material is not authorized by the owner of the rights, or its agents, or by law."
                    ) : (
                      <span dir="rtl">
                        د. إفادة من جانبك بأن لديك اعتقاد حسن النية بأن استخدام
                        المواد التي يُزعم أنها تمثل انتهاكاً غير مصرح به من قبل
                        مالك الحقوق أو وكلائه أو بموجب القانون.
                      </span>
                    )}
                  </p>
                  <p>
                    {language === "en" ? (
                      "v. A statement by you, made under penalty of perjury, that the information in your notice is accurate, and that you are the copyright owner or authorized to act on the copyright owner's behalf."
                    ) : (
                      <span dir="rtl">
                        هـ. إفادة من جانبك تحت طائلة عقوبة الحنث باليمين بأن
                        المعلومات الواردة أعلاه في رأيك دقيقة تماماً، وأنك صاحب
                        حقوق الطبع والنشر أو صاحب الملكية الفكرية أو المخول له
                        بالتصرف نيابةً عن مالك حقوق الطبع والنشر.
                      </span>
                    )}
                  </p>
                  <p>
                    {language === "en" ? (
                      "vi. An electronic or physical signature of the owner of the copyright or the person authorized to act on behalf of the owner of the copyright interest."
                    ) : (
                      <span dir="rtl">
                        ز. توقيع إلكتروني أو مادي لمالك حقوق الطبع والنشر أو
                        الشخص المخول بالتصرف نيابةً عن مالك حقوق الطبع والنشر.
                      </span>
                    )}{" "}
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "16. PROHIBITED ACTIVITIES"
                  ) : (
                    <span dir="rtl">16. الأنشطة المحظورة</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "The content and information available on the website (including, but not limited to, data, information, text, music, sound, photos, graphics, video, maps, icons, or other material), as well as the infrastructure used to provide such content and information, are owned by or licensed to Ishro by third parties. For all content other than your content, you agree not to modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or resell any information or services obtained from or through the website. In addition, the following activities are prohibited:"
                    ) : (
                      <span dir="rtl">
                        المحتوى والمعلومات المتوفرة على الموقع (بما في ذلك، على
                        سبيل المثال لا الحصر، البيانات أو المعلومات أو النصوص أو
                        الموسيقى أو الصوت أو الصور أو الرسومات أو الفيديو أو
                        الخرائط أو الأيقونات أو غيرها من المواد)، بالإضافة إلى
                        البنية التحتية المستخدمة لتوفير مثل المحتوى والمعلومات،
                        مملوكة لموقع Ishro أو مرخصة له من قبل أطراف ثالثة.
                        بالنسبة لجميع المحتويات بخلاف المحتوى الخاص بك، فإنك
                        توافق على عدم تعديل أو نسخ أو توزيع أو إرسال أو عرض أو
                        أداء أو إعادة إنتاج أو نشر أو ترخيص أو إنشاء أعمال مشتقة
                        من أو نقل أو بيع أو إعادة بيع أي معلومات أو خدمات تم
                        الحصول عليها من أو من خلال الموقع. بالإضافة إلى ذلك،
                        تحظر الأنشطة التالية:
                      </span>
                    )}
                  </span>
                  <p>
                    {language === "en" ? (
                      "I. Access, monitor, reproduce, distribute, transmit, broadcast, display, sell, license, copy or otherwise exploit any content of the website, including, but not limited to, using any robot, spider, scraper or other automated means or any manual process for any purpose not in accordance with these terms."
                    ) : (
                      <span dir="rtl">
                        أ. الوصول إلى أي محتوى على الموقع أو مراقبته أو إعادة
                        إنتاجه أو توزيعه أو إرساله أو بثه أو عرضه أو بيعه أو
                        ترخيصه أو نسخه أو استغلاله بأي طريقة أخرى، بما في ذلك،
                        على سبيل المثال لا الحصر، باستخدام أي برمجية آلية أو
                        زاحف شبكي أو أداة لسحب المعلومات أو أي وسيلة آلية أخرى
                        أو أي عملية يدوية لأي غرض لا يتوافق مع هذه الشروط.
                      </span>
                    )}
                  </p>
                  <p>
                    {language === "en" ? (
                      "II. Take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure."
                    ) : (
                      <span dir="rtl">
                        ب. اتخاذ أي إجراء من شأنه أن يفرض، أو قد يفرض، وفقاً
                        لتقديرنا الخاص، عبئاً كبيراً غير معقول أو غير متناسب على
                        بنيتنا التحتية؛
                      </span>
                    )}
                  </p>
                  <p>
                    {language === "en" ? (
                      "III. Deep-link to any part of our website for any purpose without our express written permission."
                    ) : (
                      <span dir="rtl">
                        ج. الارتباط العميق بأي جزء من موقعنا لأي غرض دون إذن
                        كتابي صريح منا؛
                      </span>
                    )}
                  </p>
                  <p>
                    {language === "en" ? (
                      "IV.	'Frame', 'mirror' or otherwise incorporate any part of the Services into any other website or service without our prior written permission."
                    ) : (
                      <span dir="rtl">
                        د. "تأطير" أو "محاكاة" أو دمج أي جزء من الخدمات بأي
                        طريقة أخرى في أي موقع أو خدمة أخرى دون إذن كتابي مسبق
                        منا؛
                      </span>
                    )}
                  </p>
                  <p>
                    {language === "en" ? (
                      "VI. Attempt to modify, translate, adapt, edit, decompile, disassemble or reverse engineer any software used by 1080 London in connection with the website and the services"
                    ) : (
                      <span dir="rtl">
                        هـ. محاولة تعديل أو ترجمة أو تكييف أو تحرير أو فك تجميع
                        شفرة أو تفكيك أو عكس هندسة أي برمجية يستخدمها 1080
                        London فيما يتعلق بالموقع والخدمات؛
                      </span>
                    )}
                  </p>
                  <p>
                    {language === "en" ? (
                      "VII. Evade, disable or otherwise interfere with security-related features of the Services or features which prevent or restrict the use or copying of any content."
                    ) : (
                      <span dir="rtl">
                        ز. تجنب أو تعطيل أو التدخل بأي شكل من الأشكال في الميزات
                        الأمنية للخدمات أو الميزات التي تمنع أو تقيد استخدام أو
                        نسخ أي محتوى.
                      </span>
                    )}
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "17. THIRD PARTIES"
                  ) : (
                    <span dir="rtl">17. الأطراف الثالثة</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "Through your use of the “Ishro” website and services you may encounter links to third party sites or be able to interact with third party sites. Such third parties may charge a fee for use of certain content or services provided on or by way of their websites. Therefore, you should make whatever investigation you feel is necessary or appropriate before proceeding with any transaction with any third party to determine whether a charge will be incurred. Where “Ishro” provide details of fees or charges for such third-party content or services, such information is provided for convenience and information purposes only. Any interactions with third party sites and apps are at your own risk. You expressly acknowledge and agree that Ishro are in no way responsible or liable for any such third-party sites."
                    ) : (
                      <span dir="rtl">
                        من خلال استخدامك لموقع وخدمات "Ishro" قد تصادف روابط
                        لمواقع أطراف ثالثة أو قد تكون قادراً على التفاعل مع
                        مواقع أطراف ثالثة. قد تفرض هذه الأطراف الثالثة رسوماً
                        مقابل استخدام محتوى أو خدمات معينة مقدمة على المواقع
                        الخاصة بهم أو عن طريقها، لذا يجب عليك إجراء أي عملية
                        تحقق تشعر أنها ضرورية أو مناسبة قبل متابعة أي معاملة مع
                        أي طرف ثالث لتحديد ما إذا كنت ستتكبد أي رسوم أم لا.
                        عندما يقدم Ishro تفاصيل عن الرسوم أو التكاليف المرتبطة
                        بمحتوى أو خدمات أي أطراف ثالثة، فإنه يتم توفير هذه
                        المعلومات لأغراض التيسير والعلم فقط. أي تفاعلات مع مواقع
                        وتطبيقات الأطراف الثالث يقع على مسؤوليتك الخاصة. أنت تقر
                        وتوافق صراحة على أن Ishro لا يتحمل بأي حال من الأحوال
                        المسؤولية أو التبعة عن أي من مواقع الأطراف الثالثة.
                      </span>
                    )}{" "}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "18. INDEMNIFICATION"
                  ) : (
                    <span dir="rtl">18. التعويض</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "You agree to defend and indemnify Ishro and any of their directors, employees and agents from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by third parties as a result of:"
                    ) : (
                      <span dir="rtl">
                        أنت توافق على الدفاع عن وتعويض Ishro وأي من مديريه
                        وموظفيه ووكلائه عن وضد أي مطالبات أو أسباب لرفع الدعاوى
                        أو طلبات أو مبالغ مستردة أو خسائر أو أضرار أو غرامات أو
                        عقوبات أو تكاليف أو نفقات الأخرى من أي نوع أو طبيعة بما
                        في ذلك على سبيل المثال لا الحصر الرسوم القانونية
                        والمحاسبية المعقولة التي ترفعها أطراف ثالثة نتيجة:
                      </span>
                    )}
                  </span>
                  <ul className="col-sm-12 p-0 fl">
                    <li>
                      {language === "en" ? (
                        "Your breach of this agreement or the documents referenced herein."
                      ) : (
                        <span dir="rtl">
                          خرقك لهذه الاتفاقية أو المستندات المشار إليها هنا.
                        </span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Your violation of any law or the rights of a third party."
                      ) : (
                        <span dir="rtl">
                          انتهاكك لأي قانون أو حقوق لطرف ثالث.
                        </span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "The use of the website and the purchase of the products."
                      ) : (
                        <span dir="rtl">استخدام الموقع وشراء المنتجات.</span>
                      )}
                    </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "19. ELECTRONIC COMMUNICATIONS"
                  ) : (
                    <span dir="rtl">19. الاتصالات الإلكترونية</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "No responsibility will be accepted by Ishro for failed, partial or garbled computer transmissions, for any computer, telephone, cable, network, electronic or internet hardware or software malfunctions, failures, connections, availability, for the acts or omissions of any service provider, internet accessibility or availability or for traffic congestion or unauthorized human act, including any errors or mistakes."
                    ) : (
                      <span dir="rtl">
                        لن يقبل Ishro أي مسؤولية عن عمليات الإرسال الحاسوبية
                        الفاشلة أو الجزئية أو المشوهة، أو عن أي أعطال أو فشل أو
                        اتصالات أو توفر للحواسيب أو الهواتف أو الكابلات أو
                        الشبكات أو الإلكترونيات أو أجهزة إنترنت أو البرمجيات، أو
                        عن أفعال أو تقصير أي مزود خدمة، أو عن إمكانية الوصول إلى
                        الإنترنت أو توفرها أو ازدحام الزيارات أو الفعل البشري
                        غير المصرح به، بما في ذلك أي أخطاء أو أغلاط.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "20. NO SPAM POLICY"
                  ) : (
                    <span dir="rtl">20. سياسة عدم السماح بالبريد المزعج</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "You understand and agree that the sending of unsolicited e-mail announcements or other unsolicited communications to Ishro's e-mail addresses or through Ishro's information systems are expressly prohibited by these Terms. Any unauthorized use of Ishro's computer systems is a violation of these terms and certain applicable laws, in particular the UAE's cybercrime legislation. Such violations may subject the sender and its agents to civil and criminal penalties. Please be aware that the UAE's cybercrime legislation carries significant penalties, including imprisonment. In the event that You intend to solicit, or contact our users by obtaining their email or telephone numbers from our website, we may report this behaviour to the relevant authorities, who may then choose to prosecute you under the relevant laws of the UAE."
                    ) : (
                      <span dir="rtl">
                        أنت تدرك وتوافق على أن إرسال إعلانات البريد الإلكتروني
                        غير المرغوب فيها أو غيرها من المراسلات غير المرغوب فيها
                        إلى عناوين البريد الإلكتروني لموقع Ishro أو من خلال
                        أنظمة معلومات Ishro محظور صراحة بموجب هذه الشروط، ويعد
                        أي استخدام غير مصرح به لأنظمة الحواسيب الخاصة بموقع
                        Ishro انتهاكاً لهذه الشروط وبعض القوانين المعمول بها،
                        ولا سيما تشريعات الجرائم الإلكترونية في دولة الإمارات
                        العربية المتحدة. قد تعرض هذه الانتهاكات المرسل ووكلائه
                        لعقوبات مدنية وجنائية. يرجى العلم بأن تشريعات الجرائم
                        الإلكترونية في الإمارات العربية المتحدة تفرض عقوبات
                        مغلظة، بما في ذلك السجن. في حال كنت تنوي الوصول إلى
                        مستخدمينا أو الاتصال بهم من خلال الحصول على بريدهم
                        الإلكتروني أو أرقام هواتفهم من موقعنا، فقد نبلغ السلطات
                        المعنية بهذا السلوك، والتي قد تقرر بعد ذلك مقاضاتك بموجب
                        القوانين ذات الصلة لدولة الإمارات العربية المتحدة.
                      </span>
                    )}{" "}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {language === "en" ? (
                    "21. CHANGES AND TERMINATION"
                  ) : (
                    <span dir="rtl">21. التغييرات والإنهاء</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "We may change the website and these Terms at any time, in our sole discretion and without notice to you. You are responsible for remaining knowledgeable about these Terms. Your continued use of the website constitutes your acceptance of any changes to these Terms and any changes will supersede all previous versions of the Terms. Unless otherwise specified herein, all changes to these Terms apply to all users take effect. Furthermore, we may terminate this agreement with you under these Terms at any time by notifying you in writing (including by email) or without any warning."
                    ) : (
                      <span dir="rtl">
                        يجوز لنا تغيير الموقع وهذه الشروط في أي وقت، وفقاً
                        لتقديرنا المطلق ودون إخطارك. أنت مسؤول عن البقاء على
                        اطلاع دائم بهذه الشروط. استمرار استخدامك للموقع يعتبر
                        موافقة منك على أي تغييرات على هذه الشروط وأي تغييرات
                        ستحل محل جميع الإصدارات السابقة من الشروط، وما لم ينص
                        على خلاف ذلك هنا، تسري جميع التغييرات على هذه الشروط على
                        جميع المستخدمين، وعلاوة على ذلك، يجوز لنا إنهاء هذه
                        الاتفاقية معك بموجب هذه الشروط في أي وقت عن طريق إخطارك
                        كتابياً (بما في ذلك عبر البريد الإلكتروني) أو دون أي
                        تحذير.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "22. PERSONAL DATA"
                  ) : (
                    <span dir="rtl">22. البيانات الشخصية</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "Any personal information you post on or otherwise submit in connection with the purchase of products will be used in accordance with our Privacy Policy. Please refer to our Privacy Policy."
                    ) : (
                      <span dir="rtl">
                        سيتم استخدام أي معلومات شخصية تنشرها أو ترسلها فيما
                        يتعلق بشراء المنتجات وفقاً لسياسة الخصوصية الخاصة بنا.
                        يرجى الرجوع إلى سياسة الخصوصية الخاصة بنا.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "23. INTEGRATION CLAUSE"
                  ) : (
                    <span dir="rtl">23. بند التكامل</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  <span>
                    {language === "en" ? (
                      "This agreement together with the Privacy Policy and any other legal notices published by Ishro, shall constitute the entire agreement between you and Ishro concerning and governs your use of the website."
                    ) : (
                      <span dir="rtl">
                        تشكل هذه الاتفاقية مع سياسة الخصوصية وأي إشعارات قانونية
                        أخرى ينشرها Ishro، الاتفاقية الكاملة بينك وبين Ishro
                        فيما يتعلق باستخدامك للموقع ويحكمه.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "24. DISPUTES"
                  ) : (
                    <span dir="rtl">24. النزاعات</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "You agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, application, interpretation, or validity of these Terms or the use of the website shall be settled by binding arbitration between you and Ishro, except that each party retains the right to bring an individual action in a court of competent jurisdiction. Any dispute shall be resolved in the courts of the Dubai International Financial Centre."
                    ) : (
                      <span dir="rtl">
                        أنت توافق على أن أي نزاع أو مطالبة أو خلاف ينشأ عن أو
                        يتعلق بهذه الشروط أو خرق أو إنهاء أو تطبيق أو تفسير أو
                        صلاحية هذه الشروط أو استخدام الموقع يجب تسويته عن طريق
                        التحكيم الملزم بينك وبين Ishro، باستثناء أن كل طرف يحتفظ
                        بالحق في رفع دعوى فردية في محكمة مختصة، ويتم حل أي نزاع
                        في محاكم مركز دبي المالي العالمي.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "25. FINAL PROVISIONS"
                  ) : (
                    <span dir="rtl">25. أحكام ختامية</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "This agreement shall be governed by and construed in accordance with the laws of the Dubai International Financial Centre. Use of our website is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms."
                    ) : (
                      <span dir="rtl">
                        تخضع هذه الاتفاقية وتفسر وفقاً لقوانين مركز دبي المالي
                        العالمي. استخدام موقعنا غير مصرح به في أي ولاية قضائية
                        لا تطبق جميع أحكام هذه الشروط.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "Our performance of these Terms is subject to existing laws and legal process, and nothing contained in these Terms limits our right to comply with law enforcement or other governmental or legal requests or requirements relating to your use of our website or information provided to or gathered by us with respect to such use."
                    ) : (
                      <span dir="rtl">
                        يخضع أدائنا لهذه الشروط للقوانين القائمة والإجراءات
                        القانونية، ولا يوجد شيء وارد في هذه الشروط يحد من حقنا
                        في الامتثال لسلطات إنفاذ القانون أو غيرها من الطلبات
                        الحكومية أو القانونية أو المتطلبات المتعلقة باستخدامك
                        لموقعنا أو المعلومات المقدمة إلى أو التي يتم جمعها من
                        قبلنا فيما يتعلق بهذا الاستخدام.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "If any part of these Terms is found to be invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions will not in any way be affected or impaired. Our failure or delay in enforcing any provision of these Terms at any time does not waive our right to enforce the same or any other provision(s) hereof in the future."
                    ) : (
                      <span dir="rtl">
                        إذا تبين أن أي جزء من هذه الشروط غير صالح أو غير قانوني
                        أو غير قابل للإنفاذ، فلن تتأثر صحة الأحكام المتبقية
                        وقانونيتها وإمكانية إنفاذها بأي شكل من الأشكال. عدم
                        إنفاذنا لأي شرط من هذه الشروط أو تأخرنا في القيام بذلك
                        في أي وقت لا يعني تنازلاً عن حقنا في إنفاذ نفس الحكم
                        (الأحكام) أو أي حكم (أحكام) أخرى واردة في هذه الاتفاقية
                        في المستقبل.
                      </span>
                    )}
                  </span>
                  <span>
                    {language === "en" ? (
                      "Any rights not expressly granted herein are reserved."
                    ) : (
                      <span dir="rtl">
                        أي حقوق غير ممنوحة صراحة في هذه الاتفاقية محفوظة.
                      </span>
                    )}
                  </span>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.paperDiv}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  {language === "en" ? (
                    "26. CONTACT INFORMATION"
                  ) : (
                    <span dir="rtl">26. بيانات الاتصال</span>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ padding: "0px 16px 26px 16px" }}>
                <Typography style={{ paddingLeft: "24px" }}>
                  {" "}
                  <span>
                    {language === "en" ? (
                      "If you have questions or concerns about these Terms or the products, please contact us through our contact page or via the contact information below:"
                    ) : (
                      <span dir="rtl">
                        إذا كانت لديك أسئلة أو استفسارات بشأن هذه الشروط أو
                        المنتجات، يرجى الاتصال بنا من خلال صفحة الاتصال الخاصة
                        بنا أو عبر معلومات الاتصال أدناه:
                      </span>
                    )}
                  </span>
                  <p>
                    <b style={{ fontFamily: "Almarai-SemiBold" }}>
                      {language === "en" ? (
                        "Ishro General Trading LLC, office 606 Ivory II, Business Bay, Dubai United Arab Emirates PO BOX 24887   Phone: 800 - ISHRO (47476)"
                      ) : (
                        <span dir="rtl">
                          800 - ISHRO (47476):إيشرو للتجارة العامة ش.ذ.م.م.،
                          مكتب 606 ايفوري 2، الخليج التجاري، دبي، الإمارات
                          العربية المتحدة، ص.ب. 24887 هاتف
                        </span>
                      )}
                    </b>
                  </p>
                  <ul className="col-sm-12 p-0 fl">
                    <li>
                      {language === "en" ? (
                        "In case of a Winner does not live in the United Arab Emirates, We will either courier the Prize to the Winner in the case of smaller items (as we shall determine in our sole discretion). For all big prizes (as we shall determine in our sole discretion), we will facilitate the shipping of the prize to the country of residence of the winner. All responsibilities and costs attached to it should be assume by the winner. Accordingly, all local taxes, custom duties, shipping and delivery at the port will be paid by the Winner."
                      ) : (
                        <span dir="rtl">
                          في حالة عدم وجود الفائز في دولة الإمارات العربية
                          المتحدة ، فسنقوم إما بإرسال الجائزة إلى الفائز في حالة
                          العناصر الأصغر (كما سنحدده وفقًا لتقديرنا الخاص).
                          بالنسبة لجميع الجوائز الكبيرة (كما سنحدد وفقًا
                          لتقديرنا الخاص) ، سنعمل على تسهيل شحن الجائزة إلى بلد
                          إقامة الفائز. يجب أن يتحمل الفائز جميع المسؤوليات
                          والتكاليف المرتبطة بها. وفقًا لذلك ، سيتم دفع جميع
                          الضرائب المحلية والرسوم الجمركية والشحن والتسليم في
                          الميناء من قبل الفائز.
                        </span>
                      )}
                    </li>
                    <li>
                      {language === "en" ? (
                        "Once a Prize is handed to/collected by the courier/handler for international delivery, risk and responsibility shall be transfered to the Winner and ISHRO is no longer responsible for any damage/injury incurred in transit, or upon delivery."
                      ) : (
                        <span dir="rtl">
                          بمجرد تسليم الجائزة او جمعها بواسطة البريد السريع او
                          المعالج للتسليم الدولي ، سيتم نقل المخاطر والمسؤولية
                          إلى الفائز ولم تعد اشرو مسؤولة عن أي ضرر او إصابة تحدث
                          أثناء النقل أو عند التسليم
                        </span>
                      )}
                    </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as actionCreators from "../../../store/actions";
import { Grid, makeStyles } from "@material-ui/core";
import MainBanner from "./MainBanner";
import ClosingPromotions from "./ClosingPromotions";
import SoldOutPromotions from "./SoldOutPromotions";
import UpcomingPromotions from "./UpcomingPromotions";
import WinnerList from "./WinnerList";
import SubBanner from "./SubBanner";
import PromotionList from "./PromotionList";
import loading from "../../../store/actions/loading";
import ReactGA from "react-ga";

const useStyles = makeStyles((theme) => ({
  divbox: {
    padding: "1% 6% 7%",
    [theme.breakpoints.down("sm")]: {
      padding: "1% 0% 7%",
    },
  },
}));
const Home = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  // const sections = useSelector((state) => state.sections).sections;
  // const user = useSelector((state) => state.user?.user);
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);
  // useEffect(() => {
  //   dispatch(loading(true));
  //   dispatch(actionCreators.loadBanners());
  //   dispatch(actionCreators.loadAds());
  //   dispatch(actionCreators.loadSections());
  //   dispatch(actionCreators.loadPromotionCategories());
  //   dispatch(actionCreators.loadPage("web", "Home", ""));
  //   dispatch(actionCreators.loadPromotions(1))
  //     .then(() => {
  //       setTimeout(function () {
  //         dispatch(loading());
  //       }, 1000);
  //     })
  //     .catch(() => {
  //       setTimeout(function () {
  //         dispatch(loading());
  //       }, 1000);
  //     });
  // }, [dispatch]);

  // useEffect(() => {
  //   dispatch(actionCreators.loadPromotions(1));
  // }, [user]);

  // const soldOutWinnerpanels = sections.map((c, index) => {
  //   switch (c.section) {
  //     case "winners":
  //       return () => (
  //         <Grid item xs={12} sm={4} md={4} lg={4} key={index}>
  //           <WinnerList
  //             title_ar={c.section_title_ar}
  //             title_en={c.section_title_en}
  //           />
  //         </Grid>
  //       );
  //     case "soldout":
  //       return () => (
  //         <Grid item xs={12} sm={8} md={8} lg={8} key={index}>
  //           <UpcomingPromotions />
  //           <SoldOutPromotions
  //             title_ar={c.section_title_ar}
  //             title_en={c.section_title_en}
  //           />
  //         </Grid>
  //       );
  //     default:
  //       return () => <></>;
  //   }
  // });
  // const soldWinnerOrder = soldOutWinnerpanels.map((p) => p());
  // const panels = sections.map((c, index) => {
  //   switch (c.section) {
  //     case "promotions":
  //       return () => (
  //         <PromotionList
  //           title_ar={c.section_title_ar}
  //           title_en={c.section_title_en}
  //           key={index}
  //         />
  //       );
  //     case "main-banner":
  //       return () => <MainBanner />;
  //     case "highlights":
  //       return () => (
  //         <ClosingPromotions
  //           title_ar={c.section_title_ar}
  //           title_en={c.section_title_en}
  //           key={index}
  //         />
  //       );
  //     case "sub-banner":
  //       return () => <SubBanner key={index} />;
  //     case "soldout":
  //       return () => (
  //         <Grid container className={classes.divbox}>
  //           {soldWinnerOrder}
  //         </Grid>
  //       );

  //     default:
  //       return () => <></>;
  //   }
  // });

  // const ordered = panels.map((p) =s> p());

  return (
    <>
      <img
        src={
          isMobile
            ? "https://storage.googleapis.com/ishro/uploads/63b57a4f0ee03.jpg"
            : "https://storage.googleapis.com/ishro/uploads/63b55e74909f7.jpg"
        }
        style={{ width: "100%" }}
      />
      {/* {ordered} */}
      {/* <Grid container className={classes.divbox}>
        {soldWinnerOrder}
      </Grid> */}
    </>
  );
};
export default Home;

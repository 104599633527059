import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as actionCreators from "../../../store/actions";
import CurrencyFormat from "react-currency-format";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loading from "../../../store/actions/loading";
import { getUser } from "../../../services/storage/user";
import { useTranslation } from "react-i18next";
import {
  Box,
  makeStyles,
  Grid,
  Paper,
  IconButton,
  Button,
  Typography,
} from "@material-ui/core";
import NotFound from "../errors/notFound/NotFound";
import CancelIcon from "@material-ui/icons/Close";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./Favourites.css";
import CalcCurrency from "../../../utils/CalcCurrency";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { isValid, decode } from "js-base64";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import analyticsEventTracker from "../../common/analyticsEventTracker";
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "30px",
    fontFamily: "Almarai-Bold",
    color: theme.palette.primary.main,
    padding: "2% 13%",
    [theme.breakpoints.down("sm")]: { fontSize: "23px", alignItems: "center" },
  },
  subtitle: {
    fontSize: "16px",
    fontFamily: "Almarai-SemiBold",
    color: theme.palette.text.secondary,
    width: "96%",
    marginBottom: "4%",
    lineHeight: "1.3rem",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "47px",
  },
  paperDiv: {
    padding: "7%",
    margin: "4%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "27px",
  },
  promobtn: {
    float: "right",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "8px",
    padding: "7px 21px",
    textTransform: "uppercase",
    fontFamily: "Almarai-SemiBold",
    whiteSpace: "nowrap",
    color: theme.palette.text.black,
  },
  promoPrice: {
    fontFamily: "Almarai-SemiBold",
    color: theme.palette.text.secondary,
    fontSize: "18px",
    marginTop: "10%",
  },
  posi: {
    justifyContent: "center",
    display: "flex",
    alignItems: "end",
  },
  posiDiv: {
    flexDirection: "column",
    justifyContent: "center",
    display: "flex",
    paddingLeft: "14%",
  },
  pricetxt: {
    color: theme.palette.text.secondary,
  },
  closebtn: {
    marginTop: "-6px",
    padding: "3px",
    backgroundColor: "grey",
    color: "white",
  },
}));

const Favourites = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const favourites = useSelector((state) => state.favourites);
  const currency = useSelector((state) => state.currency);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);
  const userData = getUser();
  const language = useSelector((state) => state.language);
  const isMobile = useMediaQuery("(max-width:600px)");

  const gaEventTracker = analyticsEventTracker("Favourites Page");
  useEffect(() => {
    dispatch(loading(true));
    dispatch(actionCreators.getFavourites());
    setTimeout(
      function () {
        dispatch(loading());
      }.bind(this),
      2000
    );
  }, [dispatch]);

  const getPercentage = (ticketNow, totalTicket) => {
    return (ticketNow / totalTicket) * 100;
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search, [
      isLoggedIn ? user.user_id : "Guest",
    ]);
  }, []);
  const handleAddToCart = (event, promotionDetails) => {
    if (isLoggedIn)
      var data = {
        product_id: promotionDetails.product_id,
        promotion_id: promotionDetails.promotion_id,
        product_quantity: promotionDetails.product.product_quantity,
      };
    else
      var data = {
        product_id: promotionDetails.product_id,
        promotion_id: promotionDetails.promotion_id,
        product_quantity: promotionDetails.product.product_quantity,
        product: promotionDetails.product,
        promotion: promotionDetails,
      };
    dispatch(
      actionCreators.loadPage("web", "AddToCart", promotionDetails.promotion_id)
    );
    dispatch(actionCreators.addToCart(data))
      .then((res) => {
        if (res) {
          toast(t("Added to Cart"));
          ReactPixel.track("AddToCart", {
            content_type: "Product",
            content_ids: promotionDetails.promotion_id,
            value:
              Number(promotionDetails.product.product_quantity) *
              Number(promotionDetails.ticket_price_with_tax),
            currency: "AED",
            Email: isLoggedIn ? user.email : "Guest",
            External_id: isLoggedIn ? user.user_id : "Guest",
          });
          gaEventTracker("AddToCart", isLoggedIn ? user.user_id : "Guest");
          navigate("/cart");
        }
      })
      .catch(() => {});
  };

  const handleRemoveFavourite = (value) => {
    toast(t("Removed from favourite"));
    var data = {
      action: "remove",
      product_id: value.product_id,
      promotion_id: value.promotion_id,
    };
    dispatch(actionCreators.addToFavourite(data));
  };

  return (
    <div>
      {favourites?.favourites?.length && favourites?.favourites?.length > 0 ? (
        <div style={{ paddingTop: "1.5%" }}>
          <Box display="flex" className={classes.title}>
            <Box flexGrow={1}>
              <span>
                <IconButton href="/">
                  <ArrowBackIosIcon
                    style={
                      language === "ar"
                        ? { transform: "rotate(180deg)", color: "#353434" }
                        : { color: "#353434" }
                    }
                  />
                </IconButton>
              </span>
              <p style={{ paddingLeft: "14px", paddingTop: "21px" }}>
                {language === "en" ? "Favourites" : "المفضلة"}
              </p>
            </Box>
          </Box>
          <Grid container className="productDetail">
            {favourites.favourites.map((item, index) => {
              var percentage = getPercentage(
                item?.promotion.sold_out_tickets,
                item?.promotion.total_tickets_alloted
              );
              return (
                <Grid item xs={12} sm={6} md={3} lg={4} key={index}>
                  <Paper className={classes.paperDiv}>
                    <Grid container>
                      <Grid item xs={11} sm={11} md={11} lg={11}>
                        <Typography
                          variant="p"
                          component="p"
                          dir={language == "en" ? "rtr" : "rtl"}
                          className={classes.subtitle}
                          dangerouslySetInnerHTML={{
                            __html: item
                              ? language === "en"
                                ? isValid(item.promotion.title)
                                  ? decode(item.promotion.title)
                                  : decode(item.promotion.title)
                                : isValid(item.promotion.title_ar)
                                ? decode(item.promotion.title_ar)
                                : decode(item.promotion.title_ar)
                              : "",
                          }}
                        />
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} lg={1}>
                        <IconButton
                          aria-label="delete"
                          className={classes.closebtn}
                          onClick={() => handleRemoveFavourite(item)}
                        >
                          <CancelIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <img
                          src={item.promotion.draw_image}
                          style={{ width: "100%", margin: "5% 0%" }}
                          alt=""
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        className={classes.posi}
                      >
                        <Button
                          variant="contained"
                          to={""}
                          onClick={(e) => handleAddToCart(e, item)}
                          className={classes.promobtn}
                        >
                          {language == "en" ? "BUY NOW" : "اشتري الآن"}
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        className={classes.posiDiv}
                      >
                        <p className={classes.pricetxt}>
                          {language === "en" ? "Price" : "سعر"}
                        </p>
                        <CurrencyFormat
                          value={CalcCurrency(
                            item.promotion.ticket_price_with_tax,
                            currency.value
                          )}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={` ${currency.label} `}
                          renderText={(value) => (
                            <div className={classes.promoPrice}>{value}</div>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </div>
      ) : (
        <NotFound
          icon={"favorite"}
          title={t("You have no Favourites")}
          message={t("What are you waiting for?")}
        />
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Favourites;

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    padding: "5% 0px 5%",
    [theme.breakpoints.down("sm")]: { padding: "0px" },
  },
  title: {
    fontFamily: "Almarai-SemiBold",
    fontSize: "20px",
    textTransform: "uppercase",
    paddingBottom: "25px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  panel: {
    backgroundColor: theme.palette.primary.main,
    minHeight: "70vh",
    color: "white",
    borderRadius: "30px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: { borderRadius: "0px", paddingTop: "10%" },
  },
  detailsGrid: {
    padding: "50px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      [theme.breakpoints.down("sm")]: { height: "100%" },
    },
  },
  iconGrid: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    position: "relative",
    [theme.breakpoints.down("sm")]: { height: "55vh" },
  },
  form: {
    margin: "30px 0px",
  },
  whatAppBtn: {
    backgroundColor: "#00d856",
    padding: "5px 5px 1px",
    borderRadius: "21px",
    verticalAlign: "middle",
  },
  whatAppBtn2: {
    right: "2%",
    position: "absolute",
    bottom: "4%",
    width: "6%",
  },
  message: {
    backgroundColor: theme.palette.background.lightgray,
    width: "100%",
    border: "none",
    color: "white",
    padding: "18px",
    borderRadius: "18px",
    fontSize: "16px",
    "&::placeholder": {
      fontFamily: "Almarai-SemiBold",
      fontWeight: "600",
      color: "#7d7d7d",
    },
    "&:hover": {
      border: "1px solid",
      borderColor: theme.palette.secondary.main,
    },
  },
  textBoxComp: {
    backgroundColor: theme.palette.background.lightgray,
    width: "100%",
    color: "white",
    borderRadius: "18px",
    margin: "4px 0px 14px",
    "&::placeholder": {
      fontFamily: "Almarai-SemiBold",
      fontWeight: "600",
      color: "#7d7d7d",
    },
    "&:hover": {
      border: "1px solid",
      borderColor: theme.palette.secondary.main,
    },
  },
  link: {
    color: theme.palette.secondary.main,
  },
  btn: {
    padding: "6px 10%",
  },
}));

const ar = {
  translation: {
    Products: "المنتجات",
    winners: "الفائزون",
    Login: "تسجيل الدخول",
    login: "تسجيل الدخول",
    "Forgot password": "هل نسيت كلمة السر؟",
    "Keep me logged in": "احفظ بيانات تسجيل الدخول",
    "Don't have an account yet?": "لا تملك حسابا حتى الآن؟",
    "Sign up": "أنشاء حساب",
    Promotion: "الحملات الترويجية",
    News: "الأخبار",
    "LOGIN WITH GOOGLE": "التسجيل عبر جوجل",
    arabic: "العربية",
    search: "بحث...",
    Quantity: "الكمية",
    Price: "السعر",
    Checkout: "الدفع",
    Buy: "قم بشراء",
    "get a chance to win": "للحصول على فرصة لفوز",
    fq: "الأسئلة الشائعة",
    Profile: "الملف الشخصى",
    Ishroins: "اشروينز",
    Tickets: "التذاكر",
    Winners: "الفائزون",
    "Draw Date": "تاريخ السحب",
    "Ticket Number": "رقم التذكرة",
    "Use Ishroins": "استخدم اشروينز",
    "Total available Ishroins": "مجموع الاشروينز المتاح",
    "Ishroin(s) used": "اشروينز المستخدمة",
    "Use 100 Ishroin(s) and save AED 10.00 off the total cart price value.":
      "استخدم 100 اشروينز و وفر 10 درهم من المبلغ الكامل للسلة",
    "You have no Favourites": "You have no Favourites",
    Subtotal: "المجموع الفرعي",
    VAT: "الضريبة",
    "Donate to": "تبرع لـــ",
    "and get additional ticket(s)": "واحصل على تذاكر إضافية",
    yes: "نعم",
    No: "لا",
    "You will earn": "سوف تحصل على",
    "Ishroin(s) from this purchase": "اشروينز بعد اكمال عملية الشراء",
    "Total Ticket": "مجموع التذاكر",
    "(s)": "",
    "(inclusive of VAT)": "(شامل الضريبة)",
    Total: "المجموع",
    "Pay Now": "ادفع الأن",
    "You have no items in the Cart": "لا يوجد لديك عناصر في عربة التسوق",
    "What are you waiting for?": "ماذا تنتظر؟",
    "Add to Cart": "أضف إلى عربة التسوق",
    "Email not valid": "البريد الإلكتروني غير صالح",
    "Kindly fill all the mandatory fields": "يرجى تعبئة جميع الحقول الإلزامية",
    "Kindly, solve ` I'm not robot challenge `":
      "يرجي حل تحدي ,'انا لست برنامج روبوت'",
    "REGISTER NEW USER": "تسجيل مستخدم جديد",
    "Where did you hear about us?": "أين سمعت عنا؟",
    "Login failed, please try again":
      "فشل تسجيل الدخول. يرجى المحاولة مرة أخرى",
    NEXT: "التالي",
    Password: "كلمة السر",
    "Confirm Password": "تاكيد كلمة السر",
    "Finish registration": "إنهاء التسجيل",
    "First Name": "الاسم الأول",
    "Last Name": "الاسم الاخير",
    "Email Address": "البريد الإلكتروني",
    Email: "البريد الإلكتروني",
    Male: "ذكر",
    Female: "أنثى",
    "Date of Birth": "تاريخ الميلاد",
    "Referral Code (optional)": "كود الإحالة (اختياري)",
    "By clicking the button, you agree to the":
      "عند الضغط على التالي, انت توافق على",
    "T&C's": " شروط الاستخدام و شروط وأحكام السحوبات ",
    and: "و",
    "Privacy Policy": "سياسة الخصوصية ",
    "Already have an account?": "هل لديك حساب؟",
    "By creating an account, you agree to our Terms of use, Privacy Policy , and Draw Terms & Conditions":
      "عند تسجيل الحساب فإنك توافق على سياسة الخصوصية و شروط الاستخدام و شروط وأحكام السحوبات",
    "Our payments are secured with state of the art three dimensional security system. We do not store any of your payment or credit card details. Accepting world class payment providers.":
      "مدفوعاتنا مؤمنة بأحدث نظام أمان ثلاثي الأبعاد. نحن لا نخزن أيًا من تفاصيل الدفع أو بطاقة الائتمان الخاصة بك. نقبل مزودي الدفع من الطراز العالمي.",
    "Email field required": "البريد الالكتروني مطلوب",
    "Succesfully sent, find reset password link from  your email inbox":
      "تم ارسال بريد إلكتروني يحتوى على رابط تغير كلمة المرور",
    "If you've lost your password or wish to reset it, enter your Mobile number to get started.":
      "إذا فقدت كلمة المرور الخاصة بك أو كنت ترغب في إعادة تعيينها ، أدخل رقم هاتفك المحمول للبدء.اذا نسيت كلمة السر او تريد تغير كلمة السر, ادخل البريد الألكترونيإذا فقدت كلمة المرور الخاصة بك أو كنت ترغب في إعادة تعيينها ، أدخل رقم هاتفك المحمول للبدء.",
    "Added to Cart": "إضافة الى السلة",
    "Removed from favourite": "تم الحذف من السلة",
    "Please Login/Sign-up to your account":
      "الرجاء تسجيل الدخول او التسجيل الى الحساب",
    submit: "ارسال",
    // menu
    "Login to your account": "تسجيل الدخول",
    "Login to unlock features like reward points, favorites, and many more.":
      "قم بتسجيل الدخول للحصول على مميزات مثل نقاط المكافآت والمفضلة وغير ذلك الكثير.",
    ADDED: "مضاف",
    "Registered by": "مسجلة من قبل",
    "Registered and Regulated by": "مسجلة ومنظمة من قبل",
    "Member of": "عضو في",
    "Download Our App": "حمل تطبيقنا",
    Support: "الدعم الفني",
    "Contact Us": "تواصل معنا",
    "Contact us": "تواصل معنا",
    "About Us": "عنا",
    ISHROINS: "اشروينز",
    "Your Balance": "رصيدك",
    "expires in": "صالحة حتى",
    days: "ايام",
    points: "نقطة",
    "Save a maximum of 20% off the total cart price":
      "وفر20٪ كحد أقصى على السعر الإجمالي",
    LOGOUT: "تسجيل الخروج",
    "ABOUT US": "عن اشرو",
    about_us_text:
      "نبذة عنا : في إشرو ندعوك لاكتشاف منتجات ذات جودة عالية تتوافق مع عروض ترويجية مذهلة. كل عملية شراء تؤهلك للحصول على فرصة للفوز بجائزة ذات قيمة عالية. يتم تضمين قسيمة مجانية للفوز بجائزة قيمة في كل مرة .الهدايا تتراوح بين الذهب والسيارات والالكترونيات . تم تركيز إهتمامنا لتقديم مجموعة متنوعة من المنتجات التي تحبها. لن تستمتع فقط بمجموعات جيدة بل نحن نعطيك فرصة لتكون الفائز.",
    MISSION: "المهمة",
    mission_text:
      "المهمة: في إشرو , نحن نعلم أن لديك خيار عندما يتعلق الأمر بمكان التسوق المفضل.لهذا السبب نحن نقدر ثقتك بنا.نحن نسعى جاهدين لإنتقاء مجموعة من الجوائز المذهلة التي سوف تثري تجربة التسوق الخاصة بك وتجعلها مميزة عن أي تجربة أخرى.",
    VISION: "الرؤية",
    vision_text:
      "الرؤية : ننشر الفرحة من خلال فرص الفوز,المكافآت والعروض الشخصية.الكل يطمح إلى الفوز ونحن نكرس جهودنا لكي نمنحكم الفوز.نحن نحفز عملائنا من خلال مضاعفة تذكرة دخول في السحب الترويجي بمجرد أن يتبرعوا بشرائهم لأحد شركائنا الخيرية.",
    Card: "الدفع عبر البطاقة",
    "Name on card": "الاسم على البطاقة",
    "Card number": "رقم البطاقة",
    "Card Expiry Date": "تاريخ انتهاء الصلاحية",
    "Security code (CVV)": "رقم الحماية",
    "Fill out the form below to contact the developers for your concerns regarding the application of the company.":
      "املأ النموذج أدناه للاتصال بالمطورين بشأن مخاوفك بشأن تطبيق الشركة.",
    Name: "الاسم",
    Message: "الرسالة",
    Address: "العنوان",
    City: "المدينة",
    "Send message": "ارسال",
    "First name should be more than 4 character":
      "الاسم الاول يجب ان يكون اكبر من 4 احرف",
    "Last name should be more than 2 character":
      "الاسم الاخير يجب ان يكون اكبر حرفين",
    "Please Accept Terms of Use and Privacy Policy":
      "الرجاء قبول شروط الاستخدام وسياسة الخصوصية",
    "Change Name": "تغيير الاسم",
    "Change Email": "تغيير عنوان البريد الإلكتروني",
    "Change Mobile Number": "تغيير رقم الهاتف المحمول",
    "Change Password": "تغيير كلمة المرور",
    "Change Location": "تغيير العنوان",
    "Change your name": "قم بتغير اسمك",
    "If you want to change the name associated with your Ishro account, you may do so below. Be sure to click the Save Changes button when you are done.":
      "إذا كنت تريد تغيير الاسم المرتبط بحساب اشرو الخاص بك ، فيمكنك القيام بذلك أدناه. تأكد من النقر فوق الزر حفظ التغييرات عند الانتهاء ",
    "Please fill the required fields": "يرجى تعبئة جميع الحقول المطلوبة",
    "Change your email": "قم بتغيير بريدك الإلكتروني",
    "error, please try again": "خطأ ، يرجى المحاولة مرة أخرى ",
    "Enter the new email address you would like to associate with your account below. We will send a One Time Password (OTP) to that address.":
      "أدخل عنوان البريد الإلكتروني الجديد الذي ترغب في ربطه بحسابك أدناه. سنرسل كلمة مرور لمرة واحدة (OTP) إلى هذا العنوان. ",
    "Change your mobile number": "قم بتغيير رقم هاتفك المحمول",
    "Enter the new mobile number you would like to associate with your account below. We will send a One Time Password (OTP) to that mobile number.":
      "أدخل رقم الهاتف المحمول الجديد الذي ترغب في ربطه بحسابك أدناه. سنرسل كلمة مرور لمرة واحدة (OTP) إلى رقم الهاتف المحمول هذا. ",
    "save changes": "احفظ التغييرات",
    "password confirmation does not match your new password":
      "تأكيد كلمة المرور لا يطابق كلمة مرورك الجديدة",
    "password change successfully": "تم تغيير كلمة المرور بنجاح",
    "Use the form below to change the password for your Ishro account.":
      "استخدم النموذج أدناه لتغيير كلمة المرور لحساب اشرو الخاص بك. ",
    "Change password": "تغيير كلمة المرور",
    "Current Password": "كلمة المرور الحالية",
    "New Password": "كلمة المرور الجديدة",
    "Re-enter New Password": "أعد إدخال كلمة المرور الجديدة",
    "location change successfully": "تم تغيير الموقع بنجاح",
    "If you want to change the location associated with your Ishro account, you may do so below. Be sure to click the Save Changes button when you are done.":
      "إذا كنت ترغب في تغيير الموقع المرتبط بحساب اشرو الخاص بك ، فيمكنك القيام بذلك أدناه. تأكد من النقر فوق الزر حفظ التغييرات عند الانتهاء. ",
    "Change Country": "تغيير الدولة",
    "Choose Country": "اختر دولة",
    "Change Address": "تغيير العنوان",
    "Change City": "تغيير المدينة",
    "DELETE ACCOUNT": "حذف الحساب",
    "Has been changed successfully": "تم التغير بنجاح",
    Instagram: "انستغرام",
    Facebook: "فيسبوك",
    "Tik Tok": "تيك توك",
    Snapchat: "سناب شات",
    YouTube: "يوتيوب ",
    "Search Engine": "محرك البحث",
    "Word of mouth": "احد معارفك",
    Other: "اخرى",
    "Please type the verification code sent to":
      "الرجاء كتابة رمز التحقق المرسل إلى",
    "Verification Code": "رمز التحقق",
    Verify: "التحقق",
    "Available Ishroins worth": "اشروينز بقيمة",
    "Use your ishroins to gain massive discounts on your next purchase. Gain reward points by purchasing promotions, daily logins, or joining in our events!":
      "استخدم اشروينز للحصول على خصومات هائلة على مشترياتك القادمة. إحصل على نقاط مكافأة عن طريق شراء العروض الترويجية، وتسجيل الدخول اليومي، أو الانضمام إلى فعالياتنا",
    "redeem points": "استبدال النقاط",
    "referral code": "رمز الإحالة",
    "Reward History": "تاريخ المكافأة",
    "Already registed account with this mobile number":
      "يوجد حساب مسجل بالفعل مع رقم الهاتف المحمول هذا",
    "Active Tickets": "التذاكر النشطة",
    Tickets: "تذاكر",
    "continue as guest": "المتابعة كضيف",
    "  Tickets and other email notifications will be sent to this email":
      "سيتم إرسال التذاكر الأشعارات الأخرى إلى هذا البريد الإلكتروني",
    "Code will be sent to the phone number above":
      "سيتم إرسال الرمز إلى رقم الهاتف أعلاه",
    "checkout as guest": "الدفع كضيف",
    "Ticket No.": "رقم التذكرة",
    "Continue Shopping": "متابعة التسوق",
    CLOSE: "أغلاق",
    "Welcome to ISHRO VIP, you need to spend at least 1000 AED to be able to access this content.":
      "مرحبًا بك في اشرو (VIP) ، تحتاج إلى إنفاق 1000 درهم على الأقل لتتمكن من الوصول إلى هذا المحتوى. ",
    "Secure payment solution provided by.": "الدفع الآمن مقدم من.",
    "Password must be at least 8 characters long contain a number and an uppercase letter and a special character":
      "يجب أن تتكون كلمة المرور من 8 أحرف على الأقل وتحتوي على رقم وحرف كبير وحرف خاص ",
    "Name must be in English with no space":
      "الأسم يجيب أن يكون من احرف إنجليزية فقط و بدون مسافة",
    "Mobile verification code": "رمز التحقق",
    "Send Code": "أرسل الرمز",
    "please fill the fields and verify your number to continue":
      "الرجاء تعبئة جميع الحقول وتاكيد رقم الهاتف للمتابعة",
    Continue: "متابعة",
    "Add a Payment Method": "اضافة وسيلة دفع",
    "Other Payment Methods": "طرق دفع اخرى",
    "Terms of Use": "سياسة الاستخدام",
    "By clicking Continue to Payment, you have read and accept the":
      "عند الضغط على المتابعة للدفع انت توافق على",
    Payment: "الدفع",
    "Ishroin(s)": "اشروينز",
    Cart: "عربة التسوق",
    "Use Card": "اسنخدم البطاقة",
    "Purchase On": "تم الشراء فى",
    "Manage Payment Options": "إدارة خيارات الدفع",
    "You don't have any saved cards": "ليس لديك أي بطاقات محفوظة",
    "No Saved Cards": "لا يوجد بطاقات محفوظة",
    "Please fill in the form below and our customer service team will be in touch with you.":
      "يرجى ملء النموذج أدناه وسيقوم فريق خدمة العملاء لدينا بالاتصال بك.",
    "Ticket(s) if you donate": "تذكرة/تذاكر عند التبرع",
    CheckOut: "الدفع",
    "New Password": "كلمة المرور الجديدة",
    "Confirm Password": "تأكيد كلمة المرور",
    "Change Password": "تغيير كلمة المرور",
    Pay: "ادفع",
    "IACAD License No": "رقم تصريح ",
    "Ending with": "تنتهي ب",
    Expires: "تاريخ الإنتهاء",
    "Use Card": "استخدم البطاقة",
    "Card number": "رقم البطاقة",
    MM: "شهر",
    YY: "سنة",
    CVV: "الرمز",
    "password and password confirmation doesn't match":
      "كلمة المرور وتأكيد كلمة المرور غير متطابقين",
    "Succesfully sent, find reset password link from  your email inbox":
      "تم الإرسال بنجاح ، ابحث عن رابط إعادة تعيين كلمة المرور من صندوق بريدك الإلكتروني",
    "Reset your password": "إعادة تعيين كلمة المرور الخاصة بك",
    "You can use": "يمكنك استخدم",
    "ishroins for this transaction.": "اشروينز لعملية الشراء",
    "Add a Payment Method": "اضف وسيلة دفع",
    Levels: "المستويات",
    "Level Benefits": "فوائد المستويات",
    Benefits: "الفوائد",
    "Get back 0.5% of your purchase in Ishroins":
      "استرجع 0.5٪ من قيمة مشترياتك  على شكل اشروينز",
    "Get back 1.0% of your purchase in Ishroins":
      "استرجع 1.0٪ من قيمة مشترياتك  على شكل اشروينز",
    "Get back 1.5% of your purchase in Ishroins":
      "استرجع 1.5٪ من قيمة مشترياتك  على شكل اشروينز",
    "Gold member": "عضو ذهبي",
    "Silver member": "عضو فضي",
    "Bronze member": "عضو برونزي",
    "Existing cards": "البطاقات الموجودة",
    "Use the app for the better experience":
      "استخدم التطبيق للحصول على تجربة أفضل",
    "Tickets and other email notifications will be sent to this email":
      "سيتم إرسال التذاكر وإخطارات البريد الإلكتروني الأخرى إلى هذا البريد الإلكتروني",
    Continue: "المتابعة",
    "Please check your card details and try again":
      "يرجى التحقق من تفاصيل بطاقتك والمحاولة مرة أخرى ",
    "You have no Favourites": "ليس لديك مفضلات",
    "Enter your card CVV Code": "ادخل رمز الحماية",
    "You have earned 50 Ishroins from your referal code.":
      "لقد ربحت 50 اشروينز من رمز الإحالة الخاص بك",
    "Successfully registered! You have received 70 Ishroins.":
      "سجلت بنجاح! لقد حصلت على 70 اشروينز. ",
    "You get 20 Ishroins from your purchase.":
      "لقد حصلت على 20 اشروينز من عملية الشراء",

    "Are you sure that you want to delete this account?":
      "هل أنت متأكد أنك تريد حذف هذا الحساب؟",
    Cancle: "إلغاء",
    "Delete account": "حذف الحساب",

    "SHOW MORE": "عرض المزيد",
    "SHOW LESS": "عرض آقل",
    Share: "المشاركة",
    "Enter Email Address": "أدخل عنوان البريد الالكتروني",
    Favourites: "المفضلة",
    "Total price inclusive of VAT": "السعر الإجمالي شاملاً الضريبة",
    "Welcome to ISHRO VIP.": " مرحبا بك في ISHRO VIP",
    "Congratulations on having been invited to be one of our first exclusive members.":
      "تهانينا لقد تمت دعوتك لتكون من أول أعضائنا الحصريين",
    "VIP Membership": " عضوية VIP",
    "Shop for a chance to win exclusive promotions available only to our VIP members.":
      " العروض الترويجية الحصرية متاحة فقط لأعضاء VIP",
    "Please fill the Mobile number": "الرجاء تعبئة رقم الهاتف المحمول",
    "We regret that the transaction was not successful because the crypto payment gateway seems to be down at this moment. Please try another payment method in the mean-time.":
      "نأسف لأن المعاملة لم تكن ناجحة , يبدو ان بوابة دفع العملات الرقمية معطلة في هذه اللحظة. يرجى تجربة طريقة دفع أخرى في الوقت الحالي.",
    "Error Processing Payment": "خطأ في عملية الدفع",
    "The transaction failed due to a technical error, please try again with a different payment method.":
      "خطاء فى عملية الدفع، يرجى المحاولة مرة أخرى بطريقة دفع مختلفة.",
    "Your password has been changed successfully.Please use your new password to login.":
      "تم تغيير كلمة المرور الخاصة بك بنجاح. الرجاء استخدام كلمة المرور الجديدة لتسجيل الدخول",
    "Use App": "استخدام التطبيق",
    "You Saved": "لقد وفرت",
    Apply: "تطبيق",
    "Enter Discount Code": "ادخل كود الخصم",
  },
};

export default ar;

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
  Grid,
  Hidden,
  Button,
  ButtonGroup,
  IconButton,
  Popover,
  MenuItem,
  MenuList,
  makeStyles,
  Dialog,
  Avatar,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Favorite from "@material-ui/icons/Favorite";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Menu from "@material-ui/icons/Menu";
import Fade from "@material-ui/core/Fade";
import { useTranslation } from "react-i18next";
import "./header.css";
import Login from "../auth/login/Login";
import WinnerDetails from "../../components/pages/winner/WinnerDetails";
import ForgotPassword from "../auth/forgot_password/ForgotPassword";
import { default as HeaderMenu } from "../menu/Menu";
import * as actionCreators from "../../store/actions";
import logo from "../../../src/assets/image/logo.svg";
import vipmsgimg from "../../../src/assets/image/vipmsg.svg";
import vipFrameEn2 from "../../../src/assets/image/vipFrameEn2.svg";
import vipFrameAr2 from "../../../src/assets/image/vipFrameAr2.svg";
import logo_vip from "../../../src/assets/image/logo_vip.svg";
import registerimg from "../../../src/assets/image/registerimg.svg";
import Language from "@material-ui/icons/Language";
import menuDialog from "../../store/actions/menuDialog";
import loginDialog from "../../store/actions/loginDialog";
import en from "../../../src/assets/image/locle/en.svg";
import ar from "../../../src/assets/image/locle/ar.svg";
import en_vip from "../../../src/assets/image/locle/en_vip.svg";
import crown from "../../../src/assets/image/crown.svg";
import ar_vip from "../../../src/assets/image/locle/ar_vip.svg";
import uae from "../../../src/assets/image/flags/uae.svg";
import usa from "../../../src/assets/image/flags/usa.svg";
import bahrain from "../../../src/assets/image/flags/bahrain.svg";
import europe from "../../../src/assets/image/flags/europe.svg";
import india from "../../../src/assets/image/flags/india.svg";
import ksa from "../../../src/assets/image/flags/ksa.svg";
import kuwait from "../../../src/assets/image/flags/kuwait.svg";
import oman from "../../../src/assets/image/flags/oman.svg";
import qatar from "../../../src/assets/image/flags/qatar.svg";
import ishro_vip from "../../../src/assets/image/ishro_vip.svg";
import Modal from "@material-ui/core/Modal";
import getCurrencyValue from "../../utils/getCurrencyValue";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import Fab from "@material-ui/core/Fab";
import { useLocation, useNavigate } from "react-router-dom";
import VerifyUser from "../auth/login/VerifyUser";
import ReactPixel from "react-facebook-pixel";
import memberimg2 from "../../../src/assets/image/memberimg2.svg";
import ReactGA from "react-ga";
const useStyles = makeStyles((theme) => ({
  btnGroup: {
    color: theme.palette.text.secondary,
    fontFamily: "Almarai-SemiBold",
    fontSize: "14px",
  },
  margin: {
    marginRight: "5px",
    marginLeft: "5px",
    color: theme.palette.text.secondary,
    backgroundImage: "none",
  },
  purple: {
    backgroundColor: theme.palette.primary.main,
    border: "2px solid #24CDE6",
    color: theme.palette.text.secondary,
    fontFamily: "Almarai-Bold",
    fontSize: "15px",
    marginRight: "2%",
  },
  loginbtn: {
    marginRight: "2%",
    borderRadius: "21px",
    color: theme.palette.primary.main,
    fontFamily: "Almarai-SemiBold",
    fontSize: "14px",
  },
  vipModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(100 97 97 / 50%)",
  },
  vipBox: {
    backgroundColor: "#141414",
    outline: "-webkit-focus-ring-color auto 0px",
    borderRadius: "27px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "white",
    padding: "3% 2%",
  },
  vipBox1: {
    backgroundColor: "#141414",
    outline: "-webkit-focus-ring-color auto 0px",
    borderRadius: "27px",
    display: "flex",
    justifyContent: "center",
    alignItems: "left",
    flexDirection: "column",
    color: "rgb(255 255 255 / 80%)",
    position: "relative",
  },
  bottomRight: {
    position: "absolute",
    bottom: "8px",
    left: "16px",
    width: "95%",
  },
  vipLogo: {
    marginBottom: "15px",
  },
  vipCloseBtn: {
    backgroundImage: "linear-gradient(180deg, #605D41,#ADA16F)",
    color: "black",
    borderRadius: "26px",
    padding: "9px 0px",
    width: "145px",
    fontFamily: "Almarai-SemiBold",
    height: "44",
    "&:hover": {
      backgroundColor: theme.palette.background.vip,
    },
  },
  vipbtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4% 4%",
  },
  vipText: {
    fontSize: "18px",
    fontFamily: "Almarai-Regular",
    padding: "0% 4% 8%",
    [theme.breakpoints.down("sm")]: { fontSize: "12px" },
  },
  vipText1: {
    color: "#B1A773",
    fontSize: "38px",
    fontFamily: "Almarai-Bold",
    padding: "2% 4%",
    [theme.breakpoints.down("sm")]: { fontSize: "18px" },
  },
  iconDiv: {
    display: "inline-flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: { padding: "0% 7.2%" },
  },
}));

const Header = ({ setHeaderHeight }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [toggleForgotPassword, setToggleForgotPassword] = React.useState("0");
  const [vipModalOpen, setVipModalOpen] = React.useState(false);
  const [vipMsgOpen, setVipMsgOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [promotionCharity] = useState("donate");
  const [paidViaPoint] = useState(false);
  const [winnerId, setWinnerId] = useState();
  const anchorRef = React.useRef(null);
  const languageSelector = useRef(null);
  const { t } = useTranslation();
  const [winnerOpen, setWinnerOpen] = React.useState(false);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);
  const user = useSelector((state) => state.user?.user);
  const language = useSelector((state) => state.language);
  const currency = useSelector((state) => state.currency);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currencyAnchor, setCurrencyAnchor] = React.useState(null);
  const currencyopen = Boolean(currencyAnchor);
  const loginDialogState = useSelector((state) => state.loginDialog);
  const menuDialogState = useSelector((state) => state.menuDialog);
  const langopen = Boolean(anchorEl);
  const id = langopen ? "simple-popover" : undefined;
  const loginopen = Boolean(loginDialogState);
  const loginid = loginopen ? "simple-popover" : undefined;
  const menuopen = Boolean(menuDialogState);
  const menuid = menuopen ? "simple-popover" : undefined;
  const [androidPlatform, setAndroidPlatform] = React.useState(true);
  const smallDevices = useMediaQuery("(max-width:700px)");
  const [vipstep, setVipStep] = React.useState("0");
  const { state } = useLocation();
  const navigate = useNavigate();
  var vipmsg = localStorage.getItem("ishro_vip_msg");
  const [VipCheck, setVip] = React.useState(user?.switched == 1 ? false : true);
  const handleLanguage = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    getMobileOperatingSystem();

    ReactGA.set({ userId: isLoggedIn ? user.user_id : "Guest" });
    user?.switched == 1 ? setVip(false) : setVip(true);
  }, [user]);

  useEffect(() => {
    ReactPixel.track("PageView", {
      Email: isLoggedIn ? user.email : "Guest",
      External_id: isLoggedIn ? user.user_id : "Guest",
    });

    if (vipmsg != undefined && vipmsg == "true") {
      if (isLoggedIn) {
        dispatch(
          actionCreators.changeUserLevel({
            user_id: user?.user_id,
            user_level: "Vip",
          })
        )
          .then((res) => {
            setVipMsgOpen(true);
          })
          .catch((msg) => {
            dispatch(actionCreators.loading());
          });
      } else {
        setVipMsgOpen(true);
      }
    }
  }, []);

  const handleCurrency = (event) => {
    setCurrencyAnchor(event.currentTarget);
  };

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      setAndroidPlatform(true);
    }

    if (/android/i.test(userAgent)) {
      setAndroidPlatform(true);
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setAndroidPlatform(false);
    }
  };

  const handleLangClose = () => {
    setAnchorEl(null);
  };
  const handleCurrencyClose = () => {
    setCurrencyAnchor(null);
  };
  const handleLogin = (event) => {
    dispatch(loginDialog(event.currentTarget));
  };
  const handleLoginClose = () => {
    dispatch(loginDialog(null));
  };

  const handleMenu = (event) => {
    dispatch(menuDialog(event.currentTarget));
  };
  const handleMenuClose = () => {
    dispatch(menuDialog(null));
  };

  const handleClose = () => {
    setWinnerOpen(false);
  };

  const setLanguage = (language) => {
    handleLangClose();
    dispatch({ type: "SET_LANGUAGE", payload: language });
  };
  const setCurrency = (currency) => {
    getCurrencyValue(currency)
      .then((value) => {
        if (value)
          dispatch({
            type: "SET_CURRENCY",
            currency: { label: currency, value },
          });
      })
      .catch((err) => {
        console.error(err);
      });
    handleCurrencyClose();
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    var data = {
      promotion_charity: promotionCharity === "donate" ? "true" : "false",
      paid_via_point: paidViaPoint.toString(),
    };
    dispatch(actionCreators.userInfo());
    dispatch(actionCreators.getFavourites());
    dispatch(actionCreators.getCarts(data));
    dispatch(actionCreators.appInfo())
      .then((res) => {
        setWinnerId(res.recentWinner.ticket_id);
      })
      .catch((error) => {});
  }, []);

  const handleVip = (val) => {
    if (!isLoggedIn) return dispatch(loginDialog(true));
    dispatch(actionCreators.loading(true));
    dispatch(
      actionCreators.switchToVip({
        switched: `${val ? "0" : "1"}`,
      })
    )
      .then((res) => {
        dispatch(actionCreators.userInfo());
        setVip(val);
        setTimeout(function () {
          dispatch(actionCreators.loading());
        }, 1500);
      })
      .catch((msg) => {
        dispatch(actionCreators.loading());
        setMsg(msg);
        setVipModalOpen(true);
        setVip(true);
      });
  };

  const getCurrentLanguage = () => {
    if (language === "en") {
      return t("English");
    } else return t("arabic");
  };

  const getSelectedCurrency = () => {
    switch (currency.label) {
      case "AED":
        return (
          <>
            <img src={uae} alt="en" className={classes.margin} />
            AED
          </>
        );
      case "USD":
        return (
          <>
            <img src={usa} alt="en" className={classes.margin} />
            USD
          </>
        );
      case "BHD":
        return (
          <>
            <img src={bahrain} alt="en" className={classes.margin} />
            BHD
          </>
        );
      case "EUR":
        return (
          <>
            <img src={europe} alt="en" className={classes.margin} />
            EUR
          </>
        );
      case "INR":
        return (
          <>
            <img src={india} alt="en" className={classes.margin} />
            INR
          </>
        );
      case "SAR":
        return (
          <>
            <img src={ksa} alt="en" className={classes.margin} />
            SAR
          </>
        );
      case "KWD":
        return (
          <>
            <img src={kuwait} alt="en" className={classes.margin} />
            KWD
          </>
        );
      case "OMR":
        return (
          <>
            <img src={oman} alt="en" className={classes.margin} />
            OMR
          </>
        );
      case "QAR":
        return (
          <>
            <img src={qatar} alt="en" className={classes.margin} />
            QAR
          </>
        );
      default:
    }
  };

  const headerRed = useRef();
  useEffect(() => {
    setTimeout(function () {
      setHeaderHeight(headerRed.current.offsetHeight);
    }, 1000);
  }, [setHeaderHeight]);

  const vipModalclose = () => {
    setVipModalOpen(false);
  };
  const vipMsgclose = () => {
    localStorage.setItem("ishro_vip_msg", "false");
    handleVip(false);
    setVipMsgOpen(false);
  };

  const performLogin = (event) => {
    setVipMsgOpen(false);
    dispatch(loginDialog(event.currentTarget));
  };

  return (
    <div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="panelcolor"
        ref={headerRed}
      >
        <Hidden smUp>
          <Grid
            item
            md={12}
            xs={12}
            style={{
              display: "inline-flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "8px 7.2%",
              backgroundColor: "rgb(210, 218, 222)",
            }}
          >
            <Grid
              item
              md={9}
              xs={9}
              style={{
                display: "flex",
                flexDirection: "column",
                color: "#353434",
                textAlign: language === "en" ? "left" : "right",
                fontFamily: "Almarai-SemiBold",
              }}
            >
              <p>Ishro</p>
              <p
                style={{
                  fontSize: "10px",
                  paddingTop: "2%",
                }}
              >
                {t("Use the app for the better experience")}
              </p>
            </Grid>
            <Grid
              item
              md={3}
              xs={3}
              style={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Fab
                variant="extended"
                size="small"
                aria-label="add"
                style={{
                  padding: "0px  10px",
                  fontFamily: "Almarai-Bold",
                  backgroundColor: "#D2DADE",
                  borderRadius: "6px",
                  fontSize: "12px",
                  boxShadow: "none",
                  border: "1px solid black",
                  lineHeight: "1",
                }}
                href={
                  androidPlatform
                    ? "https://play.google.com/store/apps/details?id=com.ishro&hl=en"
                    : "https://apps.apple.com/ae/app/ishro/id1553872493"
                }
              >
                {t("Use App")}
              </Fab>
            </Grid>
          </Grid>
        </Hidden>
        <Grid item md={5} xs={6} className={classes.iconDiv}>
          <a href="/">
            <img
              src={user?.switched !== "1" ? logo : logo_vip}
              alt="ishro logo"
            />
          </a>

          <Hidden mdDown>
            <ButtonGroup
              size="large"
              color="primary"
              aria-label="large  secondary button group"
              style={{ paddingTop: "10px" }}
            >
              <Button className={classes.btnGroup} href={`/#promotionDiv`}>
                {t("Promotion")}
              </Button>
              <Button className={classes.btnGroup} href={`/products`}>
                {t("Products")}
              </Button>
              <Button className={classes.btnGroup} href={"/news"}>
                {t("News")}
              </Button>
              <Button
                className={classes.btnGroup}
                href={`/winner/details/${winnerId}`}
              >
                {t("winners")}
              </Button>
            </ButtonGroup>
          </Hidden>
        </Grid>
        <Grid
          item
          md={6}
          xs={6}
          style={{
            display: "inline-flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Switch
            checked={VipCheck}
            onChange={(e) => handleVip(e)}
            offColor="#24CDE6"
            onColor="#B1A773"
            height={35}
            width={80}
            handleDiameter={28}
            checkedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 15,
                  color: "white",
                  paddingRight: 2,
                  fontFamily: "Almarai-SemiBold",
                }}
              >
                VIP
              </div>
            }
            uncheckedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 15,
                  color: "black",
                  paddingleft: 19,
                  fontFamily: "Almarai-SemiBold",
                }}
              >
                Ishro
              </div>
            }
            checkedHandleIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 20,
                }}
              >
                <img src={crown} />
              </div>
            }
            className="react-switch"
            id="small-radius-switch"
          />
          <Hidden mdDown>
            <Link to="/favourites">
              <IconButton className={classes.margin}>
                <Favorite />
              </IconButton>
            </Link>
            <Link to="/cart">
              <IconButton className={classes.margin}>
                <ShoppingCart />
              </IconButton>
            </Link>
          </Hidden>
          <Hidden mdUp>
            <Link to="/menu">
              <IconButton className={classes.margin}>
                <Menu />
              </IconButton>
            </Link>
          </Hidden>
          <Hidden smDown>
            <Button
              variant="contained"
              color="primary"
              // startIcon={<Language color="secondary" />}
              className={classes.margin}
              endIcon={<ArrowDropDownIcon />}
              onClick={handleCurrency}
              ref={currencyAnchor}
            >
              {getSelectedCurrency()}
            </Button>
            <Popover
              id={id}
              open={currencyopen}
              anchorEl={currencyAnchor}
              onClose={handleCurrencyClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <MenuList
                style={{
                  backgroundColor: "#353434",
                  color: "white",
                  width: languageSelector.current,
                }}
                autoFocusItem={langopen}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                <MenuItem onClick={() => setCurrency("AED")}>
                  <img src={uae} alt="en" className={classes.margin} />
                  AED
                </MenuItem>
                <MenuItem onClick={() => setCurrency("USD")}>
                  <img src={usa} alt="en" className={classes.margin} />
                  USD
                </MenuItem>
                <MenuItem onClick={() => setCurrency("BHD")}>
                  <img src={bahrain} alt="en" className={classes.margin} />
                  BHD
                </MenuItem>
                <MenuItem onClick={() => setCurrency("EUR")}>
                  <img src={europe} alt="en" className={classes.margin} />
                  EUR
                </MenuItem>
                <MenuItem onClick={() => setCurrency("INR")}>
                  <img src={india} alt="en" className={classes.margin} />
                  INR
                </MenuItem>
                <MenuItem onClick={() => setCurrency("SAR")}>
                  <img src={ksa} alt="en" className={classes.margin} />
                  SAR
                </MenuItem>
                <MenuItem onClick={() => setCurrency("KWD")}>
                  <img src={kuwait} alt="en" className={classes.margin} />
                  KWD
                </MenuItem>
                <MenuItem onClick={() => setCurrency("OMR")}>
                  <img src={oman} alt="en" className={classes.margin} />
                  OMR
                </MenuItem>
                <MenuItem onClick={() => setCurrency("QAR")}>
                  <img src={qatar} alt="en" className={classes.margin} />
                  QAR
                </MenuItem>
              </MenuList>
            </Popover>

            <Button
              variant="contained"
              color="primary"
              startIcon={<Language color="secondary" />}
              className={classes.margin}
              endIcon={<ArrowDropDownIcon />}
              onClick={handleLanguage}
              ref={languageSelector}
            >
              {getCurrentLanguage()}
            </Button>
            <Popover
              id={id}
              open={langopen}
              anchorEl={anchorEl}
              onClose={handleLangClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <MenuList
                style={{
                  backgroundColor: "#353434",
                  color: "white",
                  width: languageSelector.current,
                }}
                autoFocusItem={langopen}
                id="menu-list-grow"
                onKeyDown={handleListKeyDown}
              >
                <MenuItem onClick={() => setLanguage("en")}>
                  <img
                    src={user?.switched === "1" ? en_vip : en}
                    alt="en"
                    className={classes.margin}
                  />
                  English
                </MenuItem>
                <MenuItem onClick={() => setLanguage("ar")}>
                  <img
                    src={user?.switched === "1" ? ar_vip : ar}
                    alt="en"
                    className={classes.margin}
                  />
                  العربية
                </MenuItem>
              </MenuList>
            </Popover>

            {!isLoggedIn && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.loginbtn}
                onClick={handleLogin}
                id="loginbtn"
              >
                {t("login")}
              </Button>
            )}
            <Popover
              id={loginid}
              open={loginopen}
              className={language === "en" ? "dialogDiv" : "dialogDiv_ar"}
              anchorEl={loginDialogState}
              onClose={handleLoginClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Fade timeout={10}>
                {toggleForgotPassword == "0" ? (
                  <Login toggole={setToggleForgotPassword} />
                ) : toggleForgotPassword == "1" ? (
                  <ForgotPassword toggole={setToggleForgotPassword} />
                ) : (
                  <VerifyUser toggole={setToggleForgotPassword} />
                )}
              </Fade>
            </Popover>

            {isLoggedIn && (
              <Avatar>
                {`${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}`}
              </Avatar>
            )}
            <IconButton className={classes.margin} onClick={handleMenu}>
              <Menu />
            </IconButton>
            <Popover
              id={menuid}
              open={menuopen}
              anchorEl={menuDialogState}
              className={language === "en" ? "dialogDiv" : "dialogDiv_ar"}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <HeaderMenu />
            </Popover>
          </Hidden>
          <Hidden mdDown>
            <img src={memberimg2} alt="" className="hideimg" />
          </Hidden>
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={winnerOpen}
        onClose={handleClose}
        style={{ margin: "10px" }}
      >
        <WinnerDetails />
      </Dialog>
      <Modal
        open={vipModalOpen}
        onClose={vipModalclose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.vipModal}
      >
        <Grid item md={6} lg={4} sm={11} xs={11} className={classes.vipBox}>
          <img src={ishro_vip} alt="" className={classes.vipLogo} />
          <Typography className={classes.vipText}>{msg}</Typography>

          <Button
            className={classes.vipCloseBtn}
            onClick={() => setVipModalOpen(false)}
          >
            {t("CLOSE")}
          </Button>
        </Grid>
      </Modal>

      <Modal
        open={vipMsgOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.vipModal}
      >
        <Grid item md={6} lg={4} sm={11} xs={11} className={classes.vipBox1}>
          {vipstep == "0" ? (
            <>
              <img src={vipmsgimg} alt="" />
              <div className={classes.bottomRight}>
                <Typography className={classes.vipText1}>
                  {t("Welcome to ISHRO VIP.")}
                </Typography>
                <Typography className={classes.vipText}>
                  {t(
                    "Congratulations on having been invited to be one of our first exclusive members."
                  )}
                </Typography>

                <p className={classes.vipbtn}>
                  <Button
                    variant="text"
                    style={{
                      minWidth: "20px",
                      padding: "6px 0px",
                      color: "rgb(255 255 255 / 80%)",
                    }}
                    onClick={() => setVipStep("1")}
                  >
                    Skip
                  </Button>
                  <Button
                    className={classes.vipCloseBtn}
                    onClick={() => setVipStep("1")}
                  >
                    {t("NEXT")}
                  </Button>
                </p>
              </div>
            </>
          ) : vipstep == "1" ? (
            <>
              <img src={language === "en" ? vipFrameEn2 : vipFrameAr2} alt="" />

              <p
                style={{
                  position: "absolute",
                  top: "9%",
                  right: "16px",
                  width: "95%",
                }}
              >
                <Typography className={classes.vipText1}>
                  {t("VIP Membership")}
                </Typography>
                <Typography className={classes.vipText}>
                  {t(
                    "Shop for a chance to win exclusive promotions available only to our VIP members."
                  )}
                </Typography>
              </p>

              <p
                style={{
                  padding: "0% 4%",
                  justifyContent: "right",
                  display: "flex",
                  position: "absolute",
                  bottom: "6%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {!isLoggedIn ? (
                  <Button
                    className={classes.vipCloseBtn}
                    onClick={(e) => performLogin(e)}
                  >
                    {t("Login")}
                  </Button>
                ) : (
                  <Button
                    className={classes.vipCloseBtn}
                    onClick={() => vipMsgclose()}
                  >
                    {t("Finish")}
                  </Button>
                )}
              </p>
            </>
          ) : (
            ""
          )}
        </Grid>
      </Modal>
    </div>
  );
};
export default Header;

import { getUserCurrency, saveUserCurrency } from "../../services/authService";
import getCurrencyValue from "../../utils/getCurrencyValue";

const defaultState = getUserCurrency() ?? { label: "AED", value: 1 };
const currency = (state = defaultState, action) => {
  const { type, currency } = action;

  switch (type) {
    case "SET_CURRENCY":
      saveUserCurrency(currency);
      return currency;

    default:
      return state;
  }
};

export default currency;

const en = {
  translation: {
    Products: "Products",
    winners: "Winners",
    log: "LGIN",
    search: "Search…",
    fq: "Frequently Asked Questions and Answers Search",
    "Draw Date": "Draw Date",
    "Ticket Number": "Ticket Number",
    "You have no Favourites": "You have no Favourites",
    "What are you waiting for ?": "What are you waiting for ?",
    "Add to Cart": "Add to Cart",

    about_us_text:
      "At Ishro, We invite you to discover one-of-a-kind products coupled with amazing promotions. Each purchase qualifies you for a giveaway to win a high value prize!. A complimentary coupon to win valuable prizes is included every time. Giveaways range from Gold, Cars, Electronics and Lifestyle goods. Our business was shaped to deliver a variety of the products you love. Beyond great collections, we give you a chance to be a winner.",
    mission_text:
      "To touch people’s lives, one product at a time! At Ishro, we know you have a choice when it comes to where you shop. That is why we value you for trusting us. We strive to curate a premier selection of amazing prizes that will set your shopping experience apart from any other.",
    vision_text:
      "To spread joy through winning opportunities, rewards and personalized offers. Winning is one of the greatest feelings. We are devoted to capturing that essence. We incentivize our customers by doubling the ticket entry into a promotion draw once they give away their purchase to one of our charity partners.",
  },
};

export default en;

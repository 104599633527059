import { Types } from "../actions/actionTypes";

const initialState = {
  products: [],
  productCategories: [],
};

const products = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_PRODUCTS:
      return {
        ...state,
        products: payload.products,
      };
    case Types.CLEAR_PRODUCTS:
      return {
        ...state,
        products: [],
      };
    case Types.SET_PRODUCT_CATEGORIES:
      return {
        ...state,
        productCategories: payload.categories,
      };
    case Types.CLEAR_PRODUCT_CATEGORIES:
      return {
        ...state,
        productCategories: [],
      };
    default:
      return state;
  }
};

export default products;

import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CurrencyFormat from "react-currency-format";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  item: {
    margin: "7.5px 0px",
  },
}));
function Checkout_cartItem({ product, qty = false, price }) {
  const classes = useStyles();
  const currency = useSelector((state) => state?.currency);
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      style={{ padding: "0% 5%" }}
    >
      <Typography variant="body2" className={classes.item}>
        {qty && `${qty}  x`} {`${product}`}
      </Typography>
      <Typography variant="body2" component="div" className={classes.item}>
        <CurrencyFormat
          value={price}
          displayType={"text"}
          thousandSeparator={true}
          prefix={(currency.label ? currency.label : "AED") + " "}
          renderText={(value) => <div>{value}</div>}
        />
      </Typography>
    </Box>
  );
}

export default Checkout_cartItem;

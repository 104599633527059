import i18next from "i18next";
import { create } from "jss";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import rtl from "jss-rtl";
import { createTheme, responsiveFontSizes } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core";
import { ReCaptchaProvider } from "react-recaptcha-x";
import shadows from "../src/assets/config/theme/shadows";
import typography from "../src/assets/config/theme/typography";
import getDiraction from "./utils/getDiraction";

const preset = create({
  plugins: [...jssPreset().plugins, rtl()],
});

export default function Jss(props) {
  const language = useSelector((state) => state.language);
  const isVip = useSelector((state) => state?.user?.user?.switched) ?? "0";
  const userLevel =
    useSelector((state) => state?.user?.user?.user_level) ?? "normal";

  useEffect(() => {
    document.body.dir = getDiraction(language);
    i18next.changeLanguage(language);
  }, [language]);

  const secondaryColor = "#24CDE6";
  const primaryColor = "#353434";
  const lightgray = "#434343";
  const darkgray = "#363636";
  const accentBlue = "#0077BE";
  const secondaryColor_vip = "#B1A773";
  const avtarColor =
    userLevel == "Bronze"
      ? "#AD875C"
      : userLevel == "Silver"
      ? "#D3D9DD"
      : userLevel == "Gold"
      ? "#E8DD4F"
      : userLevel == "Vip"
      ? "#B1A773"
      : "#24CDE6";

  const theme = createTheme({
    direction: getDiraction(language),
    palette: {
      background: {
        dark: "#E2E2E3",
        default: "#303030",
        paper: "#353434",
        light: "#EBEBEB",
        lightgray: lightgray,
        blue: accentBlue,
        vip: secondaryColor_vip,
      },
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
      text: {
        primary: "#24CDE6",
        secondary: "#FFFFFF",
        black: "#353434",
        darkgray: darkgray,
      },
    },
    shadows,
    typography,
    overrides: {
      MuiOutlinedInput: {
        root: {
          backgroundColor: lightgray,
          borderRadius: "16px",
          color: "#FFFFFF",
          marginBottom: "10px",
        },
      },
      MuiTextField: {
        root: {
          backgroundColor: lightgray,
          borderRadius: "8px",
          color: "#FFFFFF",
          marginTop: "16px",
          padding: "10px 15px",
        },
        input: {
          "&::placeholder": {
            color: "white",
          },
        },
      },
      MuiInput: {
        input: {
          color: "white",
          "&::placeholder": {
            color: "white",
          },
        },
      },
      MuiCheckbox: {
        root: {
          color: "#24CDE6 !important",
        },
        colorPrimary: {
          checked: {
            color: "#24CDE6",
          },
        },
      },
      MuiButton: {
        contained: {
          borderRadius: "8px",
          fontFamily: "Almarai-SemiBold",
          backgroundColor: secondaryColor,
          color: "#353434",
          "&:hover": {
            backgroundColor: secondaryColor,
          },
        },
      },
      MuiCard: {
        root: {
          backgroundColor: lightgray,
          padding: "20px",
          borderRadius: "22px",
        },
      },
      MuiAvatar: {
        root: {
          color: "white !important",
          textTransform: "uppercase",
        },
        colorDefault: {
          backgroundColor: primaryColor,
        },
        circular: {
          border: `3px solid ${avtarColor}}`,
        },
      },
    },
  });

  const theme_vip = createTheme({
    direction: getDiraction(language),
    palette: {
      background: {
        dark: "#E2E2E3",
        default: "#303030",
        paper: "#353434",
        light: "#EBEBEB",
        lightgray: lightgray,
        blue: accentBlue,
      },
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor_vip,
      },
      text: {
        primary: "#B1A773",
        secondary: "#FFFFFF",
        black: "#353434",
        darkgray: darkgray,
      },
    },
    shadows,
    typography,
    overrides: {
      MuiOutlinedInput: {
        root: {
          backgroundColor: lightgray,
          borderRadius: "8px",
          color: "#FFFFFF",
          marginBottom: "10px",
          fontFamily: "Almarai-SemiBold",
        },
      },
      MuiTextField: {
        root: {
          backgroundColor: lightgray,
          borderRadius: "16px",
          color: "#FFFFFF",
          marginBottom: "10px",
          padding: "10px 15px",
        },
      },
      MuiFilledInput: {
        input: {
          color: "white",
          "&::placeholder": {
            color: "white",
          },
        },
      },
      MuiInput: {
        input: {
          color: "white",
          "&::placeholder": {
            color: "white",
          },
        },
      },
      MuiCheckbox: {
        root: {
          color: "#24CDE6",
        },
        colorPrimary: {
          checked: {
            color: "#24CDE6",
          },
        },
      },
      MuiButton: {
        contained: {
          borderRadius: "8px",
          fontFamily: "Almarai-SemiBold",
          backgroundImage: "linear-gradient(180deg, #605D41,#ADA16F)",
          color: primaryColor,
          "&:hover": {
            backgroundColor: secondaryColor_vip,
          },
        },
      },
      MuiCard: {
        root: {
          backgroundColor: lightgray,
          padding: "20px",
          borderRadius: "22px",
        },
      },
      MuiAvatar: {
        root: {
          color: "white !important",
          textTransform: "uppercase",
        },
        colorDefault: {
          backgroundColor: primaryColor,
        },
        circular: {
          border: `3px solid ${secondaryColor_vip}}`,
        },
      },
    },
  });
  return (
    <StylesProvider jss={preset}>
      <ThemeProvider
        theme={responsiveFontSizes(isVip === "0" ? theme : theme_vip)}
      >
        <ReCaptchaProvider
          siteKeyV2="6LfmP4McAAAAAFJpIqQ9x2sAsirgUhmTYfMFrF9Y"
          langCode={language ?? "en"}
          hideV3Badge={false}
        >
          {props.children}
        </ReCaptchaProvider>
      </ThemeProvider>
    </StylesProvider>
  );
}

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    padding: "5%  0px",
  },
  title1: {
    fontSize: "30px",
    fontFamily: "Almarai-Bold",
    padding: "2% 1%",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: { fontSize: "23px", alignItems: "center" },
  },
}));

import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";
import axios from "../../../../services/axios";

const useStyles = makeStyles({
  "&.MuiTextField": {
    borderRadius: 16,
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
    "& .MuiInput-underline::before": {
      borderBottom: "none",
    },
  },
});

export default function CountrySelect({
  onChange,
  placeholder = null,
  width = "93%",
  value,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [countValue, setValue] = React.useState("");
  useEffect(() => {
    axios.get("countries").then((res) => {
      if (res?.data?.success) {
        setCountries(res.data.data);
        setValue(
          res.data.data.find((country) => country.countryname === value)
        );
      } else {
      }
    });
  }, [value]);

  return (
    <Autocomplete
      value={countValue}
      disableClearable
      name="country"
      underlineShow={false}
      id="country-select-demo"
      style={{ width: width }}
      options={countries}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      onChange={(e, v) => {
        setValue(v);
        onChange({ target: { name: "country", value: v.countryname } });
      }}
      getOptionLabel={(option) => option.countryname}
      renderOption={(option) => (
        <React.Fragment>
          <img
            loading="lazy"
            width="25"
            src={option.icon}
            srcSet={option.icon}
            alt=""
            style={{ marginRight: "2%" }}
          />
          {option.countryname} ({option.code})
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          style={{ borderRadius: "16px" }}
          InputProps={{
            ...params.InputProps,
            autoComplete: "new-password",
            disableUnderline: true,
          }}
          placeholder={placeholder ?? t("Choose Country")}
        />
      )}
    />
  );
}

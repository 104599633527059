import { Types } from "../actions/actionTypes";

const initialState = {
    ads: []
};

const ads = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_ADS:
      return {
        ...state,
        ads: payload.ads,
      };
    case Types.CLEAR_ADS:
      return {
        ...state,
        ads: [],
      };
    default:
      return state;
  }
};

export default ads;

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    height: "80vh",
  },

  card: {
    padding: "20px 30px 30px 30px",
    borderRadius: "30px",
    backgroundColor: "#343434",
    margin: "20px",
    width: "100%",
  },
  title: {
    fontFamily: "Almarai-SemiBold",
    fontWeight: "700",
    fontSize: "24px",
    color: theme.palette.secondary.main,
    textAlign: "center",
    margin: "10px 0px 30px 0px",
  },
  input: {
    color: "white",
    marginBottom: "20px",
  },
}));

export const Types = {
  SET_USER_DATA: "SET_USER_DATA",
  CLEAR_USER_DATA: "CLEAR_USER_DATA",
  SET_LOADING_STATUS: "SET_LOADING_STATUS",
  SET_MENU_STATUS: "SET_MENU_STATUS",
  SET_LOGIN_STATUS: "SET_LOGIN_STATUS",

  // Load Banners -> Load Banners
  SET_BANNERS: "SET_BANNERS",
  CLEAR_BANNERS: "CLEAR_BANNERS",
  // Load Ads -> Load Ads
  SET_ADS: "SET_ADS",
  CLEAR_ADS: "CLEAR_ADS",
  SET_SECTIONS: "SET_SECTIONS",
  CLEAR_SECTIONS: "CLEAR_SECTIONS",
  // Load Promotions -> Load Promotions
  SET_PROMOTIONS: "SET_PROMOTIONS",
  CLEAR_PROMOTIONS: "CLEAR_PROMOTIONS",
  // Load Promotions Categories -> Load Promotions Categories
  SET_PROMOTION_CATEGORIES: "SET_PROMOTION_CATEGORIES",
  CLEAR_PROMOTION_CATEGORIES: "CLEAR_PROMOTION_CATEGORIES",
  SET_PROMOTIONS_WINNERS: "SET_PROMOTIONS_WINNERS",
  CLEAR_PROMOTION_WINNERS: "CLEAR_PROMOTION_WINNERS",
  SET_HIGHLIGHT_PROMOTIONS: "SET_HIGHLIGHT_PROMOTIONS",
  CLEAR_HIGHLIGHT_PROMOTIONS: "CLEAR_HIGHLIGHT_PROMOTIONS",
  SET_WINNERS_DETAILS: "SET_WINNERS_DETAILS",
  CLEAR_WINNERS_DETAILS: "CLEAR_WINNERS_DETAILS",
  SET_SOLD_OUT_PROMOTIONS: "SET_SOLD_OUT_PROMOTIONS",
  CLEAR_SOLD_OUT_PROMOTIONS: "CLEAR_SOLD_OUT_PROMOTIONS",
  SET_UPCOMING_PROMOTIONS: "SET_UPCOMING_PROMOTIONS",
  CLEAR_UPCOMING_PROMOTIONS: "CLEAR_UPCOMING_PROMOTIONS",
  // Load Carts -> Load Carts
  SET_CARTS: "SET_CARTS",
  CLEAR_CARTS: "CLEAR_CARTS",
  // Load Summary -> Load Summary
  SET_SUMMARY: "SET_SUMMARY",
  CLEAR_SUMMARY: "CLEAR_SUMMARY",
  // Load My Tickets -> Load My Tickets
  SET_MY_TICKETS: "SET_MY_TICKETS",
  CLEAR_MY_TICKETS: "CLEAR_MY_TICKETS",
  // Load Purchase -> Load Purchase
  SET_PURCHASE: "SET_PURCHASE",
  CLEAR_PURCHASE: "CLEAR_PURCHASE",
  // Load Favourites -> Load Favourites
  SET_FAVOURITES: "SET_FAVOURITES",
  CLEAR_FAVOURITES: "CLEAR_FAVOURITES",
  // Load Products -> Load Products
  SET_PRODUCTS: "SET_PRODUCTS",
  CLEAR_PRODUCTS: "CLEAR_PRODUCTS",
  SET_PRODUCT_CATEGORIES: "SET_PRODUCT_CATEGORIES",
  CLEAR_PRODUCT_CATEGORIES: "CLEAR_PRODUCT_CATEGORIES",
  // Load News -> Load News
  SET_NEWS: "SET_NEWS",
  CLEAR_NEWS: "CLEAR_NEWS",
  // Load News -> Load News
  SET_FAQ: "SET_FAQ",
  CLEAR_FAQ: "CLEAR_FAQ",
  // Set Language -> Set Language
  SET_LANGUAGE: "SET_LANGUAGE",
  CLEAR_LANGUAGE: "CLEAR_LANGUAGE",
};

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  checkout: {
    backgroundColor: theme.palette.background.light,
    padding: "8% 20px 13% 20px",
    color: "black",
    "& .MuiCard-root": {
      margin: "20px 0px",
      color: "#FFFFFF",
    },
  },
  title: { fontFamily: "Almarai-Bold", fontSize: "30px", marginBottom: "5%" },
  selectorBtn: {
    margin: "10px 0px",
    color: "white",
    width: "35%",
    backgroundImage: "linear-gradient(180deg, #0077BE,#0077BE)",
  },
  notselectorBtn: {
    margin: "10px 0px",
    color: "white",
    width: "35%",
    backgroundImage: "linear-gradient(180deg, #5E5E5E,#5E5E5E)",
  },
  leftDevider: {
    transform: "translateX(-10px)",
    borderStart: "2px solid #DDD",
    padding: "0px 10px",
  },
  buttonsBox: {
    margin: "0px",
  },
  checkout_input: {
    background: "white",
    height: "58px",
    borderRadius: "16px",
    padding: "9px 16px",
    color: "white",
    marginBottom: "12px",
    "&::placeholder": {
      color: "white",
    },
  },
  accordion: {
    marginBottom: "15px",
    borderRadius: "4px !important",
    padding: "0%",
  },
  link: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  loginBtn: {
    width: "100%",
    marginBottom: "5%",
  },
  payBtn: {
    height: "45px",
    borderRadius: "4px",
  },
  payWithCard: {
    height: "45px",
    borderRadius: "4px !important",
  },
  payWithguest: {
    height: "45px",
    backgroundColor: "#434343",
    borderRadius: "4px !important",
  },
  googlePayBtn: {
    width: "100%",
    marginBottom: "15px",
    "& .gpay-card-info-container": {
      width: "100%",
      height: "45px",
      borderRadius: "4px",
    },
    "& .gpay-card-info-container.white": {
      boxShadow: "none",
    },
  },
  cardPayBtn: {
    height: "45px",
    fontFamily: "Almarai-SemiBold",
    fontSize: "18px",
    borderRadius: "4px",
  },
  discounttext: {
    fontFamily: "Almarai-SemiBold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

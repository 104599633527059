import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FilledInput from "@material-ui/core/OutlinedInput";
import useStyle from "./style";
import emailIcon from "../../../../src/assets/image/email.svg";
import loginDialog from "../../../store/actions/loginDialog";
import { verifyOtp } from "../../../store/actions";
import loading from "../../../store/actions/loading";
import * as actionCreators from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import phone from "../../../../src/assets/image/phone.svg";
import OtpInput from "react-otp-input";
import { changePassword } from "../../../store/actions/auth";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function ForgotPassword({ toggole = "0" }) {
  const classes = useStyle();
  const language = useSelector((state) => state.language);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [mobile, setMobile] = React.useState();
  const [countryCode, setCountryCode] = React.useState();
  const [page, setPage] = React.useState(0);
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmNewPassword, setConfirmNewPassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const handleForgetPassword = () => {
    dispatch(loading(true));
    dispatch(loading());
  };
  const forgotPasswordHandler = () => {
    if (!mobile) toast(t("Please fill the Mobile number"));
    if (mobile) {
      dispatch(
        actionCreators.forgotPassword({ mobile, country_code: countryCode })
      )
        .then((msg) => {
          setPage(1);
          toast(msg);
        })
        .catch((error) => {
          toast(error);
          dispatch(loading());
        });
    }
  };
  const handleVerification = (otp_code) => {
    let data = { otp_code: code, mobile };
    dispatch(verifyOtp(data))
      .then((res) => {
        setPage(2);
      })
      .catch((err) => console.error(err));
  };

  const handleResetPassword = () => {
    // FIXME show notification error when there is error
    if (!newPassword || !confirmNewPassword) {
      toast(t("Please fill the required fields"));
      return;
    }
    if (newPassword !== confirmNewPassword) {
      toast(t("password confirmation does not match your new password"));
      return;
    }
    dispatch(loading(true));
    dispatch(changePassword({ old_password: "", new_password: newPassword }))
      .then((res) => {
        dispatch(loginDialog(null));
        if (isMobile) {
          navigate("/login");
        } else {
          toggole("0");
        }
        if (res.success) {
          toast(t("password change successfully"));
        }
        dispatch(loading());
        console.log(res);
      })
      .catch((error) => {
        toast(t("error, please try again"));
        dispatch(loading());
      });
  };
  return (
    <Grid container justifyContent="center">
      <Grid item md={12} className={classes.container}>
        <div className={classes.header}>
          <ArrowBackIosIcon
            className={classes.icon}
            style={
              language === "ar"
                ? { transform: "rotate(180deg)", cursor: "pointer" }
                : { cursor: "pointer" }
            }
            fontSize={"small"}
            onClick={() => {
              isMobile ? navigate("/login") : toggole("0");
            }}
          />
          <Typography variant="h4" component="h4" className={classes.title}>
            {t("Forgot password")}
          </Typography>
          <Typography variant="h4" component="h4"></Typography>
        </div>
        <Grid container justifyContent="center">
          <img src={phone} className={classes.emailIcon} alt="" />
          {page == 0 ? (
            <>
              <Typography variant="body2" gutterBottom className={classes.body}>
                {t(
                  "If you've lost your password or wish to reset it, enter your Mobile number to get started."
                )}
              </Typography>
              <PhoneInput
                country={"ae"}
                containerStyle={{
                  marginBottom: "20px",
                }}
                inputClass={classes.phoneInput}
                buttonClass={classes.buttonClass}
                dropdownClass={classes.dropdownClass}
                onChange={(value, country, e, formattedValue) => {
                  setMobile(value.replace(country.dialCode, ""));
                  setCountryCode(country.dialCode);
                }}
              />
              <Button
                variant="contained"
                className={classes.loginBtn}
                onClick={forgotPasswordHandler}
              >
                {t("submit")}
              </Button>
            </>
          ) : page == 1 ? (
            <>
              <OtpInput
                value={code}
                style={{ marginTop: "10px" }}
                onChange={(val) => setCode(val)}
                numInputs={6}
                inputStyle={{
                  height: "40px",
                  width: "40px",
                  margin: "10px",
                  backgroundColor: "#434343",
                  borderRadius: "8px",
                  color: "white",
                }}
              />
              <Button
                variant="contained"
                fullWidth
                onClick={handleVerification}
              >
                {t("Verify")}
              </Button>
            </>
          ) : (
            <>
              <FilledInput
                fullWidth
                placeholder={t("New Password")}
                className={classes.emailInput}
                type={showPassword ? "text" : "password"}
                onChange={(e) => setNewPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      style={{ color: "white" }}
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FilledInput
                fullWidth
                placeholder={t("Re-enter New Password")}
                className={classes.emailInput}
                type={showPassword ? "text" : "password"}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      style={{ color: "white" }}
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Button
                variant="contained"
                fullWidth
                onClick={handleResetPassword}
              >
                {t("submit")}
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Grid>
  );
}

export default ForgotPassword;

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    backgroundColor: "#353434",
    padding: "20px",
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "0px 0px 20px 20px",
    maxWidth: "400px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0px",
      padding: "17% 4% 20px",
      maxWidth: "100%",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  emailIcon: {
    width: "18%",
    marginBottom: "20px",
  },

  inputFeild: {
    width: "100%",
  },
  body: {
    marginBottom: "20px",
  },
  loginBtn: {
    marginBottom: "1rem",
    width: "100%",
  },
  title: {
    textTransform: "uppercase",
    padding: "6% 0px",
    fontFamily: "Almarai-SemiBold",
    fontSize: "20px",
  },
  phoneInput: {
    backgroundColor: `${theme.palette.background.lightgray} !important;`,
    border: "none !important",
    color: "white",
    width: "100% !important",
  },
  buttonClass: {
    backgroundColor: `${theme.palette.background.lightgray} !important;`,
    borderRadius: "8px !important",
    border: "none !important",
    "&:hover": {
      backgroundColor: `${theme.palette.background.lightgray} !important;`,
    },
  },
  dropdownClass: {
    backgroundColor: `${theme.palette.background.lightgray} !important;`,
    width: "362px !important",
    maxHeight: "265px !important",
    bottom: "47px !important",
  },
}));

import React from "react";
import Grid from "@material-ui/core/Grid";
import { Box, Divider, Typography, Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useStyles from "./style";
import profile from "../../../assets/image/level/profile.svg";
import redBadge from "../../../assets/image/level/badge_red.svg";
import goldBadge from "../../../assets/image/level/badge_yellow.svg";
import gryBadge from "../../../assets/image/level/badge_gry.svg";
import CircularProgressBar from "../../common/CircularProgressBar";
import arrows from "../../../assets/image/level/arrows.svg";

// function Levels() {
//     const classes = useStyles();
// 	   const {t} = useTranslation();

//     return (
//         <Grid container justifyContent="center" alignItems="center" className={classes.container}>
// 					<Grid container justifyContent="center" md={8} xs={12} className={classes.panel}>

function Levels() {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user?.user);
  const language = useSelector((state) => state.language);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.container}
    >
      <Grid
        container
        justifyContent="center"
        md={8}
        xs={12}
        className={classes.panel}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {/* <img src={profile} /> */}
          <Avatar className={classes.avatar}>
            {`${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}`}
          </Avatar>
          <Typography className={classes.username}>
            {`${user?.first_name} ${user?.last_name}`}
          </Typography>
          <Typography
            className={`${classes.userLevel} ${
              user.user_level === "Bronze"
                ? classes.bronze
                : user.user_level === "Gold"
                ? classes.gold
                : classes.silver
            }`}
          >
            {`${
              user.user_level === "Bronze"
                ? t("Bronze member")
                : user.user_level === "Gold"
                ? t("Gold member")
                : user.user_level
            }`}
          </Typography>
        </Box>

        <Grid item md={12}>
          <Divider
            light
            style={{ backgroundColor: "rgba(112, 112, 112, 0.2)" }}
          />
        </Grid>

        <Grid container justifyContent="center" flexDirection="column" md={12}>
          <Typography className={classes.text}>
            {t("Level Benefits")}
          </Typography>

          <Grid
            container
            md={12}
            justifyContent="space-evenly"
            alignItems="center"
            style={{ marginBottom: "57px" }}
            className={classes.Progressbox}
          >
            <CircularProgressBar
              type="bronze"
              user_spent_amount={user.user_business}
              userType={user.user_level}
              notYet={false}
            />

            <img
              src={arrows}
              alt="arrow"
              className={classes.arrow}
              style={language === "ar" ? { transform: "rotate(180deg)" } : {}}
              // className={className.arrow}
            />

            <Typography style={{ color: "white" }}>{` AED 5,000 `}</Typography>

            <img
              src={arrows}
              alt="arrow"
              className={classes.arrow}
              style={language === "ar" ? { transform: "rotate(180deg)" } : {}}
            />

            <CircularProgressBar
              type="silver"
              user_spent_amount={user.user_business}
              userType={user.user_level}
              notYet={
                user.user_level !== "Gold" && user.user_level !== "Silver"
                  ? true
                  : false
              }
            />

            <img
              src={arrows}
              alt="arrow"
              className={classes.arrow}
              style={language === "ar" ? { transform: "rotate(180deg)" } : {}}
            />

            <Typography style={{ color: "white" }}>{` AED 10,000 `}</Typography>

            <img
              src={arrows}
              alt="arrow"
              className={classes.arrow}
              style={language === "ar" ? { transform: "rotate(180deg)" } : {}}
            />

            <CircularProgressBar
              type="gold"
              user_spent_amount={user.user_business}
              userType={user.user_level}
              notYet={
                user.user_level === "Silver" || user.user_level === "Bronze"
                  ? true
                  : false
              }
            />
          </Grid>

          <Grid
            container
            md={12}
            justifyContent="space-between"
            flexDirection="row"
          >
            <Grid item md={4} xs={12}>
              <Box className={classes.levelDetails}>
                <Typography
                  style={{ fontSize: "22px", fontFamily: "Almarai-SemiBold" }}
                >
                  {t("Bronze member")}
                </Typography>
                <Box
                  style={{
                    width: "25px",
                    height: "3px",
                    backgroundColor: "#353434",
                  }}
                ></Box>
                <Typography
                  style={{ fontSize: "14px", fontFamily: "Almarai-SemiBold" }}
                >
                  {t("Benefits")}
                </Typography>
                <Typography
                  style={{ fontSize: "16px", fontFamily: "Almarai-SemiBold" }}
                  className={classes.badgeDescription}
                >
                  {t("Get back 0.5% of your purchase in Ishroins")}
                </Typography>

                <img src={redBadge} className={classes.badge} />
              </Box>
            </Grid>

            <Grid item md={4} xs={12}>
              <Box className={classes.levelDetails}>
                <Typography
                  style={{ fontSize: "22px", fontFamily: "Almarai-SemiBold" }}
                >
                  {t("Silver member")}
                </Typography>
                <Box
                  style={{
                    width: "25px",
                    height: "3px",
                    backgroundColor: "#353434",
                  }}
                ></Box>
                <Typography
                  style={{ fontSize: "14px", fontFamily: "Almarai-SemiBold" }}
                >
                  {t("Benefits")}
                </Typography>

                <Typography
                  style={{ fontSize: "16px", fontFamily: "Almarai-SemiBold" }}
                  className={classes.badgeDescription}
                >
                  {t("Get back 1.0% of your purchase in Ishroins")}
                </Typography>

                <img src={gryBadge} className={classes.badge} />
              </Box>
            </Grid>

            <Grid item md={4} xs={12}>
              <Box className={classes.levelDetails}>
                <Typography
                  style={{ fontSize: "22px", fontFamily: "Almarai-SemiBold" }}
                >
                  {t("Gold member")}{" "}
                </Typography>
                <Box
                  style={{
                    width: "25px",
                    height: "3px",
                    backgroundColor: "#353434",
                  }}
                ></Box>
                <Typography
                  style={{ fontSize: "14px", fontFamily: "Almarai-SemiBold" }}
                >
                  {t("Benefits")}
                </Typography>

                <Typography
                  style={{ fontSize: "16px", fontFamily: "Almarai-SemiBold" }}
                  className={classes.badgeDescription}
                >
                  {t("Get back 1.5% of your purchase in Ishroins")}
                </Typography>

                <img src={goldBadge} className={classes.badge} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Levels;

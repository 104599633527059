import { Types } from "../actions/actionTypes";

const initialState = {
  carts: [],
  summary: [],
  purchase: [],
};

const carts = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_CARTS:
      return {
        ...state,
        carts: payload.carts,
      };
    case Types.SET_SUMMARY:
      return {
        ...state,
        summary: payload.summary,
      };
    case Types.SET_PURCHASE:
      return {
        ...state,
        purchase: payload.purchase,
      };
    case Types.CLEAR_CARTS:
      return {
        ...state,
        carts: [],
      };
    case Types.CLEAR_SUMMARY:
      return {
        ...state,
        summary: [],
      };
    case Types.CLEAR_PURCHASE:
      return {
        ...state,
        purchase: [],
      };
    default:
      return state;
  }
};

export default carts;

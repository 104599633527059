import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ForgotPassword from "./components/auth/forgot_password/ForgotPassword.jsx";
import Login from "./components/auth/login/Login.js";
import VerifyUser from "./components/auth/login/VerifyUser.jsx";
import Registration from "./components/auth/registration/Registration";
import Guest from "./components/auth/guest/Guest";
import Otp from "./components/auth/otp/Otp";
import DefaultLayout from "./components/pages/defaultLayout.jsx";
import Home from "./components/pages/home/home.jsx";
import App from "./components/pages/home/App.jsx";
import Vip from "./components/pages/home/Vip.jsx";
import WinnerDetails from "./components/pages/winner/WinnerDetails";
import Profile from "./components/pages/profile/Profile";
import Ishroins from "./components/pages/ishroins/Ishroins";
import PromotionDetails from "./components/pages/promotion/PromotionDetails";
import Products from "./components/pages/product/products";
import ContactUs from "./components/pages/contact_us/ContactUs";
import AboutUs from "./components/pages/about_us/AboutUs";
import Cart from "./components/pages/cart/Cart.jsx";
import NotFound from "./components/pages/errors/notFound/NotFound.jsx";
import Unexpected from "./components/pages/errors/unexpected/Unexpected.jsx";
import PaymentFailed from "./components/pages/errors/unexpected/PaymentFailed.jsx";
import Ticket from "./components/pages/ticket/Ticket";
import Favourites from "./components/pages/Favourites/Favourites";
import Support from "./components/pages/support/Support";
import News from "./components/pages/news/news";
import NewsPage from "./components/pages/news/NewsPage";
import PrivacyPolicy from "./components/pages/privacyPolicy/privacyPolicy";
import TermsAndConditions from "./components/pages/termsAndConditions/termsAndConditions";
import UserAgreement from "./components/pages/userAgreement/userAgreement";
import Reciept from "./components/pages/reciept/Reciept";
import Menu from "./components/menu/Menu";
import ChangePassword from "./components/pages/change_password/ChangePassword";
import { getUserData } from "./services/authService";
import Levels from "./components/pages/levels/Levels.jsx";

const routesList = (isLoggedIn) => [
  {
    // User is not login
    path: "/",
    element: <DefaultLayout />,
    children: [
      { path: "", element: <Home /> },
      // { path: "winner/details/:promotion_id", element: <WinnerDetails /> },
      // { path: "forgot_password", element: <ForgotPassword /> },
      // { path: "verifyUser", element: <VerifyUser /> },
      // { path: "register", element: <Registration /> },
      // { path: "guest", element: <Guest /> },
      // { path: "menu", element: <Menu /> },
      // { path: "support", element: <Support /> },
      // { path: "contactUs", element: <ContactUs /> },
      // { path: "aboutUs", element: <AboutUs /> },
      // { path: "unexpected", element: <Unexpected /> },
      // { path: "payment-failed", element: <PaymentFailed /> },
      // { path: "not_found", element: <NotFound /> },
      // { path: "news", element: <News /> },
      // { path: "news/:id", element: <NewsPage /> },
      // { path: "privacyPolicy", element: <PrivacyPolicy /> },
      // { path: "receipt", element: <Reciept /> },
      // { path: "termsAndConditions", element: <TermsAndConditions /> },
      // { path: "userAgreement", element: <UserAgreement /> },
      // {
      //   path: "promotion/details/:promotion_id",
      //   element: <PromotionDetails />,
      // },
    
    ],
  },
  // {
  //   path: "/",
  //   element: isLoggedIn ? <Navigate to="/" /> : <DefaultLayout />,
  //   children: [
  //     { path: "login", element: <Login /> },

  //     { path: "register", element: <Registration /> },
  //   ],
  // },
  {
    // User is login
    path: "/",
    element: isLoggedIn ? <DefaultLayout /> : <Navigate to="/" />,
    children: [
      // { path: "profile", element: <Profile /> },
      // { path: "ishroins", element: <Ishroins /> },
      // { path: "ticket", element: <Ticket /> },
      // { path: "level", element: <Levels /> },
      { path: "*", element: <Navigate to="/" /> },
    ],
  },
  // {
  //   path: "/v3/mobile/ar/",
  //   children: [
  //     {
  //       path: "termsAndConditions",
  //       element: <TermsAndConditions mobile={true} lang="ar" />,
  //     },
  //     {
  //       path: "privacyPolicy",
  //       element: <PrivacyPolicy mobile={true} lang="ar" />,
  //     },
  //     {
  //       path: "userAgreement",
  //       element: <UserAgreement mobile={true} lang="ar" />,
  //     },
  //     { path: "about_us", element: <AboutUs lang="ar" /> },
  //   ],
  // },
  // {
  //   path: "/v3/mobile/en/",
  //   children: [
  //     {
  //       path: "termsAndConditions",
  //       element: <TermsAndConditions mobile={true} lang="en" />,
  //     },
  //     {
  //       path: "privacyPolicy",
  //       element: <PrivacyPolicy mobile={true} lang="en" />,
  //     },
  //     {
  //       path: "userAgreement",
  //       element: <UserAgreement mobile={true} lang="en" />,
  //     },
  //     { path: "about_us", element: <AboutUs lang="en" /> },
  //   ],
  // },
  // { path: "/app", element: <App /> },
  // { path: "/vip", element: <Vip /> },
];

const Routes = () => {
  const user = useSelector((state) => state.user);
  const routing = useRoutes(routesList(user.isLoggedIn || getUserData()));
  return routing;
};

export default Routes;

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    minHeight: "70vh",
  },
  items: {
    margin: "30px 0px",
  },
  selectedBtn: {
    backgroundColor: theme.palette.background.blue,
  },
  button: {
    margin: "10px 0px",
  },
}));

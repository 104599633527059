import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as actionCreators from "../../../store/actions";
import {
  Box,
  Paper,
  makeStyles,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import "./home.css";
import Slider from "react-slick";
import { isValid, decode } from "js-base64";
import moment from "moment";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
const useStyles = makeStyles((theme) => ({
  closePaper: {
    borderRadius: "18px",
    padding: "20px",
    margin: "5px",
    cursor: "pointer",
    transition: "-webkit-transform .3s ease-in-out",
    transition: "transform .3s ease-in-out",
    transition: "transform .3s ease-in-out,-webkit-transform .3s ease-in-out",
    "&:hover": {
      WebkitTransform: "scale3d(1.03,1.03,1.03)",
      transform: "scale3d(1.03,1.03,1.03)",
    },
    [theme.breakpoints.down("sm")]: { margin: "10px" },
  },
  promobtn: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "16px",
    padding: "1.5%",
    textTransform: "uppercase",
    fontFamily: "Almarai-SemiBold",
    whiteSpace: "nowrap",
    color: theme.palette.text.black,
    width: "48%",
    margin: "2%",
  },
  promotitle: {
    fontFamily: "Almarai-SemiBold",
    color: theme.palette.text.primary,
    lineHeight: "1rem",
    paddingBottom: "2%",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    fontSize: "14px",
  },
  winnerp: { fontFamily: "Almarai-SemiBold" },
  winners: { color: theme.palette.secondary.main },
  promotick: {
    whiteSpace: "nowrap",
    width: "99%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "10px",
    color: theme.palette.text.secondary,
    fontFamily: "Almarai-SemiBold",
  },
  winnername: {
    whiteSpace: "nowrap",
    width: "99%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "16px",
    color: theme.palette.text.primary,
    fontFamily: "Almarai-SemiBold",
    paddingBottom: "3%",
    textTransform: "capitalize",
  },
  title: {
    fontSize: "30px",
    fontFamily: "Almarai-Bold",
    paddingBottom: "9%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "23px",
      alignItems: "center",
      padding: "4% 6% 0%",
    },
  },
}));

function WinnerList(props) {
  const classes = useStyles();
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [winnerId, setWinnerId] = useState();
  const promotionsWinners = useSelector(
    (state) => state.promotions.promotionswinners
  );

  const upcomingPromotion = useSelector(
    (state) => state.promotions.upcomingPromotion
  );

  var componentRef = useRef();
  const user = useSelector((state) => state.user?.user);
  useEffect(() => {
    dispatch(actionCreators.loadPromotionsWinner());
    dispatch(actionCreators.appInfo()).then((res) => {
      setWinnerId(res.recentWinner.ticket_id);
    });
  }, [user]);

  const loadWinner = (item) => {
    if (item?.promotion?.draw_video) {
      navigate(`winner/details/${item.ticket_id}`);
    }
  };

  var settings = {
    infinite: true,
    arrows: true,
    slidesToShow: upcomingPromotion?.length > 0 ? 7 : 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
  };
  function Arrow(props) {
    let className = props.type === "next" ? "wnextArrow" : "wprevArrow";
    className += " winnerarrow";
    return (
      <span className={className} onClick={props.onClick}>
        <div
          style={{
            textAlign: "center",
          }}
        >
          {props.type === "next" ? (
            <KeyboardArrowDownIcon style={{ fontSize: "30px" }} />
          ) : (
            <KeyboardArrowUpIcon style={{ fontSize: "30px" }} />
          )}
        </div>
      </span>
    );
  }

  return (
    promotionsWinners.length > 0 && (
      <div className="winnerlist">
        <Box display="flex">
          <Box flexGrow={1}>
            <h1 className={classes.title}>
              <p>{language == "en" ? props.title_en : props.title_ar}</p>
            </h1>
          </Box>
        </Box>

        <Slider
          nextArrow={<Arrow type="next" />}
          prevArrow={<Arrow type="prev" />}
          {...settings}
          ref={(el) => (componentRef = el)}
          className="winnerlistslide"
          dir={language === "en" ? "ltr" : "rtl"}
        >
          {promotionsWinners.map((item, index) => {
            return (
              <Paper
                key={index}
                className={classes.closePaper}
                onClick={() => loadWinner(item)}
              >
                <Grid
                  container
                  alignItems="center"
                  dir={language === "en" ? "ltr" : "rtl"}
                >
                  {/* decode */}
                  <Grid item xs={10} sm={10}>
                    <Typography
                      variant="p"
                      component="h1"
                      className={classes.promotitle}
                      dir={language == "en" ? "rtr" : "rtl"}
                      dangerouslySetInnerHTML={{
                        __html: item
                          ? language === "en"
                            ? isValid(item.promotion.title)
                              ? decode(item.promotion.title)
                              : decode(item.promotion.title)
                            : isValid(item.promotion.title_ar)
                            ? decode(item.promotion.title_ar)
                            : decode(item.promotion.title_ar)
                          : "",
                      }}
                    />
                    <p className={classes.winnername}>
                      {(
                        item.user.first_name +
                        " " +
                        item.user.last_name
                      ).toLowerCase()}
                    </p>

                    <p className={classes.promotick}>
                      {item?.promotion?.winner_details?.winner_announced_on
                        ? moment(
                            item?.promotion?.winner_details?.winner_announced_on
                          ).format("MMMM DD, YYYY")
                        : ""}
                    </p>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <img
                      src={
                        item?.promotion?.winner_details?.winner_image
                          ? item?.promotion?.winner_details?.winner_image
                          : item?.promotion?.files[0].file
                      }
                      width="100%"
                    />
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
        </Slider>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            className={classes.promobtn}
            href={`/winner/details/${winnerId}`}
          >
            {language === "en" ? "See our winners" : "شاهد الفائزين لدينا"}
          </Button>
          <h4 className={classes.winnerp}>
            {language === "en"
              ? "How are winners selected?"
              : "كيف يتم اختيار الفائزين؟"}
            <a
              className={classes.winners}
              href={`https://www.youtube.com/watch?v=5O4NDwfai3o&t=2s`}
            >
              {language === "en" ? " Find out" : " اكتشف"}
            </a>
          </h4>
        </div>
      </div>
    )
  );
}
export default WinnerList;

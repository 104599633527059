import { Types } from "../actions/actionTypes";

const initialState = {
    favourites: []
};

const favourites = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_FAVOURITES:
      return {
        ...state,
        favourites: payload.favourites,
      };
    case Types.CLEAR_FAVOURITES:
      return {
        ...state,
        favourites: [],
      };
    default:
      return state;
  }
};

export default favourites;

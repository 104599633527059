import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import * as actionCreators from "../../../store/actions";
import {
  Box,
  Paper,
  makeStyles,
  Button,
  Typography,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import Slider from "react-slick";
import CurrencyFormat from "react-currency-format";
import calcCurrency from "../../../utils/CalcCurrency";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { isValid, decode } from "js-base64";
import PromotionDialog from "../promotion/PromotionDialog";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ProgressBar from "../../common/ProgressBar";
import ReactPixel from "react-facebook-pixel";
import analyticsEventTracker from "../../common/analyticsEventTracker";
const useStyles = makeStyles((theme) => ({
  closePaper: {
    borderRadius: "18px",
    padding: "20px",
  },
  promotitle: {
    fontFamily: "Almarai-Bold",
    color: theme.palette.text.secondary,
    lineHeight: "1.1rem",
    height: "41px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: { fontSize: "12px" },
  },
  promoPrice: {
    fontFamily: "Almarai-Bold",
    color: theme.palette.text.secondary,
  },
  promobtn: {
    float: "right",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "8px",
    padding: "4px 16px",
    textTransform: "uppercase",
    fontFamily: "Almarai-SemiBold",
    whiteSpace: "nowrap",
    color: theme.palette.text.black,
    [theme.breakpoints.down("sm")]: { fontSize: "12px" },
  },
  title: {
    fontSize: "30px",
    fontFamily: "Almarai-Bold",
    padding: "10px 0%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "23px",
      alignItems: "center",
      padding: "4% 6% 0%",
    },
  },
  ticketTitle: {
    margin: "7px 3px",
    color: "#FFFEFE",
    display: "flex",
    justifyContent: "space-between",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: { fontSize: "12px" },
  },
  highlight: {
    padding: "1.5% 6%",
    [theme.breakpoints.down("sm")]: { padding: "1.5% 0%" },
  },
}));

function ClosingPromotions(props) {
  const classes = useStyles();
  const language = useSelector((state) => state.language);
  const currency = useSelector((state) => state.currency);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);
  const user = useSelector((state) => state.user?.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [promotionDetailsOpen, setPromotionDetailsOpen] = React.useState(false);
  const [proId, setProId] = React.useState("");
  const isMobile = useMediaQuery("(max-width:600px)");
  const gaEventTracker = analyticsEventTracker("Highlights");
  const promotionDetailsHandleOpen = (id) => {
    if (isMobile) {
      navigate(`promotion/details/${id}`);
    } else {
      setProId(id);
      setPromotionDetailsOpen(true);
    }
  };

  const promotionDetailsClose = () => {
    setPromotionDetailsOpen(false);
  };
  const promotionsDetails = useSelector(
    (state) => state.promotions.promotionsDetails
  );
  var userData = "";
  var componentRef = useRef();

  useEffect(() => {
    dispatch(actionCreators.loadHighlightPromotions())
      .then((data) => {})
      .catch(() => {});
  }, [user]);

  var settings = {
    infinite: promotionsDetails?.data?.length > 4 ? true : false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    autoplay: isMobile ? true : false,
    centerMode: isMobile ? true : false,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: promotionsDetails?.data?.length > 4 ? true : false,
        },
      },
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: promotionsDetails?.data?.length > 3 ? true : false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: promotionsDetails?.data?.length > 2 ? true : false,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: promotionsDetails?.data?.length > 1 ? true : false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: promotionsDetails?.data?.length > 1 ? true : false,
        },
      },
    ],
  };
  const handleAddToCart = (event, promotionDetails) => {
    if (isLoggedIn)
      var data = {
        product_id: promotionDetails.product_id,
        promotion_id: promotionDetails.promotion_id,
        product_quantity: promotionDetails.product.product_quantity,
      };
    else
      var data = {
        product_id: promotionDetails.product_id,
        promotion_id: promotionDetails.promotion_id,
        product_quantity: promotionDetails.product.product_quantity,
        product: promotionDetails.product,
        promotion: promotionDetails,
      };
    dispatch(
      actionCreators.loadPage("web", "AddToCart", promotionDetails.promotion_id)
    );
    dispatch(actionCreators.addToCart(data))
      .then(() => {
        toast(t("Added to Cart"));
        ReactPixel.track("AddToCart", {
          content_type: "Product",
          content_ids: promotionDetails.promotion_id,
          value:
            Number(promotionDetails.product.product_quantity) *
            Number(promotionDetails.ticket_price_with_tax),
          currency: "AED",
          Email: isLoggedIn ? user.email : "Guest",
          External_id: isLoggedIn ? user.user_id : "Guest",
        });
        gaEventTracker("AddToCart", isLoggedIn ? user.user_id : "Guest");
        navigate("/cart");
      })
      .catch(() => {});
  };

  const getBarColor = (ticketNow, totalTicket) => {
    if ((ticketNow / totalTicket) * 100 <= 25) {
      return "linear-gradient(0deg, #4dde89, #d1ffe4)";
    } else if (
      (ticketNow / totalTicket) * 100 > 25 &&
      (ticketNow / totalTicket) * 100 <= 75
    ) {
      return "linear-gradient(0deg, #EF942C, #F9D3A2)";
    } else if ((ticketNow / totalTicket) * 100 > 75) {
      return "linear-gradient(0deg, #E74C3C, #FFB3AB)";
    }
  };

  const getPercentage = (ticketNow, totalTicket) => {
    return (ticketNow / totalTicket) * 100;
  };

  function Arrow(props) {
    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " arrow";
    return (
      <span className={className} onClick={props.onClick}>
        <div
          style={{
            position: "relative",
            top: "calc(45%)",
            textAlign: props.type === "next" ? "left" : "right",
          }}
        >
          {props.type === "next" ? (
            <ChevronRightIcon style={{ fontSize: "50px" }} />
          ) : (
            <ChevronLeftIcon style={{ fontSize: "50px" }} />
          )}
        </div>
      </span>
    );
  }
  return (
    promotionsDetails.data !== undefined &&
    promotionsDetails.data.length > 0 && (
      <div className={classes.highlight} id="highlight">
        <Box display="flex" className={classes.title}>
          <Box flexGrow={1}>
            <p>{language == "en" ? props.title_en : props.title_ar}</p>
          </Box>
        </Box>
        <Slider
          nextArrow={<Arrow type="next" />}
          prevArrow={<Arrow type="prev" />}
          {...settings}
          ref={(el) => (componentRef = el)}
          style={{ margin: "0%" }}
        >
          {promotionsDetails.data.map((item, index) => {
            var barColor = getBarColor(
              item?.sold_out_tickets,
              item?.total_tickets_alloted
            );
            var percentage = getPercentage(
              item?.sold_out_tickets,
              item?.total_tickets_alloted
            );
            return (
              <Paper key={index} className={classes.closePaper}>
                <div>
                  <Typography
                    variant="p"
                    component="p"
                    className={classes.promotitle}
                    dir={language == "en" ? "rtr" : "rtl"}
                    dangerouslySetInnerHTML={{
                      __html: item
                        ? language === "en"
                          ? isValid(item?.draw_detail)
                            ? decode(item?.draw_detail)
                            : decode(item?.draw_detail)
                          : isValid(item?.draw_detail_ar)
                          ? decode(item?.draw_detail_ar)
                          : decode(item?.draw_detail_ar)
                        : "",
                    }}
                  />

                  <img
                    alt=""
                    onClick={(e) =>
                      promotionDetailsHandleOpen(item?.promotion_id)
                    }
                    src={
                      parseInt(item.sold_out_tickets) <
                        parseInt(item.total_tickets_alloted) ||
                      item.draw_date <= new Date()
                        ? item?.draw_image
                        : item?.soldout_image
                        ? item?.soldout_image
                        : item?.draw_image
                    }
                    style={{ width: "100%", padding: "0px 10%" }}
                  />

                  <p className={classes.ticketTitle}>
                    <span>
                      {language === "en"
                        ? "Tickets Sold"
                        : "عدد التذاكر المباعة"}
                    </span>
                    <span style={{ float: "right" }}>
                      {item?.sold_out_tickets} / {item?.total_tickets_alloted}
                    </span>
                  </p>

                  <ProgressBar bgcolor={barColor} completed={percentage} />
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <CurrencyFormat
                      value={
                        currency.value
                          ? calcCurrency(
                              currency.value,
                              item.ticket_price_with_tax
                            )
                          : item.ticket_price_with_tax
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={` ${currency.label} `}
                      renderText={(value) => (
                        <div className={classes.promoPrice}> {value} </div>
                      )}
                    />
                    <Button
                      variant="contained"
                      onClick={(e) => handleAddToCart(e, item)}
                      className={classes.promobtn}
                    >
                      {t("Add to Cart")}
                    </Button>
                  </p>
                </div>
              </Paper>
            );
          })}
        </Slider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Dialog
          open={promotionDetailsOpen}
          fullWidth={true}
          maxWidth={"lg"}
          style={{ borderRadius: "27px" }}
          onClose={promotionDetailsClose}
        >
          <DialogContent
            style={{
              padding: "0px",
              overflowY: "auto",
              height: "81vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PromotionDialog
              promotionId={proId}
              dialog={true}
              promotionDetailsCloseCallback={promotionDetailsClose}
            />
          </DialogContent>
        </Dialog>
      </div>
    )
  );
}
export default ClosingPromotions;

import thunk from "redux-thunk";
import { createStore, applyMiddleware, combineReducers, compose } from "redux";

import auth from "./reducers/auth";
import banners from "./reducers/banners";
import ads from "./reducers/ads";
import sections from "./reducers/sections";
import promotions from "./reducers/promotions";
import carts from "./reducers/carts";
import favourites from "./reducers/favourites";
import products from "./reducers/products";
import news from "./reducers/news";
import faq from "./reducers/faq";
import language from "./reducers/language";
import myTickets from "./reducers/myTickets";
import loading from "./reducers/loading";
import loginDialog from "./reducers/loginDialog";
import menuDialog from "./reducers/menuDialog";
import currency from "./reducers/currency";

const reducer = combineReducers({
  user: auth,
  loading,
  loginDialog,
  menuDialog,
  banners: banners,
  ads: ads,
  sections: sections,
  promotions: promotions,
  carts: carts,
  favourites: favourites,
  products: products,
  news: news,
  faq: faq,
  language: language,
  myTickets: myTickets,
  currency,
});

const configureStore = () => {
  return createStore(
    reducer,
    compose(
      applyMiddleware(thunk)
      // window.__REDUX_DEVTOOLS_EXTENSION__ &&
      //   window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );
};

export const store = configureStore();

import { makeStyles } from "@material-ui/core/styles";
import level_details_background from "../../../assets/image/level/level_details_background.svg";

export default makeStyles((theme) => ({
  container: {
    minHeight: "70vh",
    margin: "10vh 0px",
  },
  panel: {
    background: theme.palette.background.default,
    borderRadius: "30px",
    minWidth: "1215px",
    padding: "35px",
    [theme.breakpoints.down("md")]: {
      minWidth: "100%",
    },
  },
  username: {
    color: "white",
    textAlign: "center",
    margin: "6px",
    fontFamily: "Almarai-SemiBold",
    fontSize: "26px",
    marginBottom: "0px",
  },
  text: {
    textAlign: "center",
    color: "white",
    margin: "57px 0px",
    fontFamily: "Almarai-SemiBold",
    fontSize: "30px",
  },
  userLevel: {
    textAlign: "center",
    color: "white",
    margin: "0px 0px",
    fontFamily: "Almarai-SemiBold",
    fontSize: "14px",
  },
  levelDetails: {
    width: "342px",
    height: "178px",
    background: `url(${level_details_background}) no-repeat`,
    padding: "34px",
    position: "relative",
    marginBottom: "15px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  badge: {
    position: "absolute",
    top: "34px",
    right: "30px",
  },
  badgeDescription: {
    width: "195px",
  },
  Progressbox: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  arrow: {
    width: "60px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  avatar: {
    width: "100px",
    height: "100px",
    fontSize: "32px",
    alignItems: "center",
  },
  bronze: {
    color: "#AD875C",
  },
  gold: {
    color: "#D3D9DD",
  },
  silver: {
    color: "#E8DD4F",
  },
}));

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    padding: "114px 0px",
    [theme.breakpoints.down("sm")]: { padding: "10px 0px" },
  },
  overlay: {
    inset: "0",
    position: "absolute",
    background: "linear-gradient( 360deg,#353434, transparent)",
    borderRadius: "24px",
  },
}));

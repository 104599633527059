import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  card: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    margin: "20px 0px",
    borderRadius: "27px",
    padding: "31px 20px",
  },
  divider: {
    borderColor: "#707070",
  },
  imageSide: {
    justifyContent: "space-between",
    borderLeft: "1px solid #70707020",
    paddingLeft: "30px",
    marginBottom: "8px",
    flexDirection: "column",
    overflow: "clip",
  },
  spinner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",
    flexDirection: "column",
    "& .MuiButtonGroup-groupedContainedPrimary:not(:last-child)": {
      borderColor: theme.palette.secondary.main,
    },

    [theme.breakpoints.down("sm")]: {
      // flexDirection: "row",
      // width: "100%",
      paddingLeft: "11%",
      justifyContent: "space-around",
    },
  },
  image: {
    width: "366px",
    height: "204px",
  },
  input: {
    border: "none",
    width: "40px",
    color: "white",
    background: "none",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "18px",
  },
  button: {
    color: `${theme.palette.primary.main} !important`,
    background: theme.palette.secondary.main,
    border: "none",
    width: "33px",
    height: "33px",
    minWidth: "33px",
    borderRadius: "50%",
    padding: "0px",
    "&:hover": {
      background: theme.palette.secondary.main,
    },
  },
  ticketQtyBox: {
    background: theme.palette.secondary.main,
    display: "inline-block",
    padding: "10px",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    textAlign: "center",
  },
  price: {
    fontSize: "24px",
    fontFamily: "Almarai-SemiBold",
    [theme.breakpoints.down("sm")]: { fontSize: "15px" },
  },
}));

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "20px",
    borderRadius: "20px",
    margin: "15px 0px",
  },
  title: {
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  img: {
    [theme.breakpoints.down("sm")]: { width: "100%" },
  },
}));

import { Card, Grid, Typography, Box, Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./cartItem._style.js";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";
import ticekt from "../../../../../src/assets/image/ticekt.svg";
import * as actionCreators from "../../../../store/actions";
import { isValid, decode } from "js-base64";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ReactPixel from "react-facebook-pixel";
import analyticsEventTracker from "../../../common/analyticsEventTracker";
function CartItem({
  product,
  price,
  productImage,
  prizeImage,
  qty = 1,
  promotion_id,
  promotion,
  tickts,
  promotionCharity,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currency = useSelector((state) => state?.currency);
  const language = useSelector((state) => state?.language);
  const cartSummary = useSelector((state) => state?.cart?.summary);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);
  const user = useSelector((state) => state.user?.user);
  const gaEventTracker = analyticsEventTracker("Cart");
  const handleChangeQuantity = (qty) => {
    if (qty < 0) return;
    dispatch(actionCreators.loading(true));
    var data = {
      product_id: product.product_id,
      promotion_id: promotion_id,
      product_quantity: qty.toString(),
    };
    dispatch(actionCreators.loadPage("web", "AddToCart", promotion_id));
    dispatch(actionCreators.addToCart(data, promotionCharity.toString()))
      .then((res) => {
        if (qty > 0) {
          ReactPixel.track("AddToCart", {
            content_type: "Product",
            content_ids: promotion_id,
            value: qty * price,
            currency: "AED",
            Email: isLoggedIn ? user.email : "Guest",
            External_id: isLoggedIn ? user.user_id : "Guest",
          });
          gaEventTracker("AddToCart", isLoggedIn ? user.user_id : "Guest");
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <Card className={classes.card}>
      <Grid container>
        <Grid
          md={5}
          xs={3}
          item
          style={{
            display: "flex",
            justifyContent: "left",
          }}
        >
          <img
            src={productImage}
            alt=""
            style={{ width: "100%", objectFit: "contain" }}
          />
        </Grid>
        <Grid md={6} xs={7} container className={classes.imageSide}>
          <Box style={{ marginBottom: "20px" }}>
            <Typography
              variant="body2"
              style={{
                fontSize: "16px",
                fontFamily: "Almarai-SemiBold",
                paddingBottom: "2%",
              }}
              dir={language == "en" ? "rtr" : "rtl"}
              dangerouslySetInnerHTML={{
                __html: product
                  ? language === "en"
                    ? isValid(product?.title)
                      ? decode(product?.title)
                      : decode(product?.title)
                    : isValid(product?.title_ar)
                    ? decode(product?.title_ar)
                    : decode(product?.title_ar)
                  : "",
              }}
            />
            <Typography
              variant="span"
              component="span"
              className={classes.promotitle2}
              dir={language == "en" ? "rtr" : "rtl"}
              dangerouslySetInnerHTML={{
                __html: promotion
                  ? language === "en"
                    ? isValid(promotion.title)
                      ? decode(promotion.title)
                      : decode(promotion.title)
                    : isValid(promotion.title_ar)
                    ? decode(promotion.title_ar)
                    : decode(promotion.title_ar)
                  : "",
              }}
            />
          </Box>

          <Box
            display="flex"
            alignItems="center"
            style={{ marginBottom: "35px" }}
          >
            <img src={ticekt} alt="" />

            <Typography
              variant="body2"
              style={{
                fontSize: "14px",
                margin: "0px 5px",
                fontFamily: "Almarai-Light",
              }}
            >{` ${tickts} ${t("Ticket(s) if you donate")} `}</Typography>
          </Box>

          <Typography variant="h3" color="secondary" className={classes.price}>
            <CurrencyFormat
              value={(qty * price).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={" " + (currency.label ? currency.label : "AED") + " "}
              style={{ margin: "10px 0px" }}
              renderText={(value) => <div>{value}</div>}
            />
          </Typography>
        </Grid>
        <Grid item md={1} xs={1} className={classes.spinner}>
          <Button
            className={classes.button}
            onClick={() => handleChangeQuantity(Number(qty) + 1)}
          >
            <AddIcon />
          </Button>

          <input type="text" className={classes.input} value={qty} />
          <Button
            className={classes.button}
            onClick={() => handleChangeQuantity(Number(qty) - 1)}
          >
            <RemoveIcon />
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}

export default CartItem;

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "15px",
    padding: "13px",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "17px",
  },
  useCard: {
    backgroundColor: theme.palette.background.default,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
  },
  input: {
    width: "60px",
    height: "35px",
    marginTop: "0px",
    padding: "0px 5px",
    textAlign: "center",
  },
}));

import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  container: {
    padding: "10vh 0px",
    [theme.breakpoints.down("sm")]: { padding: "0px" },
  },
  panel: {
    backgroundColor: theme.palette.primary.main,
    minHeight: "70vh",
    color: "white",
    borderRadius: "24px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: { borderRadius: "0px" },
  },
  detailsGrid: {
    padding: "6.6% 0px 3% 0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: { padding: "20px" },
  },
  profileGrid: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0px 30px 30px 40px",
  },
  selector: {
    backgroundColor: theme.palette.background.lightgray,
    color: "white",
    padding: "13px 30px",
    borderRadius: "16px",
    margin: "10px",
    "&:hover": {
      backgroundColor: theme.palette.background.lightgray,
    },
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
    color: "black",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  profileHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "150px",
  },
  avatar: {
    margin: "0px 10px",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
    marginBottom: "20px",
  },
  cardBox: {
    display: "flex",
    alignItems: "center",
  },
  pointsText: {
    border: `1px solid ${theme.palette.secondary.main}`,
    padding: "2px 7px",
  },
  text: {
    color: "white",
  },
  settings: {
    marginTop: "20px",
    marginBottom: "20px",
    background: theme.palette.background.lightgray,
    borderRadius: "22px",
    width: "85%",
    overflow: "hidden",
    "& .MuiList-padding": {
      padding: "0px",
    },
  },
  arrow: {
    color: "white",
  },
  li: {
    "&:hover": {
      backgroundColor: theme.palette.text.darkgray,
    },
    "& .MuiTypography-root ": {
      fontSize: "14px",
      fontFamily: "Almarai-SemiBold",
    },
    "& .MuiListItemIcon-root ": {
      minWidth: "0px",
    },
  },
  selectedLi: {
    backgroundColor: theme.palette.text.darkgray,
  },
  btn: {
    backgroundColor: theme.palette.background.lightgray,
    color: "white",
    borderRadius: "16px",
    marginBottom: "10%",
    fontFamily: "Almarai-SemiBold",
    fontSize: "16px",
    marginTop: "14%",
    "&:hover": {
      backgroundColor: theme.palette.text.darkgray,
    },
  },
  // dateSelector: {
  //   width: "98%",
  //   [theme.breakpoints.down("sm")]: {
  //     width: "89%",
  //   },
  //   "& .MuiInput-root": {
  //     margin: "1px",
  //   },
  //   "& .MuiInputLabel-root": {
  //     padding: "0px 20px",
  //   },
  // },
  link: {
    color: theme.palette.secondary.main,
  },
}));

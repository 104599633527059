import { Types } from "../actions/actionTypes";

const initialState = {
    faq: []
};

const faq = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_FAQ:
      return {
        ...state,
        faq: payload.faq,
      };
    case Types.CLEAR_FAQ:
      return {
        ...state,
        faq: [],
      };
    default:
      return state;
  }
};

export default faq;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Grid, Hidden } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import loading from "../../../store/actions/loading";
import { resendOtp, verifyOtp } from "../../../store/actions/auth";
import registrationApi from "../../../Api/auth/registration";
import useStyles from "./style";
import logo from "../../../../src/assets/image/logo.svg";
import logo_vip from "../../../../src/assets/image/logo_vip.svg";
import { validateEmail } from "../../../utils/validateEmail";
import * as actionCreators from "../../../store/actions";
import { getUserLocalCarts } from "../../../services/authService";
import RegisterAsGuest from "./RegisterAsGuest";
import PayAsGuest from "./PayAsGuest";
import ReactPixel from "react-facebook-pixel";
import analyticsEventTracker from "../../common/analyticsEventTracker";
const validConsecutiveChar = new RegExp(
  "^(?!\\b([a-zA-Z0-9])\\1\\1\\1\\1+\\b)"
);
function Guest() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.user?.user);
  const isLoggedIn = useSelector((state) => state.user?.isLoggedIn);
  const [solvedCaptcha, setSolvedCaptcha] = useState(null);
  const [token, setToken] = useState("");
  const [isArabicName, setIsArabicName] = useState(false);
  const [isVerificationStage, setIsVerificationStage] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [isRegistrationDone, setIsRegistrationDone] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const gaEventTracker = analyticsEventTracker("Guest Registration");
  var prevCarts = JSON.parse(getUserLocalCarts());

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [User, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    country_code: "",
    mobile: "",
    verify_code: "",
  });

  const verifyCaptcha = () => setSolvedCaptcha(true);

  const handleContinue = () => {
    if (User.mobile === "" || User.verify_code == "")
      return toast(
        t("please fill the fields and verify your number to continue")
      );

    dispatch(verifyOtp({ mobile: User.mobile, otp_code: User.verify_code }))
      .then((res) => {
        if (res.data.verified_otp) setIsUserVerified(true);
        prevCarts.map((product) => {
          var data = {
            product_id: product.product_id,
            promotion_id: product.promotion_id,
            product_quantity: product.product_quantity.toString(),
          };
          dispatch(actionCreators.addToCart(data)).then((res) => {});
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const register = () => {
    if (isRegistrationDone || isLoggedIn) {
      // user already registered resend the verification code
      dispatch(
        resendOtp({
          token: token,
          mobile: User.mobile,
          country_code: User.country_code,
        })
      );
    } else {
      // register the user and send verification code
      if (
        User.first_name === "" ||
        User.last_name === "" ||
        User.email === "" ||
        User.country_code === "" ||
        User.mobile === ""
      ) {
        return toast(t("Kindly fill all the mandatory fields"));
      }
      if (!validateEmail(User.email)) {
        // handle email address is not valid
        return toast(t("Email not valid"));
      }
      if (
        !validConsecutiveChar.test(User.first_name) ||
        !validConsecutiveChar.test(User.last_name)
      ) {
        return toast("Remove Consecutive character");
      }
      if (User.first_name.length < 2)
        return toast(t("First name should be more than 4 character"));
      if (User.last_name.length < 2)
        return toast(t("Last name should be more than 4 character"));
      if (!solvedCaptcha) {
        toast(t("Kindly, solve ` I'm not robot challenge `"));
        return;
      }
      dispatch(loading(true));
      // call the api
      registrationApi({
        first_name: User.first_name,
        last_name: User.last_name,
        email: User.email,
        password: User.password,
        country_code: User.country_code,
        mobile: User.mobile,
      }).then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            ReactPixel.track("CompleteRegistration", {
              user_id: res.data.data.user_id,
              user_type: "Guest",
            });
            gaEventTracker("CompleteRegistration", res.data.data.user_id);
            setToken(res.data.token);
            dispatch(loading(false));
            setIsRegistrationDone(true);
          } else {
            toast(res.data.message);
            // if (
            //   res.data.message ===
            //   "Already registed account with this mobile number"
            // ) {
            //   toast(t("Already registed account with this mobile number"));
            // } else {
            //   toast(t("error, please try again"));
            // }
          }
        } else {
          toast("error, please try again");
        }
        dispatch(loading(false));
      });
    }
  };

  const handleInput = (e) => {
    let { name, value } = e.target;
    if (!validConsecutiveChar.test(value)) {
      toast("Don't Enter Consecutive character");
    }
    if (name === "first_name" || name === "last_name") {
      if (value.search(/[^A-Za-z]/) !== -1) {
        value = value.replace(/[^A-Za-z]/gi, "");
        setIsArabicName(true);
      } else {
        setIsArabicName(false);
      }
    }
    setUser((prev) => ({ ...prev, [name]: value }));
  };
  const handleSelect = (name, value) => {
    console.log({ name, value });
    setUser((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Grid container className={classes.container} justifyContent="center">
      <Grid container md={8} xs={12} className={classes.panel}>
        <Hidden smDown>
          <Grid
            container
            md={4}
            justifyContent="center"
            style={{ background: "#303030" }}
          >
            <img
              src={user?.switched !== "1" ? logo : logo_vip}
              justifyContent="center"
              alt=""
              width="50%"
            />
          </Grid>
        </Hidden>
        <Grid container md={8} sm={12} justifyContent="center">
          {isLoggedIn ? (
            <PayAsGuest classes={classes} />
          ) : (
            <RegisterAsGuest
              classes={classes}
              User={User}
              handleSelect={handleSelect}
              handleInput={handleInput}
              isArabicName={isArabicName}
              showPassword={showPassword}
              handleClickShowPassword={handleClickShowPassword}
              handleMouseDownPassword={handleMouseDownPassword}
              isRegistrationDone={isRegistrationDone}
              verifyCaptcha={verifyCaptcha}
              register={register}
              handleContinue={handleContinue}
            />
          )}
        </Grid>
      </Grid>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Grid>
  );
}

export default Guest;

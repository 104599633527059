import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../footer/footer";
import Header from "../header/header";
import { Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

const DefaultLayout = () => {
  // this is a smart way to get the height of the header after rendering and put it in empty dev, thank me later :)
  // const [headerHeight, setHeaderHeight] = useState();
  return (
    <>
      {/* <Header setHeaderHeight={setHeaderHeight} /> */}
      {/* <Box style={{ paddingTop: `${headerHeight}px` }}></Box> */}
     <Outlet />
      {/*  <Footer /> */}
    </>
  );
};

export default DefaultLayout;
